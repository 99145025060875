import React, {
	Fragment,
	useState,
	useEffect,
	useImperativeHandle,
	useRef,
} from 'react';
import { useReactive, useBoolean } from 'ahooks';
import { useTranslation } from 'react-i18next';
// import ICONerror from '../../../assets/icons/error.svg';
import ICONinfo from '../../../assets/icons/info-i.svg';
import { countryData } from '../../../locales/countryphone';
import { Popover, Input } from 'antd';
import '../../../../node_modules/antd/lib/popover/style/index.css';

const SupportForm = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const [state, { setTrue, setFalse }] = useBoolean(false);
	const [countryDrop, setCountryDrop] = useState([]);
	const [open, setOpen] = useState(false);
	const [emailVal, setEmailVal] = useState('');
	const [emailData, setEmailData] = useState([]);
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	let temp = useRef(null);

	useEffect(() => {
		countryData.sort((a, b) => {
			if (a.countryName < b.countryName) {
				return -1;
			}
			if (a.countryName > b.countryName) {
				return 1;
			}
			return 0;
		});
		let country = [];
		for (let i = 0; i < countryData.length; i++) {
			let obj = {};
			obj.value = countryData[i].phoneCode;
			obj.label = `${countryData[i].countryName} +${countryData[i].phoneCode}`;
			country.push(obj);
		}
		setCountryDrop(country);
		setEmailData(country);
	}, []);

	useImperativeHandle(ref, () => ({
		supportForm: supportForm,
		countryDrop: countryDrop,
		showErrorTip: () => {
			showErrorTip();
		},
		InputError: (type, num) => {
			showErrorTip();
			if (type === 'firstname') {
				if (num === 0) {
					document.querySelector(`.${type}`).classList.add('gotError');
					document.querySelector(`.${type}`).classList.remove('isFilled');
				} else {
					document.querySelector(`.${type}`).classList.add('gotError');
					document.querySelector(`.${type}`).classList.remove('isFilled');
					const listItem = document.querySelector(`.${type}`).parentNode;
					const error = listItem.getElementsByTagName('span')[0];
					if (error) {
						error.classList.add('visible');
					}
				}
			} else if (type === 'lastname') {
				if (num === 0) {
					document.querySelector(`.${type}`).classList.add('gotError');
					document.querySelector(`.${type}`).classList.remove('isFilled');
				} else {
					document.querySelector(`.${type}`).classList.add('gotError');
					document.querySelector(`.${type}`).classList.remove('isFilled');
					const listItem = document.querySelector(`.${type}`).parentNode;
					const error = listItem.getElementsByTagName('span')[0];
					if (error) {
						error.classList.add('visible');
					}
				}
			} else if (type === 'email') {
				if (num === 0) {
					document.querySelector(`.${type}`).classList.add('gotError');
					document.querySelector(`.${type}`).classList.remove('isFilled');
				} else {
					document.querySelector(`.${type}`).classList.add('gotError');
					document.querySelector(`.${type}`).classList.remove('isFilled');
					const listItem = document.querySelector(`.${type}`).parentNode;
					const error = listItem.getElementsByTagName('span')[0];
					if (error) {
						error.classList.add('visible');
					}
				}
			} else if (type === 'subject') {
				if (num === 0) {
					document.querySelector(`.${type}`).classList.add('gotError');
					document.querySelector(`.${type}`).classList.remove('isFilled');
				} else {
					document.querySelector(`.${type}`).classList.add('gotError');
					document.querySelector(`.${type}`).classList.remove('isFilled');
					const listItem = document.querySelector(`.${type}`).parentNode;
					const error = listItem.getElementsByTagName('span')[0];
					if (error) {
						error.classList.add('visible');
					}
				}
			} else if (type === 'telephoneNumber') {
				if (num === 0) {
					document.querySelector('.telephoneNumber').classList.add('gotError');
					document
						.querySelector('.telephoneNumber')
						.classList.remove('isFilled');
				} else {
					document.querySelector('.telephoneNumber').classList.add('gotError');
					document
						.querySelector('.telephoneNumber')
						.classList.remove('isFilled');
					const listItem =
						document.querySelector('.telephoneNumber').parentNode;
					const error = listItem.getElementsByTagName('span')[0];
					if (error) {
						error.classList.add('visible');
					}
				}
			} else if (type === 'contryinput') {
				if (num === 0) {
					document.querySelector('.contryinput').classList.add('gotError');
					document.querySelector('.contryinput').classList.remove('isFilled');
				} else {
					setIsError(true);
					document.querySelector('.contryinput').classList.remove('isFilled');
					document.querySelector('.contryinput').classList.add('gotError');
					setErrorMessage(t('Support.notmatched'));
				}
			} else {
				document.querySelector(`.${type}`).classList.add('gotError');
				document.querySelector(`.${type}`).classList.remove('isFilled');
				const listItem = document.querySelector(`.${type}`).parentNode;
				const error = listItem.getElementsByTagName('span')[num];
				if (error) {
					error.classList.add('visible');
				}
			}
		},
		clearForm: () => {
			supportForm.txbFirstName = '';
			supportForm.txbLastName = '';
			supportForm.txbMailAddress = '';
			supportForm.txaSubject = '';
			supportForm.txaMessage = '';
			supportForm.telephoneNumber = '';
			supportForm.countrycode = '';
			document.querySelector('.firstname').classList.remove('isFilled');
			document.querySelector('.firstname').classList.remove('gotError');
			document.querySelector('.lastname').classList.remove('isFilled');
			document.querySelector('.lastname').classList.remove('gotError');
			document.querySelector('.email').classList.remove('isFilled');
			document.querySelector('.email').classList.remove('gotError');
			document.querySelector('.subject').classList.remove('isFilled');
			document.querySelector('.subject').classList.remove('gotError');
			document.querySelector('.message').classList.remove('isFilled');
			document.querySelector('.message').classList.remove('gotError');
			document.querySelector('.captcha').classList.remove('isFilled');
			document.querySelector('.captcha').classList.remove('gotError');
			document.querySelector('.telephoneNumber').classList.remove('isFilled');
			document.querySelector('.telephoneNumber').classList.remove('gotError');
			document.querySelector('.contryinput').classList.remove('isFilled');
			document.querySelector('.contryinput').classList.remove('gotError');
			let errInput = document.querySelectorAll('.errInput');
			for (let i = 0; i < errInput.length; i++) {
				errInput[i].classList.remove('visible');
			}
			setFalse();
			scrollToptimer();
			//
			setEmailVal('');
			setIsError(false);
		},
	}));

	const supportForm = useReactive({
		txbFirstName: '',
		txbLastName: '',
		txbMailAddress: '',
		txaSubject: '',
		txaMessage: '',
		telephoneNumber: '',
		countrycode: '',
	});

	const inputChangeHandler = (e, key) => {
		if (key === 'txaSubject') {
			if (e.target.value.length > 50) {
				const listItem = document.querySelector('.subject').parentNode;
				const error = listItem.getElementsByTagName('span')[0];
				if (error) {
					error.classList.add('visible');
				}
				return;
			}
		}
		setFalse();
		e.target.classList.remove('gotError');
		// const error1 = e.target.parentNode.getElementsByTagName('span')[0];
		// if (error1) {
		// 	error1.classList.remove('visible');
		// }
		const error2 = e.target.parentNode.getElementsByTagName('span')[0];
		if (error2) {
			error2.classList.remove('visible');
		}
		// e.target.parentNode
		// 	.getElementsByTagName('span')[0]
		// 	.classList.remove('visible');
		// e.target.parentNode
		// 	.getElementsByTagName('span')[1]
		// 	.classList.remove('visible');
		supportForm[key] = e.target.value;
		//
		if (e.target.value !== '') {
			e.target.classList.add('isFilled');
		} else {
			e.target.classList.remove('isFilled');
		}
	};

	const hideError = () => {
		const listItem = document.querySelector('.subject').parentNode;
		const error = listItem.getElementsByTagName('span')[0];
		if (error) {
			error.classList.remove('visible');
		}
	};

	const scrollToptimer = () => {
		const timer = setInterval(function () {
			var top = document.body.scrollTop || document.documentElement.scrollTop;
			var speed = top / 4;
			if (document.body.scrollTop !== 0) {
				document.body.scrollTop -= speed;
			} else {
				document.documentElement.scrollTop -= speed;
			}
			if (top === 0) {
				clearInterval(timer);
			}
		}, 10);
	};

	const showErrorTip = () => {
		scrollToptimer();
		setTrue();
	};

	const popoverRender = () => {
		return (
			<div className="emailwrap">
				{emailData.map((item, index) => {
					return (
						<span
							className="emailitem"
							key={index}
							onClick={() => clickDomain(item.label)}
						>
							{item.label}
						</span>
					);
				})}
			</div>
		);
	};

	const clickDomain = (label) => {
		setEmailVal(label);
		temp.current = label;
		setOpen(false);
		setIsError(false);
		supportForm.countrycode = label;
		document.querySelector('.contryinput').classList.add('isFilled');
		document.querySelector('.contryinput').classList.remove('gotError');
	};

	const inputOnchange = (e) => {
		const newValue = e.target.value;
		supportForm.countrycode = e.target.value;
		setEmailVal(newValue);
		temp.current = newValue;
		if (newValue !== '') {
			const tempData = [];
			for (let i = 0; i < countryDrop.length; i++) {
				if (
					countryDrop[i].label.toLowerCase().includes(newValue.toLowerCase())
				) {
					const obj = {};
					obj.value = countryDrop[i].value;
					obj.label = countryDrop[i].label;
					tempData.push(obj);
				}
			}
			setEmailData(tempData);
			if (tempData.length === 0) {
				setOpen(false);
				// setIsError(true);
				// document.querySelector('.contryinput').classList.remove('isFilled');
				// document.querySelector('.contryinput').classList.add('gotError');
				// setErrorMessage(t('Support.notmatched'));
			} else {
				setOpen(true);
				setIsError(false);
			}
		} else {
			setOpen(true);
			setIsError(false);
			//
			countryData.sort((a, b) => {
				if (a.countryName < b.countryName) {
					return -1;
				}
				if (a.countryName > b.countryName) {
					return 1;
				}
				return 0;
			});
			let country = [];
			for (let i = 0; i < countryData.length; i++) {
				let obj = {};
				obj.value = countryData[i].phoneCode;
				obj.label = `${countryData[i].countryName} +${countryData[i].phoneCode}`;
				country.push(obj);
			}
			setEmailData(country);
			//
			document.querySelector('.contryinput').classList.remove('isFilled');
			document.querySelector('.contryinput').classList.remove('gotError');
		}
	};

	const inputOnBlur = () => {
		setTimeout(() => {
			setOpen(false);
			if (temp.current && temp.current !== '') {
				let find = countryDrop.find((item) => {
					return item.label === temp.current;
				});
				if (find) {
					setIsError(false);
					document.querySelector('.contryinput').classList.add('isFilled');
					document.querySelector('.contryinput').classList.remove('gotError');
				} else {
					// setIsError(true);
					// document.querySelector('.contryinput').classList.remove('isFilled');
					// document.querySelector('.contryinput').classList.add('gotError');
					// setErrorMessage(t('Support.notmatched'));
				}
			}
		}, 300);
	};

	const inputOnFocus = () => {
		if (temp.current && temp.current !== '') {
			const tempData = [];
			for (let i = 0; i < countryDrop.length; i++) {
				if (
					countryDrop[i].label
						.toLowerCase()
						.includes(temp.current.toLowerCase())
				) {
					const obj = {};
					obj.value = countryDrop[i].value;
					obj.label = countryDrop[i].label;
					tempData.push(obj);
				}
			}
			setEmailData(tempData);
			if (tempData.length === 0) {
				setOpen(false);
				// setIsError(true);
				// document.querySelector('.contryinput').classList.remove('isFilled');
				// document.querySelector('.contryinput').classList.add('gotError');
				// setErrorMessage(t('Support.notmatched'));
			} else {
				setOpen(true);
				setIsError(false);
			}
		} else {
			setEmailData(countryDrop);
			setOpen(true);
			setIsError(false);
		}
	};

	return (
		<div ref={ref}>
			{state ? (
				<div className="errorMessage">
					<span className="img">
						<img className="ICONinfo" alt="Error" src={ICONinfo} />
					</span>
					<span className="title">{t('Support.Pleasefill')}</span>
				</div>
			) : (
				<Fragment />
			)}
			<ul className="lstSupport">
				<li className="half">
					<h4 className="tagInput">{t('Support.FirstName')}:</h4>
					<input
						type="text"
						value={supportForm.txbFirstName}
						onChange={(e) => inputChangeHandler(e, 'txbFirstName')}
						className="txbSupport firstname"
						placeholder={t('Support.Pleaseenteryourfirstname')}
					/>
					<span className="errInput">{t('Error.validfirstname')}</span>
				</li>
				<li className="half">
					<h4 className="tagInput">{t('Support.LastName')}:</h4>
					<input
						type="text"
						value={supportForm.txbLastName}
						onChange={(e) => inputChangeHandler(e, 'txbLastName')}
						className="txbSupport lastname"
						placeholder={t('Support.Pleaseenteryourlastname')}
					/>
					<span className="errInput">{t('Error.validlastname')}</span>
				</li>
				<li>
					<h4 className="tagInput">{t('Support.EMail')}:</h4>
					<input
						type="email"
						value={supportForm.txbMailAddress}
						onChange={(e) => inputChangeHandler(e, 'txbMailAddress')}
						className="txbSupport email"
						placeholder={t('Support.Pleaseenteranemailaddress')}
					/>
					<span className="errInput">{t('Error.validemail')}</span>
				</li>
				<li>
					<h4 className="tagInput">{t('Support.ContactNumber')}</h4>
					<div className="countryphone">
						<Popover
							content={popoverRender()}
							overlayClassName="selectemailtooltip"
							title={null}
							trigger="click"
							placement="bottomLeft"
							open={open}
							overlayInnerStyle={{
								maxHeight: '300px',
								overflow: 'auto',
								width: '280px',
							}}
						>
							<Input
								className="txbSupport contryinput"
								value={emailVal}
								onChange={inputOnchange}
								onBlur={inputOnBlur}
								onFocus={inputOnFocus}
								placeholder={t('Support.countrycode')}
								autoComplete="off"
							/>
							{isError ? (
								<span className="errInput visible">{errorMessage}</span>
							) : (
								<Fragment />
							)}
						</Popover>
						<div className="telephoneNumberwrap">
							<input
								value={supportForm.telephoneNumber}
								onChange={(e) => inputChangeHandler(e, 'telephoneNumber')}
								className="txbSupport telephoneNumber"
								placeholder={t('Support.ContactNumber')}
							/>
							<span className="errInput">{t('Support.Shouldnumbers')}</span>
						</div>
					</div>
				</li>
			</ul>

			<h3>{t('Support.TechnicalSupport')}</h3>
			<ul className="lstSupport">
				<li>
					<h4 className="tagInput">{t('Support.Subject')}</h4>
					<textarea
						value={supportForm.txaSubject}
						onChange={(e) => inputChangeHandler(e, 'txaSubject')}
						onBlur={() => hideError()}
						className="txaSupport subject"
						placeholder={t('Support.Pleaseprovide')}
					/>
					<span className="errInput">{t('Detail.Character50')}</span>
				</li>
				<li>
					<h4 className="tagInput">{t('Support.Message')}</h4>
					<p className="information">
						{t('Support.Tosupportyoumoreadequately')}
						<br />
						{t('Support.support1')}
						<br />
						{t('Support.support2')}
						<br />
						{t('Support.support3')}
						<br />
						{t('Support.support4')}
						<br />
						{t('Support.support5')}
						<br />
						{t('Support.support6')}
						<br />
						{t('Support.support7')}
					</p>
					<textarea
						value={supportForm.txaMessage}
						onChange={(e) => inputChangeHandler(e, 'txaMessage')}
						className="txaSupport message"
						placeholder={t('Support.Pleaseprovidedetailed')}
					/>
				</li>
			</ul>
		</div>
	);
});

export default SupportForm;
