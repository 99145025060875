import React, { useRef, Fragment, useImperativeHandle, memo } from 'react';
import { useTranslation } from 'react-i18next';
import './print.scss';
import ICONlogo from '../../../assets/icons/logo.svg';
import ICONradioOFF from '../../../assets/icons/toggle-inactiv.svg';
import ICONradioONGrey from '../../../assets/icons/toggle-activegrey.svg';
import { useMemoizedFn, useReactive } from 'ahooks';

const PrintOpen = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const printRef = useRef(null);

	useImperativeHandle(ref, () => ({
		print: (
			reasoning,
			attachment,
			cause,
			action,
			internalRefNum,
			internalInfo
		) => {
			formData.reasoning = reasoning;
			formData.attachment = attachment;
			formData.cause = cause;
			formData.action = action;
			formData.internalInfo = internalInfo;
			formData.internalRefNum = internalRefNum;
			setTimeout(() => {
				window.print();
			}, 500);
		},
	}));

	const formData = useReactive({
		reasoning: '',
		attachment: [],
		cause: {
			maindesc: '',
			detaildesc: '',
			urtxt: '',
		},
		action: [],
		internalInfo: '',
		internalRefNum: '',
	});

	const lineRender = useMemoizedFn((val) => {
		if (val) {
			// const newVal = val.replaceAll('\n', '</br>');
			const newVal = val.replace(new RegExp('##', 'g'), '</br>');
			return (
				<p
					className="description"
					dangerouslySetInnerHTML={{ __html: newVal }}
				></p>
			);
		} else {
			return <Fragment />;
		}
	});

	const textlineRender = useMemoizedFn((val) => {
		if (val) {
			return (
				<Fragment>
					{val}
					<br />
				</Fragment>
			);
		} else {
			return <Fragment />;
		}
	});

	const changetoSmall = (val) => {
		if (val) {
			return val.toLowerCase();
		} else {
			return '';
		}
	};

	const renderEmail = (val) => {
		if (val) {
			if (val.length <= 28) {
				return <p>{changetoSmall(val)}</p>;
			} else {
				let temp = val.split('@');
				if (temp[0].length <= 26) {
					return (
						<Fragment>
							<p>{changetoSmall(temp[0])}@</p>
							<p>{temp[1]}</p>
						</Fragment>
					);
				} else {
					return (
						<Fragment>
							<p>{changetoSmall(temp[0])}</p>
							<p>@{temp[1]}</p>
						</Fragment>
					);
				}
			}
		} else {
			return (
				<p>
					<Fragment />
				</p>
			);
		}
	};

	return (
		<Fragment>
			<div className="A4Size" ref={printRef}>
				<div className="printheader">
					<div className="printheaderleft"></div>
					<div className="printheaderright">
						<img alt="company logo" src={ICONlogo} />
					</div>
				</div>
				<div className="printcontent">
					<div className="printcontent_left">
						<div className="printcontent_left_top">
							<span>Feedback required by</span>
							<p>{props.detailPageDTO.duedate}</p>
						</div>
						<ul className="printcontent_ul">
							{props.etype === 'S' ? (
								<Fragment>
									<li className="printcontent_li">
										<span>{t('Overview.OriginalResponsible')}</span>
										{renderEmail(props.quaryData.email)}
										{/* <p></p> */}
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.PurchaseOrder')}</span>
										<p>{props.detailPageDTO.ponumber}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.PurchaseOrderItem')}</span>
										<p>{props.detailPageDTO.poitem}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.MaterialNumber')}</span>
										<p>{props.detailPageDTO.matnr}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.MaterialDescription')}</span>
										<p>{props.detailPageDTO.maktx}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.ComplaintUnit')}</span>
										<p>
											{props.detailPageDTO.rkmng} {props.detailPageDTO.uom}
										</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.SupplierMaterial')}</span>
										<p>{props.detailPageDTO.supplmat}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.SupplierCharge')}</span>
										<p>{props.detailPageDTO.supplchg}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.DeliveryDate')}</span>
										<p>{props.detailPageDTO.deldate}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.ShipTo')} </span>
										<p>
											{textlineRender(props.detailPageDTO.recipient)}
											{textlineRender(props.detailPageDTO.street)}
											{textlineRender(props.detailPageDTO.postcode1)}
											{textlineRender(props.detailPageDTO.city1)}
											{textlineRender(props.detailPageDTO.country)}
										</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.BASFContact')}</span>
										<p>
											{/* <a href={`mailto:${props.detailPageDTO.senderemail}`}>
												{props.detailPageDTO.senderemail}
											</a>
											<br /> */}
											{textlineRender(
												changetoSmall(props.detailPageDTO.senderemail)
											)}
											{textlineRender(props.detailPageDTO.senderrecipient)}
											{textlineRender(props.detailPageDTO.senderstreet)}
											{textlineRender(props.detailPageDTO.senderpostcode1)}
											{textlineRender(props.detailPageDTO.sendercity1)}
											{textlineRender(props.detailPageDTO.sendercountry)}
										</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.MainProblemDescription')}</span>
										<p>{props.detailPageDTO.mreason}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.DetailProblemDescription')}</span>
										<p>{props.detailPageDTO.dreason}</p>
									</li>
								</Fragment>
							) : (
								<Fragment>
									<li className="printcontent_li">
										<span>{t('Detail.Assignee')}</span>
										<p>{renderEmail(props.quaryData.email)}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.Shipment')}</span>
										<p>{props.detailPageDTO.trnumber}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.ShipmentItem')}</span>
										<p>{props.detailPageDTO.tpnum}</p>
									</li>
									{props.nctype.toLowerCase() === 'y1' ||
									props.nctype.toLowerCase() === 'y7' ||
									props.nctype.toLowerCase() === 'yp' ? (
										<li className="printcontent_li">
											<span>Pro Number</span>
											<p>{props.detailPageDTO.pronum}</p>
										</li>
									) : (
										<Fragment />
									)}
									<li className="printcontent_li">
										<span>{t('Detail.GoodsMovementDate')}</span>
										<p>{props.detailPageDTO.dtship}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.SalesOrder')}</span>
										<p>{props.detailPageDTO.salesorder}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.SalesOrderItem')}</span>
										<p>{props.detailPageDTO.soitem}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.Delivery')}</span>
										<p>{props.detailPageDTO.delivery}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.DeliveryItem')}</span>
										<p>{props.detailPageDTO.delitem}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.BillofLading')}</span>
										<p>{props.detailPageDTO.pronum}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.TruckContainer')}</span>
										<p>{props.detailPageDTO.kfznumber}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.ShipTo')} </span>
										<p>
											{textlineRender(props.detailPageDTO.recipient)}
											{textlineRender(props.detailPageDTO.street)}
											{textlineRender(props.detailPageDTO.postcode1)}
											{textlineRender(props.detailPageDTO.city1)}
											{textlineRender(props.detailPageDTO.country)}
										</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.ComplaintUnit')}</span>
										<p>
											{props.detailPageDTO.rkmng} {props.detailPageDTO.uom}
										</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.BASFContact')}</span>
										<p>
											{/* <a href={`mailto:${props.detailPageDTO.senderemail}`}>
												{props.detailPageDTO.senderemail}
											</a>
											<br /> */}
											{textlineRender(
												changetoSmall(props.detailPageDTO.senderemail)
											)}
											{textlineRender(props.detailPageDTO.senderrecipient)}
											{textlineRender(props.detailPageDTO.senderstreet)}
											{textlineRender(props.detailPageDTO.senderpostcode1)}
											{textlineRender(props.detailPageDTO.sendercity1)}
											{textlineRender(props.detailPageDTO.sendercountry)}
										</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.MainProblemDescription')}</span>
										<p>{props.detailPageDTO.mreason}</p>
									</li>
									<li className="printcontent_li">
										<span>{t('Detail.DetailProblemDescription')}</span>
										<p>{props.detailPageDTO.dreason}</p>
									</li>
								</Fragment>
							)}
							<li className="printcontent_li">
								<span>{t('Detail.InternalReferenceNumber')}*</span>
								{/* <input
									type="text"
									className="txbSupplier"
									value={props.internalRefNum}
									readOnly
								/> */}
								<div className="input">{formData.internalRefNum}</div>
							</li>
							<li className="printcontent_li">
								<span>{t('Detail.InternalInformation')}*</span>
								{/* <textarea
									className="txaSupplier"
									value={props.internalInfo}
									readOnly
								/> */}
								<div className="textarea">{formData.internalInfo}</div>
							</li>
							<p className="disclaimer">* {t('Detail.informationfeedback')}</p>
						</ul>
					</div>
					<div className="printcontent_right">
						<div className="printcontent_top">
							<span className="status open">
								<span>{t('Detail.Open')}</span>
							</span>
							<span className="createdon">
								{t('Detail.Createdon')} {props.detailPageDTO.erdat}
							</span>
							<span className="ncnumber">
								NC {props.detailPageDTO.qmnum}
								{props.nctype ? ` - ${props.nctype}` : ''}
							</span>
							<span className="toptittle">{props.detailPageDTO.mreason}</span>
							{lineRender(props.edescrtext)}
						</div>
						<div className="printcontent_justification">
							<p className="justification_text">
								{t('Detail.NonConformanceunjustified')}
							</p>
							<button className="btnJustify">
								{props.isJustified ? (
									<img alt="Justified" src={ICONradioONGrey} />
								) : (
									<img alt="Not Justified" src={ICONradioOFF} />
								)}
							</button>
						</div>
						{!props.isJustified ? (
							<Fragment>
								<div className="printcontent_cause">
									<span className="maintitle">{t('Detail.Cause')}</span>
									<span
										className={
											formData.cause.maindesc !== ''
												? 'infowrap'
												: 'infowrap_open'
										}
									>
										<p className="infotext_bold">
											{formData.cause.maindesc !== ''
												? formData.cause.maindesc
												: t('Detail.Selectmainrootcause')}
										</p>
									</span>
									<span
										className={
											formData.cause.detaildesc !== ''
												? 'infowrap'
												: 'infowrap_open'
										}
									>
										<p className="infotext_bold">
											{formData.cause.detaildesc !== ''
												? formData.cause.detaildesc
												: t('Detail.Selectdetailrootcause')}
										</p>
									</span>
									<span
										className={
											formData.cause.urtxt !== '' ? 'infowrap' : 'infowrap_open'
										}
									>
										<p className="infotext_light">
											{formData.cause.urtxt !== ''
												? formData.cause.urtxt
												: t('Detail.Addyourcomments')}
										</p>
									</span>
								</div>
								{formData.action.map((item, index) => {
									return (
										<div
											className={
												formData.action.length > 1
													? 'printcontent_action border'
													: 'printcontent_action'
											}
											key={index}
										>
											{index === 0 ? (
												<span className="maintitle">
													{t('Detail.Correctiveaction')}
												</span>
											) : (
												<span className="maintitle">
													{t('Detail.Additionalcorrectiveaction')} {index}
												</span>
											)}
											<span
												className={
													item.maindesc !== '' ? 'infowrap' : 'infowrap_open'
												}
											>
												<p className="infotext_bold">
													{item.maindesc !== ''
														? item.maindesc
														: t('Detail.Selectmainaction')}
												</p>
											</span>
											<span
												className={
													item.detaildesc !== '' ? 'infowrap' : 'infowrap_open'
												}
											>
												<p className="infotext_bold">
													{item.detaildesc !== ''
														? item.detaildesc
														: t('Detail.Selectdetailaction')}
												</p>
											</span>
											<span
												className={
													item.matxt !== '' ? 'infowrap' : 'infowrap_open'
												}
											>
												<p className="infotext_light">
													{item.matxt !== ''
														? item.matxt
														: t('Detail.Describethiscorrectiveaction')}
												</p>
											</span>
											<span className="subtitle">
												{t('Detail.Responsibility')}
											</span>
											<span
												className={
													item.usrname !== '' ? 'infowrap' : 'infowrap_open'
												}
											>
												<p className="infotext_light">
													{item.usrname !== ''
														? item.usrname
														: t('Detail.SpecifyResponsibility')}
												</p>
											</span>
											<span className="subtitle">{t('Detail.Duedate')}</span>
											<span
												className={
													item.peter !== '' ? 'infowrap' : 'infowrap_open'
												}
											>
												<p className="infotext_light">
													{item.peter !== ''
														? item.peter
														: t('Detail.SelectDate')}
												</p>
											</span>
											<span className="subtitle">
												{t('Detail.Verificationplan')}
											</span>
											<span
												className={
													item.lgtext !== '' ? 'infowrap' : 'infowrap_open'
												}
											>
												<p className="infotext_light">
													{item.lgtext !== ''
														? item.lgtext
														: t('Detail.Addyourcomments')}
												</p>
											</span>
										</div>
									);
								})}
							</Fragment>
						) : (
							<Fragment>
								<div className="printcontent_cause">
									<span className="maintitle">{t('Detail.Reasoning')}</span>
									<span
										className={
											formData.reasoning !== ''
												? 'infowrap asd'
												: 'infowrap_open asd'
										}
									>
										<p className="infotext_light">
											{formData.reasoning !== ''
												? formData.reasoning
												: t('Detail.Explainyourobjection')}
										</p>
									</span>
								</div>
							</Fragment>
						)}
						<div className="printcontent_file">
							<span className="maintitle">
								{t('Detail.FileUploadOptional')}
							</span>
							<span className="blueborder">
								<p>
									{t('Detail.Drag1')}
									<span className="alt">{t('Detail.Drag2')}</span>
									{t('Detail.Drag3')}
								</p>
							</span>
							<span className="infoUpload">
								{t('Detail.FileType2')} <br />
								{t('Detail.MaxFile')}
							</span>
						</div>
						{formData.attachment.map((item, index) => {
							return (
								<div className="printcontent_filelist" key={index}>
									<span className="filename">{item}</span>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</Fragment>
	);
});

export default memo(PrintOpen);
