import React, { Fragment, useState, memo, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import ICONdown from '../../../assets/icons/arrow-down.svg';
import ICONup from '../../../assets/icons/arrow-up.svg';
import ICONprev from '../../../assets/icons/arrow-left-alt.svg';
import ICONnext from '../../../assets/icons/arrow-right.svg';
import DropdownList from 'react-dropdown';
import { useMemoizedFn } from 'ahooks';

const quantityOptions = [
	{ value: '15', label: '15' },
	{ value: '25', label: '25' },
	{ value: '50', label: '50' },
];

const RequestsOverviewPage = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const [value, setValue] = useState(props.pageData ? props.pageData : 1);

	useImperativeHandle(ref, () => ({
		toggleValue: (val) => {
			setValue(Number.parseInt(val));
		},
	}));

	const quantityChanged = useMemoizedFn((event) => {
		props.changePageSize(event.value);
	});

	const pagePrev = useMemoizedFn((event) => {
		setValue(value - 1);
		props.changePageNo('prev', 0);
	});

	const pageNext = useMemoizedFn((event) => {
		setValue(value + 1);
		props.changePageNo('next', 0);
	});

	const inputOnChange = useMemoizedFn((value) => {
		if (!/^[1-9][0-9]*$/.test(value)) {
			return;
		} else {
			setValue(Number.parseInt(value));
		}
	});

	const inputOnBlur = useMemoizedFn((value) => {
		if (Number.parseInt(value) === props.pageData + 1) {
			return;
		} else if (Number.parseInt(value) > Number.parseInt(props.pageTotal)) {
			setValue(Number.parseInt(props.pageData) + 1);
			return;
		} else {
			props.changePageNo('change', value);
		}
	});

	return (
		<Fragment>
			<div className="boxItemQuantity">
				<span>{t('Overview.Itemsperpage')}</span>
				<DropdownList
					options={quantityOptions}
					className="ddlQuantity"
					menuClassName="options"
					onChange={(event) => quantityChanged(event)}
					controlClassName="lineControl"
					arrowClosed={<img className="iconDown" alt="Expand" src={ICONdown} />}
					arrowOpen={<img className="iconUp" alt="Expand" src={ICONup} />}
					value={props.page.pagesize}
				/>
			</div>
			<div className="boxPagination">
				{props.pageData === 0 ? (
					<button className="btnLeft" disabled></button>
				) : (
					<button className="btnLeft" onClick={(e) => pagePrev(e)}>
						<img alt="Previous Page" src={ICONprev} />
					</button>
				)}
				<input
					type="text"
					className="txbPagination"
					value={value}
					onChange={(e) => inputOnChange(e.target.value)}
					onBlur={(e) => inputOnBlur(e.target.value)}
					placeholder={props.page.pageNo}
				/>
				<span>&nbsp;/&nbsp;&nbsp;{props.pageTotal}</span>
				{props.pageData + 1 === props.pageTotal ? (
					<button className="btnLeft" disabled></button>
				) : (
					<button className="btnLeft" onClick={(e) => pageNext(e)}>
						<img alt="Previous Page" src={ICONnext} />
					</button>
				)}
			</div>
			<button className="btnDownload" onClick={(e) => props.downloadExcel()}>
				{t('Overview.DownloadasExcel')}
			</button>
		</Fragment>
	);
});

export default memo(RequestsOverviewPage);
