import React, { useImperativeHandle, useState, useEffect, memo } from 'react';
import { useReactive, useMemoizedFn, useMount } from 'ahooks';
import DropdownList from 'react-dropdown';
import ICONup from '../../../assets/icons/arrow-up.svg';
import ICONdownGrey from '../../../assets/icons/arrowdowngrey.svg';
import ICONdownBlue from '../../../assets/icons/arrowdownblue.svg';
import { useTranslation } from 'react-i18next';

const ContentA1 = React.forwardRef(
	({ detailData, qpgrcauseDTO, setFalse, fetchDetailData }, ref) => {
		const { t } = useTranslation();
		const [mainClass, setMainClass] = useState('ddlFeedbackNofilter mainCause');
		const [detailClass, setdetailClass] = useState(
			'ddlFeedbackNofilter detailCause'
		);

		useImperativeHandle(ref, () => ({
			urtxt: causeForm.urtxt,
			mainCauseLabel: causeForm.mainCauseLabel,
			mainCauseValue: causeForm.mainCauseValue,
			detailCauseValue: causeForm.detailCauseValue,
			detailCauseObj: causeForm.detailCauseObj,
			mainCauseError: () => {
				setMainClass('ddlFeedbackNofilter mainCause gotError');
			},
			detailCauseError: () => {
				setdetailClass('ddlFeedbackNofilter detailCause gotError');
			},
			descriptionCauseError: () => {
				document.querySelector('.descriptionInput').classList.add('gotError');
				document
					.querySelector('.descriptionInput')
					.classList.remove('isFilled');
			},
			clearForm: () => {
				causeForm.urtxt = '';
				causeForm.detailCauseValue = '';
				causeForm.mainCauseValue = '';
			},
			inputError1000: () => {
				document
					.querySelector('.descriptionInput')
					.classList.remove('isFilled');
				document.querySelector('.descriptionInput').classList.add('gotError');
				const listItem = document.querySelector('.descriptionInput').parentNode;
				console.log(listItem.getElementsByTagName('span'));
				const error = listItem.getElementsByTagName('span')[0];
				error.classList.add('visible');
			},
		}));

		const causeForm = useReactive({
			urtxt: '',
			mainCauseLabel: '',
			mainCauseValue: '',
			detailCauseObj: {},
			detailCauseOption: [],
			detailCauseValue: '',
		});

		useMount(() => {});

		useEffect(() => {
			if (
				JSON.stringify(fetchDetailData) !== '{}' &&
				detailData.qpcdcauseDTO &&
				detailData.qpcdcauseDTO.qpctDtoList &&
				qpgrcauseDTO.length
			) {
				// urtxt
				causeForm.urtxt = fetchDetailData['cause_urtxt']
					? fetchDetailData['cause_urtxt']
					: '';
				if (fetchDetailData['cause_urtxt'] !== '') {
					document.querySelector('.descriptionInput').classList.add('isFilled');
				}
				// cause
				if (fetchDetailData['cause_code']) {
					let filter = detailData.qpcdcauseDTO.qpctDtoList.filter((item) => {
						return (
							item.codegruppe === fetchDetailData['cause_codegruppe'] &&
							item.katalogart === fetchDetailData['cause_katalogart']
						);
					});
					let temp = filter.map((item) => {
						let obj = {
							value: item.code,
							label: item.kurztext,
						};
						return obj;
					});
					let detailCauseObjTemp = filter.find((item) => {
						return item.code === fetchDetailData['cause_code'];
					});
					causeForm.detailCauseOption = temp;
					causeForm.detailCauseValue = fetchDetailData['cause_code'];
					causeForm.detailCauseObj = detailCauseObjTemp;
					//
					let mainActionObjTemp = qpgrcauseDTO.find((item) => {
						return item.value === fetchDetailData['cause_codegruppe'];
					});
					if (mainActionObjTemp) {
						setMainClass('ddlFeedbackNofilter mainCause isFilled');
						setdetailClass('ddlFeedbackNofilter detailCause isFilled');
						//
						causeForm.mainCauseLabel = mainActionObjTemp.label;
						causeForm.mainCauseValue = mainActionObjTemp.value;
					} else {
						setMainClass('ddlFeedbackNofilter mainCause');
						setdetailClass('ddlFeedbackNofilter detailCause');
						//
						causeForm.mainCauseLabel = '';
						causeForm.mainCauseValue = '';
						causeForm.detailCauseObj = {};
						causeForm.detailCauseOption = [];
						causeForm.detailCauseValue = '';
					}
				} else {
					setMainClass('ddlFeedbackNofilter mainCause');
					setdetailClass('ddlFeedbackNofilter detailCause');
					//
					causeForm.mainCauseLabel = '';
					causeForm.mainCauseValue = '';
					causeForm.detailCauseObj = {};
					causeForm.detailCauseOption = [];
					causeForm.detailCauseValue = '';
				}
			}
		}, [fetchDetailData, detailData, qpgrcauseDTO]); // eslint-disable-line

		const mainCauseHandler = useMemoizedFn((e) => {
			if (e.value === '') {
				return;
			}
			setFalse();
			//
			let find = detailData.qpgrcauseDTO.qpgtDTOList.find((item) => {
				return item.kurztext === e.label;
			});
			console.log(find);
			console.log(detailData.qpcdcauseDTO.qpctDtoList);
			let filter = detailData.qpcdcauseDTO.qpctDtoList.filter((item) => {
				return (
					item.codegruppe === find.codegruppe &&
					item.katalogart === find.katalogart
				);
			});
			let temp = filter.map((item) => {
				let obj = {
					value: item.code,
					label: item.kurztext,
				};
				return obj;
			});
			causeForm.mainCauseValue = e.value;
			causeForm.mainCauseLabel = e.label;
			causeForm.detailCauseOption = temp;
			causeForm.detailCauseValue = '';
			//
			setMainClass('ddlFeedbackNofilter mainCause isFilled');
			//
			setdetailClass('ddlFeedbackNofilter detailCause');
		});

		const detailCauseHandler = useMemoizedFn((e) => {
			if (e.value === '') {
				return;
			}
			setFalse();
			let find = detailData.qpcdcauseDTO.qpctDtoList.find((item) => {
				return item.code === e.value;
			});
			causeForm.detailCauseObj = find;
			causeForm.detailCauseValue = e.value;
			//
			setdetailClass('ddlFeedbackNofilter detailCause isFilled');
		});

		const inputChangeHandler = useMemoizedFn((e) => {
			e.target.classList.remove('gotError');
			e.target.parentNode
				.getElementsByTagName('span')[0]
				.classList.remove('visible');
			causeForm.urtxt = e.target.value;
			setFalse();
			//
			if (e.target.value !== '') {
				e.target.classList.add('isFilled');
			} else {
				e.target.classList.remove('isFilled');
			}
		});

		return (
			<div ref={ref}>
				<h5>{t('Detail.Cause')}</h5>
				<ul className="lstFeedback">
					<li>
						<DropdownList
							disabled={qpgrcauseDTO.length === 0}
							options={qpgrcauseDTO}
							className={mainClass}
							placeholder={t('Detail.Selectmainrootcause')}
							menuClassName="options"
							onChange={(event) => mainCauseHandler(event)}
							controlClassName="lineControl"
							arrowClosed={
								qpgrcauseDTO.length ? (
									<img className="iconDown" alt="Expand" src={ICONdownBlue} />
								) : (
									<img className="iconDown" alt="Expand" src={ICONdownGrey} />
								)
							}
							arrowOpen={<img className="iconUp" alt="Expand" src={ICONup} />}
							value={causeForm.mainCauseValue}
						/>
						{/* <span className="errInput">Main Cause can't be empty.</span> */}
					</li>
					<li>
						<DropdownList
							disabled={causeForm.detailCauseOption.length === 0}
							options={causeForm.detailCauseOption}
							className={detailClass}
							placeholder={t('Detail.Selectdetailrootcause')}
							menuClassName="options"
							onChange={(event) => detailCauseHandler(event)}
							controlClassName="lineControl"
							arrowClosed={
								causeForm.detailCauseOption.length ? (
									<img className="iconDown" alt="Expand" src={ICONdownBlue} />
								) : (
									<img className="iconDown" alt="Expand" src={ICONdownGrey} />
								)
							}
							arrowOpen={<img className="iconUp" alt="Expand" src={ICONup} />}
							value={causeForm.detailCauseValue}
						/>
						{/* <span className="errInput">Detailed Cause can't be empty.</span> */}
					</li>
					<li>
						<textarea
							className="txaFeedback descriptionInput"
							value={causeForm.urtxt}
							onChange={(e) => inputChangeHandler(e)}
							placeholder={t('Detail.Addyourcomments')}
						/>
						<span className="errInput">{t('Detail.urtxt1000')}</span>
					</li>
				</ul>
			</div>
		);
	}
);

export default memo(ContentA1);
