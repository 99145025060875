import React, { useState, useEffect, Fragment, useRef } from 'react';
import ICONdown from '../../assets/icons/arrow-down.svg';
import ICONup from '../../assets/icons/arrow-up.svg';
import ICONradioOFF from '../../assets/icons/toggle-inactiv.svg';
import ICONradioON from '../../assets/icons/toggle-activ.svg';
import ICONradioONGrey from '../../assets/icons/toggle-activegrey.svg';
import IMAGlandingBig from '../../assets/images/landing_big.jpg';
import './Detail.scss';
import axios from '../../axios-custom';
import { useLocation, useHistory, Prompt } from 'react-router-dom';
import {
	getQueryVariable,
	dataURLtoFile,
	getBase64Type,
	awaitWrap,
	decrypt,
	getQueryVariableTemp,
} from '../../utils/util';
import Translate from './components/Translate';
import SupplierContentDetail from './components/SupplierContentDetail';
import HaulierContentDetail from './components/HaulierContentDetail';
import ContentA1 from './components/ContentA1';
import ContentA1Closed from './components/ContentA1Closed';
import Content1Preview from './components/Content1Preview';
import ContentA2 from './components/ContentA2';
import Content2Closed from './components/Content2Closed';
import Content2Preview from './components/Content2Preview';
import ContentB from './components/ContentB';
import ContentBClosed from './components/ContentBClosed';
import Attachment from './components/Attachment';
import InputFileUpload from '../InputFileUpload/InputFileUpload';
import InputFileUploadClosed from '../InputFileUpload/InputFileUploadClosed';
import InputFileUploadClosedDown from '../InputFileUpload/InputFileUploadClosedDown';
import Longtext from './components/Longtext';
import PrintClosed from './components/PrintClosed';
import PrintDraft from './components/PrintDraft';
import PrintOpen from './components/PrintOpen';
import store from '../../state/store';
import { useTranslation } from 'react-i18next';
import { Modal, notification, Button } from 'antd';
import '../../../node_modules/antd/lib/modal/style/index.css';
import ICONclose from '../../assets/icons/closeWhite.svg';
import ICONcloseGrey from '../../assets/icons/closegrey.svg';
import {
	useMount,
	useUnmount,
	useBoolean,
	useResponsive,
	useKeyPress,
	useInterval,
	useReactive,
} from 'ahooks';
import ICONinfo from '../../assets/icons/info-i.svg';
import moment from 'moment';
import Dexie from 'dexie';

const { useModel } = store;

const db = new Dexie('NCM_database');
db.version(1).stores({ nc: '++id,qmnum,report,lang' });

const beforeunloadHandler = (e) => {
	e.preventDefault();
	e = e || window.event;
	if (e) {
		// e.returnValue = t('Detail.cancel2');
		e.returnValue =
			'Your input has not been saved, please save before closing to create a draft.';
	}
	// return t('Detail.cancel2');
	return 'Your input has not been saved, please save before closing to create a draft.';
};

const Detail = ({ bgHandler, isUserLogin }) => {
	const responsive = useResponsive();
	const { t, i18n } = useTranslation();
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { languagePopup, userProfileDetail } = state;
	const {
		toggleLoading,
		toastMessageShow,
		changeDetailIsPrompt,
		changeLanguagePopup,
		changeUserProfileDetail,
	} = dispatchers;

	const [etype, setEtype] = useState('');
	const [status, setStatus] = useState('');
	const [report, setreport] = useState('');
	const [reportid, setreportid] = useState('');
	const [ncTypeUrl, setncTypeUrl] = useState('');
	const [targetSystem, settargetSystem] = useState('');
	const [qmnum, setQmnum] = useState('');
	const [typeuser, setTypeuser] = useState('');
	const [nctype, setNctype] = useState('');
	const [id, setId] = useState('');
	const [refDocNo, setRefDocNo] = useState('');
	const [expandedSupplier, setExpandedSupplier] = useState(false);
	const [isJustified, setIsJustified] = useState(false);
	const [oisJustified, setoIsJustified] = useState(false);
	const [fetchDetailData, setFetchDetailData] = useState({});
	//
	// const [canbeSubmit, setCanbeSubmit] = useState(true);
	//
	const [quaryData, setQuaryData] = useState({});
	const [detailData, setDetailData] = useState({});
	//
	const [detailPageDTO, setDetailPageDTO] = useState({});
	const [etAttachmentDTO, setEtAttachmentDTO] = useState([]);
	const [qpgrcauseDTO, setQpgrcauseDTO] = useState([]);
	const [qpgrcorrDTO, setQpgrcorrDTO] = useState([]);
	//
	const [edescrtext, setEdescrtext] = useState('');
	const [originedescrtext, set0riginedescrtext] = useState('');
	const [internalInfo, setInternalInfo] = useState('');
	const [internalRefNum, setInternalRefNum] = useState('');
	//
	const [isModalVisible, setIsModalVisible] = useState(false);
	//
	const [errorShow, { setTrue, setFalse }] = useBoolean(false);
	const [isPrompt, setIsPrompt] = useState(false);
	const [promptLoc, setPromptLoc] = useState('');
	// close
	const [etcauseclosed, setEtcauseclosed] = useState({});
	const [etcorrectionclosed, setEtcorrectionclosed] = useState([]);
	const [etattachmentclosed, setEtattachmentclosed] = useState([]);
	const [etattachment, setEtattachment] = useState([]);
	const [reasoning, setReasoning] = useState('');
	//
	const [interval, setCheckInterval] = useState(undefined);
	const [lockInterval, setLockInterval] = useState(undefined);
	//
	const location = useLocation();
	let history = useHistory();
	//
	const contentDetailRef = useRef(null);
	const contentBRef = useRef(null);
	const contentA1Ref = useRef(null);
	const contentA2Ref = useRef(null);
	const fileUploadRef = useRef(null);
	const PrintClosedRef = useRef(null);
	const PrintDraftRef = useRef(null);
	const PrintOpenRef = useRef(null);

	// const data = useReactive({
	// 	detectLang: '',
	// });

	const formData = useReactive({
		langu: '',
	});

	useInterval(
		async () => {
			await lockResFetch();
		},
		lockInterval,
		{ immediate: true }
	);

	useInterval(
		() => {
			// console.log(isPrompt);
			// if (!canbeSubmit) {
			// 	window.removeEventListener('beforeunload', beforeunloadHandler);
			// 	setCheckInterval(undefined);
			// 	setIsPrompt(false);
			// 	return;
			// }
			if (typeuser === 'preview') {
				window.removeEventListener('beforeunload', beforeunloadHandler);
				setCheckInterval(undefined);
				setIsPrompt(false);
				changeDetailIsPrompt(false);
				return;
			} else {
				if (status === 'closed') {
					window.removeEventListener('beforeunload', beforeunloadHandler);
					setCheckInterval(undefined);
					setIsPrompt(false);
					changeDetailIsPrompt(false);
				} else if (status === 'open') {
					// isJustified
					if (isJustified) {
						window.addEventListener('beforeunload', beforeunloadHandler);
						setIsPrompt(true);
						changeDetailIsPrompt(true);
						return;
					} else {
						// internalInfo
						if (contentDetailRef.current) {
							if (contentDetailRef.current.internalInfo !== internalInfo) {
								window.addEventListener('beforeunload', beforeunloadHandler);
								setIsPrompt(true);
								changeDetailIsPrompt(true);
								return;
							}
							if (contentDetailRef.current.internalRefNum !== internalRefNum) {
								window.addEventListener('beforeunload', beforeunloadHandler);
								setIsPrompt(true);
								changeDetailIsPrompt(true);
								return;
							}
						}
						// contentA1Ref
						if (contentA1Ref.current) {
							if (contentA1Ref.current.mainCauseValue !== '') {
								window.addEventListener('beforeunload', beforeunloadHandler);
								setIsPrompt(true);
								changeDetailIsPrompt(true);
								return;
							}
							if (contentA1Ref.current.detailCauseValue !== '') {
								window.addEventListener('beforeunload', beforeunloadHandler);
								setIsPrompt(true);
								changeDetailIsPrompt(true);
								return;
							}
							if (contentA1Ref.current.urtxt !== '') {
								window.addEventListener('beforeunload', beforeunloadHandler);
								setIsPrompt(true);
								changeDetailIsPrompt(true);
								return;
							}
						}
						// contentA2Ref
						if (contentA2Ref.current) {
							let ACAData = JSON.parse(
								JSON.stringify(contentA2Ref.current.actionForm.ACAData)
							);
							if (ACAData.length > 1) {
								window.addEventListener('beforeunload', beforeunloadHandler);
								setIsPrompt(true);
								changeDetailIsPrompt(true);
								return;
							}
							if (ACAData.length === 1) {
								if (ACAData[0].mainActionValue !== '') {
									window.addEventListener('beforeunload', beforeunloadHandler);
									setIsPrompt(true);
									changeDetailIsPrompt(true);
									return;
								}
								if (ACAData[0].detailActionValue !== '') {
									window.addEventListener('beforeunload', beforeunloadHandler);
									setIsPrompt(true);
									changeDetailIsPrompt(true);
									return;
								}
								if (ACAData[0].lgtext !== '') {
									window.addEventListener('beforeunload', beforeunloadHandler);
									setIsPrompt(true);
									changeDetailIsPrompt(true);
									return;
								}
								if (ACAData[0].matxt !== '') {
									window.addEventListener('beforeunload', beforeunloadHandler);
									setIsPrompt(true);
									changeDetailIsPrompt(true);
									return;
								}
								if (ACAData[0].peter !== '') {
									window.addEventListener('beforeunload', beforeunloadHandler);
									setIsPrompt(true);
									changeDetailIsPrompt(true);
									return;
								}
								if (ACAData[0].usrname !== '') {
									window.addEventListener('beforeunload', beforeunloadHandler);
									setIsPrompt(true);
									changeDetailIsPrompt(true);
									return;
								}
							}
						}
						// fileUploadRef
						if (fileUploadRef.current.itattachments.length !== 0) {
							window.addEventListener('beforeunload', beforeunloadHandler);
							setIsPrompt(true);
							changeDetailIsPrompt(true);
							return;
						}
					}
					window.removeEventListener('beforeunload', beforeunloadHandler);
					setIsPrompt(false);
					changeDetailIsPrompt(false);
				} else if (status === 'draft') {
					if (isJustified !== oisJustified) {
						window.addEventListener('beforeunload', beforeunloadHandler);
						setIsPrompt(true);
						changeDetailIsPrompt(true);
						return;
					} else {
						// internalInfo
						if (contentDetailRef.current) {
							if (contentDetailRef.current.internalInfo !== internalInfo) {
								window.addEventListener('beforeunload', beforeunloadHandler);
								setIsPrompt(true);
								changeDetailIsPrompt(true);
								return;
							}
							if (contentDetailRef.current.internalRefNum !== internalRefNum) {
								window.addEventListener('beforeunload', beforeunloadHandler);
								setIsPrompt(true);
								changeDetailIsPrompt(true);
								return;
							}
						}
						// contentB
						if (contentBRef.current) {
							if (
								contentBRef.current.reasonForm !== fetchDetailData['reasoning']
							) {
								window.addEventListener('beforeunload', beforeunloadHandler);
								setIsPrompt(true);
								changeDetailIsPrompt(true);
								return;
							}
						}
						// contentA1Ref
						if (contentA1Ref.current) {
							if (
								contentA1Ref.current.mainCauseValue !==
								fetchDetailData['cause_codegruppe']
							) {
								window.addEventListener('beforeunload', beforeunloadHandler);
								setIsPrompt(true);
								changeDetailIsPrompt(true);
								return;
							}
							let detailCauseObj = JSON.stringify(
								contentA1Ref.current.detailCauseObj
							);
							if (detailCauseObj !== '{}') {
								if (
									JSON.parse(detailCauseObj).code !==
									fetchDetailData['cause_code']
								) {
									window.addEventListener('beforeunload', beforeunloadHandler);
									setIsPrompt(true);

									console.log(6);
									changeDetailIsPrompt(true);
									return;
								}
							}

							if (
								contentA1Ref.current.urtxt !== fetchDetailData['cause_urtxt']
							) {
								window.addEventListener('beforeunload', beforeunloadHandler);
								setIsPrompt(true);

								console.log(7);
								changeDetailIsPrompt(true);
								return;
							}
						}
						// contentA2Ref
						if (contentA2Ref.current) {
							//
							let tempnum = [
								fetchDetailData['corr_code'].length,
								fetchDetailData['corr_codegruppe'].length,
								fetchDetailData['corr_katalogart'].length,
								fetchDetailData['corr_lgtext'].length,
								fetchDetailData['corr_matxt'].length,
								fetchDetailData['corr_peter'].length,
								fetchDetailData['corr_usrname'].length,
							];
							tempnum.sort((a, b) => b - a);
							let num = tempnum[0];
							let numFix = num === 0 ? 1 : num;
							//
							let ACAData = JSON.parse(
								JSON.stringify(contentA2Ref.current.actionForm.ACAData)
							);
							if (ACAData.length) {
								if (ACAData.length !== numFix) {
									window.addEventListener('beforeunload', beforeunloadHandler);
									setIsPrompt(true);
									changeDetailIsPrompt(true);
									return;
								} else {
									let action_maindesc = [];
									let corr_codegruppe = [];
									let action_detaildesc = [];
									let corr_code = [];
									let corr_lgtext = [];
									let corr_matxt = [];
									let corr_peter = [];
									let corr_usrname = [];
									for (let i = 0; i < ACAData.length; i++) {
										if (ACAData[i].peter !== '') {
											corr_peter.push(
												moment(ACAData[i].peter).format('DD/MM/YYYY')
											);
										} else {
											corr_peter.push(ACAData[i].peter);
										}
										corr_usrname.push(ACAData[i].usrname);
										corr_matxt.push(ACAData[i].matxt);
										corr_lgtext.push(ACAData[i].lgtext);
										//
										if (
											ACAData[i].detailActionObj &&
											JSON.stringify(ACAData[i].detailActionObj) !== '{}'
										) {
											action_maindesc.push(ACAData[i].mainActionLabel);
											corr_codegruppe.push(ACAData[i].mainActionValue);
											action_detaildesc.push(
												ACAData[i].detailActionObj.kurztext
											);
											corr_code.push(ACAData[i].detailActionValue);
										} else {
											action_maindesc.push('');
											action_detaildesc.push('');
											corr_codegruppe.push('');
											corr_code.push('');
										}
									}
									//
									if (
										JSON.stringify(fetchDetailData['corr_codegruppe']) !== '[]'
									) {
										if (
											JSON.stringify(corr_codegruppe) !==
											JSON.stringify(fetchDetailData['corr_codegruppe'])
										) {
											window.addEventListener(
												'beforeunload',
												beforeunloadHandler
											);
											setIsPrompt(true);
											changeDetailIsPrompt(true);
											return;
										}
									} else {
										if (JSON.stringify(corr_codegruppe) !== '[""]') {
											window.addEventListener(
												'beforeunload',
												beforeunloadHandler
											);
											setIsPrompt(true);
											changeDetailIsPrompt(true);
											return;
										}
									}
									//
									if (JSON.stringify(fetchDetailData['corr_code']) !== '[]') {
										if (
											JSON.stringify(corr_code) !==
											JSON.stringify(fetchDetailData['corr_code'])
										) {
											window.addEventListener(
												'beforeunload',
												beforeunloadHandler
											);
											setIsPrompt(true);
											changeDetailIsPrompt(true);
											return;
										}
									} else {
										if (JSON.stringify(corr_code) !== '[""]') {
											window.addEventListener(
												'beforeunload',
												beforeunloadHandler
											);
											setIsPrompt(true);
											changeDetailIsPrompt(true);
											return;
										}
									}
									//
									if (JSON.stringify(fetchDetailData['corr_lgtext']) !== '[]') {
										if (
											JSON.stringify(corr_lgtext) !==
											JSON.stringify(fetchDetailData['corr_lgtext'])
										) {
											window.addEventListener(
												'beforeunload',
												beforeunloadHandler
											);
											setIsPrompt(true);
											changeDetailIsPrompt(true);
											return;
										}
									} else {
										if (JSON.stringify(corr_lgtext) !== '[""]') {
											window.addEventListener(
												'beforeunload',
												beforeunloadHandler
											);
											setIsPrompt(true);
											changeDetailIsPrompt(true);
											return;
										}
									}
									//
									if (JSON.stringify(fetchDetailData['corr_matxt']) !== '[]') {
										if (
											JSON.stringify(corr_matxt) !==
											JSON.stringify(fetchDetailData['corr_matxt'])
										) {
											window.addEventListener(
												'beforeunload',
												beforeunloadHandler
											);
											setIsPrompt(true);
											changeDetailIsPrompt(true);
											return;
										}
									} else {
										if (JSON.stringify(corr_matxt) !== '[""]') {
											window.addEventListener(
												'beforeunload',
												beforeunloadHandler
											);
											setIsPrompt(true);
											changeDetailIsPrompt(true);
											return;
										}
									}
									//
									if (JSON.stringify(fetchDetailData['corr_peter']) !== '[]') {
										if (
											JSON.stringify(corr_peter) !==
											JSON.stringify(fetchDetailData['corr_peter'])
										) {
											window.addEventListener(
												'beforeunload',
												beforeunloadHandler
											);
											setIsPrompt(true);
											changeDetailIsPrompt(true);
											return;
										}
									} else {
										if (JSON.stringify(corr_peter) !== '[""]') {
											window.addEventListener(
												'beforeunload',
												beforeunloadHandler
											);
											setIsPrompt(true);
											changeDetailIsPrompt(true);
											return;
										}
									}
									//
									if (
										JSON.stringify(fetchDetailData['corr_usrname']) !== '[]'
									) {
										if (
											JSON.stringify(corr_usrname) !==
											JSON.stringify(fetchDetailData['corr_usrname'])
										) {
											window.addEventListener(
												'beforeunload',
												beforeunloadHandler
											);
											setIsPrompt(true);
											changeDetailIsPrompt(true);
											return;
										}
									} else {
										if (JSON.stringify(corr_usrname) !== '[""]') {
											window.addEventListener(
												'beforeunload',
												beforeunloadHandler
											);
											setIsPrompt(true);
											changeDetailIsPrompt(true);
											return;
										}
									}
								}
							}
						}
						// fileUploadRef
						if (
							fileUploadRef.current.itattachments.length !==
							fetchDetailData['itattachments'].length
						) {
							window.addEventListener('beforeunload', beforeunloadHandler);
							setIsPrompt(true);
							changeDetailIsPrompt(true);
							return;
						}
					}
					//
					window.removeEventListener('beforeunload', beforeunloadHandler);
					setIsPrompt(false);
					changeDetailIsPrompt(false);
				}
			}
		},
		interval,
		{ immediate: true }
	);

	useMount(async () => {
		window.scrollTo(0, 0);
		if (
			!localStorage.getItem('NCM_Token') ||
			localStorage.getItem('NCM_Token') === ''
		) {
			window.removeEventListener('beforeunload', beforeunloadHandler);
			setCheckInterval(undefined);
			setIsPrompt(false);
			changeDetailIsPrompt(false);
			localStorage.removeItem('NCM_Token');
			localStorage.removeItem('NCM_User');
			localStorage.removeItem('NCM_User_bak');
			localStorage.removeItem('NCM_AuthorizationTime');
			localStorage.removeItem('NCM_landing_popup');
			localStorage.removeItem('NCM_searchval');
			localStorage.removeItem('NCM_filterval');
			localStorage.removeItem('NCM_expandedAll');
			localStorage.removeItem('NCM_scrollY');
			localStorage.removeItem('NCM_lockId');
			localStorage.removeItem('NCM_lang_bak');
			history.replace('/');
			setTimeout(() => {
				window.location.reload();
			}, 10);
			return;
		}
		if (
			localStorage.getItem('NCM_AuthorizationTime') &&
			localStorage.getItem('NCM_AuthorizationTime') !== ''
		) {
			let newtime = Date.parse(new Date()).toString();
			newtime = newtime.substr(0, 10);
			const oldtime = localStorage.getItem('NCM_AuthorizationTime');
			if (Number.parseInt(newtime) - Number.parseInt(oldtime) > 12 * 60 * 60) {
				window.removeEventListener('beforeunload', beforeunloadHandler);
				setCheckInterval(undefined);
				setIsPrompt(false);
				changeDetailIsPrompt(false);
				localStorage.removeItem('NCM_Token');
				localStorage.removeItem('NCM_User');
				localStorage.removeItem('NCM_User_bak');
				localStorage.removeItem('NCM_AuthorizationTime');
				localStorage.removeItem('NCM_landing_popup');
				localStorage.removeItem('NCM_searchval');
				localStorage.removeItem('NCM_filterval');
				localStorage.removeItem('NCM_expandedAll');
				localStorage.removeItem('NCM_scrollY');
				localStorage.removeItem('NCM_lockId');
				localStorage.removeItem('NCM_lang_bak');
				history.replace('/');
				setTimeout(() => {
					window.location.reload();
				}, 10);
				return;
			}
		}
		// flesh browser and close tab---open native popup
		window.addEventListener('beforeunload', beforeunloadHandler);
		// // stop browser back
		// window.history.pushState(null, null, document.URL);
		// window.onpopstate = function (e) {
		// 	window.history.pushState(null, null, document.URL);
		// };
	});

	useUnmount(async () => {
		await unLockFetch();
		setLockInterval(undefined);
		window.removeEventListener('beforeunload', beforeunloadHandler);
		setIsPrompt(false);
		changeDetailIsPrompt(false);
		// window.onpopstate = null;
	});

	useEffect(() => {
		async function fetchData() {
			const { search } = location;
			if (!search || search === '') {
				setCheckInterval(undefined);
				window.removeEventListener('beforeunload', beforeunloadHandler);
				setIsPrompt(false);
				changeDetailIsPrompt(false);
				history.replace('/landing');
				return;
			}
			//
			let lang = '';
			if (localStorage.getItem('NCM_lang') === 'en') {
				lang = 'E';
			} else if (localStorage.getItem('NCM_lang') === 'de') {
				lang = 'D';
			} else if (localStorage.getItem('NCM_lang') === 'fr') {
				lang = 'F';
			} else if (localStorage.getItem('NCM_lang') === 'es') {
				lang = 'S';
			} else if (localStorage.getItem('NCM_lang') === 'pt') {
				lang = 'P';
			}
			//
			let valueTemp = getQueryVariable(search, 'value');
			let value = decrypt(valueTemp);
			//
			let email = getQueryVariableTemp(value, 'email');
			let nc = getQueryVariableTemp(value, 'nc');
			let report = getQueryVariableTemp(value, 'report');
			let status = getQueryVariableTemp(value, 'status');
			let id = getQueryVariableTemp(value, 'id');
			let refDocNo = getQueryVariableTemp(value, 'refDocNo');
			let typeuser = getQueryVariableTemp(value, 'typeuser');
			let from = getQueryVariableTemp(value, 'from');
			let reportid = getQueryVariableTemp(value, 'reportid');
			let targetSystem = getQueryVariableTemp(value, 'targetSystem');
			let ncTypeUrl = getQueryVariableTemp(value, 'ncTypeUrl');
			//
			setPromptLoc(from);
			//
			let statustemp = '';
			if (status === 'closed') {
				statustemp = 'C';
			} else {
				statustemp = 'O';
			}
			if (email === '' || nc === '' || report === '' || status === '') {
				setCheckInterval(undefined);
				window.removeEventListener('beforeunload', beforeunloadHandler);
				setIsPrompt(false);
				changeDetailIsPrompt(false);
				history.replace('/landing');
				return;
			}
			// let query = {
			// 	email: email,
			// 	lang: lang,
			// 	nc: nc,
			// 	report: report,
			// 	imstatus: statustemp,
			// };
			let query = {
				targetSystem: targetSystem,
				email: email,
				nc: nc,
				report: report,
				lang: lang,
				acceptLang: localStorage.getItem('NCM_lang')
					? localStorage.getItem('NCM_lang')
					: 'en',
				imstatus: statustemp,
				reportid: reportid,
				ncType: ncTypeUrl,
			};
			setStatus(status);
			//
			setreport(report);
			//
			setreportid(reportid);
			//
			setncTypeUrl(ncTypeUrl);
			//
			settargetSystem(targetSystem);
			//
			setQmnum(nc);
			//
			setTypeuser(typeuser);
			//
			setId(id);
			//
			setRefDocNo(refDocNo);
			//
			toggleLoading();
			if (id !== '') {
				const fetchData = await axios.get(
					`/feedbackServices/api/fetchDraftFeedback/${id}`
				);
				console.log(fetchData.data);
				//
				let obj = {};
				// unjustified
				// obj.is_mjust = fetchData.data['is_mjust'] === 'X' ? true : false;
				setIsJustified(fetchData.data['is_mjust'] === 'true' ? true : false);
				setoIsJustified(fetchData.data['is_mjust'] === 'true' ? true : false);
				// reasoning
				obj.reasoning = fetchData.data['reasoning']
					? fetchData.data['reasoning']
					: '';
				// Cause
				obj['cause_code'] = fetchData.data['cause_code']
					? fetchData.data['cause_code']
					: '';
				obj['cause_codegruppe'] = fetchData.data['cause_codegruppe']
					? fetchData.data['cause_codegruppe']
					: '';
				obj['cause_katalogart'] = fetchData.data['cause_katalogart']
					? fetchData.data['cause_katalogart']
					: '';
				obj['cause_maindesc'] = fetchData.data['cause_maindesc']
					? fetchData.data['cause_maindesc']
					: '';
				obj['cause_detaildesc'] = fetchData.data['cause_detaildesc']
					? fetchData.data['cause_detaildesc']
					: '';
				obj['cause_urtxt'] = fetchData.data['cause_urtxt']
					? fetchData.data['cause_urtxt']
					: '';
				// action
				let corrPeter = [];
				if (
					fetchData.data['corr_peter'] &&
					fetchData.data['corr_peter'].length
				) {
					corrPeter = fetchData.data['corr_peter'];
				} else {
					corrPeter = [];
				}
				//
				let corrLgtext = [];
				if (
					fetchData.data['corr_lgtext'] &&
					fetchData.data['corr_lgtext'].length
				) {
					corrLgtext = fetchData.data['corr_lgtext'];
				} else {
					corrLgtext = [];
				}
				//
				let corrMatxt = [];
				if (
					fetchData.data['corr_matxt'] &&
					fetchData.data['corr_matxt'].length
				) {
					corrMatxt = fetchData.data['corr_matxt'];
				} else {
					corrMatxt = [];
				}
				//
				let corrUsrname = [];
				if (
					fetchData.data['corr_usrname'] &&
					fetchData.data['corr_usrname'].length
				) {
					corrUsrname = fetchData.data['corr_usrname'];
				} else {
					corrUsrname = [];
				}
				//
				let corrCode = [];
				if (fetchData.data['corr_code'] && fetchData.data['corr_code'].length) {
					corrCode = fetchData.data['corr_code'];
				} else {
					corrCode = [];
				}
				let corrCodegruppe = [];
				if (
					fetchData.data['corr_codegruppe'] &&
					fetchData.data['corr_codegruppe'].length
				) {
					corrCodegruppe = fetchData.data['corr_codegruppe'];
				} else {
					corrCodegruppe = [];
				}
				let corrKatalogart = [];
				if (
					fetchData.data['corr_katalogart'] &&
					fetchData.data['corr_katalogart'].length
				) {
					corrKatalogart = fetchData.data['corr_katalogart'];
				} else {
					corrKatalogart = [];
				}
				//
				let actionMaindesc = [];
				if (
					fetchData.data['action_maindesc'] &&
					fetchData.data['action_maindesc'].length
				) {
					actionMaindesc = fetchData.data['action_maindesc'];
				} else {
					actionMaindesc = [];
				}
				let actionDetaildesc = [];
				if (
					fetchData.data['action_detaildesc'] &&
					fetchData.data['action_detaildesc'].length
				) {
					actionDetaildesc = fetchData.data['action_detaildesc'];
				} else {
					actionDetaildesc = [];
				}
				//
				// itattachments
				let itattachments = [];
				if (
					fetchData.data['itattachments'] &&
					fetchData.data['itattachments'].length
				) {
					for (let i = 0; i < fetchData.data['itattachments'].length; i++) {
						let obj = {};
						let temp = fetchData.data['itattachments'][i].filename.split('.');
						let tempFilename =
							fetchData.data['itattachments'][i].filename.split('$$');
						let file = temp[temp.length - 1].toLowerCase();
						let mime = getBase64Type(file);
						obj.content = `${mime}${fetchData.data['itattachments'][i].content}`;
						obj.filename = `${tempFilename[0]}.${temp[temp.length - 1]}`;
						// obj.size = dataURLtoFile(
						// 	fetchData.data['itattachments'][i].content
						// );
						obj.size = fetchData.data['itattachments'][i].size
							? fetchData.data['itattachments'][i].size
							: dataURLtoFile(fetchData.data['itattachments'][i].content);
						itattachments.push(obj);
					}
				} else {
					itattachments = [];
				}
				//
				obj['corr_peter'] = corrPeter;
				obj['corr_lgtext'] = corrLgtext;
				obj['corr_matxt'] = corrMatxt;
				obj['corr_usrname'] = corrUsrname;
				obj['corr_code'] = corrCode;
				obj['corr_codegruppe'] = corrCodegruppe;
				obj['corr_katalogart'] = corrKatalogart;
				obj['action_maindesc'] = actionMaindesc;
				obj['action_detaildesc'] = actionDetaildesc;
				obj['itattachments'] = itattachments;
				setFetchDetailData(obj);
			}
			setQuaryData(query);
			/* workaround approach for empty query, when not coming from Landing or Overview Page */
			// let obj = {
			// 	"email": "MARC.RUSSELL@DACHSER.COM",
			// 	"lang": "E",
			// 	"nc": "800002078088",
			// 	"report": "YNCM_HAULIER_FEEDBACK"
			// }
			// setQuaryData(obj);
			const response = await axios.post(
				'/feedbackServices/portalSap/detailedPage',
				query
			);
			// lock
			setLockInterval(60000);
			//
			console.log(response.data);
			// langu
			formData.langu = response.data.langu ? response.data.langu : '';
			// NCTYPE
			setNctype(response.data.nctype ? response.data.nctype : '');
			// close--detail
			if (status === 'closed') {
				if (response.data.mjust === null || response.data.mjust === 'false') {
					setIsJustified(false);
				} else {
					setIsJustified(true);
					setReasoning(response.data.reasoning);
				}

				if (response.data.reasoning && response.data.reasoning !== '') {
					setReasoning(response.data.reasoning);
				}

				if (response.data.etcorrectionclosed) {
					setEtcauseclosed(response.data.etcauseclosed);
				}

				if (
					response.data.etcorrectionclosed &&
					response.data.etcorrectionclosed.item
				) {
					setEtcorrectionclosed(response.data.etcorrectionclosed.item);
				}
				if (
					response.data.etattachment &&
					response.data.etattachment.item &&
					response.data.etattachment.item.length
				) {
					setEtattachment(response.data.etattachment.item);
				}
				if (
					response.data.etattachmentclosed &&
					response.data.etattachmentclosed.item &&
					response.data.etattachmentclosed.item.length
				) {
					setEtattachmentclosed(response.data.etattachmentclosed.item);
				}
			}
			//
			setDetailData(response.data);
			//
			setEtype(response.data.etype);
			setInternalInfo(
				response.data.internalInfo ? response.data.internalInfo : ''
			);
			setInternalRefNum(
				response.data.internalRefNum ? response.data.internalRefNum : ''
			);
			//
			if (response.data.edescrtext.includes('//Open')) {
				let edescrtext = response.data.edescrtext;
				edescrtext = edescrtext.replace('//Open', '');
				set0riginedescrtext(edescrtext);
				// detect
				// const detectLang = await detectHandle(edescrtext);
				// data.detectLang = detectLang;
				//
				const indexFind = await db.nc.get({ qmnum: nc, report: report });
				console.log('indexFind', indexFind);
				if (indexFind) {
					let edescrtextTemp = await translateHandle(
						edescrtext,
						indexFind.lang
					);
					// let edescrtextTemp = edescrtext;
					toggleLoading();
					console.log(11111111111, edescrtextTemp);
					setEdescrtext(edescrtextTemp);
				} else {
					toggleLoading();
					setEdescrtext(edescrtext);
				}
				//
			} else if (response.data.edescrtext.includes('//Closed')) {
				let edescrtext = response.data.edescrtext;
				edescrtext = edescrtext.replace('//Closed', '');
				set0riginedescrtext(edescrtext);
				// detect
				// const detectLang = await detectHandle(edescrtext);
				// data.detectLang = detectLang;
				//
				const indexFind = await db.nc.get({ qmnum: nc, report: report });
				if (indexFind) {
					let edescrtextTemp = await translateHandle(
						edescrtext,
						indexFind.lang
					);
					// let edescrtextTemp = edescrtext;
					toggleLoading();
					setEdescrtext(edescrtextTemp);
				} else {
					toggleLoading();
					setEdescrtext(edescrtext);
				}
			} else {
				let edescrtext = response.data.edescrtext;
				set0riginedescrtext(edescrtext);
				// detect
				// const detectLang = await detectHandle(edescrtext);
				// data.detectLang = detectLang;
				//
				const indexFind = await db.nc.get({ qmnum: nc, report: report });
				console.log('indexFind', indexFind);
				if (indexFind && edescrtext) {
					let edescrtextTemp = await translateHandle(
						edescrtext,
						indexFind.lang
					);
					// let edescrtextTemp = edescrtext;
					toggleLoading();
					setEdescrtext(edescrtextTemp);
				} else {
					toggleLoading();
					setEdescrtext(edescrtext);
				}
			}
			//

			// detail data
			setDetailPageDTO(response.data.detailPageDTO);
			// file attachment
			if (
				response.data.etAttachmentDTO &&
				response.data.etAttachmentDTO.downlaodAttachmentDTOList
			) {
				setEtAttachmentDTO(
					response.data.etAttachmentDTO.downlaodAttachmentDTOList
				);
			}

			//
			if (
				response.data.qpgrcauseDTO &&
				response.data.qpgrcauseDTO.qpgtDTOList.length === 0
			) {
				// setCanbeSubmit(false);
			}

			// Main Cause
			if (
				response.data.qpgrcauseDTO &&
				response.data.qpgrcauseDTO.qpgtDTOList
			) {
				let temp = response.data.qpgrcauseDTO.qpgtDTOList.map((item) => {
					let obj = {
						value: item.codegruppe,
						label: item.kurztext,
					};
					return obj;
				});
				setQpgrcauseDTO(temp);
			}
			// Main Action
			if (response.data.qpgrcorrDTO && response.data.qpgrcorrDTO.qpgtDTOList) {
				let temp = response.data.qpgrcorrDTO.qpgtDTOList.map((item) => {
					let obj = {
						value: item.codegruppe,
						label: item.kurztext,
					};
					return obj;
				});
				setQpgrcorrDTO(temp);
			}
			//
			setCheckInterval(1000);
		}
		if (isUserLogin) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUserLogin]);

	useEffect(() => {
		bgHandler(false, false, IMAGlandingBig, false);
	}, [bgHandler]);

	// const detectHandle = async (text) => {
	// 	let translateObj = [
	// 		{
	// 			Text: text,
	// 		},
	// 	];
	// 	let res = await axios.post(
	// 		'/feedbackServices/translator/detect',
	// 		translateObj
	// 	);
	// 	return res.data[0].language ? res.data[0].language : '';
	// };

	const translateHandle = async (text, to) => {
		let translateObj = {
			to: to,
			texts: [
				{
					Text: text,
				},
			],
		};
		// if (to === 'fr') {
		// 	let res = await axios.post(
		// 		'/feedbackServices/translator/translateV2',
		// 		translateObj
		// 	);
		// 	return res.data[0].translations[0].text;
		// } else {
		// 	let res = await axios.post(
		// 		'/feedbackServices/translator/translate',
		// 		translateObj
		// 	);
		// 	return res.data[0].translations[0].text;
		// }
		let res = await axios.post(
			'/feedbackServices/translator/translate',
			translateObj
		);
		return res.data[0].translations[0].text;
	};

	const lockResFetch = async () => {
		if (typeuser === 'preview') {
			//
		} else {
			if (status === 'open' || status === 'draft') {
				const [checkLockErr, checkLockRes] = await awaitWrap(
					axios.get(`/feedbackServices/apiResource/getResource/${quaryData.nc}`)
				);
				console.log(checkLockErr);
				console.log(checkLockRes);
				if (checkLockErr && checkLockErr.data === '') {
					let user = JSON.parse(localStorage.getItem('NCM_User'));
					let lockRes = await axios.post(
						`/feedbackServices/apiResource/lockResource/${quaryData.nc}?qmnum=${quaryData.nc}&userid=${user.email}`,
						{}
					);
					console.log(lockRes);
				}
				if (
					checkLockRes &&
					checkLockRes.data &&
					checkLockRes.data.isUnderEdit === 'false'
				) {
					let user = JSON.parse(localStorage.getItem('NCM_User'));
					let lockRes = await axios.post(
						`/feedbackServices/apiResource/lockResource/${quaryData.nc}?qmnum=${quaryData.nc}&userid=${user.email}`,
						{}
					);
					console.log(lockRes);
				}
				localStorage.setItem('NCM_lockId', quaryData.nc);
			}
		}
	};

	const unLockFetch = async () => {
		if (typeuser === 'preview') {
			//
		} else {
			if (status === 'open' || status === 'draft') {
				setLockInterval(undefined);
				let user = JSON.parse(localStorage.getItem('NCM_User'));
				const [checkLockErr, checkLockRes] = await awaitWrap(
					axios.get(`/feedbackServices/apiResource/getResource/${quaryData.nc}`)
				);
				console.log(checkLockErr);
				console.log(checkLockRes);
				if (
					checkLockRes &&
					checkLockRes.data &&
					checkLockRes.data.isUnderEdit === 'true'
				) {
					let nc = localStorage.getItem('NCM_lockId');
					if (nc && nc !== '') {
						const [err, unlockRes] = await awaitWrap(
							axios.post(
								`feedbackServices/apiResource/updateResource?qmnum=${nc}&flag=false&userId=${user.email}`,
								{}
							)
						);
						localStorage.removeItem('NCM_lockId');
						console.log(err, unlockRes);
					}
				}
			}
		}
	};

	const iconError = () => {
		return (
			<>
				<img className="ICONinfo" alt="Error" src={ICONinfo} />
			</>
		);
	};

	const btn = (
		<Button type="primary" size="small" onClick={() => saveAsDraft()}>
			{t('Detail.Saveasdraft')}
		</Button>
	);

	const descriptionHtml = () => {
		return (
			<Fragment>
				<span>{t('Detail.417Error')}</span>
				<br />
				<button
					className={`btn-back-to-start ${localStorage.getItem('NCM_lang')}`}
					onClick={() => sendForm()}
				>
					<span>{t('Detail.TryAgain')}</span>
				</button>
			</Fragment>
		);
	};

	const sendForm = async () => {
		notification.destroy();
		let lang = '';
		if (localStorage.getItem('NCM_lang') === 'en') {
			lang = 'E';
		} else if (localStorage.getItem('NCM_lang') === 'de') {
			lang = 'D';
		} else if (localStorage.getItem('NCM_lang') === 'fr') {
			lang = 'F';
		} else if (localStorage.getItem('NCM_lang') === 'es') {
			lang = 'S';
		} else if (localStorage.getItem('NCM_lang') === 'pt') {
			lang = 'P';
		}
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		//
		let payload = {};
		//
		let loggedinId = '';
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		if (userBak && JSON.stringify(userBak) !== '{}') {
			loggedinId = userBak.email;
		} else {
			loggedinId = user.email;
		}
		//
		payload.loggedinId = loggedinId;
		//
		let flag = false;
		// contentDetail form check
		if (
			contentDetailRef.current.internalRefNum !== '' &&
			contentDetailRef.current.internalRefNum.length > 20
		) {
			contentDetailRef.current.internalRefNumError();
			showErrorTip();
			flag = true;
			return;
		}
		if (
			contentDetailRef.current.internalInfo !== '' &&
			contentDetailRef.current.internalInfo.length > 400
		) {
			contentDetailRef.current.internalInfoError();
			showErrorTip();
			flag = true;
			return;
		}
		//
		for (let i = 0; i < fileUploadRef.current.itattachments.length; i++) {
			if (fileUploadRef.current.itattachments[i].errorMessage) {
				flag = true;
				return;
			}
		}
		//
		let itattachments = [];
		if (
			fileUploadRef.current.itattachments &&
			fileUploadRef.current.itattachments.length
		) {
			for (let i = 0; i < fileUploadRef.current.itattachments.length; i++) {
				if (fileUploadRef.current.itattachments[i].content !== '') {
					let obj = {};
					obj.content = fileUploadRef.current.itattachments[i].content;
					obj.filename = fileUploadRef.current.itattachments[i].filename;
					itattachments.push(obj);
				} else {
					flag = true;
				}
			}
		}
		payload.itattachments = itattachments;
		payload.imdetails = detailData.detailPageDTO;
		payload.imdescrtext = edescrtext;
		payload.imemailresponsible = quaryData.email;
		payload.imlang = lang;
		payload.imqmnum = quaryData.nc;
		payload.imreport = quaryData.report;
		payload.internalInfo = contentDetailRef.current.internalInfo
			? contentDetailRef.current.internalInfo
			: '';
		payload.internalRefNum = contentDetailRef.current.internalRefNum
			? contentDetailRef.current.internalRefNum
			: '';
		payload.imemaillogin = user.email;
		if (isJustified) {
			// contentB form check
			if (
				!contentBRef.current.reasonForm ||
				contentBRef.current.reasonForm === ''
			) {
				contentBRef.current.inputError();
				// showErrorTip();
				flag = true;
			}
			payload.imujstf = 'X';
			payload.itcorrection = [
				{ lgtext: contentBRef.current.reasonForm.replaceAll(';', ',') },
			];
			payload.reasoning = contentBRef.current.reasonForm.replaceAll(';', ',');
			//
		} else {
			console.log('contentA1Ref', contentA1Ref);
			console.log('contentDetailRef', contentDetailRef);
			console.log(
				'contentA2Ref',
				JSON.parse(JSON.stringify(contentA2Ref.current.actionForm.ACAData))
			);
			// contentA1 form check
			if (
				!contentA1Ref.current.mainCauseValue ||
				contentA1Ref.current.mainCauseValue === ''
			) {
				showErrorTip();
				flag = true;
				contentA1Ref.current.mainCauseError();
				// return
			}
			if (
				!contentA1Ref.current.detailCauseValue ||
				contentA1Ref.current.detailCauseValue === ''
			) {
				showErrorTip();
				flag = true;
				contentA1Ref.current.detailCauseError();
				// return
			}
			if (!contentA1Ref.current.urtxt || contentA1Ref.current.urtxt === '') {
				showErrorTip();
				flag = true;
				contentA1Ref.current.descriptionCauseError();
				// return
			}
			//
			payload.imujstf = '';
			payload.reasoning = '';
			// imcause
			let imcause = JSON.parse(
				JSON.stringify(contentA1Ref.current.detailCauseObj)
			);
			//
			payload.imcause = {
				katalogart: imcause.katalogart,
				codegruppe: imcause.codegruppe,
				code: imcause.code,
				codetext: imcause.kurztext,
				codegrptext: contentA1Ref.current.mainCauseLabel,
				urtxt: contentA1Ref.current.urtxt.replaceAll(';', ','),
			};
			// itcorrection
			payload.itcorrection = [];
			let ACAData = JSON.parse(
				JSON.stringify(contentA2Ref.current.actionForm.ACAData)
			);
			if (ACAData.length) {
				for (let i = 0; i < ACAData.length; i++) {
					// contentA2 form check
					if (
						!ACAData[i].mainActionValue ||
						ACAData[i].mainActionValue === ''
					) {
						showErrorTip();
						flag = true;
						contentA2Ref.current.mainActionError(i);
						// return
					}
					if (
						!ACAData[i].detailActionValue ||
						ACAData[i].detailActionValue === ''
					) {
						showErrorTip();
						flag = true;
						contentA2Ref.current.detailActionError(i);
						// return
					}
					if (!ACAData[i].matxt || ACAData[i].matxt === '') {
						showErrorTip();
						flag = true;
						contentA2Ref.current.InputError(i, 'matxt', 0);
						// return
					}
					if (!ACAData[i].usrname || ACAData[i].usrname === '') {
						showErrorTip();
						flag = true;
						contentA2Ref.current.InputError(i, 'usrname', 1);
						// return
					}
					if (!ACAData[i].peter || ACAData[i].peter === '') {
						showErrorTip();
						flag = true;
						contentA2Ref.current.InputError(i, 'peter', 2);
						// return
					}
					if (!ACAData[i].lgtext || ACAData[i].lgtext === '') {
						showErrorTip();
						flag = true;
						contentA2Ref.current.InputError(i, 'lgtext', 1);
						// return
					}
					if (ACAData[i].usrname.length > 35) {
						contentA2Ref.current.inputError1000('usrname', i, 1);
						scrollToptimer();
						flag = true;
					}
					let obj = {
						katalogart: ACAData[i].detailActionObj.katalogart,
						codegruppe: ACAData[i].detailActionObj.codegruppe,
						code: ACAData[i].detailActionObj.code,
						codetext: ACAData[i].detailActionObj.kurztext,
						codegrptext: ACAData[i].mainActionLabel,
						matxt: ACAData[i].matxt.replaceAll(';', ','),
						usrname: ACAData[i].usrname.replaceAll(';', ','),
						peter: ACAData[i].peter,
						lgtext: ACAData[i].lgtext.replaceAll(';', ','),
					};
					payload.itcorrection.push(obj);
				}
			}
		}
		// langu
		payload.langu = formData.langu;
		//
		payload.imreportid = reportid;
		payload.targetSystem = targetSystem;
		console.log('Files to send: ', payload);
		//
		if (flag) {
			return;
		}
		//
		toggleLoading();
		const response = await axios.post(
			'/feedbackServices/postFeedback/saveFeedback',
			payload
		);
		console.log(response);
		if (response.data.ecode === 0) {
			//
			if (isJustified) {
				// await axios.post(
				// 	`/feedbackServices/portalSap/deleteInternalRefInfo?qmnum=${payload.imqmnum}&report=${payload.imreport}`
				// );
				// await axios.post(
				// 	`/feedbackServices/portalSap/deleteNCJustification?qmnum=${payload.imqmnum}&report=${payload.imreport}`
				// );
			} else {
				// await axios.post(
				// 	`/feedbackServices/portalSap/deleteInternalRefInfo?qmnum=${payload.imqmnum}&report=${payload.imreport}`
				// );
				// await axios.post(
				// 	`/feedbackServices/portalSap/deleteNCJustification?qmnum=${payload.imqmnum}&report=${payload.imreport}`
				// );
			}
			//
			toggleLoading();
			//
			let notificationObj = {
				type: 'success',
				title: t('Detail.submitSuccess1'),
				description: t('Detail.submitSuccess2'),
				duration: 5,
			};
			window.removeEventListener('beforeunload', beforeunloadHandler);
			setCheckInterval(undefined);
			setIsPrompt(false);
			changeDetailIsPrompt(false);
			history.replace('/overview');
			toastMessageShow(notificationObj);
		} else if (response.data.ecode === 1) {
			toggleLoading();
			notification.open({
				message: t('Detail.417ErrorTitle'),
				description: descriptionHtml(),
				icon: iconError(),
				duration: 0,
				btn,
			});
		} else {
			toggleLoading();
			notification.open({
				message: 'Server Error',
				description: response.data.emessage,
				icon: iconError(),
				duration: 0,
				btn,
			});
		}
	};

	const saveAsDraft = async () => {
		notification.destroy();
		let flag = false;
		if (
			contentDetailRef.current.internalRefNum !== '' &&
			contentDetailRef.current.internalRefNum.length > 20
		) {
			contentDetailRef.current.internalRefNumError();
			showErrorTip();
			flag = true;
			return;
		}
		if (
			contentDetailRef.current.internalInfo !== '' &&
			contentDetailRef.current.internalInfo.length > 400
		) {
			contentDetailRef.current.internalInfoError();
			showErrorTip();
			flag = true;
			return;
		}
		// let obj = {
		// 	"qmnum": "20210504",
		// 	"carrier": "78945", ---
		// 	"carr_name": "NCM", ---
		// 	"ls_kdauf": "SalesOrder1234", ---
		// 	"ls_kdpos": 123, ---
		// 	"ls_vbeln": "delivery", ---
		// 	"ls_posnr": 1, ---
		// 	"country": "Germany",
		// 	"recipient": "John",
		// 	"maktx": "Pesticide",
		// 	"trdatum": "21/05/2021",
		// 	"kfznumber": "1234",
		// 	"pronum": "pronum",
		// 	"dt_ship": "ship", ---
		// 	"qmtxt": "short text",
		// 	"cause_codegruppe": "CGrp001",
		// 	"cause_code": "001",
		// 	"cause_urtxt": "parameter string",
		// 	"corr_codegruppe": ["Corr001, Corr002"],
		// 	"corr_katalogart": ["Kata001, Kata002"],
		// 	"corr_code": ["001, 002"],
		// 	"corr_usrname": ["mail@mail.com, mail2@mail2.com"],
		// 	"corr_peter": ["21/05/2021, 28/05/2021"],
		// 	"corr_matxt": ["code text ", "code text 2"],
		// 	"corr_lgtext": ["corr paarmeter string, string2"],
		// 	"attachment": "upload.pdf", ---
		// 	"uploadAttachment": true ----
		// }
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		//
		for (let i = 0; i < fileUploadRef.current.itattachments.length; i++) {
			if (fileUploadRef.current.itattachments[i].errorMessage) {
				flag = true;
				return;
			}
		}
		//
		let itattachments = [];
		//
		// for (let i = 0; i < etAttachmentDTO.length; i++) {
		// 	let obj = {};
		// 	//
		// 	let temp = etAttachmentDTO[i].filename.split('.');
		// 	let fileType = temp[temp.length - 1].toLowerCase();
		// 	let mime = getBase64Type(fileType);
		// 	obj.content = `${mime}${etAttachmentDTO[i].filedet}`;
		// 	obj.filename = etAttachmentDTO[i].filename;
		// 	obj.extension = `.${fileType}`;
		// 	obj.description = 'FROM_SAP';
		// 	itattachments.push(obj);
		// }
		for (let i = 0; i < fileUploadRef.current.itattachments.length; i++) {
			if (fileUploadRef.current.itattachments[i].content !== '') {
				let obj = {};
				obj.content = fileUploadRef.current.itattachments[i].content;
				obj.filename = fileUploadRef.current.itattachments[i].filename;
				let temp = fileUploadRef.current.itattachments[i].filename.split('.');
				let extension = temp[temp.length - 1].toLowerCase();
				obj.extension = `.${extension}`;
				obj.size = fileUploadRef.current.itattachments[i].size + '';
				// obj.description = 'FROM_SAP';
				itattachments.push(obj);
			}
		}
		//
		let postObj = {
			report: report,
			is_mjust: '',
			reasoning: '',
			qmnum: detailData.detailPageDTO.qmnum,
			country: detailData.detailPageDTO.country,
			recipient: detailData.detailPageDTO.recipient,
			maktx: detailData.detailPageDTO.maktx,
			// trdatum: moment(detailData.detailPageDTO.trdatum).format('DD/MM/YYYY'),
			trdatum: moment().format('DD/MM/YYYY'),
			kfznumber: detailData.detailPageDTO.kfznumber,
			pronum: detailData.detailPageDTO.kfznumber,
			qmtxt: detailData.detailPageDTO.qmtxt,
			cause_codegruppe: '',
			cause_katalogart: '',
			cause_code: '',
			cause_maindesc: '',
			cause_detaildesc: '',
			cause_urtxt: '',
			corr_codegruppe: [],
			corr_katalogart: [],
			corr_code: [],
			action_maindesc: [],
			action_detaildesc: [],
			corr_usrname: [],
			corr_peter: [],
			corr_matxt: [],
			corr_lgtext: [],
			itattachments: itattachments,
			uploadAttachment: fileUploadRef.current.itattachments.length
				? true
				: false,
			lastLoggedInUser: `${user.firstName} ${user.lastName}`,
			internalInfo: contentDetailRef.current.internalInfo,
			internalRefNum: contentDetailRef.current.internalRefNum,
			refDocNo: refDocNo,
		};
		//
		let loggedinId = '';
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		if (userBak && JSON.stringify(userBak) !== '{}') {
			loggedinId = userBak.email;
		} else {
			loggedinId = user.email;
		}
		//
		postObj.loggedinId = loggedinId;
		postObj.imResposible = user.email;
		//
		if (id !== '') {
			postObj.id = id;
		}

		if (isJustified) {
			console.log('contentA1Ref', contentBRef.current);
			postObj['is_mjust'] = 'X';
			//
			postObj['reasoning'] = contentBRef.current.reasonForm;
		} else {
			// contentA1
			// console.log('contentA1Ref', contentA1Ref.current);
			let detailCauseObj = JSON.parse(
				JSON.stringify(contentA1Ref.current.detailCauseObj)
			);
			if (detailCauseObj && JSON.stringify(detailCauseObj) !== '{}') {
				postObj['cause_code'] = detailCauseObj.code;
				postObj['cause_codegruppe'] = detailCauseObj.codegruppe;
				postObj['cause_katalogart'] = detailCauseObj.katalogart;
				postObj['cause_maindesc'] = contentA1Ref.current.mainCauseLabel;
				postObj['cause_detaildesc'] = detailCauseObj.kurztext;
			} else {
				postObj['cause_code'] = '';
				postObj['cause_codegruppe'] = '';
				postObj['cause_katalogart'] = '';
				postObj['cause_maindesc'] = '';
				postObj['cause_detaildesc'] = '';
			}
			//
			postObj['cause_urtxt'] = contentA1Ref.current.urtxt;
			// contentA2
			console.log(
				'contentA2Ref',
				JSON.parse(JSON.stringify(contentA2Ref.current.actionForm.ACAData))
			);
			let ACAData = JSON.parse(
				JSON.stringify(contentA2Ref.current.actionForm.ACAData)
			);
			console.log(ACAData);
			for (let i = 0; i < ACAData.length; i++) {
				if (ACAData[i].peter !== '') {
					postObj['corr_peter'].push(
						moment(ACAData[i].peter).format('DD/MM/YYYY')
					);
				} else {
					postObj['corr_peter'].push(ACAData[i].peter);
				}
				if (ACAData[i].usrname.length > 35) {
					contentA2Ref.current.inputError1000('usrname', i, 1);
					scrollToptimer();
					flag = true;
				}
				//
				postObj['corr_usrname'].push(ACAData[i].usrname);
				postObj['corr_matxt'].push(ACAData[i].matxt);
				postObj['corr_lgtext'].push(ACAData[i].lgtext);
				//
				if (
					ACAData[i].detailActionObj &&
					JSON.stringify(ACAData[i].detailActionObj) !== '{}'
				) {
					postObj['corr_codegruppe'].push(
						ACAData[i].detailActionObj.codegruppe
					);
					postObj['corr_katalogart'].push(
						ACAData[i].detailActionObj.katalogart
					);
					postObj['corr_code'].push(ACAData[i].detailActionObj.code);
					postObj['action_maindesc'].push(ACAData[i].mainActionLabel);
					postObj['action_detaildesc'].push(
						ACAData[i].detailActionObj.kurztext
					);
				} else {
					postObj['corr_codegruppe'].push('');
					postObj['corr_katalogart'].push('');
					postObj['corr_code'].push('');
					postObj['action_maindesc'].push('');
					postObj['action_detaildesc'].push('');
				}
			}
		}
		console.log(123, postObj);
		// return;
		if (flag) {
			return;
		}
		//
		toggleLoading();
		//
		const response = await axios.post(
			'/feedbackServices/api/submitDraftFeedback',
			postObj
		);
		// const [err, response] = await awaitWrap(
		// 	axios.post('/feedbackServices/api/submitDraftFeedback', postObj)
		// );
		console.log(response);
		toggleLoading();
		//
		window.removeEventListener('beforeunload', beforeunloadHandler);
		setCheckInterval(undefined);
		setIsPrompt(false);
		changeDetailIsPrompt(false);
		//
		// await unLockFetch();
		//
		let notificationObj = {
			type: 'success',
			title: t('Detail.draftSuccess1'),
			description: t('Detail.draftSuccess2'),
			duration: 5,
		};
		history.replace('/overview');
		toastMessageShow(notificationObj);
	};

	const toggleAll = () => {
		setFalse();
		setIsJustified(!isJustified);
	};

	const showModal = () => {
		setCheckInterval(undefined);
		setIsPrompt(false);
		changeDetailIsPrompt(false);
		setIsModalVisible(true);
	};

	const handleOk = async () => {
		if (userProfileDetail) {
			window.removeEventListener('beforeunload', beforeunloadHandler);
			history.replace('/landing');
			let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
			localStorage.removeItem('NCM_User_bak');
			localStorage.setItem('NCM_User', JSON.stringify(userBak));
			setTimeout(() => {
				window.location.reload();
			}, 10);
			return;
		}
		//
		// await unLockFetch();
		//
		window.removeEventListener('beforeunload', beforeunloadHandler);
		history.replace(promptLoc);
		// setIsModalVisible(false);
		// history.replace('/overview');
		// history.replace(promptLoc);
	};

	const handleCancel = () => {
		setCheckInterval(1000);
		setIsPrompt(true);
		changeDetailIsPrompt(true);
		setIsModalVisible(false);
		//
		changeUserProfileDetail(false);
	};

	const handleOk1 = async () => {
		window.removeEventListener('beforeunload', beforeunloadHandler);
		changeLanguagePopup(false);
		let lang = localStorage.getItem('NCM_lang_bak');
		i18n.changeLanguage(lang);
		localStorage.setItem('NCM_lang', lang);
		localStorage.removeItem('NCM_lang_bak');
		window.location.reload();
	};

	const handleCancel1 = () => {
		changeLanguagePopup(false);
		localStorage.removeItem('NCM_lang_bak');
	};

	useKeyPress(['ctrl.p'], () => {
		if (typeuser === 'preview') {
			return;
		} else {
			if (status === 'open') {
				printopen();
			} else if (status === 'draft') {
				printdraft();
			} else if (status === 'closed') {
				print();
			}
		}
	});

	const print = () => {
		PrintClosedRef.current.print();
	};

	const printdraft = () => {
		let reasoning = '';
		let attachment = [];
		let cause = {};
		let action = [];
		let internalRefNum = contentDetailRef.current.internalRefNum;
		let internalInfo = contentDetailRef.current.internalInfo;
		//
		for (let i = 0; i < fileUploadRef.current.itattachments.length; i++) {
			if (fileUploadRef.current.itattachments[i].content !== '') {
				attachment.push(fileUploadRef.current.itattachments[i].filename);
			}
		}
		//
		if (isJustified) {
			reasoning = contentBRef.current.reasonForm;
		} else {
			//
			let detailCauseObj = JSON.parse(
				JSON.stringify(contentA1Ref.current.detailCauseObj)
			);
			if (detailCauseObj && JSON.stringify(detailCauseObj) !== '{}') {
				cause['maindesc'] = contentA1Ref.current.mainCauseLabel;
				cause['detaildesc'] = detailCauseObj.kurztext;
			} else {
				cause['maindesc'] = '';
				cause['detaildesc'] = '';
			}
			cause['urtxt'] = contentA1Ref.current.urtxt;
			//
			let ACAData = JSON.parse(
				JSON.stringify(contentA2Ref.current.actionForm.ACAData)
			);
			for (let i = 0; i < ACAData.length; i++) {
				let obj = {};
				if (ACAData[i].peter !== '') {
					obj['peter'] = moment(ACAData[i].peter).format('YYYY-MM-DD');
				} else {
					obj['peter'] = ACAData[i].peter;
				}
				obj['usrname'] = ACAData[i].usrname;
				obj['matxt'] = ACAData[i].matxt;
				obj['lgtext'] = ACAData[i].lgtext;
				//
				if (
					ACAData[i].detailActionObj &&
					JSON.stringify(ACAData[i].detailActionObj) !== '{}'
				) {
					obj['maindesc'] = ACAData[i].mainActionLabel;
					obj['detaildesc'] = ACAData[i].detailActionObj.kurztext;
				} else {
					obj['maindesc'] = '';
					obj['detaildesc'] = '';
				}
				action.push(obj);
			}
		}
		PrintDraftRef.current.print(
			reasoning,
			attachment,
			cause,
			action,
			internalRefNum,
			internalInfo
		);
	};

	const printopen = () => {
		let reasoning = '';
		let attachment = [];
		let cause = {};
		let action = [];
		let internalRefNum = contentDetailRef.current.internalRefNum;
		let internalInfo = contentDetailRef.current.internalInfo;

		//
		for (let i = 0; i < fileUploadRef.current.itattachments.length; i++) {
			if (fileUploadRef.current.itattachments[i].content !== '') {
				attachment.push(fileUploadRef.current.itattachments[i].filename);
			}
		}
		//
		if (isJustified) {
			reasoning = contentBRef.current.reasonForm;
		} else {
			//
			let detailCauseObj = JSON.parse(
				JSON.stringify(contentA1Ref.current.detailCauseObj)
			);
			if (detailCauseObj && JSON.stringify(detailCauseObj) !== '{}') {
				cause['maindesc'] = contentA1Ref.current.mainCauseLabel;
				cause['detaildesc'] = detailCauseObj.kurztext;
			} else {
				cause['maindesc'] = '';
				cause['detaildesc'] = '';
			}
			cause['urtxt'] = contentA1Ref.current.urtxt;
			//
			let ACAData = JSON.parse(
				JSON.stringify(contentA2Ref.current.actionForm.ACAData)
			);
			for (let i = 0; i < ACAData.length; i++) {
				let obj = {};
				if (ACAData[i].peter !== '') {
					obj['peter'] = moment(ACAData[i].peter).format('YYYY-MM-DD');
				} else {
					obj['peter'] = ACAData[i].peter;
				}
				obj['usrname'] = ACAData[i].usrname;
				obj['matxt'] = ACAData[i].matxt;
				obj['lgtext'] = ACAData[i].lgtext;
				//
				if (
					ACAData[i].detailActionObj &&
					JSON.stringify(ACAData[i].detailActionObj) !== '{}'
				) {
					obj['maindesc'] = ACAData[i].mainActionLabel;
					obj['detaildesc'] = ACAData[i].detailActionObj.kurztext;
				} else {
					obj['maindesc'] = '';
					obj['detaildesc'] = '';
				}
				action.push(obj);
			}
		}
		PrintOpenRef.current.print(
			reasoning,
			attachment,
			cause,
			action,
			internalRefNum,
			internalInfo
		);
	};

	const scrollToptimer = () => {
		const timer = setInterval(function () {
			var top = document.body.scrollTop || document.documentElement.scrollTop;
			var speed = top / 4;
			if (document.body.scrollTop !== 0) {
				document.body.scrollTop -= speed;
			} else {
				document.documentElement.scrollTop -= speed;
			}
			if (top === 0) {
				clearInterval(timer);
			}
		}, 10);
	};

	const showErrorTip = () => {
		scrollToptimer();
		setTrue();
	};

	const changeLongtext = (val) => {
		if (val) {
			setEdescrtext(val);
		}
	};

	const boxJustification = () => {
		if (typeuser === 'preview') {
			return (
				<div className="boxJustification">
					<p>{t('Detail.NonConformanceunjustified')}</p>
					<button className="btnJustify">
						{isJustified ? (
							<img alt="Justified" src={ICONradioONGrey} />
						) : (
							<img alt="Not Justified" src={ICONradioOFF} />
						)}
					</button>
				</div>
			);
		} else {
			if (status === 'open' || status === 'draft') {
				return (
					<div className="boxJustification">
						<p>{t('Detail.NonConformanceunjustified')}</p>
						<button className="btnJustify" onClick={() => toggleAll()}>
							{isJustified ? (
								<img alt="Justified" src={ICONradioON} />
							) : (
								<img alt="Not Justified" src={ICONradioOFF} />
							)}
						</button>
					</div>
				);
			} else {
				return (
					<div className="boxJustification">
						<p>{t('Detail.NonConformanceunjustified')}</p>
						<button className="btnJustify">
							{isJustified ? (
								<img alt="Justified" src={ICONradioONGrey} />
							) : (
								<img alt="Not Justified" src={ICONradioOFF} />
							)}
						</button>
					</div>
				);
			}
		}
	};

	const contentA1func = () => {
		if (typeuser === 'preview') {
			if (status === 'open') {
				return (
					<Content1Preview
						codegrptext=""
						codetext=""
						urtxt=""
					></Content1Preview>
				);
			} else if (status === 'draft') {
				return (
					<Content1Preview
						codegrptext={fetchDetailData['cause_maindesc']}
						codetext={fetchDetailData['cause_maindesc']}
						urtxt={fetchDetailData['cause_urtxt']}
					></Content1Preview>
				);
			} else {
				return (
					<ContentA1Closed etcauseclosed={etcauseclosed}></ContentA1Closed>
				);
			}
		} else {
			if (status === 'open' || status === 'draft') {
				return (
					<ContentA1
						ref={contentA1Ref}
						qpgrcauseDTO={qpgrcauseDTO}
						detailData={detailData}
						setFalse={setFalse}
						fetchDetailData={fetchDetailData}
					></ContentA1>
				);
			} else {
				return (
					<ContentA1Closed etcauseclosed={etcauseclosed}></ContentA1Closed>
				);
			}
		}
	};

	const contentA2func = () => {
		if (typeuser === 'preview') {
			if (status === 'open') {
				return <Content2Preview fetchDetailData={{}}></Content2Preview>;
			} else if (status === 'draft') {
				return (
					<Content2Preview fetchDetailData={fetchDetailData}></Content2Preview>
				);
			} else {
				return (
					<Content2Closed
						etcorrectionclosed={etcorrectionclosed}
					></Content2Closed>
				);
			}
		} else {
			if (status === 'open' || status === 'draft') {
				return (
					<ContentA2
						status={status}
						ref={contentA2Ref}
						qpgrcorrDTO={qpgrcorrDTO}
						detailData={detailData}
						setFalse={setFalse}
						fetchDetailData={fetchDetailData}
					></ContentA2>
				);
			} else {
				return (
					<Content2Closed
						etcorrectionclosed={etcorrectionclosed}
					></Content2Closed>
				);
			}
		}
	};

	const contentBfunc = () => {
		if (typeuser === 'preview') {
			if (status === 'open' || status === 'draft') {
				return (
					<ContentBClosed
						reasoning={
							fetchDetailData['reasoning'] &&
							fetchDetailData['reasoning'] !== ''
								? fetchDetailData['reasoning']
								: ''
						}
					></ContentBClosed>
				);
			} else {
				return <ContentBClosed reasoning={reasoning}></ContentBClosed>;
			}
		} else {
			if (status === 'open' || status === 'draft') {
				return (
					<ContentB
						ref={contentBRef}
						setFalse={setFalse}
						fetchDetailData={fetchDetailData}
					></ContentB>
				);
			} else {
				return <ContentBClosed reasoning={reasoning}></ContentBClosed>;
			}
		}
	};

	const uploadFile = () => {
		if (typeuser === 'preview') {
			if (status === 'open' || status === 'draft') {
				return (
					<InputFileUploadClosed
						etattachment={
							fetchDetailData['itattachments'] &&
							fetchDetailData['itattachments'].length
								? fetchDetailData['itattachments']
								: []
						}
					></InputFileUploadClosed>
				);
			} else {
				return (
					<InputFileUploadClosedDown
						etattachment={etattachmentclosed}
						reportid={reportid}
						qmnum={qmnum}
						targetSystem={targetSystem}
					></InputFileUploadClosedDown>
				);
			}
		} else {
			if (status === 'open' || status === 'draft') {
				return (
					<Fragment>
						<h5 className="centered">{t('Detail.FileUploadOptional')}</h5>
						<div className="boxInputContainer">
							<InputFileUpload
								ref={fileUploadRef}
								fetchDetailData={fetchDetailData}
							/>
						</div>
					</Fragment>
				);
			} else {
				return (
					<InputFileUploadClosedDown
						etattachment={etattachmentclosed}
						reportid={reportid}
						qmnum={qmnum}
						targetSystem={targetSystem}
					></InputFileUploadClosedDown>
				);
			}
		}
	};

	const buttongroup = () => {
		if (typeuser === 'preview') {
			return (
				// <div className="boxActionsPrint">
				// 	<button className="btnPrint" onClick={print}>
				// 		{t('Detail.Print')}
				// 	</button>
				// </div>
				<Fragment />
			);
		} else {
			if (status === 'open') {
				if (responsive.large && !responsive.huge) {
					return (
						<div className="boxActions1024">
							<button className="btnX" onClick={showModal}>
								<img alt="" src={ICONcloseGrey} />
							</button>
							<div className="wrap1024">
								<button className="btnPrint" onClick={printopen}>
									{t('Detail.Print')}
								</button>
								<button className="btnSave" onClick={saveAsDraft}>
									{t('Detail.Saveasdraft')}
								</button>
								<button className="btnSend" onClick={sendForm}>
									{t('Detail.Save')}
								</button>
							</div>
						</div>
					);
				} else {
					return (
						<div className="boxActions">
							<button className="btnCancel" onClick={showModal}>
								{t('Detail.Cancel')}
							</button>
							<button className="btnPrint" onClick={printopen}>
								{t('Detail.Print')}
							</button>
							<button className="btnSave" onClick={saveAsDraft}>
								{t('Detail.Saveasdraft')}
							</button>
							<button className="btnSend" onClick={sendForm}>
								{t('Detail.Save')}
							</button>
						</div>
					);
				}
			} else if (status === 'draft') {
				if (responsive.large && !responsive.huge) {
					return (
						<div className="boxActions1024">
							<button className="btnX" onClick={showModal}>
								<img alt="" src={ICONcloseGrey} />
							</button>
							<div className="wrap1024">
								<button className="btnPrint" onClick={printdraft}>
									{t('Detail.Print')}
								</button>
								<button className="btnSave" onClick={saveAsDraft}>
									{t('Detail.Saveasdraft')}
								</button>
								<button className="btnSend" onClick={sendForm}>
									{t('Detail.Save')}
								</button>
							</div>
						</div>
					);
				} else {
					return (
						<div className="boxActions">
							<button className="btnCancel" onClick={showModal}>
								{t('Detail.Cancel')}
							</button>
							<button className="btnPrint" onClick={printdraft}>
								{t('Detail.Print')}
							</button>
							<button className="btnSave" onClick={saveAsDraft}>
								{t('Detail.Saveasdraft')}
							</button>
							<button className="btnSend" onClick={sendForm}>
								{t('Detail.Save')}
							</button>
						</div>
					);
				}
			} else {
				return (
					<div className="boxActionsPrint">
						<button className="btnPrint" onClick={print}>
							{t('Detail.Print')}
						</button>
					</div>
				);
			}
		}
	};

	const PrintTemplate = () => {
		if (typeuser === 'preview') {
			return <Fragment />;
		} else {
			if (status === 'draft') {
				return (
					<PrintDraft
						ref={PrintDraftRef}
						quaryData={quaryData}
						etype={etype}
						detailPageDTO={detailPageDTO}
						edescrtext={edescrtext}
						isJustified={isJustified}
						nctype={nctype}
					/>
				);
			} else if (status === 'closed') {
				return (
					<PrintClosed
						ref={PrintClosedRef}
						quaryData={quaryData}
						etype={etype}
						etcauseclosed={etcauseclosed}
						detailPageDTO={detailPageDTO}
						internalInfo={internalInfo}
						internalRefNum={internalRefNum}
						etcorrectionclosed={etcorrectionclosed}
						etattachment={etattachmentclosed}
						edescrtext={edescrtext}
						isJustified={isJustified}
						reasoning={reasoning}
						nctype={nctype}
					/>
				);
			} else if (status === 'open') {
				return (
					<PrintOpen
						ref={PrintOpenRef}
						quaryData={quaryData}
						etype={etype}
						detailPageDTO={detailPageDTO}
						edescrtext={edescrtext}
						isJustified={isJustified}
						nctype={nctype}
					/>
				);
			}
		}
	};

	return (
		<Fragment>
			<Prompt
				message={(loc) => {
					// if (111) {
					// 	return true;
					// }
					setPromptLoc(loc.pathname);
					showModal();
					return false;
				}}
				when={isPrompt}
			/>
			{PrintTemplate()}
			<section className="detail print_hide">
				<Modal
					open={languagePopup}
					className="cancelModal"
					footer={null}
					closable={false}
					getContainer={false}
				>
					<div className="contentinto">
						<div className="title">{t('Detail.cancel1')}</div>
						<div className="content">{t('Detail.cancel3')}</div>
						<div className="buttongroup">
							<button className="btnCancel" onClick={handleCancel1}>
								{t('Detail.Cancel')}
							</button>
							<button className="btnLogin" onClick={handleOk1}>
								{t('Detail.Continue')}
							</button>
						</div>
					</div>
					<div className="closeicon" onClick={handleCancel1}>
						<img alt="close" src={ICONclose} />
					</div>
				</Modal>
				<Modal
					open={isModalVisible}
					className="cancelModal"
					footer={null}
					closable={false}
				>
					<div className="contentinto">
						<div className="title">{t('Detail.cancel1')}</div>
						<div className="content">{t('Detail.cancel2')}</div>
						<div className="buttongroup">
							<button className="btnCancel" onClick={handleCancel}>
								{t('Detail.Cancel')}
							</button>
							<button className="btnLogin" onClick={handleOk}>
								{t('Detail.Continue')}
							</button>
						</div>
					</div>
					<div className="closeicon" onClick={handleCancel}>
						<img alt="close" src={ICONclose} />
					</div>
				</Modal>
				<div className="supplier">
					<div className="boxHeadSupplier">
						<span>{t('Detail.Duedate')}</span>
						<h3>{detailPageDTO.duedate}</h3>
						<button
							className={
								expandedSupplier
									? 'btnToggleSupplier expanded'
									: 'btnToggleSupplier'
							}
							onClick={() => setExpandedSupplier(!expandedSupplier)}
						>
							<img className="expand" alt="Expand" src={ICONdown} />
							<img className="shrink" alt="Shrink" src={ICONup} />
							<span className="lessInfo">show less details</span>
							<span className="moreInfo">show more details</span>
						</button>
					</div>

					{etype === 'S' ? (
						<SupplierContentDetail
							ref={contentDetailRef}
							expandedSupplier={expandedSupplier}
							quaryData={quaryData}
							internalInfo={internalInfo}
							internalRefNum={internalRefNum}
							detailPageDTO={detailPageDTO}
							setFalse={setFalse}
							typeuser={typeuser}
							status={status}
						></SupplierContentDetail>
					) : (
						<HaulierContentDetail
							ref={contentDetailRef}
							expandedSupplier={expandedSupplier}
							quaryData={quaryData}
							internalInfo={internalInfo}
							internalRefNum={internalRefNum}
							detailPageDTO={detailPageDTO}
							setFalse={setFalse}
							status={status}
							nctype={nctype}
						></HaulierContentDetail>
					)}
				</div>

				<div className="feedback">
					<span className={`status ${status}`}>
						{(() => {
							if (status === 'open') {
								return <span>{t('Detail.Open')}</span>;
							} else if (status === 'closed') {
								return <span>{t('Detail.Closed')}</span>;
							} else if (status === 'draft') {
								return <span>{t('Detail.Draft')}</span>;
							}
						})()}
					</span>

					<div className="boxHeadFeedback">
						<span>
							{t('Detail.Createdon')} {detailPageDTO.erdat}
						</span>
						<h3>
							NC {detailPageDTO.qmnum}
							{/* {nctype && nctype.toUpperCase() !== 'Y1' ? ` - ${nctype}` : ''} */}
						</h3>
						<div className="cctext">
							{nctype && nctype.toUpperCase() === 'Y1' ? (
								<>Customer Complaint</>
							) : (
								<Fragment />
							)}
						</div>
					</div>

					<h2>{detailPageDTO.mreason}</h2>

					{edescrtext ? <Longtext val={edescrtext} /> : <Fragment />}

					{edescrtext ? (
						<Translate
							text={originedescrtext}
							changeLongtext={changeLongtext}
							qmnum={qmnum}
							report={report}
						/>
					) : (
						<Fragment />
					)}
					{(status === 'open' || status === 'draft') &&
					etAttachmentDTO &&
					etAttachmentDTO.length ? (
						<Attachment
							etAttachmentDTO={etAttachmentDTO}
							reportid={reportid}
							qmnum={qmnum}
							targetSystem={targetSystem}
						/>
					) : (
						<Fragment />
					)}

					{status === 'closed' && etattachment.length ? (
						<Attachment
							etAttachmentDTO={etattachment}
							reportid={reportid}
							qmnum={qmnum}
							targetSystem={targetSystem}
						/>
					) : (
						<Fragment />
					)}
					{boxJustification()}
					{errorShow ? (
						<div className="errorMessage">
							<span className="img">
								<img className="ICONinfo" alt="Error" src={ICONinfo} />
							</span>
							<span className="title">{t('Support.Pleasefill')}</span>
						</div>
					) : (
						<Fragment />
					)}
					{!isJustified ? (
						<Fragment>
							{contentA1func()}
							{contentA2func()}
						</Fragment>
					) : (
						contentBfunc()
					)}
					{uploadFile()}
					{buttongroup()}
				</div>
			</section>
		</Fragment>
	);
};

export default Detail;
