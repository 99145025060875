import React, { useEffect, useRef } from 'react';
import axios from '../../axios-custom';
import { useHistory } from 'react-router-dom';
import InputFileUpload from '../InputFileUpload/InputFileUpload';
import SupportForm from './component/SupportForm';
import './Support.scss';
import { useTranslation } from 'react-i18next';
import store from '../../state/store';
import { useCaptchaHook } from './component/useCaptchaHook';
import { Tooltip } from 'antd';
import '../../../node_modules/antd/lib/tooltip/style/index.css';
const { useModel } = store;

let siteKey = '';
let secret = '';
let verification =
	'https://dev.ncm.basf.com/feedbackServices/captchaValidator/siteverify';
if (window.location.origin.includes('localhost')) {
	siteKey = 'FCMTO350BKJQ389H';
	secret = 'A1VQ4AH9BENLA8F7QQJ4133RA51UJSFOJG32IIJTTJN1A5F7NDDS6PTOR4';
	verification =
		'https://dev.ncm.basf.com/feedbackServices/captchaValidator/siteverify';
} else if (window.location.origin.includes('dev')) {
	siteKey = 'FCMTO350BKJQ389H';
	secret = 'A1VQ4AH9BENLA8F7QQJ4133RA51UJSFOJG32IIJTTJN1A5F7NDDS6PTOR4';
	verification =
		'https://dev.ncm.basf.com/feedbackServices/captchaValidator/siteverify';
} else if (window.location.origin.includes('qual')) {
	siteKey = 'FCMTO350BNQRDV6M';
	secret = 'A1KV6L7B2G3CAFDQO3VP8JTJV78IG1AEFNM5T4RI9CBANEFA7867V1HU5P';
	verification =
		'https://qual.ncm.basf.com/feedbackServices/captchaValidator/siteverify';
} else {
	siteKey = 'FCMTO350BNMNGMR0';
	secret = 'A1OEOCOICM22LSPETVI05UO4H2LU2ELUBHU5F9KG00UM98J4AHI0OVBVNH';
	verification =
		'https://ncm.basf.com/feedbackServices/captchaValidator/siteverify';
}

const Support = (props) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const { t } = useTranslation();

	const fileUploadRef = useRef(null);
	const supportFormRef = useRef(null);
	let history = useHistory();
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading, toastMessageShow } = dispatchers;

	const captchaHook = useCaptchaHook({
		siteKey,
		secret,
		endpoint: 'GLOBAL1',
		verification,
		language: !localStorage.getItem('NCM_lang')
			? 'en'
			: localStorage.getItem('NCM_lang'),
		startMode: 'none',
		showAttribution: true,
	});

	const validateCaptcha = async () => {
		console.log(JSON.parse(JSON.stringify(supportFormRef.current.supportForm)));
		let itattachments = [];
		if (
			fileUploadRef.current.itattachments &&
			fileUploadRef.current.itattachments.length
		) {
			for (let i = 0; i < fileUploadRef.current.itattachments.length; i++) {
				if (fileUploadRef.current.itattachments[i].content !== '') {
					let obj = {};
					obj.content = fileUploadRef.current.itattachments[i].content;
					obj.filename = fileUploadRef.current.itattachments[i].filename;
					itattachments.push(obj);
				}
			}
		}
		let supportForm = JSON.parse(
			JSON.stringify(supportFormRef.current.supportForm)
		);
		//
		let telephoneNumber = '';
		if (supportForm.countrycode !== '') {
			telephoneNumber = `${supportForm.countrycode}-${supportForm.telephoneNumber}`;
		} else {
			telephoneNumber = supportForm.telephoneNumber;
		}
		//
		let obj = {
			firstName: supportForm.txbFirstName,
			lastName: supportForm.txbLastName,
			emailAddress: supportForm.txbMailAddress,
			telephoneNumber: telephoneNumber,
			subject: supportForm.txaSubject,
			messageText: supportForm.txaMessage,
			attachmnetDto: itattachments,
			lang: localStorage.getItem('NCM_lang'),
		};
		toggleLoading();
		let temp = await axios.post(
			'/feedbackServices/supportFormMailer/sendMail',
			obj
		);
		toggleLoading();
		console.log(temp);
		let notificationObj = {
			type: 'success',
			title: 'Thank you!',
			description: t('Error.successfeedback'),
			duration: 5,
		};
		if (
			!localStorage.getItem('NCM_Token') ||
			localStorage.getItem('NCM_Token') === ''
		) {
			history.replace('/');
		} else {
			history.replace('/landing');
		}
		toastMessageShow(notificationObj);
	};

	useEffect(() => {
		// loadCaptcha();
	}, []);

	useEffect(() => {
		props.bgHandler(false, true, '', false);
	}, [props]);

	const clearForm = () => {
		supportFormRef.current.clearForm();
		fileUploadRef.current.clearAll();
	};

	const sendForm = () => {
		let flag = false;
		let postObj = JSON.parse(
			JSON.stringify(supportFormRef.current.supportForm)
		);
		// countrycodeCheck
		let countryDrop = JSON.parse(
			JSON.stringify(supportFormRef.current.countryDrop)
		);
		if (postObj.countrycode !== '') {
			let findcountryDrop = countryDrop.find((item) => {
				return item.label === postObj.countrycode;
			});
			if (!findcountryDrop) {
				supportFormRef.current.InputError('contryinput', 1);
				flag = true;
			}
		}
		if (postObj.telephoneNumber === '' && postObj.countrycode !== '') {
			supportFormRef.current.InputError('telephoneNumber', 0);
			flag = true;
		}
		if (postObj.telephoneNumber !== '' && postObj.countrycode === '') {
			supportFormRef.current.InputError('contryinput', 0);
			flag = true;
		}
		if (!/^-?[0-9]\d*$/.test(postObj.telephoneNumber.trim())) {
			supportFormRef.current.InputError('telephoneNumber', 1);
			flag = true;
		}
		// first name
		if (postObj.txbFirstName === '') {
			supportFormRef.current.InputError('firstname', 0);
			flag = true;
		} else {
			if (!/^[a-zA-Z][-a-zA-Z]+$/.test(postObj.txbFirstName.trim())) {
				supportFormRef.current.InputError('firstname', 1);
				flag = true;
			}
		}
		// last name
		if (postObj.txbLastName === '') {
			supportFormRef.current.InputError('lastname', 0);
			flag = true;
		} else {
			if (!/^[A-Za-z]+$/.test(postObj.txbLastName.trim())) {
				supportFormRef.current.InputError('lastname', 1);
				flag = true;
			}
		}
		// email
		if (postObj.txbMailAddress === '') {
			supportFormRef.current.InputError('email', 0);
			flag = true;
		} else {
			if (
				// eslint-disable-next-line no-useless-escape
				!/^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/.test(
					postObj.txbMailAddress.trim()
				)
			) {
				supportFormRef.current.InputError('email', 1);
				flag = true;
			}
		}
		// subject
		if (postObj.txaSubject === '') {
			supportFormRef.current.InputError('subject', 0);
			flag = true;
		}
		if (postObj.txaSubject.length > 50) {
			supportFormRef.current.InputError('subject', 1);
			flag = true;
		}
		// message
		if (postObj.txaMessage === '') {
			supportFormRef.current.InputError('message', 0);
			flag = true;
		}
		// attachment
		if (
			fileUploadRef.current.itattachments &&
			fileUploadRef.current.itattachments.length
		) {
			for (let i = 0; i < fileUploadRef.current.itattachments.length; i++) {
				if (fileUploadRef.current.itattachments[i].content === '') {
					flag = true;
				}
			}
		}
		if (flag) {
			scrollToptimer();
			return;
		}
		validateCaptcha();
	};

	const scrollToptimer = () => {
		const timer = setInterval(function () {
			var top = document.body.scrollTop || document.documentElement.scrollTop;
			var speed = top / 4;
			if (document.body.scrollTop !== 0) {
				document.body.scrollTop -= speed;
			} else {
				document.documentElement.scrollTop -= speed;
			}
			if (top === 0) {
				clearInterval(timer);
			}
		}, 30);
	};

	return (
		<section className="support">
			<h2>{t('Support.title')}</h2>
			<p className="information">
				{t('Support.info1')}
				<br />
				<br />
				{t('Support.info21')} <a href="/faq">FAQ</a> {t('Support.info22')}.
				<br />
				<br />
				{t('Support.info3')}{' '}
				<a href="mailto:ncmportal@basf.com">ncmportal@basf.com</a>.
			</p>

			<h3>{t('Support.ContactInformation')}</h3>

			<SupportForm ref={supportFormRef} />

			<h3>{t('Detail.FileUploadOptional')}</h3>

			<InputFileUpload ref={fileUploadRef} />

			<ul className="lstSupport">
				<li className="captchawidget">{captchaHook.CaptchaWidget()}</li>
			</ul>
			<div className="boxInteraction">
				<button className="btnReset" onClick={clearForm}>
					{t('Support.Reset')}
				</button>
				{captchaHook.captchaStatus.solution !== null ? (
					<button className="btnSend" onClick={sendForm}>
						{t('Support.Send')}
					</button>
				) : (
					<Tooltip
						mouseLeaveDelay={0}
						color="#c71526"
						placement="bottomRight"
						title={t('Support.Captchatips')}
						overlayClassName="filetooltip"
					>
						<span className="btnSend1">{t('Support.Send')}</span>
					</Tooltip>
				)}
			</div>
		</section>
	);
};

export default Support;
