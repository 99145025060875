import React, { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoizedFn, useMount, useUpdateEffect } from 'ahooks';
import { DatePicker, Input, Form, Row, Col, Radio } from 'antd';
import '../../../../node_modules/antd/lib/input/style/index.css';
import '../../../../node_modules/antd/lib/form/style/index.css';
import '../../../../node_modules/antd/lib/radio/style/index.css';
import {
	onSubmitRequired,
	onBlurValidateDirtyword,
	onBlurEmpty,
} from './patternRex';

const D7Prevention = React.forwardRef(({ form }, ref) => {
	const { t } = useTranslation();

	const nameValue1 = Form.useWatch('d7_1', form);
	const nameValue2 = Form.useWatch('d7_2', form);
	const nameValue3 = Form.useWatch('d7_3', form);
	const nameValue4 = Form.useWatch('d7_4', form);
	const nameValue5 = Form.useWatch('d7_5', form);
	const nameValue6 = Form.useWatch('d7_6', form);
	const nameValue7 = Form.useWatch('d7_7', form);
	const nameValue8 = Form.useWatch('d7_8', form);
	const nameValue9 = Form.useWatch('d7_9', form);
	const nameValue10 = Form.useWatch('d7_10', form);
	const d7ChoiceValue = Form.useWatch('d7_choice', form);

	// useMount(() => {
	// 	form.setFieldsValue({
	// 		d7_choice: '',
	// 	});
	// });

	useUpdateEffect(() => {
		if (d7ChoiceValue === 'yes') {
			setComponentDisabled('yes');
		} else if (d7ChoiceValue === 'no') {
			setComponentDisabled('no');
		} else {
			//
		}
	}, [d7ChoiceValue]);

	const [componentDisabled, setComponentDisabled] = useState('');
	const onChange = (e) => {
		setComponentDisabled(e.target.value);
	};

	return (
		<div ref={ref}>
			<Row>
				<Col xs={24}>
					<Form.Item
						className="antd_label"
						label=""
						name="d7_prevention"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input placeholder={t('8D.Prevention')} className="antd_input" />
					</Form.Item>
				</Col>
				<Col xs={24}>
					<Form.Item
						className="antd_label"
						label=""
						name="d7_responsible"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input placeholder={t('8D.Responsible')} className="antd_input" />
					</Form.Item>
				</Col>
				<Col xs={24} md={11}>
					<Form.Item
						className="antd_label"
						label=""
						name="d7_datedue"
						rules={[
							{
								required: true,
								message: '',
							},
						]}
					>
						<DatePicker
							style={{ width: '100%' }}
							suffixIcon={<></>}
							allowClear={false}
							placeholder={t('8D.DateDue')}
							format="YYYY-MM-DD"
							showToday={false}
							className="antd_date"
						/>
					</Form.Item>
				</Col>
				<Col xs={24} md={{ span: 11, offset: 2 }}>
					<Form.Item
						label=""
						name="d7_datecompl"
						className="antd_label"
						rules={[
							{
								required: true,
								message: '',
							},
						]}
					>
						<DatePicker
							style={{ width: '100%' }}
							suffixIcon={<></>}
							allowClear={false}
							placeholder={t('8D.DateCompleted')}
							format="YYYY-MM-DD"
							showToday={false}
							className="antd_date"
						/>
					</Form.Item>
				</Col>
				<Col xs={24}>
					<Form.Item
						colon={false}
						label={t('8D.OtherAreasAffected')}
						name="d7_choice"
						extra={componentDisabled === 'yes' ? t('8D.Ifyes') : ''}
						className="antd_label"
						rules={[
							{
								required: true,
								message: 'Please select one',
							},
						]}
					>
						<Radio.Group onChange={onChange}>
							<Radio value="yes"> {t('8D.Yes')} </Radio>
							<Radio value="no"> {t('8D.No')} </Radio>
						</Radio.Group>
					</Form.Item>
				</Col>
				{(() => {
					if (componentDisabled === 'no') {
						return (
							<Col xs={24}>
								<Form.Item
									label=""
									name="d7_11"
									labelCol={{
										span: 6,
									}}
									validateTrigger={['onSubmit', 'onBlur']}
									rules={[onSubmitRequired, onBlurValidateDirtyword]}
								>
									<Input
										placeholder={t('8D.Pleaseexplain')}
										className="antd_input"
									/>
								</Form.Item>
							</Col>
						);
					} else if (componentDisabled === 'yes') {
						return (
							<>
								<Col xs={24} md={11}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label={t('8D.ProcessFMEA')}
										name="d7_1"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input
											placeholder={t('8D.ProcessFMEA')}
											className="antd_input"
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={{ span: 11, offset: 2 }}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label={t('8D.Form')}
										name="d7_2"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input placeholder={t('8D.Form')} className="antd_input" />
									</Form.Item>
								</Col>
								<Col xs={24} md={11}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label={t('8D.ProductFMEA')}
										name="d7_3"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input
											placeholder={t('8D.ProductFMEA')}
											className="antd_input"
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={{ span: 11, offset: 2 }}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label={t('8D.Specification')}
										name="d7_4"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input
											placeholder={t('8D.Specification')}
											className="antd_input"
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={11}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label={t('8D.ControlPlan')}
										name="d7_5"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input
											placeholder={t('8D.ControlPlan')}
											className="antd_input"
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={{ span: 11, offset: 2 }}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label="PPAP"
										name="d7_6"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input placeholder="PPAP" className="antd_input" />
									</Form.Item>
								</Col>
								<Col xs={24} md={11}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label={t('8D.InspectionPlan')}
										name="d7_7"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input
											placeholder={t('8D.InspectionPlan')}
											className="antd_input"
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={{ span: 11, offset: 2 }}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label={t('8D.JobInstruction')}
										name="d7_8"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input
											placeholder={t('8D.JobInstruction')}
											className="antd_input"
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={11}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label={t('8D.Procedure')}
										name="d7_9"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input
											placeholder={t('8D.Procedure')}
											className="antd_input"
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={{ span: 11, offset: 2 }}>
									<Form.Item
										labelCol={{
											span: 12,
										}}
										colon={false}
										label={t('8D.Procedure')}
										name="d7_10"
										className="antd_sub_label"
										validateTrigger={['onSubmit', 'onBlur']}
										rules={[
											onBlurEmpty(
												nameValue1,
												nameValue2,
												nameValue3,
												nameValue4,
												nameValue5,
												nameValue6,
												nameValue7,
												nameValue8,
												nameValue9,
												nameValue10
											),
											onBlurValidateDirtyword,
										]}
									>
										<Input
											placeholder={t('8D.Procedure')}
											className="antd_input"
										/>
									</Form.Item>
								</Col>
							</>
						);
					} else {
						return <></>;
					}
				})()}
			</Row>
		</div>
	);
});

export default memo(D7Prevention);
