import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import VConsole from 'vconsole';

// eslint-disable-next-line no-unused-vars
let vConsole = null;
if (window.location.origin.includes('localhost')) {
	vConsole = new VConsole();
} else if (window.location.origin.includes('dev')) {
	vConsole = new VConsole();
} else if (window.location.origin.includes('qual')) {
	vConsole = new VConsole();
}

const app = (
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));
