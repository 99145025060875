import React, { Fragment, useEffect, useState, memo, useRef } from 'react';
// import { useHistory, Prompt } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import axios from '../../../axios-custom';
import { useReactive, useMemoizedFn, useUnmount } from 'ahooks';
import store from '../../../state/store';
import InputFileUpload from '../../InputFileUploadImage/InputFileUploadImage';
import '@wangeditor/editor/dist/css/style.css';
import RichEditor from './RichEditor';
import _ from 'lodash';
import { Tree, Modal } from 'antd';
import '../../../../node_modules/antd/lib/tree/style/index.css';
import { getBase64Type } from '../../../utils/util';
import TitleModal from './titleModal';
import QuestionModal from './questionModal';
import ICONclose from '../../../assets/icons/closeWhite.svg';
import '../../../../node_modules/antd/lib/modal/style/index.css';
const { useModel } = store;

let oTreeData = [];

// const beforeunloadHandler = (e) => {
// 	e.preventDefault();
// 	e = e || window.event;
// 	if (e) {
// 		// e.returnValue = t('Detail.cancel2');
// 		e.returnValue =
// 			'Your input has not been saved, please save before closing to create a draft.';
// 	}
// 	// return t('Detail.cancel2');
// 	return 'Your input has not been saved, please save before closing to create a draft.';
// };

const Faq = ({ bgHandler, isUserLogin }) => {
	// const { t } = useTranslation();
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading, toastMessageShow } = dispatchers;

	const [allFaqData, setAllFaqData] = useState([]);
	const [treeData, setTreeData] = useState([]);
	const [type, setType] = useState('');

	const [isModalVisible1, setIsModalVisible1] = useState(false);
	const [isModalVisible2, setIsModalVisible2] = useState(false);
	const [isModalVisible3, setIsModalVisible3] = useState(false);
	// const [isModalVisible4, setIsModalVisible4] = useState(false);

	const [isSort, setIsSort] = useState(false);

	// const [isPrompt, setIsPrompt] = useState(false);
	// const [promptLoc, setPromptLoc] = useState('');

	// let history = useHistory();

	let fileUploadRef = useRef(null);
	let editorRef1 = useRef(null);
	let editorRef2 = useRef(null);
	let editorRef3 = useRef(null);
	let editorRef4 = useRef(null);
	let editorRef5 = useRef(null);
	let titleModalRef = useRef(null);
	let questionModal = useRef(null);

	const titleData = useReactive({
		sectionen: '',
		sectionde: '',
		sectionfr: '',
		sectiones: '',
		sectionpt: '',
		id: '',
		hasChildren: false,
	});

	const questionData = useReactive({
		answeren: '',
		questionen: '',
		answerde: '',
		questionde: '',
		answerfr: '',
		questionfr: '',
		answeres: '',
		questiones: '',
		answerpt: '',
		questionpt: '',
		qid: '',
		sid: '',
		ansImageEncoded: '',
	});

	useEffect(() => {
		// window.addEventListener('beforeunload', beforeunloadHandler);
		//
		fetchData();
		//
		async function fetchData() {
			toggleLoading();
			const response = await axios.get('/feedbackServices/faqMgmt/getAllFaq');
			toggleLoading();
			//
			setAllFaqData(response.data);
			//
			let tempall = [];
			for (let i = 0; i < response.data.length; i++) {
				let obj = response.data[i];
				obj.sectionid = response.data[i].section.id;
				obj.sectionIndex = response.data[i].sectionIndex;
				tempall.push(obj);
			}
			console.log(111, tempall);
			//
			let tempfaq = [];
			for (const value of Object.entries(_.groupBy(tempall, 'sectionid'))) {
				console.log(333, value);
				let obj = {};
				//
				let lang = `section${localStorage.getItem('NCM_lang')}`;
				//
				if (value[1][0].section[lang] === '') {
					obj.title = value[1][0].section.sectionen;
				} else {
					obj.title = value[1][0].section[lang];
				}
				obj.sectionIndex = value[1][0].sectionIndex;
				obj.id = value[0];
				obj.key = `${value[0]}---section`;
				// obj.section = value[1][0].section['sectionen'];
				obj.children = [];
				if (value[1].length === 1 && value[1][0].faqid === null) {
					tempfaq.push(obj);
					continue;
				} else {
					console.log(44444444, value[1]);
					for (let i = 0; i < value[1].length; i++) {
						//
						let anslang = `answer${localStorage.getItem('NCM_lang')}`;
						let queslang = `question${localStorage.getItem('NCM_lang')}`;
						//
						let questiondataen = {};
						console.log(1111111111, value[1][i][anslang]);
						//
						if (value[1][i].ansImageEncoded && value[1][i].ansImageName) {
							let temp = value[1][i].ansImageName.split('.');
							let file = temp[temp.length - 1].toLowerCase();
							let mime = getBase64Type(file);
							console.log(7777777777, file);
							//
							questiondataen.ansImageEncoded = `${mime}${value[1][i].ansImageEncoded}`;
							questiondataen.ansImageName = value[1][i].ansImageName;
						} else {
							questiondataen.ansImageEncoded = '';
							questiondataen.ansImageName = '';
						}
						//
						if (
							value[1][i][anslang] === '' ||
							value[1][i][anslang] === '<p><br></p>'
						) {
							questiondataen.answer = value[1][i].answeren;
						} else {
							questiondataen.answer = value[1][i][anslang];
						}
						//
						if (
							`${value[1][i][queslang]}` === '<p><br></p>' ||
							`${value[1][i][queslang]}` === ''
						) {
							questiondataen.question = `${value[1][i].questionen}`;
							questiondataen.title = `${value[1][i].questionen}`;
						} else {
							questiondataen.question = `${value[1][i][queslang]}`;
							questiondataen.title = `${value[1][i][queslang]}`;
						}
						// questiondataen.question = `${value[1][i]['questionen']}`;
						questiondataen.questionIndex = value[1][i].questionIndex;
						questiondataen.id = value[1][i].faqid;
						questiondataen.key = `${value[0]}---${value[1][i].faqid}---question`;
						obj.children.push(questiondataen);
					}
					tempfaq.push(obj);
				}
			}
			console.log(222, tempfaq);
			tempfaq.sort(compareSectionIndex('sectionIndex'));
			//
			for (let i = 0; i < tempfaq.length; i++) {
				if (tempfaq[i].children.length) {
					tempfaq[i].children.sort(compareAQIndex('questionIndex'));
				}
			}
			//
			oTreeData = JSON.parse(JSON.stringify(tempfaq));
			setTreeData(tempfaq);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchAllData = async () => {
		toggleLoading();
		const response = await axios.get('/feedbackServices/faqMgmt/getAllFaq');
		toggleLoading();
		//
		setAllFaqData(response.data);
		//
		let tempall = [];
		for (let i = 0; i < response.data.length; i++) {
			let obj = response.data[i];
			obj.sectionid = response.data[i].section.id;
			obj.sectionIndex = response.data[i].sectionIndex;
			tempall.push(obj);
		}
		console.log(111, tempall);
		//
		let tempfaq = [];
		for (const value of Object.entries(_.groupBy(tempall, 'sectionid'))) {
			console.log(333, value);
			let obj = {};
			//
			let lang = `section${localStorage.getItem('NCM_lang')}`;
			//
			if (value[1][0].section[lang] === '') {
				obj.title = value[1][0].section.sectionen;
			} else {
				obj.title = value[1][0].section[lang];
			}
			obj.sectionIndex = value[1][0].sectionIndex;
			obj.id = value[0];
			obj.key = `${value[0]}---section`;
			// obj.section = value[1][0].section['sectionen'];
			obj.children = [];
			if (value[1].length === 1 && value[1][0].faqid === null) {
				tempfaq.push(obj);
				continue;
			} else {
				console.log(44444444, value[1]);
				for (let i = 0; i < value[1].length; i++) {
					//
					let anslang = `answer${localStorage.getItem('NCM_lang')}`;
					let queslang = `question${localStorage.getItem('NCM_lang')}`;
					//
					let questiondataen = {};
					console.log(1111111111, value[1][i][anslang]);
					//
					if (value[1][i].ansImageEncoded && value[1][i].ansImageName) {
						let temp = value[1][i].ansImageName.split('.');
						let file = temp[temp.length - 1].toLowerCase();
						let mime = getBase64Type(file);
						console.log(7777777777, file);
						//
						questiondataen.ansImageEncoded = `${mime}${value[1][i].ansImageEncoded}`;
						questiondataen.ansImageName = value[1][i].ansImageName;
					} else {
						questiondataen.ansImageEncoded = '';
						questiondataen.ansImageName = '';
					}
					//
					if (
						value[1][i][anslang] === '' ||
						value[1][i][anslang] === '<p><br></p>'
					) {
						questiondataen.answer = value[1][i].answeren;
					} else {
						questiondataen.answer = value[1][i][anslang];
					}
					//
					if (
						`${value[1][i][queslang]}` === '<p><br></p>' ||
						`${value[1][i][queslang]}` === ''
					) {
						questiondataen.question = `${value[1][i].questionen}`;
						questiondataen.title = `${value[1][i].questionen}`;
					} else {
						questiondataen.question = `${value[1][i][queslang]}`;
						questiondataen.title = `${value[1][i][queslang]}`;
					}
					// questiondataen.question = `${value[1][i]['questionen']}`;
					questiondataen.questionIndex = value[1][i].questionIndex;
					questiondataen.id = value[1][i].faqid;
					questiondataen.key = `${value[0]}---${value[1][i].faqid}---question`;
					obj.children.push(questiondataen);
				}
				tempfaq.push(obj);
			}
		}
		console.log(222, tempfaq);
		tempfaq.sort(compareSectionIndex('sectionIndex'));
		//
		for (let i = 0; i < tempfaq.length; i++) {
			if (tempfaq[i].children.length) {
				tempfaq[i].children.sort(compareAQIndex('questionIndex'));
			}
		}
		//
		oTreeData = JSON.parse(JSON.stringify(tempfaq));
		setTreeData(tempfaq);
	};

	const compareSectionIndex = (propertyName) => {
		return function (obj1, obj2) {
			let value1 = obj1[propertyName];
			let value2 = obj2[propertyName];
			if (value1 < value2) {
				return -1;
			} else if (value1 > value2) {
				return 1;
			} else {
				return 0;
			}
		};
	};

	const compareAQIndex = (propertyName) => {
		return function (obj1, obj2) {
			let value1 = obj1[propertyName];
			let value2 = obj2[propertyName];
			if (value1 < value2) {
				return -1;
			} else if (value1 > value2) {
				return 1;
			} else {
				return 0;
			}
		};
	};

	const onSelect = (selectedKeys, info) => {
		console.log(selectedKeys[0], info.selectedNodes[0]);
		if (!selectedKeys[0] || !info.selectedNodes[0]) {
			let notificationObj = {
				type: 'error',
				title: 'Error',
				description: 'Try to choose another section or question.',
				duration: 5,
			};
			toastMessageShow(notificationObj);
			return;
		}
		if (selectedKeys[0].includes('---section')) {
			setType('section');
			//
			let find = allFaqData.find((item) => {
				return item.section.id === selectedKeys[0].split('---')[0];
			});
			if (find) {
				console.log(find);
				titleData.sectionen = find.section.sectionen;
				titleData.sectionde = find.section.sectionde;
				titleData.sectionfr = find.section.sectionfr;
				titleData.sectiones = find.section.sectiones;
				titleData.sectionpt = find.section.sectionpt;
				titleData.id = find.section.id;
				titleData.hasChildren = find.faqid ? true : false;
			}
			console.log(123, titleData);
		} else if (selectedKeys[0].includes('---question')) {
			setType('question');
			let find = allFaqData.find((item) => {
				if (item.faqid) {
					console.log(21332, selectedKeys[0]);
					return item.faqid === selectedKeys[0].split('---')[1];
				} else {
					return null;
				}
			});
			if (find) {
				console.log(find);
				questionData.answeren = find.answeren;
				questionData.answerde = find.answerde;
				questionData.answerfr = find.answerfr;
				questionData.answeres = find.answeres;
				questionData.answerpt = find.answerpt;
				questionData.questionen = find.questionen;
				questionData.questionde = find.questionde;
				questionData.questionfr = find.questionfr;
				questionData.questiones = find.questiones;
				questionData.questionpt = find.questionpt;
				questionData.qid = find.faqid;
				questionData.sid = find.section.id;
				//
				if (find.ansImageEncoded && find.ansImageName) {
					let temp = find.ansImageName.split('.');
					let file = temp[temp.length - 1].toLowerCase();
					let mime = getBase64Type(file);
					//
					questionData.ansImageEncoded = `${mime}${find.ansImageEncoded}`;
				} else {
					questionData.ansImageEncoded = '';
				}
				//
				setTimeout(() => {
					editorRef1.current.setHtml(find.answeren);
					editorRef2.current.setHtml(find.answerde);
					editorRef3.current.setHtml(find.answerfr);
					editorRef4.current.setHtml(find.answeres);
					editorRef5.current.setHtml(find.answerpt);
					fileUploadRef.current.clearAll();
				}, 10);
			}
			console.log(123, questionData);
		}
	};

	// value
	const inputChangeTitleHandler = useMemoizedFn((e, val) => {
		e.target.classList.remove('gotError');
		e.target.classList.add('isFilled');
		//
		if (e.target.value === '') {
			e.target.classList.remove('gotError');
			e.target.classList.remove('isFilled');
		}
		//
		titleData[val] = e.target.value;
	});

	const inputChangeAnswerHandler = useMemoizedFn((e, val) => {
		e.target.classList.remove('gotError');
		e.target.classList.add('isFilled');
		//
		if (e.target.value === '') {
			e.target.classList.remove('gotError');
			e.target.classList.remove('isFilled');
		}
		//
		questionData[val] = e.target.value;
	});

	const addTitleModel = () => {
		titleModalRef.current.openModel();
	};

	const addQuestionModel = () => {
		questionModal.current.openModel(treeData);
	};

	const saveTitle = async () => {
		let temp = JSON.parse(JSON.stringify(titleData));
		let flag = false;
		let obj = {
			sectionen: temp.sectionen,
			sectionde: temp.sectionde,
			sectionfr: temp.sectionfr,
			sectiones: temp.sectiones,
			sectionpt: temp.sectionpt,
			id: temp.id,
		};
		if (obj.sectionen === '') {
			document.querySelector('.sectionen').classList.remove('isFilled');
			document.querySelector('.sectionen').classList.add('gotError');
			flag = true;
		}
		if (flag) {
			return;
		}
		console.log(obj);
		toggleLoading();
		await axios.post('/feedbackServices/saction/saveSec', obj);
		toggleLoading();
		fetchAllData();
	};

	const removeTitle = async () => {
		let temp = JSON.parse(JSON.stringify(titleData));
		if (temp.hasChildren) {
			setIsModalVisible3(true);
			return;
		}
		console.log(temp.id);
		toggleLoading();
		await axios.delete(`feedbackServices/saction/getSec/${temp.id}`);
		setIsModalVisible1(false);
		toggleLoading();
		fetchAllData();
		setType('');
	};

	const removeimage = () => {
		questionData.ansImageEncoded = '';
	};

	const saveQuestion = async () => {
		let temp = JSON.parse(JSON.stringify(questionData));
		let obj = {
			answeren: editorRef1.current.getHtml(),
			answerde: editorRef2.current.getHtml(),
			answerfr: editorRef3.current.getHtml(),
			answeres: editorRef4.current.getHtml(),
			answerpt: editorRef5.current.getHtml(),
			questionen: temp.questionen,
			questionde: temp.questionde,
			questionfr: temp.questionfr,
			questiones: temp.questiones,
			questionpt: temp.questionpt,
			id: temp.qid,
			ansImageEncoded: '',
			ansImageName: '',
		};
		let flag = false;
		if (obj.questionen === '') {
			document.querySelector('.questionen').classList.remove('isFilled');
			document.querySelector('.questionen').classList.add('gotError');
			flag = true;
		}
		if (flag) {
			return;
		}
		//
		if (fileUploadRef.current && fileUploadRef.current.itattachments.length) {
			obj.ansImageEncoded = fileUploadRef.current.itattachments[0].content;
			obj.ansImageName = fileUploadRef.current.itattachments[0].filename;
		} else {
			obj.ansImageEncoded = null;
			obj.ansImageName = null;
		}
		//
		let post = {
			sectionId: temp.sid,
			list: [obj],
		};
		console.log(post);
		toggleLoading();
		await axios.post('/feedbackServices/faqMgmt/saveFaq', post);
		toggleLoading();
		fetchAllData();
	};

	const removeQuestion = async () => {
		let temp = JSON.parse(JSON.stringify(questionData));
		console.log(temp.qid);
		toggleLoading();
		await axios.delete(`/feedbackServices/faqMgmt/getFaq/${temp.qid}`);
		setIsModalVisible2(false);
		toggleLoading();
		fetchAllData();
		setType('');
	};

	const setSorting = () => {
		setIsSort(true);
		setType('');
	};

	const onDrop = (info) => {
		const dropKey = info.node.key;
		const dragKey = info.dragNode.key;
		const dropPos = info.node.pos.split('-');
		const dropPosition =
			info.dropPosition - Number(dropPos[dropPos.length - 1]);

		console.log(dragKey);
		console.log(dropKey);
		console.log(dropPosition);
		//
		if (dragKey.includes('---section')) {
			if (dropKey.includes('---question')) {
				let notificationObj = {
					type: 'error',
					title: 'Error',
					description: 'Section can not drop to question area.',
					duration: 5,
				};
				toastMessageShow(notificationObj);
				return;
			}
			if (dropPosition === 0) {
				let notificationObj = {
					type: 'error',
					title: 'Error',
					description: 'Section should not drop to question area.',
					duration: 5,
				};
				toastMessageShow(notificationObj);
				return;
			}
		}
		//
		if (dragKey.includes('---question')) {
			if (
				dropKey.includes('---question') &&
				dropKey.split('---')[0] !== dragKey.split('---')[0]
			) {
				let notificationObj = {
					type: 'error',
					title: 'Error',
					description: 'Question should not belong to another section.',
					duration: 5,
				};
				toastMessageShow(notificationObj);
				return;
			}
			if (
				dropKey.includes('---section') &&
				dropPosition === 0 &&
				dropKey.split('---')[0] !== dragKey.split('---')[0]
			) {
				let notificationObj = {
					type: 'error',
					title: 'Error',
					description: 'Question should not belong to another section.',
					duration: 5,
				};
				toastMessageShow(notificationObj);
				return;
			}
			if (dropKey.includes('---section') && dropPosition !== 0) {
				let notificationObj = {
					type: 'error',
					title: 'Error',
					description: 'Question should not drop to section area.',
					duration: 5,
				};
				toastMessageShow(notificationObj);
				return;
			}
			if (dropKey.includes('---question') && dropPosition === 0) {
				let notificationObj = {
					type: 'error',
					title: 'Error',
					description: 'Question should not belong to another question.',
					duration: 5,
				};
				toastMessageShow(notificationObj);
				return;
			}
		}

		const loop = (data, key, callback) => {
			for (let i = 0; i < data.length; i++) {
				if (data[i].key === key) {
					return callback(data[i], i, data);
				}

				if (data[i].children) {
					loop(data[i].children, key, callback);
				}
			}
		};

		const data = [...treeData]; // Find dragObject

		let dragObj;
		loop(data, dragKey, (item, index, arr) => {
			arr.splice(index, 1);
			dragObj = item;
		});

		if (!info.dropToGap) {
			// Drop on the content
			loop(data, dropKey, (item) => {
				item.children = item.children || []; // where to insert 示例添加到头部，可以是随意位置

				item.children.unshift(dragObj);
			});
		} else if (
			(info.node.props.children || []).length > 0 && // Has children
			info.node.props.expanded && // Is expanded
			dropPosition === 1 // On the bottom gap
		) {
			loop(data, dropKey, (item) => {
				item.children = item.children || []; // where to insert 示例添加到头部，可以是随意位置

				item.children.unshift(dragObj); // in previous version, we use item.children.push(dragObj) to insert the
				// item to the tail of the children
			});
		} else {
			let ar = [];
			let i;
			loop(data, dropKey, (_item, index, arr) => {
				ar = arr;
				i = index;
			});

			if (dropPosition === -1) {
				ar.splice(i, 0, dragObj);
			} else {
				ar.splice(i + 1, 0, dragObj);
			}
		}
		setTreeData(data);
	};

	const savesorting = async () => {
		console.log(treeData);
		let sectionobj = [];
		for (let i = 0; i < treeData.length; i++) {
			let obj = {};
			obj.sectionId = treeData[i].id;
			obj.sectionIndex = i;
			sectionobj.push(obj);
		}
		console.table(sectionobj);
		toggleLoading();
		// section
		await axios.post('/feedbackServices/faqMgmt/sectionMove', sectionobj);
		// question
		for (let i = 0; i < treeData.length; i++) {
			if (treeData[i].children && treeData[i].children.length) {
				let postobj = [];
				for (let k = 0; k < treeData[i].children.length; k++) {
					let obj = {};
					obj.sectionId = treeData[i].children[k].key.split('---')[0];
					obj.questionId = treeData[i].children[k].id;
					obj.title = treeData[i].children[k].title;
					obj.questionIndex = k;
					postobj.push(obj);
				}
				console.table(postobj);
				await axios.post('/feedbackServices/faqMgmt/questionMove', postobj);
			}
		}
		toggleLoading();
		setIsSort(false);
		fetchAllData();
		setType('');
	};

	const cancelsorting = async () => {
		console.log(oTreeData);
		setTreeData(oTreeData);
		setIsSort(false);
		setType('');
	};

	const resetType = () => {
		setType('');
		setIsModalVisible1(false);
		setIsModalVisible2(false);
		setIsModalVisible3(false);
		// setIsModalVisible4(false);
	};

	useUnmount(() => {
		// setLockInterval(undefined);
		// window.removeEventListener('beforeunload', beforeunloadHandler);
		// setIsPrompt(false);
	});

	// const handleOk4 = () => {
	// 	window.removeEventListener('beforeunload', beforeunloadHandler);
	// 	history.replace(promptLoc);
	// };

	// const handleCancel4 = () => {
	// 	// setCheckInterval(1000);
	// 	setIsPrompt(true);
	// 	setIsModalVisible4(false);
	// };

	// const showModal = () => {
	// 	// setCheckInterval(undefined);
	// 	setIsPrompt(false);
	// 	setIsModalVisible4(true);
	// };

	return (
		<section className="faqaaawrap">
			<TitleModal
				ref={titleModalRef}
				fetchAllData={fetchAllData}
				resetType={resetType}
			/>
			<QuestionModal
				ref={questionModal}
				fetchAllData={fetchAllData}
				resetType={resetType}
			/>
			{/* <Prompt
				message={(loc) => {
					// if (111) {
					// 	return true;
					// }
					setPromptLoc(loc.pathname);
					showModal();
					return false;
				}}
				when={isPrompt}
			/> */}
			<Modal
				open={isModalVisible1}
				className="cancelModal"
				footer={null}
				closable={false}
				getContainer={false}
			>
				<div className="contentinto">
					<div className="title">Note:</div>
					<div className="content">Are you sure you want to delete it?</div>
					<div className="buttongroup">
						<button
							className="btnCancel"
							onClick={() => setIsModalVisible1(false)}
						>
							Cancel
						</button>
						<button className="btnLogin" onClick={() => removeTitle()}>
							Delete
						</button>
					</div>
				</div>
				<div className="closeicon" onClick={() => setIsModalVisible1(false)}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			<Modal
				open={isModalVisible2}
				className="cancelModal"
				footer={null}
				closable={false}
			>
				<div className="contentinto">
					<div className="title">Note:</div>
					<div className="content">Are you sure you want to delete it?</div>
					<div className="buttongroup">
						<button
							className="btnCancel"
							onClick={() => setIsModalVisible2(false)}
						>
							Cancel
						</button>
						<button className="btnLogin" onClick={() => removeQuestion()}>
							Delete
						</button>
					</div>
				</div>
				<div className="closeicon" onClick={() => setIsModalVisible2(false)}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			<Modal
				open={isModalVisible3}
				className="cancelModal"
				footer={null}
				closable={false}
				getContainer={false}
			>
				<div className="contentinto">
					<div className="title">Note:</div>
					<div className="content">
						This section has includes one or more questions, can not be removed.
					</div>
					<div className="buttongroup">
						<button
							className="btnCancel"
							onClick={() => setIsModalVisible3(false)}
						>
							Cancel
						</button>
					</div>
				</div>
				<div className="closeicon" onClick={() => setIsModalVisible3(false)}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			{/* <Modal
				open={isModalVisible4}
				className="cancelModal"
				footer={null}
				closable={false}
				getContainer={false}
			>
				<div className="contentinto">
					<div className="title">{t('Detail.cancel1')}</div>
					<div className="content">{t('Detail.cancel2')}</div>
					<div className="buttongroup">
						<button className="btnCancel" onClick={handleCancel4}>
							{t('Detail.Cancel')}
						</button>
						<button className="btnLogin" onClick={handleOk4}>
							{t('Detail.Continue')}
						</button>
					</div>
				</div>
				<div className="closeicon" onClick={handleCancel4}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal> */}
			<div className="faqleft">
				<div className="faqtopbutton">
					{isSort ? (
						<Fragment>
							<div className="addsection" onClick={() => savesorting()}>
								Save Sorting
							</div>
							<div className="btnCancel" onClick={() => cancelsorting()}>
								Cancel
							</div>
						</Fragment>
					) : (
						<Fragment>
							<div className="addsection" onClick={() => setSorting()}>
								Sorting
							</div>
						</Fragment>
					)}
				</div>
				<div className="treewrap">
					{treeData.length ? (
						<Tree
							onDrop={isSort ? onDrop : null}
							draggable={isSort ? (true, { icon: false }) : false}
							showLine
							onSelect={isSort ? null : onSelect}
							treeData={treeData}
						/>
					) : (
						<Fragment />
					)}
				</div>
			</div>
			<div className="faqright">
				<div className="faqtopbutton">
					{isSort ? (
						<Fragment />
					) : (
						<Fragment>
							<div className="addsection" onClick={() => addQuestionModel()}>
								Add Question
							</div>
							<div className="addsection" onClick={() => addTitleModel()}>
								Add Section
							</div>
						</Fragment>
					)}
				</div>
				<div className="faqbottomformwrap">
					{(() => {
						if (type === 'section') {
							return (
								<Fragment>
									<div className="inputwrap">
										<textarea
											className="txaFeedback m34 sectionen"
											value={titleData.sectionen}
											onChange={(e) => inputChangeTitleHandler(e, 'sectionen')}
										/>
										<span className="editbutton">English</span>
									</div>
									<div className="inputwrap">
										<textarea
											className="txaFeedback m34 sectionde"
											value={titleData.sectionde}
											onChange={(e) => inputChangeTitleHandler(e, 'sectionde')}
										/>
										<span className="editbutton">Deutsch</span>
									</div>
									<div className="inputwrap">
										<textarea
											className="txaFeedback m34 sectionfr"
											value={titleData.sectionfr}
											onChange={(e) => inputChangeTitleHandler(e, 'sectionfr')}
										/>
										<span className="editbutton">Français</span>
									</div>
									<div className="inputwrap">
										<textarea
											className="txaFeedback m34 sectiones"
											value={titleData.sectiones}
											onChange={(e) => inputChangeTitleHandler(e, 'sectiones')}
										/>
										<span className="editbutton">Español</span>
									</div>
									<div className="inputwrap">
										<textarea
											className="txaFeedback m34 sectionpt"
											value={titleData.sectionpt}
											onChange={(e) => inputChangeTitleHandler(e, 'sectionpt')}
										/>
										<span className="editbutton">Português</span>
									</div>
									<div className="addbuttongroup">
										<button className="btnSend" onClick={() => saveTitle()}>
											Save
										</button>
										<button
											className="btnCancel"
											onClick={() => setIsModalVisible1(true)}
										>
											Remove
										</button>
									</div>
								</Fragment>
							);
						} else if (type === 'question') {
							return (
								<Fragment>
									<div className="inputwrap">
										<span className="editbutton">English</span>
										<textarea
											className="txaFeedback m34 questionen"
											value={questionData.questionen}
											onChange={(e) =>
												inputChangeAnswerHandler(e, 'questionen')
											}
										></textarea>
										<RichEditor
											value={questionData.answeren}
											ref={editorRef1}
										/>
									</div>
									<div className="inputwrap">
										<span className="editbutton">Deutsch</span>
										<textarea
											className="txaFeedback m34 questionde"
											value={questionData.questionde}
											onChange={(e) =>
												inputChangeAnswerHandler(e, 'questionde')
											}
										></textarea>
										<RichEditor
											value={questionData.answerde}
											ref={editorRef2}
										/>
									</div>
									<div className="inputwrap">
										<span className="editbutton">Français</span>
										<textarea
											className="txaFeedback m34 questionfr"
											value={questionData.questionfr}
											onChange={(e) =>
												inputChangeAnswerHandler(e, 'questionfr')
											}
										></textarea>
										<RichEditor
											value={questionData.answerfr}
											ref={editorRef3}
										/>
									</div>
									<div className="inputwrap">
										<span className="editbutton">Español</span>
										<textarea
											className="txaFeedback m34 questiones"
											value={questionData.questiones}
											onChange={(e) =>
												inputChangeAnswerHandler(e, 'questiones')
											}
										></textarea>
										<RichEditor
											value={questionData.answeres}
											ref={editorRef4}
										/>
									</div>
									<div className="inputwrap">
										<span className="editbutton">Português</span>
										<textarea
											className="txaFeedback m34 questionpt"
											value={questionData.questionpt}
											onChange={(e) =>
												inputChangeAnswerHandler(e, 'questionpt')
											}
										></textarea>
										<RichEditor
											value={questionData.answerpt}
											ref={editorRef5}
										/>
									</div>
									{questionData.ansImageEncoded !== '' ? (
										<Fragment>
											<div className="answerimage">
												<img src={questionData.ansImageEncoded} alt="" />
											</div>
											<div className="addbuttongroup">
												<button
													className="btnCancel"
													onClick={() => removeimage()}
												>
													Remove Image
												</button>
											</div>
										</Fragment>
									) : (
										<Fragment />
									)}
									<div style={{ marginTop: '20px' }}>
										<InputFileUpload ref={fileUploadRef} />
									</div>
									<div className="addbuttongroup">
										<button className="btnSend" onClick={() => saveQuestion()}>
											Save
										</button>
										<button
											className="btnCancel"
											onClick={() => setIsModalVisible2(true)}
										>
											Remove
										</button>
									</div>
								</Fragment>
							);
						} else {
							return <Fragment />;
						}
					})()}
				</div>
			</div>
		</section>
	);
};

export default memo(Faq);
