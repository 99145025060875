import React, { useImperativeHandle, memo } from 'react';
import { useReactive, useMemoizedFn } from 'ahooks';
import { useTranslation } from 'react-i18next';
import ICONclose from '../../../assets/icons/close.svg';
import { filterNullwithArray } from '../../../utils/util';

const RequestsOverviewFilter = React.forwardRef((props, ref) => {
	const { t } = useTranslation();

	useImperativeHandle(ref, () => ({
		changeFilterVal: (obj) => {
			for (const key in obj) {
				if (key === 'status') {
					ROFilter['statusArray'] = obj[key].split(',');
					ROFilter['status'] = obj['status'];
				} else {
					ROFilter[key] = obj[key];
				}
			}
		},
	}));

	const ROFilter = useReactive({
		statusArray: [],
		status: '',
		shipTo: '',
		responsible: '',
		maktx: '',
		mreason: '',
		mreasontext: '',
		dreasontext: '',
		mreasontextCorr: '',
		dreasontextCorr: '',
	});

	const changeStatus = useMemoizedFn((value) => {
		if (value === 'OPEN') {
			if (ROFilter.statusArray.length === 0) {
				ROFilter.statusArray.push('OPEN');
			} else if (
				ROFilter.statusArray.length === 1 &&
				ROFilter.statusArray.indexOf('OPEN') === -1
			) {
				ROFilter.statusArray.push('OPEN');
			} else if (
				ROFilter.statusArray.length === 1 &&
				ROFilter.statusArray.indexOf('OPEN') > -1
			) {
				ROFilter.statusArray = [];
			} else if (
				ROFilter.statusArray.length === 2 &&
				ROFilter.statusArray.indexOf('OPEN') > -1
			) {
				let findIndex = ROFilter.statusArray.findIndex(
					(item) => item === 'OPEN'
				);
				ROFilter.statusArray.splice(findIndex, 1);
			}
			ROFilter.status = ROFilter.statusArray.join(',');
		} else if (value === 'DRAFT') {
			if (ROFilter.statusArray.length === 0) {
				ROFilter.statusArray.push('DRAFT');
			} else if (
				ROFilter.statusArray.length === 1 &&
				ROFilter.statusArray.indexOf('DRAFT') === -1
			) {
				ROFilter.statusArray.push('DRAFT');
			} else if (
				ROFilter.statusArray.length === 1 &&
				ROFilter.statusArray.indexOf('DRAFT') > -1
			) {
				ROFilter.statusArray = [];
			} else if (
				ROFilter.statusArray.length === 2 &&
				ROFilter.statusArray.indexOf('DRAFT') > -1
			) {
				let findIndex = ROFilter.statusArray.findIndex(
					(item) => item === 'DRAFT'
				);
				ROFilter.statusArray.splice(findIndex, 1);
			}
			ROFilter.status = ROFilter.statusArray.join(',');
		} else if (value === 'CLOSED') {
			if (ROFilter.statusArray.length === 0) {
				ROFilter.statusArray.push('CLOSED');
			} else if (
				ROFilter.statusArray.length === 1 &&
				ROFilter.statusArray.indexOf('CLOSED') === -1
			) {
				ROFilter.statusArray.push('CLOSED');
			} else if (
				ROFilter.statusArray.length === 1 &&
				ROFilter.statusArray.indexOf('CLOSED') > -1
			) {
				ROFilter.statusArray = [];
			} else if (
				ROFilter.statusArray.length === 2 &&
				ROFilter.statusArray.indexOf('CLOSED') > -1
			) {
				let findIndex = ROFilter.statusArray.findIndex(
					(item) => item === 'CLOSED'
				);
				ROFilter.statusArray.splice(findIndex, 1);
			}
			ROFilter.status = ROFilter.statusArray.join(',');
		}
	});

	const inputChangeHandler = useMemoizedFn((e, key) => {
		ROFilter[key] = e.target.value;
	});

	const applyFilters = useMemoizedFn((event) => {
		let filter = filterNullwithArray(JSON.parse(JSON.stringify(ROFilter)));
		if (JSON.stringify(filter) === '{}') {
			return;
		} else {
			delete filter.statusArray;
		}
		props.changeFilterNumber(Object.keys(filter).length);
		props.applyFilters(filter, 'search');
	});

	const resetFilters = useMemoizedFn((event) => {
		ROFilter.statusArray = [];
		ROFilter.status = '';
		ROFilter.shipTo = '';
		ROFilter.responsible = '';
		ROFilter.maktx = '';
		ROFilter.mreason = '';
		ROFilter.mreasontext = '';
		ROFilter.dreasontext = '';
		ROFilter.mreasontextCorr = '';
		ROFilter.dreasontextCorr = '';
		props.changeFilterNumber(0);
		props.applyFilters(JSON.parse(JSON.stringify(ROFilter)), 'reset');
	});

	return (
		<aside className={props.filtersPanel ? 'filters visible' : 'filters'}>
			<button
				className="btnHideFilters"
				onClick={() => props.changeFilterPanel()}
			>
				<img alt="Hide Filters" src={ICONclose} />
			</button>
			<ul className="lstFilters">
				<li>
					<span>{t('Overview.Status')}</span>
					<button
						className={
							ROFilter.statusArray.indexOf('OPEN') > -1
								? 'btnStatusFilter active'
								: 'btnStatusFilter'
						}
						onClick={() => changeStatus('OPEN')}
					>
						{t('Overview.Open')}
					</button>
					<button
						className={
							ROFilter.statusArray.indexOf('DRAFT') > -1
								? 'btnStatusFilter active'
								: 'btnStatusFilter'
						}
						onClick={() => changeStatus('DRAFT')}
					>
						{t('Overview.Draft')}
					</button>
					<button
						className={
							ROFilter.statusArray.indexOf('CLOSED') > -1
								? 'btnStatusFilter active'
								: 'btnStatusFilter'
						}
						onClick={() => changeStatus('CLOSED')}
					>
						{t('Overview.Closed')}
					</button>
				</li>
				<li>
					<span>{t('Overview.Shipto')}</span>
					<input
						type="text"
						className="txbFilter"
						value={ROFilter.shipTo}
						onChange={(e) => inputChangeHandler(e, 'shipTo')}
					/>
				</li>
				<li>
					<span>{t('Overview.OriginalResponsible')}</span>
					<input
						type="text"
						className="txbFilter"
						value={ROFilter.responsible}
						onChange={(e) => inputChangeHandler(e, 'responsible')}
					/>
				</li>
				<li>
					<span>{t('Overview.MaterialDesciption')}</span>
					<input
						type="text"
						className="txbFilter"
						value={ROFilter.maktx}
						onChange={(e) => inputChangeHandler(e, 'maktx')}
					/>
				</li>
				<li>
					<span>{t('Overview.MainProblem')}</span>
					<input
						type="text"
						className="txbFilter"
						value={ROFilter.mreason}
						onChange={(e) => inputChangeHandler(e, 'mreason')}
					/>
				</li>
				<li>
					<span>{t('Overview.MainCause')}</span>
					<input
						type="text"
						className="txbFilter"
						value={ROFilter.mreasontext}
						onChange={(e) => inputChangeHandler(e, 'mreasontext')}
					/>
				</li>
				<li>
					<span>{t('Overview.DetailCause')}</span>
					<input
						type="text"
						className="txbFilter"
						value={ROFilter.dreasontext}
						onChange={(e) => inputChangeHandler(e, 'dreasontext')}
					/>
				</li>
				<li>
					<span>{t('Overview.MainCorrectiveAction')}</span>
					<input
						type="text"
						className="txbFilter"
						value={ROFilter.mreasontextCorr}
						onChange={(e) => inputChangeHandler(e, 'mreasontextCorr')}
					/>
				</li>
				<li>
					<span>{t('Overview.DetailCorrectiveAction')}</span>
					<input
						type="text"
						className="txbFilter"
						value={ROFilter.dreasontextCorr}
						onChange={(e) => inputChangeHandler(e, 'dreasontextCorr')}
					/>
				</li>
				<li>
					<button className="btnApplyFilters" onClick={() => applyFilters()}>
						{t('Overview.ApplyFilter')}
					</button>
					<button className="btnResetFilters" onClick={() => resetFilters()}>
						{t('Overview.ResetFilter')}
					</button>
				</li>
			</ul>
		</aside>
	);
});

export default memo(RequestsOverviewFilter);
