import React, { useImperativeHandle, memo, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactive, useMemoizedFn, useBoolean } from 'ahooks';
import { DatePicker } from 'antd';
import axios from '../../../axios-custom';
// import ICONerror from '../../../assets/icons/error.svg';
import ICONinfoGrey from '../../../assets/icons/info-i.svg';
import { Tooltip } from 'antd';
import '../../../../node_modules/antd/lib/tooltip/style/index.css';
import store from '../../../state/store';
import ICONinfo from '../../../assets/icons/info-red.svg';
const { useModel } = store;

const FlyoutDeputyAdd = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const [startDateData, setStartDateData] = useState(null);
	const [endDateData, setEndDateData] = useState(null);
	const [state, { setTrue, setFalse }] = useBoolean(false);
	// eslint-disable-next-line no-unused-vars
	const [tempState, dispatchers] = useModel('global');
	const { toggleLoading, toastMessageShow } = dispatchers;

	useImperativeHandle(ref, () => ({
		//
	}));

	const addForm = useReactive({
		firstname: '',
		lastname: '',
		imdeputy: '',
		checkimdeputy: '',
		imfromdate: '',
		imtodate: '',
		immainpartner: '',
		imop: 'I',
	});

	const inputChangeHandler = useMemoizedFn((e, key) => {
		e.target.classList.remove('gotError');
		addForm[key] = e.target.value;
		setFalse();
		//
		let targetDom = e.target.parentNode.getElementsByTagName('span');
		for (let i = 0; i < targetDom.length; i++) {
			targetDom[i].classList.remove('visible');
		}
		// e.target.parentNode
		// 	.getElementsByTagName('span')[1]
		// 	.classList.remove('visible');
		//
		// if (e.target.value !== '') {
		// 	e.target.classList.add('isFilled');
		// } else {
		// 	e.target.classList.remove('isFilled');
		// }
	});

	const startDatePickerOnChange = useMemoizedFn((date) => {
		setStartDateData(date);
		setFalse();
		addForm.imfromdate = props.moment(date).format('YYYY-MM-DD');
		//
		setEndDateData(null);
		addForm.imtodate = '';
	});

	const endDatePickerOnChange = useMemoizedFn((date) => {
		setEndDateData(date);
		setFalse();
		addForm.imtodate = props.moment(date).format('YYYY-MM-DD');
	});

	const inputError = useMemoizedFn((type, num) => {
		if (type === 'firstname') {
			if (num === 0) {
				document.querySelector(`.${type}`).classList.add('gotError');
				document.querySelector(`.${type}`).classList.remove('isFilled');
			} else {
				document.querySelector(`.${type}`).classList.add('gotError');
				document.querySelector(`.${type}`).classList.remove('isFilled');
				const listItem = document.querySelector(`.${type}`).parentNode;
				const error = listItem.getElementsByTagName('span')[1];
				if (error) {
					error.classList.add('visible');
				}
			}
		} else if (type === 'lastname') {
			if (num === 0) {
				document.querySelector(`.${type}`).classList.add('gotError');
				document.querySelector(`.${type}`).classList.remove('isFilled');
			} else {
				document.querySelector(`.${type}`).classList.add('gotError');
				document.querySelector(`.${type}`).classList.remove('isFilled');
				const listItem = document.querySelector(`.${type}`).parentNode;
				const error = listItem.getElementsByTagName('span')[1];
				if (error) {
					error.classList.add('visible');
				}
			}
		} else if (type === 'imdeputy') {
			if (num === 0) {
				document.querySelector(`.${type}`).classList.add('gotError');
				document.querySelector(`.${type}`).classList.remove('isFilled');
			} else {
				document.querySelector(`.${type}`).classList.add('gotError');
				document.querySelector(`.${type}`).classList.remove('isFilled');
				const listItem = document.querySelector(`.${type}`).parentNode;
				const error = listItem.getElementsByTagName('span')[num];
				if (error) {
					error.classList.add('visible');
				}
			}
		} else if (type === 'checkimdeputy') {
			if (num === 0) {
				document.querySelector(`.${type}`).classList.add('gotError');
				document.querySelector(`.${type}`).classList.remove('isFilled');
			} else {
				document.querySelector(`.${type}`).classList.add('gotError');
				document.querySelector(`.${type}`).classList.remove('isFilled');
				const listItem = document.querySelector(`.${type}`).parentNode;
				const error = listItem.getElementsByTagName('span')[num];
				if (error) {
					error.classList.add('visible');
				}
			}
		} else {
			document.querySelector(`.${type}`).classList.add('gotError');
			document.querySelector(`.${type}`).classList.remove('isFilled');
			const listItem = document.querySelector(`.${type}`).parentNode;
			const error = listItem.getElementsByTagName('span')[num];
			if (error) {
				error.classList.add('visible');
			}
		}
	});

	const addDeputy = useMemoizedFn(async () => {
		let flag = false;
		let addFormTemp = JSON.parse(JSON.stringify(addForm));
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		// first name
		if (addFormTemp.firstname === '') {
			inputError('firstname', 0);
			flag = true;
			setTrue();
		} else {
			if (!/^[a-zA-Z][-a-zA-Z]+$/.test(addFormTemp.firstname.trim())) {
				inputError('firstname', 1);
				flag = true;
				// setTrue();
			}
		}
		// last name
		if (addFormTemp.lastname === '') {
			inputError('lastname', 0);
			flag = true;
			setTrue();
		} else {
			if (!/^[A-Za-z]+$/.test(addFormTemp.lastname.trim())) {
				inputError('lastname', 1);
				flag = true;
				// setTrue();
			}
		}
		// imdeputy
		if (addFormTemp.imdeputy === '') {
			inputError('imdeputy', 0);
			flag = true;
			setTrue();
		} else {
			if (
				// eslint-disable-next-line no-useless-escape
				!/^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/.test(
					addFormTemp.imdeputy.trim()
				)
			) {
				inputError('imdeputy', 1);
				flag = true;
				// setTrue();
			}
		}
		//
		if (
			addFormTemp.imdeputy.trim().toLowerCase() ===
			user.email.trim().toLowerCase()
		) {
			inputError('imdeputy', 3);
			flag = true;
			// setTrue();
		}
		//
		// checkimdeputy
		if (addFormTemp.checkimdeputy === '') {
			inputError('checkimdeputy', 0);
			flag = true;
			setTrue();
		} else {
			if (
				// eslint-disable-next-line no-useless-escape
				!/^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/.test(
					addFormTemp.checkimdeputy.trim()
				)
			) {
				inputError('checkimdeputy', 1);
				flag = true;
				// setTrue();
			}
		}
		//
		if (
			addFormTemp.checkimdeputy.trim().toLowerCase() ===
			user.email.trim().toLowerCase()
		) {
			inputError('checkimdeputy', 4);
			flag = true;
			// setTrue();
		}
		// imdeputy+checkimdeputy
		if (
			addFormTemp.imdeputy !== '' &&
			addFormTemp.checkimdeputy !== '' &&
			// eslint-disable-next-line no-useless-escape
			/^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/.test(
				addFormTemp.imdeputy.trim()
			) &&
			// eslint-disable-next-line no-useless-escape
			/^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/.test(
				addFormTemp.checkimdeputy.trim()
			)
		) {
			if (addFormTemp.imdeputy !== addFormTemp.checkimdeputy) {
				inputError('checkimdeputy', 2);
				flag = true;
				// setTrue();
			}
		}
		// imfromdate
		if (addFormTemp.imfromdate === '') {
			inputError('imfromdate', 0);
			flag = true;
			setTrue();
		}
		// imtodate
		if (addFormTemp.imtodate === '') {
			inputError('imtodate', 0);
			flag = true;
			setTrue();
		}
		if (flag) {
			return;
		}
		console.log(addFormTemp);
		let loggedinId = '';
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		if (userBak && JSON.stringify(userBak) !== '{}') {
			loggedinId = userBak.email;
		} else {
			loggedinId = user.email;
		}
		let userTemp = JSON.parse(localStorage.getItem('NCM_User'));
		let imlangu = '';
		if (localStorage.getItem('NCM_lang') === 'en') {
			imlangu = 'E';
		} else if (localStorage.getItem('NCM_lang') === 'de') {
			imlangu = 'D';
		} else if (localStorage.getItem('NCM_lang') === 'fr') {
			imlangu = 'F';
		} else if (localStorage.getItem('NCM_lang') === 'es') {
			imlangu = 'S';
		} else if (localStorage.getItem('NCM_lang') === 'pt') {
			imlangu = 'P';
		} else {
			imlangu = 'E';
		}
		let postobj = {
			imdeputy: addFormTemp.imdeputy.trim(),
			imfromdate: addFormTemp.imfromdate.trim(),
			immainpartner: user.email.trim(),
			imop: 'I',
			imtodate: addFormTemp.imtodate.trim(),
			fname: addFormTemp.firstname.trim(),
			lname: addFormTemp.lastname.trim(),
			loggedinId: loggedinId.trim(),
			mainpartner_fname: userTemp.firstName.trim(),
			mainpartner_lname: userTemp.lastName.trim(),
			lang: localStorage.getItem('NCM_lang')
				? localStorage.getItem('NCM_lang')
				: 'en',
			imlangu: imlangu,
			targetSystem: 'COBALT,EC',
		};
		//
		let find = props.deputyData.find((item) => {
			return (
				item.deputy.toLowerCase() === addFormTemp.imdeputy.trim().toLowerCase()
			);
		});
		if (find) {
			let notificationObj = {
				type: 'error',
				title: 'Error',
				description: `Deputy ${addFormTemp.imdeputy
					.trim()
					.toLowerCase()} already exist`,
				duration: 0,
			};
			toastMessageShow(notificationObj);
			return;
		}
		//
		toggleLoading();
		const response = await axios.post(
			'/feedbackServices/portalSap/maintainDeputy',
			postobj
		);
		toggleLoading();
		console.log(response);
		if (response.data.exsubrc === 0) {
			let notificationObj = {
				type: 'success',
				title: t('Detail.submitSuccess1'),
				description: `${t(
					'Deputy.submitsuccess1'
				)} ${postobj.imdeputy.toLowerCase()} ${t('Deputy.submitsuccess2')}`,
				duration: 5,
			};
			toastMessageShow(notificationObj);
			props.handleDeputy('', {});
			props.fetchDeputy();
		} else {
			// exmessage
			let notificationObj = {
				type: 'error',
				title: 'Error',
				description: response.data.exmessage,
				duration: 0,
			};
			toastMessageShow(notificationObj);
		}
	});

	const startDisabledDate = useMemoizedFn((current) => {
		return (
			// (current && current < props.moment().subtract(1, 'day').endOf('day')) ||
			// current > props.moment().add(2, 'year').subtract(1, 'day')
			current <= props.moment().subtract(1, 'day').endOf('day') ||
			current >= props.moment().add(2, 'year')
		);
	});

	const endDisabledDate = useMemoizedFn((current) => {
		if (startDateData) {
			return (
				current <=
					props.moment(startDateData).subtract(1, 'day').endOf('day') ||
				current >= props.moment().add(2, 'year')
			);
		} else {
			return (
				(current && current < props.moment().add(1, 'day').endOf('day')) ||
				current > props.moment().add(2, 'year').subtract(1, 'day')
			);
		}
	});

	return (
		<Fragment>
			<h4>{t('Deputy.AddDeputy')}</h4>
			{state ? (
				<div className="errorMessage">
					<span className="img">
						<img className="ICONinfo" alt="Error" src={ICONinfoGrey} />;
					</span>
					<span className="title">{t('Support.Pleasefill')}</span>
				</div>
			) : (
				<Fragment />
			)}
			<ul className="lstDeputyInfo">
				<li>
					<span className="tag">{t('Deputy.Firstname')}</span>
					<input
						type="text"
						id="txb_user"
						className="txbDeputy firstname"
						value={addForm.firstname}
						onChange={(event) => inputChangeHandler(event, 'firstname')}
						placeholder={t('Deputy.Firstname')}
					/>
					{/* <span className="errInput">{t('Register.ErrorMesage1')}</span> */}
					<span className="errInput">
						<span className="errorwrap">
							{t('Error.validfirstname')}
							<Tooltip
								mouseLeaveDelay={0}
								color="#c71526"
								placement="right"
								title={t('Register.ErrorMesage4')}
							>
								<img className="erroricon" alt="iconinfo" src={ICONinfo} />
							</Tooltip>
						</span>
					</span>
				</li>
				<li>
					<span className="tag">{t('Deputy.Lastname')}</span>
					<input
						type="text"
						id="txb_user"
						className="txbDeputy lastname"
						value={addForm.lastname}
						onChange={(event) => inputChangeHandler(event, 'lastname')}
						placeholder={t('Deputy.Lastname')}
					/>
					{/* <span className="errInput">{t('Register.ErrorMesage2')}</span> */}
					<span className="errInput">
						<span className="errorwrap">
							{t('Error.validfirstname')}
							<Tooltip
								mouseLeaveDelay={0}
								color="#c71526"
								placement="right"
								title={t('Register.ErrorMesage4')}
							>
								<img className="erroricon" alt="iconinfo" src={ICONinfo} />
							</Tooltip>
						</span>
					</span>
				</li>
				<li>
					<span className="tag">{t('Deputy.Email')}</span>
					<input
						type="text"
						id="txb_user"
						className="txbDeputy imdeputy"
						value={addForm.imdeputy}
						onChange={(event) => inputChangeHandler(event, 'imdeputy')}
						placeholder={t('Support.EMail')}
					/>
					{/* <span className="errInput">{t('Support.Pleaseenter')}</span> */}
					<span className="errInput">
						<span className="errorwrap">
							{t('Error.validemail')}
							<Tooltip
								mouseLeaveDelay={0}
								color="#c71526"
								placement="right"
								title={t('Register.ErrorMesage5')}
							>
								<img className="erroricon" alt="iconinfo" src={ICONinfo} />
							</Tooltip>
						</span>
					</span>
					<span className="errInput lh22">{t('Deputy.sameuser')}</span>
				</li>
				<li>
					<span className="tag">{t('Deputy.ConfirmEmail')}</span>
					<input
						type="text"
						id="txb_user"
						className="txbDeputy checkimdeputy"
						value={addForm.checkimdeputy}
						onChange={(event) => inputChangeHandler(event, 'checkimdeputy')}
						placeholder={t('Support.EMail')}
					/>
					{/* <span className="errInput">{t('Support.Pleaseenter')}</span> */}
					<span className="errInput">
						<span className="errorwrap">
							{t('Error.validemail')}
							<Tooltip
								mouseLeaveDelay={0}
								color="#c71526"
								placement="right"
								title={t('Register.ErrorMesage5')}
							>
								<img className="erroricon" alt="iconinfo" src={ICONinfo} />
							</Tooltip>
						</span>
					</span>
					<span className="errInput">{t('Error.nomatchemail')}</span>
					<span className="errInput lh22">{t('Deputy.sameuser')}</span>
				</li>
				<li id="from">
					<span className="tag">{t('Deputy.From')}</span>
					<DatePicker
						locale={props.locale}
						disabledDate={startDisabledDate}
						suffixIcon={<></>}
						allowClear={false}
						className="dtpDeputy imfromdate"
						onChange={(date) => startDatePickerOnChange(date)}
						placeholder={t('Detail.SelectDate')}
						format="YYYY-MM-DD"
						value={startDateData}
						showToday={false}
						getPopupContainer={() => document.getElementById('from')}
						inputReadOnly
					/>
				</li>
				<li id="to">
					<span className="tag">{t('Deputy.Until')}</span>
					<DatePicker
						locale={props.locale}
						disabledDate={endDisabledDate}
						suffixIcon={<></>}
						allowClear={false}
						className="dtpDeputy imtodate"
						onChange={(date) => endDatePickerOnChange(date)}
						placeholder={t('Detail.SelectDate')}
						format="YYYY-MM-DD"
						value={endDateData}
						showToday={false}
						getPopupContainer={() => document.getElementById('to')}
						inputReadOnly
						disabled={startDateData === null}
					/>
				</li>
			</ul>
			<div className="boxActions">
				<button
					className="btnCancel"
					onClick={() => props.handleDeputy('', {})}
				>
					{t('Detail.Cancel')}
				</button>
				<button className="btnApply" onClick={() => addDeputy()}>
					{t('Deputy.AddNewDeputy')}
				</button>
			</div>
		</Fragment>
	);
});

export default memo(FlyoutDeputyAdd);
