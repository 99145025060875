import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactive } from 'ahooks';
import moment from 'moment';

const Content2Preview = ({ fetchDetailData }) => {
	const { t } = useTranslation();

	const actionForm = useReactive({
		ACAData: [],
	});

	useEffect(() => {
		actionForm.ACAData = [];
		if (JSON.stringify(fetchDetailData) !== '{}') {
			let tempnum = [
				fetchDetailData['corr_code'].length,
				fetchDetailData['corr_codegruppe'].length,
				fetchDetailData['corr_katalogart'].length,
				fetchDetailData['corr_lgtext'].length,
				fetchDetailData['corr_matxt'].length,
				fetchDetailData['corr_peter'].length,
				fetchDetailData['corr_usrname'].length,
			];
			tempnum.sort((a, b) => b - a);
			let num = tempnum[0];
			if (num === 0) {
				let obj = {};
				obj.maindesc = '';
				obj.detaildesc = '';
				obj.matxt = '';
				obj.usrname = '';
				obj.peter = '';
				obj.lgtext = '';
				actionForm.ACAData.push(obj);
			} else {
				for (let i = 0; i < num; i++) {
					let obj = {};
					// action
					obj.maindesc = fetchDetailData['action_maindesc'][i]
						? fetchDetailData['action_maindesc'][i]
						: '';
					obj.detaildesc = fetchDetailData['action_detaildesc'][i]
						? fetchDetailData['action_detaildesc'][i]
						: '';
					// matet
					obj.matxt = fetchDetailData['corr_matxt'][i]
						? fetchDetailData['corr_matxt'][i]
						: '';
					// usrname
					obj.usrname = fetchDetailData['corr_usrname'][i]
						? fetchDetailData['corr_usrname'][i]
						: '';
					// peter
					if (
						fetchDetailData['corr_peter'][i] &&
						fetchDetailData['corr_peter'][i] !== ''
					) {
						let peter = moment(
							fetchDetailData['corr_peter'][i],
							'DD/MM/YYYY'
						).format('YYYY-MM-DD');
						obj.peter = peter;
					} else {
						obj.peter = '';
					}
					// lgtext
					obj.lgtext = fetchDetailData['corr_lgtext'][i]
						? fetchDetailData['corr_lgtext'][i]
						: '';
					//
					actionForm.ACAData.push(obj);
				}
			}
		} else {
			let obj = {};
			obj.maindesc = '';
			obj.detaildesc = '';
			obj.matxt = '';
			obj.usrname = '';
			obj.peter = '';
			obj.lgtext = '';
			actionForm.ACAData.push(obj);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchDetailData]);

	return (
		<div>
			{actionForm.ACAData.map((item, index) => {
				return (
					<div
						className={
							actionForm.ACAData.length < 4 && actionForm.ACAData.length > 1
								? 'border'
								: ''
						}
						key={index}
					>
						{index === 0 ? (
							<h5>{t('Detail.Correctiveaction')}</h5>
						) : (
							<h5>
								{t('Detail.Additionalcorrectiveaction')} {index}
							</h5>
						)}
						<ul className="lstFeedback">
							<li>
								<input
									type="text"
									id="txb_description"
									className={
										item.maindesc !== ''
											? 'txbFeedback-noborder isFilled'
											: 'txbFeedback-noborder'
									}
									value={item.maindesc || ''}
									readOnly
									placeholder={t('Detail.Selectmainaction')}
								/>
							</li>
							<li>
								<input
									type="text"
									id="txb_description"
									className={
										item.detaildesc !== ''
											? 'txbFeedback-noborder isFilled'
											: 'txbFeedback-noborder'
									}
									value={item.detaildesc || ''}
									readOnly
									placeholder={t('Detail.Selectdetailaction')}
								/>
							</li>
							<li>
								<textarea
									className={
										item.matxt !== ''
											? 'txaFeedback-noborder isFilled'
											: 'txaFeedback-noborder'
									}
									value={item.matxt || ''}
									readOnly
									placeholder={t('Detail.Describethiscorrectiveaction')}
								/>
							</li>
						</ul>
						<ul className="lstFeedback">
							<li>
								<span>{t('Detail.Responsibility')}</span>
								<input
									type="text"
									id="txb_description"
									className={
										item.usrname !== ''
											? 'txbFeedback-noborder isFilled'
											: 'txbFeedback-noborder'
									}
									value={item.usrname || ''}
									readOnly
									placeholder={t('Detail.SpecifyResponsibility')}
								/>
							</li>
							<li>
								<span>{t('Detail.Duedate')}</span>
								<input
									type="text"
									id="txb_description"
									className={
										item.peter !== ''
											? 'txbFeedback-noborder isFilled'
											: 'txbFeedback-noborder'
									}
									value={item.peter || ''}
									readOnly
									placeholder={t('Detail.SelectDate')}
								/>
							</li>
							<li>
								<span>{t('Detail.Verificationplan')}</span>
								<textarea
									className={
										item.lgtext !== ''
											? 'txaFeedback-noborder isFilled'
											: 'txaFeedback-noborder'
									}
									value={item.lgtext || ''}
									readOnly
									placeholder={t('Detail.Addyourcomments')}
								/>
							</li>
						</ul>
					</div>
				);
			})}
		</div>
	);
};

export default memo(Content2Preview);
