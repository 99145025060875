import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const ContentA1Preview = (props) => {
	const { t } = useTranslation();

	return (
		<div>
			<h5>{t('Detail.Cause')}</h5>
			<ul className="lstFeedback">
				<li>
					<input
						type="text"
						id="txb_description"
						className={
							props.codegrptext !== ''
								? 'txbFeedback-noborder isFilled'
								: 'txbFeedback-noborder'
						}
						value={props.codegrptext || ''}
						readOnly
						placeholder={t('Detail.Selectmainrootcause')}
					/>
				</li>
				<li>
					<input
						type="text"
						id="txb_description"
						className={
							props.codetext !== ''
								? 'txbFeedback-noborder isFilled'
								: 'txbFeedback-noborder'
						}
						value={props.codetext || ''}
						readOnly
						placeholder={t('Detail.Selectdetailrootcause')}
					/>
				</li>
				<li>
					<textarea
						className={
							props.urtxt !== ''
								? 'txaFeedback-noborder isFilled'
								: 'txaFeedback-noborder'
						}
						value={props.urtxt || ''}
						readOnly
						placeholder={t('Detail.Addyourcomments')}
					/>
				</li>
			</ul>
		</div>
	);
};

export default memo(ContentA1Preview);
