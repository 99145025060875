/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import './Privacy.scss';
import { useTranslation } from 'react-i18next';

const Privacy = (props) => {
	//
	const { t } = useTranslation();
	//
	useEffect(() => {
		// scroll top after page load
		window.scrollTo(0, 0);
		props.bgHandler(true, false, '', false);
	}, [props]);

	const scrollToAnchor = (anchorName) => {
		if (anchorName) {
			let anchorElement = document.getElementById(anchorName);
			if (anchorElement) {
				anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
			}
		}
	};

	useEffect(() => {
		let val = localStorage.getItem('NCM_lang')
			? localStorage.getItem('NCM_lang')
			: 'en';
		if (window.OneTrust) {
			window.OneTrust.changeLanguage(val);
		}
	}, []);

	const cookieStyle = {
		fontSize: '16px',
		fontFamily: 'Roboto,sans-serif',
		fontWeight: '300',
		lineHeight: '22px',
		color: '#000',
		marginTop: '20px',
	};

	return (
		<section className="privacy">
			<div className="inner-text">
				<h1 className="ui-main-heading">{t('Privacy.PrivacyPlicy')}</h1>

				<h2 className="ui-sub-heading">{t('Privacy.MainTitle')}</h2>

				<p className="ui-copy-text">{t('Privacy.SubTitle')}</p>

				<ol className="ui-copy-text">
					<li>
						<span onClick={() => scrollToAnchor('A')} className="anchor">
							A. {t('Privacy.questionA')}
						</span>
					</li>
					<li>
						<span onClick={() => scrollToAnchor('B')} className="anchor">
							B. {t('Privacy.questionB')}
						</span>
					</li>
					<li>
						<span onClick={() => scrollToAnchor('C')} className="anchor">
							C. {t('Privacy.questionC')}
						</span>
					</li>
					<li>
						<span onClick={() => scrollToAnchor('D')} className="anchor">
							D. {t('Privacy.questionD')}
						</span>
					</li>
					<li>
						<span onClick={() => scrollToAnchor('E')} className="anchor">
							E. {t('Privacy.questionE')}
						</span>
					</li>
					<li>
						<span onClick={() => scrollToAnchor('F')} className="anchor">
							F. {t('Privacy.questionF')}
						</span>
					</li>
					<li>
						<span onClick={() => scrollToAnchor('G')} className="anchor">
							G. {t('Privacy.questionG')}
						</span>
					</li>
					<li>
						<span onClick={() => scrollToAnchor('H')} className="anchor">
							H. {t('Privacy.questionH')}
						</span>
					</li>
				</ol>

				<h3 className="ui-minor-heading" id="A">
					A. {t('Privacy.questionA')}
				</h3>

				<p className="ui-copy-text">{t('Privacy.answerA')}</p>

				<h3 className="ui-minor-heading" id="B">
					B. {t('Privacy.questionB')}
				</h3>

				<p className="ui-copy-text is-grey is-bigger">
					1. {t('Privacy.answerB1')}
				</p>

				<p className="ui-copy-text">1. {t('Privacy.answerB2')}</p>

				<p className="ui-copy-text is-bold">a. {t('Privacy.answerB3')}</p>

				<p className="ui-copy-text">{t('Privacy.answerB4')}</p>
				<ul>
					<li>{t('Privacy.answerB5')}</li>
					<li>{t('Privacy.answerB6')}</li>
					<li>{t('Privacy.answerB7')}</li>
					<li>{t('Privacy.answerB8')}</li>
					<li>{t('Privacy.answerB9')}</li>
					<li>{t('Privacy.answerB10')}</li>
					<li>{t('Privacy.answerB11')}</li>
					<li>{t('Privacy.answerB12')}</li>
				</ul>

				<p className="ui-copy-text is-bold">b. {t('Privacy.answerB13')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB14')}</p>
				<ol className="ui-copy-text lower-roman-list">
					<li>{t('Privacy.answerB15')}</li>
					<li>{t('Privacy.answerB16')}</li>
					<li>{t('Privacy.answerB17')}</li>
				</ol>
				<p className="ui-copy-text">{t('Privacy.answerB18')}</p>

				<p className="ui-copy-text is-bold">c. {t('Privacy.answerB19')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB20')}</p>

				<p className="ui-copy-text is-grey is-bigger">
					2. {t('Privacy.answerB21')}
				</p>
				<p className="ui-copy-text is-bold">a. {t('Privacy.answerB22')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB23')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB24')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB25')}</p>
				<p className="ui-copy-text">- {t('Privacy.answerB26')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB27')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB28')}</p>
				<p className="ui-copy-text">- {t('Privacy.answerB29')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB30')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB31')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB32')}</p>
				<p className="ui-copy-text">- {t('Privacy.answerB33')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB34')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB35')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB36')}</p>
				<p className="ui-copy-text">- {t('Privacy.answerB37')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB38')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB39')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB40')}</p>

				<p className="ui-copy-text is-bold">b. {t('Privacy.answerB41')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB42')}</p>
				{/* <p className="ui-copy-text">[Automatic insertion of ONETRUST]</p> */}
				<div id="ot-sdk-cookie-policy" style={cookieStyle}></div>
				<p className="ui-copy-text">{t('Privacy.answerB43')}</p>

				<p className="ui-copy-text is-grey is-bigger">
					3. {t('Privacy.answerB44')}
				</p>
				<p className="ui-copy-text is-bold">a. {t('Privacy.answerB45')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB46')}</p>
				<p className="ui-copy-text is-bold">b. {t('Privacy.answerB47')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB48')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB49')}</p>
				<p className="ui-copy-text is-bold">c. {t('Privacy.answerB50')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB51')}</p>
				<p className="ui-copy-text">{t('Privacy.answerB52')}</p>

				<p className="ui-copy-text is-grey is-bigger">
					4. {t('Privacy.answerB53')}
				</p>
				<p className="ui-copy-text">{t('Privacy.answerB54')}</p>
				<ol className="ui-copy-text disc-list">
					<li>{t('Privacy.answerB55')}</li>
					<li>{t('Privacy.answerB56')}</li>
					<li>{t('Privacy.answerB57')}</li>
					<li>{t('Privacy.answerB577')}</li>
				</ol>
				<p className="ui-copy-text">{t('Privacy.answerB58')}</p>

				<p className="ui-copy-text is-grey is-bigger">
					5. {t('Privacy.answerB59')}
				</p>
				<p className="ui-copy-text">{t('Privacy.answerB60')}</p>
				<ol className="ui-copy-text disc-list">
					<li>{t('Privacy.answerB55')}</li>
					<li>{t('Privacy.answerB56')}</li>
					<li>{t('Privacy.answerB57')}</li>
				</ol>
				<p className="ui-copy-text">{t('Privacy.answerB61')}</p>
				<p className="ui-copy-text is-grey is-bigger">
					6. {t('Privacy.answerB62')}
				</p>
				<p className="ui-copy-text">{t('Privacy.answerB63')}</p>

				<h3 className="ui-minor-heading" id="C">
					C. {t('Privacy.questionC')}{' '}
				</h3>
				<p className="ui-copy-text">{t('Privacy.answerC1')}</p>
				<p className="ui-copy-text">{t('Privacy.answerC2')}</p>
				<ul>
					<li>{t('Privacy.answerC3')}</li>
					<li>{t('Privacy.answerC4')}</li>
					<li>{t('Privacy.answerC5')}</li>
				</ul>
				<p className="ui-copy-text">{t('Privacy.answerC6')}</p>
				<p className="ui-copy-text">{t('Privacy.answerC7')}</p>

				<h3 className="ui-minor-heading" id="D">
					D. {t('Privacy.questionD')}
				</h3>
				<p className="ui-copy-text">{t('Privacy.answerD1')}</p>
				<p className="ui-copy-text">{t('Privacy.answerD2')}</p>
				<ul>
					<li>{t('Privacy.answerD3')}</li>
					<li>{t('Privacy.answerD4')}</li>
					<li>{t('Privacy.answerD5')}</li>
					<li>{t('Privacy.answerD6')}</li>
				</ul>
				<p className="ui-copy-text">{t('Privacy.answerD7')}</p>
				<p className="ui-copy-text">{t('Privacy.answerD8')}</p>

				<h3 className="ui-minor-heading" id="E">
					E. {t('Privacy.questionE')}
				</h3>
				<p className="ui-copy-text">{t('Privacy.answerE')}</p>

				<h3 className="ui-minor-heading" id="F">
					F. {t('Privacy.questionF')}
				</h3>
				<p className="ui-copy-text">{t('Privacy.answerF1')}</p>
				<p className="ui-copy-text">
					<span className="ui-copy-text is-bold">{t('Privacy.answerF2')}</span>{' '}
					{t('Privacy.answerF3')}
				</p>
				<p className="ui-copy-text">
					<span className="ui-copy-text is-bold">{t('Privacy.answerF4')}</span>{' '}
					{t('Privacy.answerF5')}
				</p>
				<p className="ui-copy-text">
					<span className="ui-copy-text is-bold">{t('Privacy.answerF6')}</span>{' '}
					{t('Privacy.answerF7')}
				</p>
				<p className="ui-copy-text">
					<span className="ui-copy-text is-bold">{t('Privacy.answerF8')}</span>{' '}
					{t('Privacy.answerF9')}
				</p>
				<p className="ui-copy-text">
					<span className="ui-copy-text is-bold">{t('Privacy.answerF10')}</span>{' '}
					{t('Privacy.answerF11')}
				</p>
				<p className="ui-copy-text">
					<span className="ui-copy-text is-bold">{t('Privacy.answerF12')}</span>{' '}
					{t('Privacy.answerF13')}
				</p>

				<div className="ui-copy-text highlighted-text">
					<span className="ui-copy-text is-bold is-blue">
						{t('Privacy.answerF14')}
					</span>{' '}
					{t('Privacy.answerF15')}
					<div style={{ marginTop: '16px' }}>{t('Privacy.answerF16')}</div>
				</div>
				<p className="ui-copy-text">{t('Privacy.answerF17')}</p>
				<h3 className="ui-minor-heading" id="G">
					G. {t('Privacy.questionG')}
				</h3>
				<p className="ui-copy-text">{t('Privacy.answerG1')}</p>
				<p className="ui-copy-text">{t('Privacy.answerG2')}</p>
				<p className="ui-copy-text">{t('Privacy.answerG3')}</p>
				<p className="ui-copy-text">Hintere Bleiche 34</p>
				<p className="ui-copy-text">55116 Mainz</p>
				<a
					href="https://www.datenschutz.rlp.de/de/general-storage/footer/ueber-den-lfdi/kontakt/"
					className="ui-link margin-top-25"
					target="_blank"
					rel="noopener noreferrer"
				>
					https://www.datenschutz.rlp.de/de/general-storage/footer/ueber-den-lfdi/kontakt/
				</a>
				<p className="ui-copy-text">
					E-Mail:{' '}
					<a href="mailto:poststelle@datenschutz.rlp.de" className="ui-link">
						poststelle@datenschutz.rlp.de
					</a>
				</p>

				<h3 className="ui-minor-heading" id="H">
					H. {t('Privacy.questionH')}
				</h3>
				<p className="ui-copy-text">{t('Privacy.answerH')}</p>
			</div>
		</section>
	);
};

export default Privacy;
