import React, { useRef, Fragment, useImperativeHandle, memo } from 'react';
import { useTranslation } from 'react-i18next';
import './print.scss';
import ICONlogo from '../../../assets/icons/logo.svg';
import ICONradioOFF from '../../../assets/icons/toggle-inactiv.svg';
import ICONradioONGrey from '../../../assets/icons/toggle-activegrey.svg';
import { useMemoizedFn } from 'ahooks';

const Print = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const printRef = useRef(null);

	useImperativeHandle(ref, () => ({
		print: () => {
			setTimeout(() => {
				window.print();
			}, 500);
		},
	}));

	const lineRender = useMemoizedFn((val) => {
		if (val) {
			// const newVal = val.replaceAll('\n', '</br>');
			const newVal = val.replace(new RegExp('##', 'g'), '</br>');
			return (
				<p
					className="description"
					dangerouslySetInnerHTML={{ __html: newVal }}
				></p>
			);
		} else {
			return <Fragment />;
		}
	});

	const textlineRender = useMemoizedFn((val) => {
		if (val) {
			return (
				<Fragment>
					{val}
					<br />
				</Fragment>
			);
		} else {
			return <Fragment />;
		}
	});

	const changetoSmall = (val) => {
		if (val) {
			return val.toLowerCase();
		} else {
			return '';
		}
	};

	const renderEmail = (val) => {
		if (val) {
			if (val.length <= 28) {
				return <p>{changetoSmall(val)}</p>;
			} else {
				let temp = val.split('@');
				if (temp[0].length <= 26) {
					return (
						<Fragment>
							<p>{changetoSmall(temp[0])}@</p>
							<p>{temp[1]}</p>
						</Fragment>
					);
				} else {
					return (
						<Fragment>
							<p>{changetoSmall(temp[0])}</p>
							<p>@{temp[1]}</p>
						</Fragment>
					);
				}
			}
		} else {
			return (
				<p>
					<Fragment />
				</p>
			);
		}
	};

	return (
		<Fragment>
			<div className="A4Size" ref={printRef}>
				<div className="printheader">
					<div className="printheaderleft"></div>
					<div className="printheaderright">
						<img alt="company logo" src={ICONlogo} />
					</div>
				</div>
				<div className="printcontent">
					<div className="printcontent_left">
						<div className="printcontent_left_top">
							<span>Date</span>
							<p>01-05-2021</p>
						</div>
						<ul className="printcontent_ul">
							<li className="printcontent_li">
								<span>Notification No</span>
								<p>8000002592713</p>
							</li>
							<li className="printcontent_li">
								<span>Notification Date</span>
								<p>01-05-2021</p>
							</li>
							<li className="printcontent_li">
								<span>Purchase Order</span>
								<p>54999463</p>
							</li>
							<li className="printcontent_li">
								<span>Complaint Quantity</span>
								<p>39683.207 LB</p>
							</li>
							<li className="printcontent_li">
								<span>Reference Quantity</span>
								<p>567.207 LB</p>
							</li>
							<li className="printcontent_li">
								<span>BASF Material No</span>
								<p>54999463</p>
							</li>
							<li className="printcontent_li">
								<span>BASF Material Name</span>
								<p>PARA-CHLORPHENYLGLYCINE</p>
							</li>
							<li className="printcontent_li">
								<span>Name</span>
								<p>Yuen Yee Ng</p>
							</li>
							<li className="printcontent_li">
								<span>Dept/Site</span>
								<p>GDD/EC</p>
							</li>
							<li className="printcontent_li">
								<span>Telephone</span>
								<p>+8522731 1520</p>
							</li>
							<li className="printcontent_li">
								<span>Fax</span>
								<p>+8522731 5638</p>
							</li>
							<li className="printcontent_li">
								<span>Ship To</span>
								<p className="lh22">
									{lineRender('recipient')}
									{lineRender('street')}
									{lineRender('postcode1')}
									{lineRender('city1')}
									{lineRender('country')}
								</p>
							</li>
							<li className="printcontent_li">
								<span>Internal Reference Number</span>
								<p>374564634</p>
							</li>
							<li className="printcontent_li">
								<span>Internal Information</span>
								<p>85227315638</p>
							</li>
							<p className="disclaimer">* {t('Detail.informationfeedback')}</p>
						</ul>
					</div>
					<div className="printcontent_right">
						<div className="printcontent_cause">
							<span className="maintitle">Supplier Material No.</span>
							{/* <span className="infowrap">
								<p className="infotext_light">12325637</p>
							</span> */}
							<span className="infowrap_open">
								<p className="infotext_light">Supplier Material No.</p>
							</span>
						</div>
						<div className="printcontent_action border">
							<span className="maintitle">Supplier Material Name</span>
							<span className="infowrap_open">
								<p className="infotext_light">Supplier Material Name</p>
							</span>
						</div>

						<div className="printcontent_action_8dreport border">
							<span className="maintitle_8d">D1. Team</span>
							<span className="subtitle">Leader</span>
							<div className="item8d">
								<span className="infowraphalf_open">
									<p className="infotext_light">Name</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Dept/Role</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Telephone</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Email</p>
								</span>
							</div>
							<span className="subtitle">Additional Members 1</span>
							<div className="item8d">
								<span className="infowraphalf_open">
									<p className="infotext_light">Name</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Dept/Role</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Telephone</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Email</p>
								</span>
							</div>
						</div>

						<div className="printcontent_action_8dreport border">
							<span className="maintitle_8d">D2. Describe the Problem</span>
							<span className="infowrap_open">
								<p className="infotext_light">Problem from SAP</p>
							</span>
							<span className="infowrap_open">
								<p className="infotext_light">Describe the Problem</p>
							</span>
						</div>

						{/* <div className="printcontent_action_8dreport border">
							<span className="maintitle_8d">
								D3. Containment Action(s)
							</span>
							<span className="subtitle">Containment Action(s)</span>
							<div className="item8d">
								<span className="infowraphalf_open">
									<p className="infotext_light">Containment Action</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Responsible</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Date Due</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Date Completed</p>
								</span>
							</div>
							<span className="subtitle">Interim Action(s)</span>
							<div className="item8d">
								<span className="infowraphalf_open">
									<p className="infotext_light">Interim Action</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Responsible</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Date Due</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Date Completed</p>
								</span>
							</div>
						</div> */}

						<div className="printcontent_file">
							<span className="maintitle">
								{t('Detail.FileUploadOptional')}
							</span>
							<span className="blueborder">
								<p>
									{t('Detail.Drag1')}
									<span className="alt">{t('Detail.Drag2')}</span>
									{t('Detail.Drag3')}
								</p>
							</span>
							<span className="infoUpload">
								{t('Detail.FileType2')} <br />
								{t('Detail.MaxFile')}
							</span>
						</div>

						<div className="printcontent_action_8dreport border">
							<span className="maintitle_8d">
								D4. Define Root Cause(s) <br></br>D5. Permanent Corrective
								Action(s)
							</span>
							<span className="infowrap_open">
								<p className="infotext_light">Select RCA Method/Tool used</p>
							</span>
							<span className="subtitle">Define Root Cause 1</span>
							<div className="item8d">
								<span className="infowrapfull_open">
									<p className="infotext_light">Define Root Cause</p>
								</span>
							</div>
							<span className="subtitle">Permanent Corrective Action 1-1</span>
							<div className="item8d">
								<span className="infowraphalf_open">
									<p className="infotext_light">Permanent Corrective Actions</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Responsible</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Date Due.</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Date Completed</p>
								</span>
							</div>
						</div>

						<div className="printcontent_action_8dreport border">
							<span className="maintitle_8d">
								D6. Verification of Effectiveness
							</span>
							<div className="item8d">
								<span className="infowrapfull_open">
									<p className="infotext_light">
										Verification of Effectiveness
									</p>
								</span>
								<span className="infowrapfull_open">
									<p className="infotext_light">Responsible</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Date Due.</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Date Completed</p>
								</span>
							</div>
						</div>

						<div className="printcontent_file">
							<span className="maintitle">
								{t('Detail.FileUploadOptional')}
							</span>
							<span className="blueborder">
								<p>
									{t('Detail.Drag1')}
									<span className="alt">{t('Detail.Drag2')}</span>
									{t('Detail.Drag3')}
								</p>
							</span>
							<span className="infoUpload">
								{t('Detail.FileType2')} <br />
								{t('Detail.MaxFile')}
							</span>
						</div>

						<div className="printcontent_action_8dreport border">
							<span className="maintitle_8d">D7. Prevention</span>
							<div className="item8d">
								<span className="infowrapfull_open">
									<p className="infotext_light">Prevention</p>
								</span>
								<span className="infowrapfull_open">
									<p className="infotext_light">Responsible</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Date Due.</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Date Completed</p>
								</span>
							</div>
							<span className="subtitle">Other Areas Affected: No</span>
							<div className="item8d">
								<span className="infowrapfull_open">
									<p className="infotext_light">Please Explain</p>
								</span>
							</div>
						</div>

						<div className="printcontent_action_8dreport border">
							<span className="maintitle_8d">
								D8. Communicate Results and Recognize Team
							</span>
							<div className="item8d">
								<span className="infowrapfull_open">
									<p className="infotext_light">
										Communicate Results and Recognize Team
									</p>
								</span>
								<span className="infowrapfull_open">
									<p className="infotext_light">Prepared By</p>
								</span>
								<span className="infowraphalf_open">
									<p className="infotext_light">Date Completed</p>
								</span>
							</div>
						</div>
						{/* {props.etattachment.length ? (
							<div className="printcontent_file">
								<span className="maintitle">{t('Detail.FileUpload')}</span>
							</div>
						) : (
							<Fragment />
						)}
						{props.etattachment.map((item, index) => {
							return (
								<div className="printcontent_filelist" key={index}>
									<span className="filename">{item.filename}</span>
								</div>
							);
						})} */}
					</div>
				</div>
			</div>
		</Fragment>
	);
});

export default memo(Print);
