import React from 'react';
import './index.scss';
import landingRequestEmpty from '../../assets/images/maintenance.png';
// import { useTranslation } from 'react-i18next';
import { useMount } from 'ahooks';

const Maintenance = ({ bgHandler, isUserLogin }) => {
	// const { t } = useTranslation();

	useMount(() => {
		localStorage.removeItem('NCM_Token');
		localStorage.removeItem('NCM_User');
		localStorage.removeItem('NCM_User_bak');
		localStorage.removeItem('NCM_AuthorizationTime');
		localStorage.removeItem('NCM_landing_popup');
		localStorage.removeItem('NCM_searchval');
		localStorage.removeItem('NCM_filterval');
		localStorage.removeItem('NCM_expandedAll');
		localStorage.removeItem('NCM_scrollY');
		localStorage.removeItem('NCM_lockId');
		localStorage.removeItem('NCM_lang_bak');
	});

	return (
		<section className="maintenance">
			<div className="requestempty">
				<div className="eimage">
					<img alt="empty" src={landingRequestEmpty} />
				</div>
				<div className="ewrap">
					<span className="etitle">We are under maintenance.</span>
					<span className="econtent">
						We are improving the Portal and the site is currently undergoing
						scheduled maintenance. We should be back shortly.
						<br />
						<br />
						Thank you for your patience!
					</span>
				</div>
			</div>
		</section>
	);
};

export default Maintenance;
