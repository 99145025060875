import i18n from 'i18next';
import Start_en from './Start/en.json';
import Start_de from './Start/de.json';
import Start_es from './Start/es.json';
import Start_fr from './Start/fr.json';
import Start_pt from './Start/pt.json';
import Register_en from './Register/en.json';
import Register_de from './Register/de.json';
import Register_es from './Register/es.json';
import Register_fr from './Register/fr.json';
import Register_pt from './Register/pt.json';
import Footer_en from './Footer/en.json';
import Footer_de from './Footer/de.json';
import Footer_es from './Footer/es.json';
import Footer_fr from './Footer/fr.json';
import Footer_pt from './Footer/pt.json';
import Detail_en from './Detail/en.json';
import Detail_de from './Detail/de.json';
import Detail_es from './Detail/es.json';
import Detail_fr from './Detail/fr.json';
import Detail_pt from './Detail/pt.json';
import Header_en from './Header/en.json';
import Header_de from './Header/de.json';
import Header_es from './Header/es.json';
import Header_fr from './Header/fr.json';
import Header_pt from './Header/pt.json';
import Support_en from './Support/en.json';
import Support_de from './Support/de.json';
import Support_es from './Support/es.json';
import Support_fr from './Support/fr.json';
import Support_pt from './Support/pt.json';
import FAQ_en from './FAQ/en.json';
import FAQ_de from './FAQ/de.json';
import FAQ_es from './FAQ/es.json';
import FAQ_fr from './FAQ/fr.json';
import FAQ_pt from './FAQ/pt.json';
import Landing_en from './Landing/en.json';
import Landing_de from './Landing/de.json';
import Landing_es from './Landing/es.json';
import Landing_fr from './Landing/fr.json';
import Landing_pt from './Landing/pt.json';
import Overview_en from './Overview/en.json';
import Overview_de from './Overview/de.json';
import Overview_es from './Overview/es.json';
import Overview_fr from './Overview/fr.json';
import Overview_pt from './Overview/pt.json';
import ContentManagement_en from './ContentManagement/en.json';
import ContentManagement_de from './ContentManagement/de.json';
import ContentManagement_es from './ContentManagement/es.json';
import ContentManagement_fr from './ContentManagement/fr.json';
import ContentManagement_pt from './ContentManagement/pt.json';
import Privacy_en from './Privacy/en.json';
import Privacy_de from './Privacy/de.json';
import Privacy_es from './Privacy/es.json';
import Privacy_fr from './Privacy/fr.json';
import Privacy_pt from './Privacy/pt.json';
import Deputy_en from './Deputy/en.json';
import Deputy_de from './Deputy/de.json';
import Deputy_es from './Deputy/es.json';
import Deputy_fr from './Deputy/fr.json';
import Deputy_pt from './Deputy/pt.json';
import Error_en from './Error/en.json';
import Error_de from './Error/de.json';
import Error_es from './Error/es.json';
import Error_fr from './Error/fr.json';
import Error_pt from './Error/pt.json';
import D_en from './8D/en.json';
import D_de from './8D/de.json';
import D_es from './8D/es.json';
import D_fr from './8D/fr.json';
import D_pt from './8D/pt.json';
import { initReactI18next } from 'react-i18next';

const en = {
	...Start_en,
	...Register_en,
	...Footer_en,
	...Detail_en,
	...Header_en,
	...Support_en,
	...FAQ_en,
	...Landing_en,
	...Overview_en,
	...ContentManagement_en,
	...Privacy_en,
	...Deputy_en,
	...Error_en,
	...D_en,
};

const de = {
	...Start_de,
	...Register_de,
	...Footer_de,
	...Detail_de,
	...Header_de,
	...Support_de,
	...FAQ_de,
	...Landing_de,
	...Overview_de,
	...ContentManagement_de,
	...Privacy_de,
	...Deputy_de,
	...Error_de,
	...D_de,
};
const es = {
	...Start_es,
	...Register_es,
	...Footer_es,
	...Detail_es,
	...Header_es,
	...Support_es,
	...FAQ_es,
	...Landing_es,
	...Overview_es,
	...ContentManagement_es,
	...Privacy_es,
	...Deputy_es,
	...Error_es,
	...D_es,
};
const fr = {
	...Start_fr,
	...Register_fr,
	...Footer_fr,
	...Detail_fr,
	...Header_fr,
	...Support_fr,
	...FAQ_fr,
	...Landing_fr,
	...Overview_fr,
	...ContentManagement_fr,
	...Privacy_fr,
	...Deputy_fr,
	...Error_fr,
	...D_fr,
};
const pt = {
	...Start_pt,
	...Register_pt,
	...Footer_pt,
	...Detail_pt,
	...Header_pt,
	...Support_pt,
	...FAQ_pt,
	...Landing_pt,
	...Overview_pt,
	...ContentManagement_pt,
	...Privacy_pt,
	...Deputy_pt,
	...Error_pt,
	...D_pt,
};

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: en,
		},
		de: {
			translation: de,
		},
		es: {
			translation: es,
		},
		fr: {
			translation: fr,
		},
		pt: {
			translation: pt,
		},
	},
	fallbackLng: localStorage.getItem('NCM_lang') || 'en',
	debug: false,
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
});

export default i18n;
