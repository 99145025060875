import React from 'react';
import './LayerBackground.scss';

const LayerBackground = (props) => {
	return (
		<React.Fragment>
			{
				props.image !== ''
					? <img alt="background_register"
						className={props.cover ? 'bgImage cover print_hide' : 'bgImage print_hide'}
						src={props.image}
					/>
					: null
			}
			{
				props.solid
					? <div className="bgColor"></div>
					: null
			}
		</React.Fragment>
	);
}

export default LayerBackground;