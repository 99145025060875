import { notificationShow, notificationShowHtml } from '../utils/util';

const global = {
	state: {
		loading: false,
		notification: {},
		detailIsPrompt: false,
		languagePopup: false,
		isLogin: false,
		userProfileDetail: false,
		showDeputy: false,
	},
	reducers: {
		toggleLoading: (state) => {
			state.loading = !state.loading;
		},
		showLoading: (state) => {
			state.loading = true;
		},
		hideLoading: (state) => {
			state.loading = false;
		},
		changeDetailIsPrompt: (state, val) => {
			state.detailIsPrompt = val;
		},
		changeUserProfileDetail: (state, val) => {
			state.userProfileDetail = val;
		},
		changeLanguagePopup: (state, val) => {
			state.languagePopup = val;
		},
		changeShowDeputy: (state, val) => {
			state.showDeputy = val;
		},
		changeisLogin: (state) => {
			state.isLogin = state;
		},
	},
	effects: () => ({
		toastMessageShow(payload) {
			notificationShow(
				payload.type,
				payload.title,
				payload.description,
				payload.duration
			);
		},
		toastMessageShowHTML(payload) {
			notificationShowHtml(
				payload.type,
				payload.title,
				payload.description,
				payload.duration
			);
		},
	}),
};

export default global;
