import { Button, notification } from 'antd';
import React, { Fragment } from 'react';
import ICONsuccess from '../assets/icons/success.svg';
// import ICONerror from '../assets/icons/error.svg';
import ICONinfo from '../assets/icons/info-i.svg';
import { Translation } from 'react-i18next';
import i18n from '../locales/i18n';
import crypto from 'crypto';

export function getQueryVariable(search, variable) {
	let query = search.substring(1);
	let vars = query.split('&');
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split('=');
		if (pair[0] === variable) {
			return pair[1];
		}
	}
	return '';
}

export function getQueryVariableTemp(search, variable) {
	let vars = search.split('&');
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split('=');
		if (pair[0] === variable) {
			return pair[1];
		}
	}
	return '';
}

export function variableToObj(search, variable) {
	let result = {};
	let vars = search.split('&');
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split('=');
		result[pair[0]] = pair[1];
	}
	return result;
}

export function removeEmptyField(obj) {
	let newObj = {};
	if (typeof obj === 'string') {
		obj = JSON.parse(obj);
	}
	if (obj instanceof Array) {
		newObj = [];
	}
	if (obj instanceof Object) {
		for (let attr in obj) {
			if (
				obj.hasOwnProperty(attr) &&
				obj[attr] !== '' &&
				obj[attr] !== null &&
				obj[attr] !== undefined
			) {
				if (obj[attr] instanceof Object) {
					if (
						JSON.stringify(obj[attr]) === '{}' ||
						JSON.stringify(obj[attr]) === '[]'
					) {
						continue;
					}
					//
					newObj[attr] = removeEmptyField(obj[attr]);
				} else if (
					typeof obj[attr] === 'string' &&
					((obj[attr].indexOf('{') > -1 && obj[attr].indexOf('}') > -1) ||
						(obj[attr].indexOf('[') > -1 && obj[attr].indexOf(']') > -1))
				) {
					try {
						let attrObj = JSON.parse(obj[attr]);
						if (attrObj instanceof Object) {
							newObj[attr] = removeEmptyField(attrObj);
						}
					} catch (e) {
						newObj[attr] = obj[attr];
					}
				} else {
					newObj[attr] = obj[attr];
				}
			}
		}
	}
	return newObj;
}

export function toType(obj) {
	return {}.toString
		.call(obj)
		.match(/\s([a-zA-Z]+)/)[1]
		.toLowerCase();
}

export function filterNull(o) {
	for (var key in o) {
		if (o[key] === null || o[key] === '' || o[key] === undefined) {
			delete o[key];
		}
		if (toType(o[key]) === 'string') {
			o[key] = o[key].trim();
		} else if (toType(o[key]) === 'object') {
			o[key] = filterNull(o[key]);
		} else if (toType(o[key]) === 'array') {
			o[key] = filterNull(o[key]);
		}
	}
	return o;
}

export function filterNullwithArray(o) {
	for (var key in o) {
		if (
			o[key] === null ||
			o[key] === '' ||
			o[key] === undefined ||
			o[key].length === 0
		) {
			delete o[key];
		}
		if (toType(o[key]) === 'string') {
			o[key] = o[key].trim();
		} else if (toType(o[key]) === 'object') {
			o[key] = filterNull(o[key]);
		} else if (toType(o[key]) === 'array') {
			o[key] = filterNull(o[key]);
		}
	}
	return o;
}

export function awaitWrap(promise) {
	return promise.then((data) => [null, data]).catch((err) => [err, null]);
}

export function getQueryletiable(search, letiable) {
	let query = search.substring(1);
	let lets = query.split('&');
	for (let i = 0; i < lets.length; i++) {
		let pair = lets[i].split('=');
		if (pair[0] === letiable) {
			return pair[1];
		}
	}
	return false;
}

export function getBase64Type(type) {
	// eslint-disable-next-line default-case
	switch (type) {
		case 'txt':
			return 'data:text/plain;base64,';
		case 'doc':
			return 'data:application/msword;base64,';
		case 'docx':
			return 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,';
		case 'xls':
			return 'data:application/vnd.ms-excel;base64,';
		case 'xlsx':
			return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
		case 'pdf':
			return 'data:application/pdf;base64,';
		case 'pptx':
			return 'data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,';
		case 'ppt':
			return 'data:application/vnd.ms-powerpoint;base64,';
		case 'png':
			return 'data:image/png;base64,';
		case 'jpg':
			return 'data:image/jpeg;base64,';
		case 'jpeg':
			return 'data:image/jpeg;base64,';
		case 'gif':
			return 'data:image/gif;base64,';
		case 'svg':
			return 'data:image/svg+xml;base64,';
		case 'ico':
			return 'data:image/x-icon;base64,';
		case 'bmp':
			return 'data:image/bmp;base64,';
		case 'msg':
			return 'data:application/octet-stream;base64,';
	}
}

function dataURLtoBlob(base64, mime) {
	let bstr = atob(base64);
	let n = bstr.length;
	let u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], { type: mime });
}

function downloadFile(url, name) {
	let a = document.createElement('a');
	a.setAttribute('href', url);
	a.setAttribute('download', name);
	a.setAttribute('target', '_blank');
	let clickEvent = document.createEvent('MouseEvents');
	clickEvent.initEvent('click', true, true);
	a.dispatchEvent(clickEvent);
}

export function downloadFileByBase64(base64, filename) {
	let temp = filename.split('.');
	let file = temp[temp.length - 1].toLowerCase();
	let mime = getBase64Type(file);
	let myBlob = dataURLtoBlob(base64, mime);
	let myUrl = URL.createObjectURL(myBlob);
	downloadFile(myUrl, filename);
}

export function downloadFileByBase64WithFileName(base64, filename) {
	if (base64 === '') {
		return;
	}
	let arr = base64.split(',');
	let mime = arr[0].match(/:(.*?);/)[1];
	let myBlob = dataURLtoBlob(arr[1], mime);
	let myUrl = URL.createObjectURL(myBlob);
	downloadFile(myUrl, filename);
}

export function fileToBase64(blob) {
	return new Promise((res) => {
		const reader = new window.FileReader();
		reader.readAsDataURL(blob);
		reader.onload = () => {
			res(reader.result);
		};
	});
}

export function queryParams(data = {}, arrayFormat = 'brackets') {
	let _result = [];
	if (['indices', 'brackets', 'repeat', 'comma'].indexOf(arrayFormat) === -1)
		arrayFormat = 'brackets';
	for (let key in data) {
		let value = data[key];
		if (['', undefined, null].indexOf(value) >= 0) {
			continue;
		}
		if (value.constructor === Array) {
			switch (arrayFormat) {
				case 'indices':
					for (let i = 0; i < value.length; i++) {
						_result.push(key + '[' + i + ']=' + value[i]);
					}
					break;
				case 'brackets':
					value.forEach((_value) => {
						_result.push(key + '[]=' + _value);
					});
					break;
				case 'repeat':
					value.forEach((_value) => {
						_result.push(key + '=' + _value);
					});
					break;
				case 'comma':
					let commaStr = '';
					value.forEach((_value) => {
						commaStr += (commaStr ? ',' : '') + _value;
					});
					_result.push(key + '=' + commaStr);
					break;
				default:
					value.forEach((_value) => {
						_result.push(key + '[]=' + _value);
					});
			}
		} else {
			_result.push(key + '=' + value);
		}
	}
	return _result.length ? _result.join('&') : '';
}

export function dataURLtoFile(dataurl) {
	let size0 = dataurl.length;
	let equalIndex = dataurl.indexOf('=');
	if (equalIndex > 0) {
		size0 = size0 - (10 - equalIndex);
	}
	let result = size0 - (size0 / 8) * 2;
	return Math.round(result * 100) / 100;
}

export function notificationShow(type, title, description, duration = 10) {
	if (type === 'success') {
		notification.open({
			message: title,
			description: description,
			icon: iconSuccess(),
			duration: duration,
		});
	} else if (type === 'error') {
		notification.open({
			message: title,
			description: description,
			icon: iconError(),
			duration: duration,
		});
	}
}
// const key = `open${Date.now()}`;
const btnClick = function () {
	// to hide notification box
	// notification.close(key);

	// workaround to reload the page without history method, since there is not react hooks context here
	let url = document.URL;
	window.location.href = url;
};
const btn = (
	<Button type="primary" size="small" onClick={btnClick}>
		Refresh
	</Button>
);
export function notificationShowHtml(
	type,
	title,
	description,
	duration = 10,
	key = '',
	statusNum = 0,
	hasBtn = true
) {
	if (type === 'success') {
		if (title.includes('Error.') && title.includes('title')) {
			notification.open({
				message: titleTran(title),
				description: descriptionHtml(description, key),
				icon: iconSuccess(),
				duration: duration,
			});
		} else {
			notification.open({
				message: title,
				description: descriptionHtml(description, key),
				icon: iconSuccess(),
				duration: duration,
			});
		}
	} else if (type === 'error') {
		if (title.includes('Error.') && title.includes('title')) {
			if (title === 'Error.403title') {
				notification.open({
					message: titleTran(title, statusNum),
					description: descriptionHtml(description, key),
					icon: iconError(),
					duration: duration,
					// key,
					btn,
					key: '403',
				});
			} else if (title === 'Error.500title') {
				notification.open({
					message: titleTran(title, statusNum),
					description: descriptionHtml(description, key),
					icon: iconError(),
					duration: duration,
					// key,
					btn,
					key: '500',
				});
			} else if (title === 'Error.504title') {
				notification.open({
					message: titleTran(title, statusNum),
					description: descriptionHtml504(description, key),
					icon: iconError(),
					duration: duration,
					// key,
					btn: btn,
				});
			} else if (title === 'Error.502title') {
				notification.open({
					message: titleTran(title, statusNum),
					description: descriptionHtml502(description, key),
					icon: iconError(),
					duration: duration,
					// key,
					btn: null,
				});
			} else {
				notification.open({
					message: titleTran(title, statusNum),
					description: descriptionHtml(description, key),
					icon: iconError(),
					duration: duration,
					// key,
					btn: hasBtn ? btn : null,
				});
			}
		} else if (title === 'Error.LetterFailed') {
			notification.open({
				message: titleTran(title),
				description: descriptionLetterLockHtml(description, key),
				icon: iconError(),
				duration: 0,
			});
		} else {
			notification.open({
				message: title,
				description: descriptionHtml(description, key),
				icon: iconError(),
				duration: duration,
				// key,
				btn: hasBtn ? btn : null,
			});
		}
	}
}

const iconSuccess = () => {
	return <img alt="Succes" src={ICONsuccess} />;
};

const iconError = () => {
	return <img className="ICONinfo" alt="Error" src={ICONinfo} />;
};

const titleTran = (val, statusNum) => {
	if (statusNum === 0) {
		return (
			<Translation i18n={i18n}>
				{(t, { i18n }) => <Fragment>{t(val)}</Fragment>}
			</Translation>
		);
	} else {
		return (
			<Translation i18n={i18n}>
				{(t, { i18n }) => (
					<Fragment>
						{statusNum} {t(val)}
					</Fragment>
				)}
			</Translation>
		);
	}
};

const descriptionHtml = (str, key = '') => {
	if (key !== '') {
		return (
			<Translation i18n={i18n}>
				{(t, { i18n }) => (
					<Fragment>
						<span dangerouslySetInnerHTML={{ __html: t(key) }}></span>
						<a href="mailto:ncmportal@basf.com" className="ui-link">
							ncmportal@basf.com
						</a>
						.<br />
						<button className="btn-back-to-start">
							<a href="/">Back to Start</a>
						</button>
					</Fragment>
				)}
			</Translation>
		);
	} else {
		return <span dangerouslySetInnerHTML={{ __html: str }}></span>;
	}
};

const descriptionLetterLockHtml = (str, key = '') => {
	return (
		<Translation i18n={i18n}>
			{(t, { i18n }) => (
				<Fragment>
					<span dangerouslySetInnerHTML={{ __html: t(key) }}></span>
				</Fragment>
			)}
		</Translation>
	);
};

const descriptionHtml504 = (str, key = '') => {
	if (key !== '') {
		return (
			<Translation i18n={i18n}>
				{(t, { i18n }) => (
					<Fragment>
						<span dangerouslySetInnerHTML={{ __html: t(key) }}></span>
						<a href="mailto:ncmportal@basf.com" className="ui-link">
							ncmportal@basf.com
						</a>
						.<br />
						<button
							className="btn-back-to-start"
							style={{ visibility: 'hidden' }}
						>
							<a href="/">Back to Start</a>
						</button>
					</Fragment>
				)}
			</Translation>
		);
	} else {
		return <span dangerouslySetInnerHTML={{ __html: str }}></span>;
	}
};

const descriptionHtml502 = (str, key = '') => {
	if (key !== '') {
		return (
			<Translation i18n={i18n}>
				{(t, { i18n }) => (
					<Fragment>
						<span dangerouslySetInnerHTML={{ __html: t('Error.502_1') }}></span>
						<a href="mailto:ncmportal@basf.com" className="ui-link">
							ncmportal@basf.com
						</a>
						<span dangerouslySetInnerHTML={{ __html: t('Error.502_2') }}></span>
					</Fragment>
				)}
			</Translation>
		);
	} else {
		return <span dangerouslySetInnerHTML={{ __html: str }}></span>;
	}
};

export function encrypt(val) {
	const key = 'BASF&NCMFeedbackPortal@Sebastian';
	const iv = '0123456789AB';
	const cipher = crypto.createCipheriv('aes-256-gcm', key, iv);
	const encrypted = cipher.update(JSON.stringify(val), 'utf8');
	const end = cipher.final();
	const tag = cipher.getAuthTag();
	const res = Buffer.concat([encrypted, end, tag]);
	return res.toString('base64');
}

export function encryptStr(val) {
	const key = 'BASF&NCMFeedbackPortal@Sebastian';
	const iv = '0123456789AB';
	const cipher = crypto.createCipheriv('aes-256-gcm', key, iv);
	const encrypted = cipher.update(val, 'utf8');
	const end = cipher.final();
	const tag = cipher.getAuthTag();
	const res = Buffer.concat([encrypted, end, tag]);
	return res.toString('base64');
}

export function decrypt(val) {
	const key = 'BASF&NCMFeedbackPortal@Sebastian';
	const iv = '0123456789AB';
	const decipher = crypto.createDecipheriv('aes-256-gcm', key, iv);
	const b = Buffer.from(val, 'base64');
	decipher.setAuthTag(b.subarray(b.length - 16));
	const str = decipher.update(Buffer.from(b.subarray(0, b.length - 16), 'hex'));
	const fin = decipher.final();
	const decryptedStr = new TextDecoder('utf8').decode(
		Buffer.concat([str, fin])
	);
	return decryptedStr;
}
