import React, { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoizedFn } from 'ahooks';
import { DatePicker, Input, Form, Row, Col } from 'antd';
import '../../../../node_modules/antd/lib/input/style/index.css';
import '../../../../node_modules/antd/lib/form/style/index.css';
import { onSubmitRequired, onBlurValidateDirtyword } from './patternRex';
import { useMount, useReactive } from 'ahooks';

const D8Communicate = React.forwardRef((_, ref) => {
	const { t } = useTranslation();

	return (
		<div ref={ref}>
			<Row>
				<Col xs={24}>
					<Form.Item
						label=""
						name="d8_communicate"
						className="antd_label"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input className="antd_input" placeholder={t('8D.Communicate')} />
					</Form.Item>
				</Col>
				<Col xs={24}>
					<Form.Item
						label=""
						name="d8_prepared"
						className="antd_label"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input placeholder={t('8D.PreparedBy')} className="antd_input" />
					</Form.Item>
				</Col>
				<Col xs={24} md={11}>
					<Form.Item
						label=""
						name="d8_datecompl"
						className="antd_label"
						rules={[
							{
								required: true,
								message: '',
							},
						]}
					>
						<DatePicker
							style={{ width: '100%' }}
							suffixIcon={<></>}
							placeholder={t('8D.DateCompleted')}
							format="YYYY-MM-DD"
							showToday={false}
							className="antd_date"
						/>
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
});

export default memo(D8Communicate);
