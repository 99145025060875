import React, { useState, useEffect, memo, useImperativeHandle } from 'react';
import '@wangeditor/editor/dist/css/style.css';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import { i18nChangeLanguage } from '@wangeditor/editor';
i18nChangeLanguage('en');

const RichEditor = React.forwardRef((props, ref) => {
	const [editor, setEditor] = useState(null);

	useImperativeHandle(ref, () => ({
		getHtml: () => {
			return editor.getHtml();
		},
		setHtml: (val) => {
			editor.setHtml(val);
		},
	}));

	const toolbarConfig = {
		excludeKeys: [
			'blockquote',
			'clearStyle',
			'insertImage',
			'insertVideo',
			'fullScreen',
			'code',
			'redo',
			'undo',
			'uploadImage',
			'codeBlock',
			'viewImageLink',
			'group-image',
		],
	};

	// 编辑器配置
	const editorConfig = {
		onCreated: (editor) => {
			setEditor(editor);
		},
	};

	useEffect(() => {
		return () => {
			if (editor == null) return;
			editor.destroy();
			setEditor(null);
		};
	}, [editor]);

	return (
		<div style={{ border: '1px solid #ccc', zIndex: 100 }}>
			<Toolbar
				editor={editor}
				defaultConfig={toolbarConfig}
				style={{ borderBottom: '1px solid #ccc' }}
				mode="simple"
			/>
			<Editor
				defaultConfig={editorConfig}
				defaultHtml={props.value}
				style={{ height: '200px', overflowY: 'hidden' }}
			/>
		</div>
	);
});

export default memo(RichEditor);
