export const onSubmitRequired = () => {
	return {
		required: true,
		message: '',
		validateTrigger: 'onSubmit',
	};
};

export const onBlurValidateDirtyword = () => {
	return {
		validator: (_, value) => {
			if (value) {
				if (
					value.trim() === '-' ||
					value.trim() === 'N/A' ||
					value.trim() === 'n/a'
				) {
					return Promise.reject(
						'This is a mandatory field. Please provide a valid response.'
					);
				}
				return Promise.resolve();
			} else {
				return Promise.resolve();
			}
		},
		validateTrigger: 'onBlur',
	};
};

export const patterns = {
	name: /(^[a-zA-Z]{1}[a-zA-Z\s]{0,20}[a-zA-Z]{1}$)/,
	tel: /^[0-9]*$/,
	email:
		// eslint-disable-next-line no-useless-escape
		/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
};

export const onBlurValidateName = () => {
	return {
		validator: (_, value) => {
			if (value) {
				if (!patterns.name.test(value)) {
					return Promise.reject();
				} else {
					return Promise.resolve();
				}
			} else {
				return Promise.resolve();
			}
		},
		validateTrigger: 'onBlur',
	};
};

export const onBlurValidateEmail = () => {
	return {
		validator: (_, value) => {
			if (value) {
				if (!patterns.email.test(value)) {
					return Promise.reject();
				} else {
					return Promise.resolve();
				}
			} else {
				return Promise.resolve();
			}
		},
		validateTrigger: 'onBlur',
	};
};

export const onBlurValidateTel = () => {
	return {
		validator: (_, value) => {
			if (value) {
				if (!patterns.tel.test(value)) {
					return Promise.reject('invalid contact number');
				} else {
					return Promise.resolve();
				}
			} else {
				return Promise.resolve();
			}
		},
		validateTrigger: 'onBlur',
	};
};

export const onBlurEmpty = (
	nameValue1,
	nameValue2,
	nameValue3,
	nameValue4,
	nameValue5,
	nameValue6,
	nameValue7,
	nameValue8,
	nameValue9,
	nameValue10
) => {
	return {
		validator: (_, value) => {
			if (
				!nameValue1 &&
				!nameValue2 &&
				!nameValue3 &&
				!nameValue4 &&
				!nameValue5 &&
				!nameValue6 &&
				!nameValue7 &&
				!nameValue8 &&
				!nameValue9 &&
				!nameValue10
			) {
				return Promise.reject();
			} else {
				return Promise.resolve();
			}
		},
		validateTrigger: 'onBlur',
	};
};

export const patternName = (name) => {
	if (!patterns.name.test(name)) {
		return false;
	} else {
		return true;
	}
};

export const patternTel = (tel) => {
	if (!patterns.tel.test(tel)) {
		return false;
	} else {
		return true;
	}
};

export const patternEmail = (email) => {
	if (!patterns.email.test(email)) {
		return false;
	} else {
		return true;
	}
};
