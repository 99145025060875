import React, { Fragment, useEffect, useState, useRef } from 'react';
import IMAGlandingBig from '../../assets/images/adminlanding_bg.png';
import axios from '../../axios-custom';
import { useHistory } from 'react-router-dom';
import './index.scss';
import store from '../../state/store';
import { useReactive, useMemoizedFn } from 'ahooks';
import { Modal } from 'antd';
import '../../../node_modules/antd/lib/modal/style/index.css';
import ICONclose from '../../assets/icons/close.svg';
import { useMount } from 'ahooks';
import RichEditor from '../CM/Faq/RichEditor';
import ICONradioOFF from '../../assets/icons/toggle-inactiv.svg';
import ICONradioON from '../../assets/icons/toggle-activ.svg';
const { useModel } = store;

const AdminAnnouncement = ({ bgHandler, isUserLogin }) => {
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading, toastMessageShow } = dispatchers;
	const history = useHistory();
	const [isModalVisible, setIsModalVisible] = useState(false);

	const editorRef1 = useRef(null);

	const userForm = useReactive({
		id: '',
		isDeactivate: 0,
	});

	useMount(async () => {
		window.scrollTo(0, 0);
		if (
			!localStorage.getItem('NCM_Token') ||
			localStorage.getItem('NCM_Token') === ''
		) {
			localStorage.removeItem('NCM_Token');
			localStorage.removeItem('NCM_User');
			localStorage.removeItem('NCM_User_bak');
			localStorage.removeItem('NCM_AuthorizationTime');
			localStorage.removeItem('NCM_landing_popup');
			localStorage.removeItem('NCM_searchval');
			localStorage.removeItem('NCM_filterval');
			localStorage.removeItem('NCM_expandedAll');
			localStorage.removeItem('NCM_scrollY');
			localStorage.removeItem('NCM_lang_bak');
			history.replace('/');
			setTimeout(() => {
				window.location.reload();
			}, 10);
			return;
		}
		if (
			localStorage.getItem('NCM_AuthorizationTime') &&
			localStorage.getItem('NCM_AuthorizationTime') !== ''
		) {
			let newtime = Date.parse(new Date()).toString();
			newtime = newtime.substr(0, 10);
			const oldtime = localStorage.getItem('NCM_AuthorizationTime');
			if (Number.parseInt(newtime) - Number.parseInt(oldtime) > 12 * 60 * 60) {
				localStorage.removeItem('NCM_Token');
				localStorage.removeItem('NCM_User');
				localStorage.removeItem('NCM_User_bak');
				localStorage.removeItem('NCM_AuthorizationTime');
				localStorage.removeItem('NCM_landing_popup');
				localStorage.removeItem('NCM_searchval');
				localStorage.removeItem('NCM_filterval');
				localStorage.removeItem('NCM_expandedAll');
				localStorage.removeItem('NCM_scrollY');
				localStorage.removeItem('NCM_lang_bak');
				history.replace('/');
				setTimeout(() => {
					window.location.reload();
				}, 10);
				return;
			}
		}
		//
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		//
		let email = '';
		if (userBak && JSON.stringify(userBak) !== '{}') {
			email = userBak.email;
		} else {
			email = user.email;
		}
		//
		toggleLoading();
		let admin = await axios.post('/feedbackServices/apiUser/getUserInfo', {
			email: email,
		});
		toggleLoading();
		if (admin && admin.data && admin.data.isAdmin === 'true') {
			//
		} else {
			history.replace('/landing');
			setTimeout(() => {
				window.location.reload();
			}, 10);
		}
	});

	useMount(async () => {
		toggleLoading();
		let data = await axios.get('/feedbackServices/api/getPublicNotification');
		userForm.id = data.data.id;
		userForm.isDeactivate = data.data.isDeactivate ? 0 : 1;
		setTimeout(() => {
			editorRef1.current.setHtml(data.data.enmessage);
		}, 10);
		toggleLoading();
	});

	useEffect(() => {
		bgHandler(true, false, IMAGlandingBig, false);
	}, [bgHandler]);

	const changeExpandedAll = useMemoizedFn(() => {
		if (userForm.isDeactivate === 1) {
			userForm.isDeactivate = 0;
		} else {
			userForm.isDeactivate = 1;
		}
	});

	const openModal = () => {
		userForm.content = editorRef1.current.getHtml();
		setIsModalVisible(true);
	};

	const submit = async () => {
		const content = editorRef1.current.getHtml();
		const obj = {
			id: userForm.id,
			enmessage: content,
			isDeactivate: userForm.isDeactivate === 0,
		};
		const response = await axios.post(
			`/feedbackServices/api/updatePublicNotification`,
			obj
		);
		console.log(response);
		let notificationObj = {
			type: 'success',
			title: 'Success',
			description: `You have successfully add the announcement.`,
			duration: 5,
		};
		toastMessageShow(notificationObj);
	};

	return (
		<Fragment>
			<Modal
				open={isModalVisible}
				className="tipsModal"
				footer={null}
				closable={false}
				centered
				getContainer={false}
			>
				<div className="contentinto">
					<div
						className="contentwrap"
						dangerouslySetInnerHTML={{
							__html: userForm.content,
						}}
					></div>
				</div>
				<div className="closeicon" onClick={() => setIsModalVisible(false)}>
					<img alt="close" src={ICONclose} />
				</div>
			</Modal>
			<section className="adminan">
				<div className="maintitle">Announcement Management</div>
				<div className="contentwrapmiddle">
					<RichEditor ref={editorRef1} />
				</div>
				<div>
					<span className="deactivewrapa">
						{userForm.isDeactivate === 1 ? (
							<span className="deactivewrapatitle">Active</span>
						) : (
							<span className="deactivewrapatitle">Deactivate</span>
						)}
						<button
							className="btnAllExpand"
							onClick={() => changeExpandedAll()}
						>
							{userForm.isDeactivate === 1 ? (
								<img alt="Shrinked" src={ICONradioON} />
							) : (
								<img alt="Expanded" src={ICONradioOFF} />
							)}
						</button>
					</span>
				</div>
				<div className="buttonfunction">
					<span className="button">
						<button className="btnCancel" onClick={() => openModal()}>
							Preview
						</button>
						<button className="btnSend" onClick={() => submit()}>
							Submit
						</button>
					</span>
				</div>
			</section>
		</Fragment>
	);
};

export default AdminAnnouncement;
