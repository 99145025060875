import React, { useEffect } from 'react';
import ICONdown from '../../assets/icons/arrow-down.svg';
import ICONup from '../../assets/icons/arrow-up.svg';
import ICONlanguages from '../../assets/icons/languages.svg';
import ICONuser from '../../assets/icons/user.svg';
import ICONdeputyman from '../../assets/icons/deputy-manage.svg';
import { useTranslation } from 'react-i18next';
import './FAQ.scss';

const FAQ = (props) => {
	const { t } = useTranslation();
	const delay = 155;

	useEffect(() => {
		// scroll top after page load
		window.scrollTo(0, 0);
		props.bgHandler(true, false, '', false);
	}, [props]);

	const closeChildren = (elem) => {
		if (elem.querySelector('.faq-lst-item.expanded')) {
			elem.querySelector('.faq-lst-item.expanded').classList.remove('expanded');
		}
	};

	const closeSiblings = (elem) => {
		// Setup siblings array and get the first sibling
		// var siblings = [];
		var sibling = elem.parentNode.firstChild;
		// Loop through each sibling and push to the array
		while (sibling) {
			if (
				sibling.nodeType === 1 &&
				sibling !== elem &&
				sibling.classList.contains('expanded')
			) {
				// siblings.push(sibling);
				const thisSibling = sibling;

				setTimeout(() => {
					thisSibling.classList.remove('expanded');

					// if there is a sublist (on main categories), close it
					if (thisSibling.childNodes[1].querySelector('.faq-lst')) {
						closeChildren(thisSibling.childNodes[1].querySelector('.faq-lst'));
					}
				}, delay); // using timeout here for a bit better user experience
			}
			sibling = sibling.nextSibling;
		}
	};

	const toggleElement = (event) => {
		const listItem = event.target.closest('.faq-lst-item');
		closeSiblings(listItem);
		// const bttnItem = event.target.closest('.btnExpand');

		if (listItem.classList.contains('expanded')) {
			listItem.classList.remove('expanded');

			setTimeout(() => {
				// if there is a sublist (on main categories), close it
				if (listItem.childNodes[1].querySelector('.faq-lst')) {
					closeChildren(listItem.childNodes[1].querySelector('.faq-lst'));
				}
			}, delay); // using timeout here for a bit better user experience
		} else {
			listItem.classList.add('expanded');
			// bttnItem.classList.add('expanded');
		}
	};

	return (
		<section className="faq">
			<div className="inner-text">
				<h1 className="ui-main-heading">{t('FAQ.title')}</h1>
				<h2 className="ui-sub-heading has-color">{t('FAQ.intro1')}</h2>
				{/* <p className="ui-copy-text">{t('FAQ.intro2')}</p>
				<p className="ui-copy-text">
					{t('FAQ.intro3-1')}
					<a href="/support" className="ui-link">
						{t('FAQ.intro3-2')}.
					</a>
				</p> */}
				{/* <p className="ui-copy-text">{t('FAQ.intro2')}</p> */}
				<p className="ui-copy-text">
					{t('FAQ.intro3-1')}
					<a href="/support" className="ui-link">
						{t('FAQ.intro3-2')}
					</a>
				</p>
				{/* <h2 className="ui-sub-heading has-color">
					This is an example for a colorful headline: BASF is pleased about your visit
				</h2> */}
				<div className="faq-content-area">
					<ul className="faq-lst">
						<li
							className={
								props.expandedAll ? 'faq-lst-item expanded' : 'faq-lst-item'
							}
						>
							<div
								className="faq-lst-item-panel"
								onClick={(e) => toggleElement(e)}
							>
								<h4 className="faq-lst-item-headline">{t('FAQ.category1')}</h4>
								<button className="btnExpand">
									<img
										className="accordion-icon expand"
										alt="Expand"
										src={ICONdown}
									/>
									<img
										className="accordion-icon shrink"
										alt="Shrink"
										src={ICONup}
									/>
								</button>
							</div>
							<div className="faq-lst-item-content">
								<ul className="faq-lst">
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category1-question1')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category1-answer1-1')}
											</p>
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category1-answer1-2')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category1-question2')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category1-answer2')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category1-question25')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category1-answer25a')}
											</p>
											<p className="faq-lst-item-element faq-lst-item-text">
												<b>{t('FAQ.category1-answer25b')}</b>
												{t('FAQ.category1-answer25c')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category1-question3')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category1-answer3')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category1-question4')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category1-answer4')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category1-question5')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category1-answer5')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category1-question6')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category1-answer6')}
											</p>
										</div>
									</li>
								</ul>
							</div>
						</li>

						<li
							className={
								props.expandedAll ? 'faq-lst-item expanded' : 'faq-lst-item'
							}
						>
							<div
								className="faq-lst-item-panel"
								onClick={(e) => toggleElement(e)}
							>
								<h4 className="faq-lst-item-headline">{t('FAQ.category2')}</h4>
								<button className="btnExpand">
									<img
										className="accordion-icon expand"
										alt="Expand"
										src={ICONdown}
									/>
									<img
										className="accordion-icon shrink"
										alt="Shrink"
										src={ICONup}
									/>
								</button>
							</div>
							<div className="faq-lst-item-content">
								<ul className="faq-lst">
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category2-question1')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category2-answer1-1')}
												<a href="/support" className="ui-link">
													{t('FAQ.category2-answer1-2')}.
												</a>
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category2-question2')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category2-answer2')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category2-question3')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category2-answer3')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category2-question4')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category2-answer4-1')}
												<a href="/support" className="ui-link">
													{t('FAQ.category2-answer4-2')}.
												</a>
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category2-question5')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category2-answer5')}
											</p>
										</div>
									</li>
								</ul>
							</div>
						</li>

						<li
							className={
								props.expandedAll ? 'faq-lst-item expanded' : 'faq-lst-item'
							}
						>
							<div
								className="faq-lst-item-panel"
								onClick={(e) => toggleElement(e)}
							>
								<h4 className="faq-lst-item-headline">{t('FAQ.category3')}</h4>
								<button className="btnExpand">
									<img
										className="accordion-icon expand"
										alt="Expand"
										src={ICONdown}
									/>
									<img
										className="accordion-icon shrink"
										alt="Shrink"
										src={ICONup}
									/>
								</button>
							</div>
							<div className="faq-lst-item-content">
								<ul className="faq-lst">
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category3-question1')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category3-answer1-1')}
											</p>
											<ul className="faq-lst-item-element faq-lst-item-unordered-lst">
												<li>{t('FAQ.category3-answer1-2')}</li>
												<li>{t('FAQ.category3-answer1-3')}</li>
												<li>{t('FAQ.category3-answer1-4')}</li>
												<li>{t('FAQ.category3-answer1-5')}</li>
												<li>{t('FAQ.category3-answer1-6')}</li>
											</ul>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category3-question2')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category3-answer21')}{' '}
												<a
													target="_blank"
													href="https://external-profile-management.basf.com/forgotpassword/"
													rel="noopener noreferrer"
												>
													{t('FAQ.category3-answer22')}
												</a>{' '}
												{t('FAQ.category3-answer23')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category3-question3')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category3-answer3')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category3-question4')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category3-answer4-1')}
												<a
													target="_blank"
													href="https://external-profile-management.basf.com/mydata"
													rel="noopener noreferrer"
												>
													{t('FAQ.category3-answer4-2')}
												</a>
												{t('FAQ.category3-answer4-3')}
												{/* <img
													alt="languages"
													src={ICONuser}
													className="faq-lst-item-icon"
												/> */}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category3-question5')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category3-answer5')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category3-question6')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category3-answer6')}
												<img
													alt="languages"
													src={ICONlanguages}
													className="faq-lst-item-icon"
												/>
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category3-question7')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category3-answer7')}
												<img
													alt="languages"
													src={ICONuser}
													className="faq-lst-item-icon"
												/>
											</p>
										</div>
									</li>
								</ul>
							</div>
						</li>

						<li
							className={
								props.expandedAll ? 'faq-lst-item expanded' : 'faq-lst-item'
							}
						>
							<div
								className="faq-lst-item-panel"
								onClick={(e) => toggleElement(e)}
							>
								<h4 className="faq-lst-item-headline">{t('FAQ.category4')}</h4>
								<button className="btnExpand">
									<img
										className="accordion-icon expand"
										alt="Expand"
										src={ICONdown}
									/>
									<img
										className="accordion-icon shrink"
										alt="Shrink"
										src={ICONup}
									/>
								</button>
							</div>
							<div className="faq-lst-item-content">
								<ul className="faq-lst">
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category4-question1')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category4-answer1-1')}
											</p>
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category4-answer1-2')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category4-question2')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category4-answer2')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category4-question3')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category4-answer3')}
												<img
													alt="languages"
													src={ICONdeputyman}
													className="faq-lst-item-icon big"
												/>
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category4-question4')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category4-answer4')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category4-question5')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category4-answer5')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category4-question6')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category4-answer6')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category4-question7')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category4-answer7')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category4-question8')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category4-answer8')}
											</p>
										</div>
									</li>
									{/* <li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category4-question9')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category4-answer9')}
											</p>
											<div className="answerimage2">
												<img src="https://ts1.cn.mm.bing.net/th/id/R-C.541d315de4b11e79e0bcb84f35ccf30b?rik=V96LdUGfUZJu5g&riu=http%3a%2f%2fimage.qianye88.com%2fpic%2f812f74e3a664e1c2b1e0e8cde0480e8c&ehk=o55Cw49jjhzmX%2bO%2fvYRXQsoMo3R%2f%2fuXTqpx%2bM88E63M%3d&risl=&pid=ImgRaw&r=0" />
											</div>
										</div>
									</li> */}
								</ul>
							</div>
						</li>

						<li
							className={
								props.expandedAll ? 'faq-lst-item expanded' : 'faq-lst-item'
							}
						>
							<div
								className="faq-lst-item-panel"
								onClick={(e) => toggleElement(e)}
							>
								<h4 className="faq-lst-item-headline">{t('FAQ.category5')}</h4>
								<button className="btnExpand">
									<img
										className="accordion-icon expand"
										alt="Expand"
										src={ICONdown}
									/>
									<img
										className="accordion-icon shrink"
										alt="Shrink"
										src={ICONup}
									/>
								</button>
							</div>
							<div className="faq-lst-item-content">
								<ul className="faq-lst">
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category5-question1')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category5-answer1')}
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category5-question2')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category5-answer2')}
												<img
													alt="languages"
													src={ICONdeputyman}
													className="faq-lst-item-icon big"
												/>
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category5-question3')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category5-answer3')}
												<img
													alt="languages"
													src={ICONdeputyman}
													className="faq-lst-item-icon big"
												/>
											</p>
										</div>
									</li>
									<li
										className={
											props.expandedAll
												? 'faq-lst-item expanded'
												: 'faq-lst-item'
										}
									>
										<div
											className="faq-lst-item-panel"
											onClick={(e) => toggleElement(e)}
										>
											<h4 className="faq-lst-item-headline">
												{t('FAQ.category5-question4')}
											</h4>
											<button className="btnExpand">
												<img
													className="accordion-icon expand"
													alt="Expand"
													src={ICONdown}
												/>
												<img
													className="accordion-icon shrink"
													alt="Shrink"
													src={ICONup}
												/>
											</button>
										</div>
										<div className="faq-lst-item-content">
											<p className="faq-lst-item-element faq-lst-item-text">
												{t('FAQ.category5-answer4')}
											</p>
											{/* <img src="https://picsum.photos/800/400" className="faq-lst-item-element faq-lst-item-image" /> */}
										</div>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default FAQ;
