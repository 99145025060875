import React, { Fragment, useImperativeHandle, memo } from 'react';
import { useReactive, useUpdateEffect, useMemoizedFn } from 'ahooks';
import { useTranslation } from 'react-i18next';

const ContentB = React.forwardRef(({ setFalse, fetchDetailData }, ref) => {
	const { t } = useTranslation();

	useImperativeHandle(ref, () => ({
		reasonForm: reasonForm.value,
		inputError: () => {
			document.getElementById('txaFeedback').classList.remove('isFilled');
			document.getElementById('txaFeedback').classList.add('gotError');
			// const listItem = document.getElementById('txaFeedback').parentNode;
			// const error = listItem.getElementsByTagName('span')[0];
			// error.classList.add('visible');
		},
		inputError1000: () => {
			document.getElementById('txaFeedback').classList.remove('isFilled');
			document.getElementById('txaFeedback').classList.add('gotError');
			const listItem = document.getElementById('txaFeedback').parentNode;
			const error = listItem.getElementsByTagName('span')[0];
			error.classList.add('visible');
		},
		clearForm: () => {
			reasonForm.value = '';
		},
	}));

	const reasonForm = useReactive({
		value: '',
	});

	useUpdateEffect(() => {
		if (JSON.stringify(fetchDetailData) !== '{}') {
			reasonForm.value = fetchDetailData['reasoning']
				? fetchDetailData['reasoning']
				: '';
			if (fetchDetailData['reasoning'] !== '') {
				document.querySelector('.reasoning').classList.add('isFilled');
			}
		}
	}, [fetchDetailData]); // eslint-disable-line

	const inputChangeHandler = useMemoizedFn((e) => {
		setFalse();
		e.target.classList.remove('gotError');
		e.target.parentNode
			.getElementsByTagName('span')[0]
			.classList.remove('visible');
		reasonForm.value = e.target.value;
		//
		if (e.target.value !== '') {
			e.target.classList.add('isFilled');
		} else {
			e.target.classList.remove('isFilled');
		}
	});

	return (
		<div ref={ref}>
			<Fragment>
				<h5>{t('Detail.Reasoning')}</h5>
				<ul className="lstFeedback">
					<li>
						<textarea
							id="txaFeedback"
							value={reasonForm.value}
							onChange={(e) => inputChangeHandler(e)}
							className="txaFeedback reasoning"
							placeholder={t('Detail.Explainyourobjection')}
						/>
						<span className="errInput">{t('Detail.Reason1000')}</span>
					</li>
				</ul>
			</Fragment>
		</div>
	);
});

export default memo(ContentB);
