import React, { useImperativeHandle, memo, useState, useRef } from 'react';
import { Carousel, Modal } from 'antd';
import '../../../node_modules/antd/lib/carousel/style/index.css';
import '../../../node_modules/antd/lib/modal/style/index.css';
import { LeftOutlined, RightOutlined, CloseOutlined } from '@ant-design/icons';
import { useMemoizedFn, useReactive } from 'ahooks';

const SliderPopup = React.forwardRef((props, ref) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [autoplay, setAutoplay] = useState(true);
	const SliderRef = useRef();

	useImperativeHandle(ref, () => ({
		showModel: (itattachments) => {
			data.images = itattachments;
			setIsModalVisible(true);
		},
	}));

	const data = useReactive({
		images: [],
	});

	const handleCancel = useMemoizedFn(() => {
		setIsModalVisible(false);
	});

	const carouselleft = () => {
		SliderRef.current.prev();
		setAutoplay(false);
	};

	const carouselright = () => {
		SliderRef.current.next();
		setAutoplay(false);
	};

	return (
		<Modal
			open={isModalVisible}
			className="carouselmodel"
			footer={null}
			closable={false}
			getContainer={false}
		>
			<div className="contentinto">
				<Carousel ref={SliderRef} autoplaySpeed={10000} autoplay={autoplay}>
					{data.images.map((item, index) => {
						return (
							<div className="slidewrap" key={index}>
								<img src={item.content} alt="" />
							</div>
						);
					})}
				</Carousel>
				<LeftOutlined
					onClick={() => carouselleft()}
					className="carouselleft"
					style={{ fontSize: '16px', color: '#08c' }}
				/>
				<RightOutlined
					onClick={() => carouselright()}
					className="carouselright"
					style={{ fontSize: '16px', color: '#08c' }}
				/>
			</div>
			<div className="closeicon" onClick={handleCancel}>
				<CloseOutlined />
			</div>
		</Modal>
	);
});

export default memo(SliderPopup);
