import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const ContentA2Closed = ({ etcorrectionclosed }) => {
	const { t } = useTranslation();

	return (
		<div>
			{etcorrectionclosed.map((item, index) => {
				return (
					<div
						className={
							etcorrectionclosed.length < 4 && etcorrectionclosed.length > 1
								? 'border'
								: ''
						}
						key={index}
					>
						{index === 0 ? (
							<h5>{t('Detail.Correctiveaction')}</h5>
						) : (
							<h5>
								{t('Detail.Additionalcorrectiveaction')} {index}
							</h5>
						)}
						<ul className="lstFeedback">
							<li>
								<input
									type="text"
									id="txb_description"
									className="txbFeedback-noborder"
									value={item.codegrptext || ''}
									readOnly
								/>
							</li>
							<li>
								<input
									type="text"
									id="txb_description"
									className="txbFeedback-noborder"
									value={item.codetext || ''}
									readOnly
								/>
							</li>
							<li>
								<textarea
									className="txaFeedback-noborder"
									value={item.matxt || ''}
									readOnly
								/>
							</li>
						</ul>
						<ul className="lstFeedback">
							<li>
								<span>{t('Detail.Responsibility')}</span>
								<input
									type="text"
									id="txb_description"
									className="txbFeedback-noborder"
									value={item.usrname || ''}
									readOnly
								/>
							</li>
							<li>
								<span>{t('Detail.Duedate')}</span>
								<input
									type="text"
									id="txb_description"
									className="txbFeedback-noborder"
									value={item.peter || ''}
									readOnly
								/>
							</li>
							<li>
								<span>{t('Detail.Verificationplan')}</span>
								<textarea
									className="txaFeedback-noborder"
									value={item.lgtext || ''}
									readOnly
								/>
							</li>
						</ul>
					</div>
				);
			})}
		</div>
	);
};

export default memo(ContentA2Closed);
