import React, { useState, useEffect, useRef, Fragment } from 'react';
import './index.scss';
import LeftDetail from './detail';
import IMAGlandingBig from '../../assets/images/landing_big.jpg';
import ICONdown from '../../assets/icons/arrow-down.svg';
import ICONup from '../../assets/icons/arrow-up.svg';
import { Input, Modal, Form, notification } from 'antd';
import ICONinfo from '../../assets/icons/info-i.svg';
import '../../../node_modules/antd/lib/input/style/index.css';
import '../../../node_modules/antd/lib/modal/style/index.css';
import '../../../node_modules/antd/lib/form/style/index.css';
import Tab1 from './components/Tab1';
import Tab2 from './components/Tab2';
import axios from '../../axios-custom';
import { getQueryVariable } from '../../utils/util';
// import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useMount, useReactive } from 'ahooks';
import { useTranslation } from 'react-i18next';
import Print from './components/Print';
import moment from 'moment';
import _ from 'lodash';
import store from '../../state/store';

const { useModel } = store;

function DReport({ bgHandler }) {
	const { t } = useTranslation();

	const [state, dispatchers] = useModel('global');
	const { toggleLoading } = dispatchers;

	const [isModalVisible, setIsModalVisible] = useState(true);
	const [expandedSupplier, setExpandedSupplier] = useState(false);

	// const location = useLocation();

	const tab1Ref = useRef();
	const tab2Ref = useRef();
	const printRef = useRef(null);

	const renderData = useReactive({
		tabShow: 1,
		topFormError: false,
		form1Error: false,
		form2Error: false,
		itattachments2: [],
	});

	const form1Data = useReactive({
		d8_communicate: '',
		d8_prepared: '',
		d8_datecompl: '',
	});

	const form2Data = useReactive({
		d4d5_rac: null,
		d4d5_rcaother: '',
		d4d5: [
			{
				d4_define: '',
				d5: [
					{
						d5_permanent: '',
						d5_responsible: '',
						d5_datedue: null,
						d5_datecompl: null,
					},
				],
			},
		],
		d6_verification: '',
		d6_responsible: '',
		d6_datedue: null,
		d6_datecompl: null,
		d7_prevention: '',
		d7_responsible: '',
		d7_datedue: null,
		d7_datecompl: null,
		d7_choice: '',
		d7_1: '',
		d7_2: '',
		d7_3: '',
		d7_4: '',
		d7_5: '',
		d7_6: '',
		d7_7: '',
		d7_8: '',
		d7_9: '',
		d7_10: '',
		d7_11: '',
		d8_communicate: '',
		d8_prepared: '',
		d8_datecompl: null,
	});

	const queryData = useReactive({
		id: '',
		report: 'YNCM_SUPPLIER_FEEDBACK',
	});

	const [formtop] = Form.useForm();
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();

	useEffect(() => {
		bgHandler(false, false, IMAGlandingBig, false);
	}, [bgHandler]);

	useMount(async () => {
		if (queryData.id) {
			let temp = await axios.get(
				`/feedbackServices/8dreport/getDraft/${queryData.id}`
			);
			console.log(temp);
			// D4D5 -- top
			form2Data.d4d5_rac = temp.data.defineRootCausePermanentCorrectiveActions
				.RCAMethodToolUsed
				? temp.data.defineRootCausePermanentCorrectiveActions.RCAMethodToolUsed
				: null;
			form2Data.d4d5_rcaother =
				temp.data.defineRootCausePermanentCorrectiveActions.RCAMethodToolUsedOther;
			// D4D5
			let d4d5 = [];
			let d4d5Temp =
				temp.data.defineRootCausePermanentCorrectiveActions
					.DefineRootCausePermanentCorrectiveActions;
			for (let i = 0; i < d4d5Temp.length; i++) {
				let d4d5obj = {};
				d4d5obj.d4_define = d4d5Temp[i].DefineRootCause;
				d4d5obj.d5 = [];
				for (
					let j = 0;
					j < d4d5Temp[i].PermanentCorrectiveActions.length;
					j++
				) {
					let d5obj = {};
					d5obj.d5_permanent =
						d4d5Temp[i].PermanentCorrectiveActions[j].PermanentCorrectiveAction;
					d5obj.d5_responsible =
						d4d5Temp[i].PermanentCorrectiveActions[j].Responsible;
					d5obj.d5_datedue = d4d5Temp[i].PermanentCorrectiveActions[j].DueDate
						? moment(
								d4d5Temp[i].PermanentCorrectiveActions[j].DueDate,
								'YYYY-MM-DD'
						  )
						: null;
					d5obj.d5_datecompl = d4d5Temp[i].PermanentCorrectiveActions[j]
						.CompleteDate
						? moment(
								d4d5Temp[i].PermanentCorrectiveActions[j].CompleteDate,
								'YYYY-MM-DD'
						  )
						: null;
					d4d5obj.d5.push(d5obj);
				}
				d4d5.push(d4d5obj);
			}
			form2Data.d4d5 = d4d5;
			// D6. Verification of Effectiveness
			form2Data.d6_verification =
				temp.data.verificationOfEffectiveness.VerificationOfEffectiveness;
			form2Data.d6_responsible =
				temp.data.verificationOfEffectiveness.Responsible;
			form2Data.d6_datedue = temp.data.verificationOfEffectiveness.DueDate
				? moment(temp.data.verificationOfEffectiveness.DueDate, 'YYYY-MM-DD')
				: null;
			form2Data.d6_datecompl = temp.data.verificationOfEffectiveness
				.CompleteDate
				? moment(
						temp.data.verificationOfEffectiveness.CompleteDate,
						'YYYY-MM-DD'
				  )
				: null;
			//
			renderData.itattachments2 =
				temp.data.verificationOfEffectiveness.itattachments;
			// D7. Prevention
			form2Data.d7_prevention = temp.data.prevention.Prevention;
			form2Data.d7_responsible = temp.data.prevention.Responsible;
			form2Data.d7_datedue = temp.data.prevention.DueDate
				? moment(temp.data.prevention.DueDate, 'YYYY-MM-DD')
				: null;
			form2Data.d7_datecompl = temp.data.prevention.CompleteDate
				? moment(temp.data.prevention.CompleteDate, 'YYYY-MM-DD')
				: null;
			// D7 choice
			if (temp.data.prevention.OtherAreasAffected === 'yes') {
				form2Data.d7_choice = 'yes';
				form2Data.d7_1 = temp.data.prevention.ProcessFMEA;
				form2Data.d7_2 = temp.data.prevention.Form;
				form2Data.d7_3 = temp.data.prevention.ProductFMEA;
				form2Data.d7_4 = temp.data.prevention.Specification;
				form2Data.d7_5 = temp.data.prevention.ControlPlan;
				form2Data.d7_6 = temp.data.prevention.PPAP;
				form2Data.d7_7 = temp.data.prevention.InspectionPlan;
				form2Data.d7_8 = temp.data.prevention.JobInstruction;
				form2Data.d7_9 = temp.data.prevention.Procedure;
				form2Data.d7_10 = temp.data.prevention.Other;
			} else if (temp.data.prevention.OtherAreasAffected === 'no') {
				form2Data.d7_choice = 'no';
				form2Data.d7_11 = temp.data.prevention.ReasonExplained;
			} else {
				form2Data.d7_choice = '';
			}
			// D8. Communicate Results and Recognize Team
			form2Data.d8_communicate =
				temp.data.communicateResultsRecognizeTeam.CommunicateResultsRecognizeTeam;
			form2Data.d8_prepared =
				temp.data.communicateResultsRecognizeTeam.PreparedBy;
			form2Data.d8_datecompl = temp.data.communicateResultsRecognizeTeam
				.CompleteDate
				? moment(
						temp.data.communicateResultsRecognizeTeam.CompleteDate,
						'YYYY-MM-DD'
				  )
				: null;
			const formDataTemp = _.cloneDeep(form2Data);
			form2.setFieldsValue(formDataTemp);
		}
		const formDataTemp = _.cloneDeep(form2Data);
		form2.setFieldsValue(formDataTemp);
		// date
		// form2.setFieldsValue({
		// 	d6_datedue: moment(
		// 		temp.verificationOfEffectiveness.DueDate,
		// 		'YYYY-MM-DD'
		// 	),
		// 	d6_datecompl: moment(
		// 		temp.verificationOfEffectiveness.CompleteDate,
		// 		'YYYY-MM-DD'
		// 	),
		// 	d8_datecompl: moment(
		// 		temp.communicateResultsRecognizeTeam.CompleteDate,
		// 		'YYYY-MM-DD'
		// 	),
		// });
	});

	const onSearch = (value) => {
		// goFed();
		if (value === '8dreport') {
			setIsModalVisible(false);
		}
	};

	const changeTab = (val) => {
		renderData.tabShow = val;
	};

	const draft = async () => {
		notification.destroy();
		//
		let flag = false;
		let attachmentTemp = tab2Ref.current.attachment();
		//
		for (let i = 0; i < attachmentTemp.length; i++) {
			if (attachmentTemp[i].errorMessage) {
				flag = true;
				return;
			}
		}
		//
		const values = form2.getFieldsValue();
		console.log('Success:', values);
		//
		let obj = {};
		//
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		// let email = '';
		// if (userBak && JSON.stringify(userBak) !== '{}') {
		// 	email = userBak.email;
		// } else {
		// 	email = user.email;
		// }
		// obj.email = email;
		obj.email = 'test@test.com';
		//
		obj.report = queryData.report;
		//
		if (queryData.id !== '') {
			obj.id = queryData.id;
		}
		//
		let itattachments = [];
		for (let i = 0; i < attachmentTemp.length; i++) {
			if (attachmentTemp[i].content !== '') {
				let obj = {};
				obj.content = attachmentTemp[i].content;
				obj.filename = attachmentTemp[i].filename;
				let temp = attachmentTemp[i].filename.split('.');
				let extension = temp[temp.length - 1].toLowerCase();
				obj.extension = `.${extension}`;
				obj.size = attachmentTemp[i].size + '';
				itattachments.push(obj);
			}
		}
		//
		obj.ncNumber = '80000088888';
		// D4D5
		obj.defineRootCausePermanentCorrectiveActions = {};
		// select
		if (values.d4d5_rac === 'other') {
			obj.defineRootCausePermanentCorrectiveActions.RCAMethodToolUsed = 'other';
			obj.defineRootCausePermanentCorrectiveActions.RCAMethodToolUsedOther =
				values.d4d5_rcaother || '';
		} else {
			obj.defineRootCausePermanentCorrectiveActions.RCAMethodToolUsed =
				values.d4d5_rac || '';
			obj.defineRootCausePermanentCorrectiveActions.RCAMethodToolUsedOther = '';
		}
		obj.defineRootCausePermanentCorrectiveActions.DefineRootCausePermanentCorrectiveActions =
			[];
		// Root Cause --- Permanent Correct Action
		// remove empty
		let d4d5temp = [];
		let d4d5temptemp = [];
		// more than 1
		if (values.d4d5.length > 1) {
			for (let i = 0; i < values.d4d5.length; i++) {
				if (values.d4d5[i].d4_define) {
					let d4obj = {};
					d4obj.d4_define = values.d4d5[i].d4_define || '';
					d4obj.d5 = [];
					for (let j = 0; j < values.d4d5[i].d5.length; j++) {
						let d5obj = {};
						d5obj.d5_permanent = values.d4d5[i].d5[j].d5_permanent || '';
						d5obj.d5_responsible = values.d4d5[i].d5[j].d5_responsible || '';
						d5obj.d5_datedue = values.d4d5[i].d5[j].d5_datedue || null;
						d5obj.d5_datecompl = values.d4d5[i].d5[j].d5_datecompl || null;
						d4obj.d5.push(d5obj);
					}
					d4d5temp.push(d4obj);
				} else {
					let d4obj = {};
					d4obj.d4_define = values.d4d5[i].d4_define || '';
					d4obj.d5 = [];
					for (let j = 0; j < values.d4d5[i].d5.length; j++) {
						if (
							!values.d4d5[i].d5[j].d5_datedue &&
							!values.d4d5[i].d5[j].d5_datecompl &&
							values.d4d5[i].d5[j].d5_permanent === '' &&
							values.d4d5[i].d5[j].d5_responsible === ''
						) {
							continue;
						} else {
							let d5obj = {};
							d5obj.d5_permanent = values.d4d5[i].d5[j].d5_permanent || '';
							d5obj.d5_responsible = values.d4d5[i].d5[j].d5_responsible || '';
							d5obj.d5_datedue = values.d4d5[i].d5[j].d5_datedue || null;
							d5obj.d5_datecompl = values.d4d5[i].d5[j].d5_datecompl || null;
							d4obj.d5.push(d5obj);
						}
					}
					d4d5temp.push(d4obj);
				}
			}
			for (let i = 0; i < d4d5temp.length; i++) {
				if (d4d5temp[i].d4_define === '' && !d4d5temp[i].d5.length) {
					//
				} else {
					d4d5temptemp.push(d4d5temp[i]);
				}
			}
		} else {
			// only one
			for (let i = 0; i < values.d4d5.length; i++) {
				let d4obj = {};
				d4obj.d4_define = values.d4d5[i].d4_define || '';
				d4obj.d5 = [];
				for (let j = 0; j < values.d4d5[i].d5.length; j++) {
					let d5obj = {};
					d5obj.d5_permanent = values.d4d5[i].d5[j].d5_permanent || '';
					d5obj.d5_responsible = values.d4d5[i].d5[j].d5_responsible || '';
					d5obj.d5_datedue = values.d4d5[i].d5[j].d5_datedue || null;
					d5obj.d5_datecompl = values.d4d5[i].d5[j].d5_datecompl || null;
					d4obj.d5.push(d5obj);
				}
				d4d5temptemp.push(d4obj);
			}
		}
		//
		for (let i = 0; i < d4d5temptemp.length; i++) {
			let d4d5obj = {};
			d4d5obj.DefineRootCause = d4d5temptemp[i].d4_define || '';
			d4d5obj.PermanentCorrectiveActions = [];
			for (let j = 0; j < d4d5temptemp[i].d5.length; j++) {
				let d5obj = {};
				d5obj.PermanentCorrectiveAction =
					d4d5temptemp[i].d5[j].d5_permanent || '';
				d5obj.Responsible = d4d5temptemp[i].d5[j].d5_responsible || '';
				d5obj.DueDate = d4d5temptemp[i].d5[j].d5_datedue
					? moment(d4d5temptemp[i].d5[j].d5_datedue).format('YYYY-MM-DD')
					: '';
				d5obj.CompleteDate = d4d5temptemp[i].d5[j].d5_datecompl
					? moment(d4d5temptemp[i].d5[j].d5_datecompl).format('YYYY-MM-DD')
					: '';
				d4d5obj.PermanentCorrectiveActions.push(d5obj);
			}
			obj.defineRootCausePermanentCorrectiveActions.DefineRootCausePermanentCorrectiveActions.push(
				d4d5obj
			);
		}
		// D6. Verification of Effectiveness
		obj.verificationOfEffectiveness = {
			VerificationOfEffectiveness: values.d6_verification || '',
			Responsible: values.d6_responsible || '',
			DueDate: values.d6_datedue
				? moment(values.d6_datedue).format('YYYY-MM-DD')
				: '',
			CompleteDate: values.d6_datecompl
				? moment(values.d6_datecompl).format('YYYY-MM-DD')
				: '',
			itattachments: itattachments,
		};
		// D7. Prevention
		obj.prevention = {
			Prevention: values.d7_prevention || '',
			Responsible: values.d7_responsible || '',
			DueDate: values.d7_datedue
				? moment(values.d7_datedue).format('YYYY-MM-DD')
				: '',
			CompleteDate: values.d7_datecompl
				? moment(values.d7_datecompl).format('YYYY-MM-DD')
				: '',
		};
		// D7. Prevention - Other Areas Affected
		if (values.d7_choice === 'yes') {
			obj.prevention.OtherAreasAffected = 'yes';
			obj.prevention.ProcessFMEA = values.d7_1 || '';
			obj.prevention.Form = values.d7_2 || '';
			obj.prevention.ProductFMEA = values.d7_3 || '';
			obj.prevention.Specification = values.d7_4 || '';
			obj.prevention.ControlPlan = values.d7_5 || '';
			obj.prevention.PPAP = values.d7_6 || '';
			obj.prevention.InspectionPlan = values.d7_7 || '';
			obj.prevention.JobInstruction = values.d7_8 || '';
			obj.prevention.Procedure = values.d7_9 || '';
			obj.prevention.Other = values.d7_10 || '';
		} else if (values.d7_choice === 'no') {
			obj.prevention.OtherAreasAffected = 'no';
			obj.prevention.ReasonExplained = values.d7_11 || '';
			obj.prevention.ProcessFMEA = '';
			obj.prevention.Form = '';
			obj.prevention.ProductFMEA = '';
			obj.prevention.Specification = '';
			obj.prevention.ControlPlan = '';
			obj.prevention.PPAP = '';
			obj.prevention.InspectionPlan = '';
			obj.prevention.JobInstruction = '';
			obj.prevention.Procedure = '';
			obj.prevention.Other = '';
		} else {
			obj.prevention.OtherAreasAffected = '';
			obj.prevention.ReasonExplained = '';
			obj.prevention.ProcessFMEA = '';
			obj.prevention.Form = '';
			obj.prevention.ProductFMEA = '';
			obj.prevention.Specification = '';
			obj.prevention.ControlPlan = '';
			obj.prevention.PPAP = '';
			obj.prevention.InspectionPlan = '';
			obj.prevention.JobInstruction = '';
			obj.prevention.Procedure = '';
			obj.prevention.Other = '';
		}
		// D8. Communicate Results and Recognize Team
		obj.communicateResultsRecognizeTeam = {
			CommunicateResultsRecognizeTeam: values.d8_communicate || '',
			PreparedBy: values.d8_prepared || '',
			CompleteDate: values.d8_datecompl
				? moment(values.d8_datecompl).format('YYYY-MM-DD')
				: '',
		};
		//
		if (flag) {
			return;
		}
		console.log(obj);
		toggleLoading();
		const response = await axios.post(
			'/feedbackServices/8dreport/saveDraft',
			obj
		);
		toggleLoading();
		console.log(response);
	};

	const submit = async () => {
		if (renderData.tabShow === 1) {
			try {
				const result = await Promise.all([
					formtop.validateFields(),
					form1.validateFields(),
				]);
				console.log(result);
				console.log('验证成功');
			} catch (errorInfo) {
				console.log(errorInfo);
				scrollTop();
				renderData.topFormError = true;
				//
				for (let i = 0; i < errorInfo.errorFields.length; i++) {
					if (errorInfo.errorFields[i].name[0].includes('d1_')) {
						tab1Ref.current.D1error();
					}
					// if (errorInfo.errorFields[i].name[0].includes('d2_')) {
					// 	tab1Ref.current.D2error();
					// }
					if (errorInfo.errorFields[i].name[0].includes('d3_')) {
						tab1Ref.current.D3error();
					}
				}
				//
				setTimeout(() => {
					renderData.topFormError = false;
				}, 5000);
			}
		} else {
			try {
				const result = await Promise.all([
					formtop.validateFields(),
					form2.validateFields(),
				]);
				console.log(result);
				console.log('验证成功');
			} catch (errorInfo) {
				console.log(errorInfo);
				scrollTop();
				renderData.topFormError = true;
				//
				for (let i = 0; i < errorInfo.errorFields.length; i++) {
					if (errorInfo.errorFields[i].name[0].includes('d45_')) {
						tab2Ref.current.D45error();
					}
					if (errorInfo.errorFields[i].name[0].includes('d6_')) {
						tab2Ref.current.D6error();
					}
					if (errorInfo.errorFields[i].name[0].includes('d7_')) {
						tab2Ref.current.D7error();
					}
					if (errorInfo.errorFields[i].name[0].includes('d8_')) {
						tab2Ref.current.D8error();
					}
				}
				//
				setTimeout(() => {
					renderData.topFormError = false;
				}, 5000);
			}
		}

		return;
		try {
			const values = await formtop.validateFields();
			console.log('Success1:', values);
			await aaa();
		} catch (err) {
			console.log('Failed:', err);
			renderData.topFormError = true;
			scrollTop();
			// setTimeout(() => {
			// 	document.querySelector('.ant-form-item-has-error').scrollIntoView({
			// 		block: 'start',
			// 		inline: 'nearest',
			// 		behavior: 'smooth',
			// 	});
			// }, 100);
			setTimeout(() => {
				renderData.topFormError = false;
			}, 5000);
		}

		return;

		try {
			const values = await formtop.validateFields();
			console.log('Success1:', values);
			throw 'myException';
		} catch (errorInfo) {
			console.log('Failed:', errorInfo);
			renderData.topFormError = true;
			scrollTop();
			// setTimeout(() => {
			// 	document.querySelector('.ant-form-item-has-error').scrollIntoView({
			// 		block: 'start',
			// 		inline: 'nearest',
			// 		behavior: 'smooth',
			// 	});
			// }, 100);
			setTimeout(() => {
				renderData.topFormError = false;
			}, 5000);
			throw new Error(errorInfo);
		}
		try {
			const values = await form1.validateFields();
			console.log('Success2:', values);
		} catch (errorInfo) {
			console.log('Failed:', errorInfo);
			console.log('Failed:', errorInfo.errorFields);
			renderData.form1Error = true;
			scrollTop();
			setTimeout(() => {
				renderData.form1Error = false;
			}, 5000);
			for (let i = 0; i < errorInfo.errorFields.length; i++) {
				if (errorInfo.errorFields[i].name[0].includes('d2_')) {
					tab1Ref.current.D2error();
				}
				if (errorInfo.errorFields[i].name[0].includes('d1_')) {
					tab1Ref.current.D1error();
				}
				if (errorInfo.errorFields[i].name[0].includes('d3_')) {
					tab1Ref.current.D3error();
				}
			}
		}
	};

	const aaa = async () => {
		try {
			const values = await form1.validateFields();
			console.log('Success2:', values);
		} catch (err) {
			console.error('inner', err);
		}
	};

	const scrollTop = (number = 0, time) => {
		if (!time) {
			document.body.scrollTop = document.documentElement.scrollTop = number;
			return number;
		}
		const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
		let spacingInex = time / spacingTime; // 计算循环的次数
		let nowTop = document.body.scrollTop + document.documentElement.scrollTop; // 获取当前滚动条位置
		const everTop = (number - nowTop) / spacingInex; // 计算每次滑动的距离
		const scrollTimer = setInterval(() => {
			if (spacingInex > 0) {
				spacingInex--;
				scrollTop((nowTop += everTop));
			} else {
				clearInterval(scrollTimer); // 清除计时器
			}
		}, spacingTime);
	};

	const print = () => {
		printRef.current.print();
	};

	return (
		<Fragment>
			<Print ref={printRef} />
			<section className="dreport print_hide">
				<Modal
					style={{
						maxWidth: '100vw',
						top: 0,
						paddingBottom: 0,
					}}
					className="passmodal"
					bodyStyle={{
						height: 'calc(100vh - 48px)',
					}}
					width="100vw"
					open={isModalVisible}
					footer={null}
					closable={false}
				>
					<div
						className="passwrap"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
						}}
					>
						<div
							className="passwordwrap"
							style={{ width: '400px', marginTop: '20px' }}
						>
							<Input.Search
								placeholder="input password"
								allowClear
								enterButton="Submit"
								size="large"
								type="password"
								onSearch={onSearch}
							/>
						</div>
					</div>
				</Modal>
				<div className="supplier">
					<div className="boxHeadSupplier">
						<span>{t('8D.Date')}</span>
						<h3>09-08-2021</h3>
						<button
							className={
								expandedSupplier
									? 'btnToggleSupplier expanded'
									: 'btnToggleSupplier'
							}
							onClick={() => setExpandedSupplier(!expandedSupplier)}
						>
							<img className="expand" alt="Expand" src={ICONdown} />
							<img className="shrink" alt="Shrink" src={ICONup} />
							<span className="lessInfo">show less details</span>
							<span className="moreInfo">show more details</span>
						</button>
					</div>

					<LeftDetail expandedSupplier={expandedSupplier}></LeftDetail>
				</div>
				<div className="dfeedback">
					<span className="status open">
						<span>{t('Detail.Open')}</span>
					</span>
					<Form.Provider>
						<div>
							{renderData.topFormError ? (
								<div className="toperrorMessage">
									<span className="img">
										<img className="ICONinfo" alt="Error" src={ICONinfo} />
									</span>
									<span className="title">{t('Support.Pleasefill')}</span>
								</div>
							) : (
								<></>
							)}
							<div className="formtopwrap">
								<Form
									size="large"
									className="reportform"
									name="formtop"
									autoComplete="off"
									labelCol={{ span: 24 }}
									wrapperCol={{ span: 24 }}
									form={formtop}
								>
									<span className="antd_form_title">
										{t('8D.SupplierMaterialNo')}
									</span>
									<Form.Item label="" name="SupplierMaterialNo">
										<Input
											className="antd_input"
											placeholder={t('8D.SupplierMaterialNo')}
										/>
									</Form.Item>
									<span className="antd_form_title">
										{t('8D.SupplierMaterialName')}
									</span>
									<Form.Item label="" name="Supplier Material Name">
										<Input
											className="antd_input"
											placeholder={t('8D.SupplierMaterialName')}
										/>
									</Form.Item>
								</Form>
							</div>
							<div className="tabwrap">
								<span
									className={
										renderData.tabShow === 1 ? 'tabitem current' : 'tabitem'
									}
									onClick={() => changeTab(1)}
								>
									{t('8D.Acknowledgement')}
								</span>
								<span
									className={
										renderData.tabShow === 2 ? 'tabitem current' : 'tabitem'
									}
									onClick={() => changeTab(2)}
								>
									{t('8D.Investigation')}
								</span>
							</div>
							{renderData.form1Error ? (
								<div className="toperrorMessage">
									<span className="img">
										<img className="ICONinfo" alt="Error" src={ICONinfo} />
									</span>
									<span className="title">{t('Support.Pleasefill')}</span>
								</div>
							) : (
								<></>
							)}
							<Form
								size="large"
								name="form1"
								className={
									renderData.tabShow === 1
										? 'reportform formshow'
										: 'reportform formhide'
								}
								autoComplete="off"
								form={form1}
							>
								<Tab1 form={form1} ref={tab1Ref} />
							</Form>
							{renderData.form2Error ? (
								<div className="toperrorMessage">
									<span className="img">
										<img className="ICONinfo" alt="Error" src={ICONinfo} />
									</span>
									<span className="title">{t('Support.Pleasefill')}</span>
								</div>
							) : (
								<></>
							)}
							<Form
								size="large"
								name="form2"
								className={
									renderData.tabShow === 1
										? 'reportform formhide'
										: 'reportform formshow'
								}
								autoComplete="off"
								form={form2}
							>
								<Tab2
									form={form2}
									ref={tab2Ref}
									itattachments={renderData.itattachments2}
								/>
							</Form>
						</div>
					</Form.Provider>
					<div className="boxActions">
						<button className="btnCancel">{t('Detail.Cancel')}</button>
						<button className="btnPrint" onClick={print}>
							{t('Detail.Print')}
						</button>
						{renderData.tabShow === 1 ? (
							<Fragment />
						) : (
							<button className="btnSave" onClick={() => draft()}>
								{t('Detail.Saveasdraft')}
							</button>
						)}
						<button className="btnSend" onClick={() => submit()}>
							{t('Detail.Save')}
						</button>
					</div>
				</div>
			</section>
		</Fragment>
	);
}

export default DReport;
