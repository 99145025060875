import React, { useImperativeHandle, memo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import ICONdeputize from '../../../assets/icons/deputize.svg';
import UserLanguage from './UserLanguage';
import store from '../../../state/store';
const { useModel } = store;

let changeyourpassword = '';
let deactivateaccount = '';
if (window.location.origin.includes('localhost')) {
	changeyourpassword =
		'https://external-profile-management.basf.com/mydata/indexui5.html#SubSct3';
	deactivateaccount = 'https://external-profile-management.basf.com/mydata';
} else if (window.location.origin.includes('dev')) {
	changeyourpassword =
		'https://external-profile-management.basf.com/mydata/indexui5.html#SubSct3';
	deactivateaccount = 'https://external-profile-management.basf.com/mydata';
} else if (window.location.origin.includes('qual')) {
	changeyourpassword =
		'https://qual-external-profile-management.basf.com/mydata/indexui5.html#SubSct3';
	deactivateaccount =
		'https://qual-external-profile-management.basf.com/mydata';
} else {
	changeyourpassword =
		'https://external-profile-management.basf.com/mydata/indexui5.html#SubSct3';
	deactivateaccount = 'https://external-profile-management.basf.com/mydata';
}

const FlyoutProfile = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const [state] = useModel('global');
	const { showDeputy } = state;

	useImperativeHandle(ref, () => ({
		//
	}));

	return (
		<>
			<div className="profile">
				<div className="boxProfile">
					<h3>{t('Deputy.Yourprofile')}</h3>

					<div className="boxInformation">
						<span>
							{props.user.firstName} {props.user.lastName}
						</span>
						<p>{props.user.email}</p>
					</div>

					<h3>{t('Deputy.Accountsettings')}</h3>

					<ul className="lstActions">
						<li>
							<a
								target="_blank"
								href={changeyourpassword}
								rel="noopener noreferrer"
							>
								{t('Deputy.Changeyourpassword')}
							</a>
						</li>
						<li>
							<a
								target="_blank"
								href={deactivateaccount}
								rel="noopener noreferrer"
							>
								{t('Deputy.Deactivateaccount')}
							</a>
						</li>
					</ul>

					<UserLanguage
						activeClass={props.activeClass}
						changelang={props.changelang}
					/>
				</div>

				{showDeputy === false ? (
					<Fragment />
				) : (
					<div className="boxDeputy">
						<img alt="deputy management" src={ICONdeputize} />
						<span>{t('Deputy.Deputies')}</span>
						<p>{t('Deputy.deputiesinfo')}</p>
						<button
							className="btnOpenDeputy"
							onClick={() => props.changeFlyoutContent('userdeputy')}
						>
							{t('Header.DeputyManagement')}
						</button>
					</div>
				)}
			</div>
		</>
	);
});

export default memo(FlyoutProfile);
