import React, { useImperativeHandle, memo } from 'react';
import { useTranslation } from 'react-i18next';

const FlyoutLanguage = React.forwardRef((props, ref) => {
	const { t } = useTranslation();

	useImperativeHandle(ref, () => ({
		//
	}));

	return (
		<div className="languages">
			<h3>{t('Header.SelectLanguage')}</h3>
			<div className="boxLangs">
				<span
					className={props.activeClass === 'en' ? 'active' : ''}
					onClick={() => props.changelang('en')}
				>
					English
				</span>
				<span
					className={props.activeClass === 'de' ? 'active' : ''}
					onClick={() => props.changelang('de')}
				>
					Deutsch
				</span>
				<span
					className={props.activeClass === 'fr' ? 'active' : ''}
					onClick={() => props.changelang('fr')}
				>
					Français
				</span>
				<span
					className={props.activeClass === 'es' ? 'active' : ''}
					onClick={() => props.changelang('es')}
				>
					Español
				</span>
				<span
					className={props.activeClass === 'pt' ? 'active' : ''}
					onClick={() => props.changelang('pt')}
				>
					Português
				</span>
			</div>
		</div>
	);
});

export default memo(FlyoutLanguage);
