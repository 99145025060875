export const countryData = [
	{
		countryName: 'Andorra',
		phoneCode: '376',
		countryCode: 'AD',
	},
	{
		countryName: 'United Arab Emirates',
		phoneCode: '971',
		countryCode: 'AE',
	},
	{
		countryName: 'Afghanistan',
		phoneCode: '93',
		countryCode: 'AF',
	},
	{
		countryName: 'Antigua & Barbuda',
		phoneCode: '1',
		countryCode: 'AG',
	},
	{
		countryName: 'Anguilla',
		phoneCode: '1',
		countryCode: 'AI',
	},
	{
		countryName: 'Albania',
		phoneCode: '355',
		countryCode: 'AL',
	},
	{
		countryName: 'Armenia',
		phoneCode: '374',
		countryCode: 'AM',
	},
	{
		countryName: 'Angola',
		phoneCode: '244',
		countryCode: 'AO',
	},
	{
		countryName: 'Antarctica',
		phoneCode: '672',
		countryCode: 'AQ',
	},
	{
		countryName: 'Argentina',
		phoneCode: '54',
		countryCode: 'AR',
	},
	{
		countryName: 'American Samoa',
		phoneCode: '1',
		countryCode: 'AS',
	},
	{
		countryName: 'Austria',
		phoneCode: '43',
		countryCode: 'AT',
	},
	{
		countryName: 'Australia',
		phoneCode: '61',
		countryCode: 'AU',
	},
	{
		countryName: 'Aruba',
		phoneCode: '297',
		countryCode: 'AW',
	},
	{
		countryName: 'Azerbaijan',
		phoneCode: '994',
		countryCode: 'AZ',
	},
	{
		countryName: 'Bosnia & Herzegovina',
		phoneCode: '387',
		countryCode: 'BA',
	},
	{
		countryName: 'Barbados',
		phoneCode: '1',
		countryCode: 'BB',
	},
	{
		countryName: 'Bangladesh',
		phoneCode: '880',
		countryCode: 'BD',
	},
	{
		countryName: 'Belgium',
		phoneCode: '32',
		countryCode: 'BE',
	},
	{
		countryName: 'Burkina Faso',
		phoneCode: '226',
		countryCode: 'BF',
	},
	{
		countryName: 'Bulgaria',
		phoneCode: '359',
		countryCode: 'BG',
	},
	{
		countryName: 'Bahrain',
		phoneCode: '973',
		countryCode: 'BH',
	},
	{
		countryName: 'Burundi',
		phoneCode: '257',
		countryCode: 'BI',
	},
	{
		countryName: 'Benin',
		phoneCode: '229',
		countryCode: 'BJ',
	},
	{
		countryName: 'St. Barthélemy',
		phoneCode: '590',
		countryCode: 'BL',
	},
	{
		countryName: 'Bermuda',
		phoneCode: '1',
		countryCode: 'BM',
	},
	{
		countryName: 'Brunei',
		phoneCode: '673',
		countryCode: 'BN',
	},
	{
		countryName: 'Bolivia',
		phoneCode: '591',
		countryCode: 'BO',
	},
	{
		countryName: 'Caribbean Netherlands',
		phoneCode: '599',
		countryCode: 'BQ',
	},
	{
		countryName: 'Brazil',
		phoneCode: '55',
		countryCode: 'BR',
	},
	{
		countryName: 'Bahamas',
		phoneCode: '1',
		countryCode: 'BS',
	},
	{
		countryName: 'Bhutan',
		phoneCode: '975',
		countryCode: 'BT',
	},
	{
		countryName: 'Botswana',
		phoneCode: '267',
		countryCode: 'BW',
	},
	{
		countryName: 'Belarus',
		phoneCode: '375',
		countryCode: 'BY',
	},
	{
		countryName: 'Belize',
		phoneCode: '501',
		countryCode: 'BZ',
	},
	{
		countryName: 'Canada',
		phoneCode: '1',
		countryCode: 'CA',
	},
	{
		countryName: 'Cocos (Keeling) Islands',
		phoneCode: '61',
		countryCode: 'CC',
	},
	{
		countryName: 'Congo - Kinshasa',
		phoneCode: '243',
		countryCode: 'CD',
	},
	{
		countryName: 'Central African Republic',
		phoneCode: '236',
		countryCode: 'CF',
	},
	{
		countryName: 'Congo - Brazzaville',
		phoneCode: '242',
		countryCode: 'CG',
	},
	{
		countryName: 'Switzerland',
		phoneCode: '41',
		countryCode: 'CH',
	},
	{
		countryName: 'Côte d’Ivoire',
		phoneCode: '225',
		countryCode: 'CI',
	},
	{
		countryName: 'Cook Islands',
		phoneCode: '682',
		countryCode: 'CK',
	},
	{
		countryName: 'Chile',
		phoneCode: '56',
		countryCode: 'CL',
	},
	{
		countryName: 'Cameroon',
		phoneCode: '237',
		countryCode: 'CM',
	},
	{
		countryName: 'China',
		phoneCode: '86',
		countryCode: 'CN',
	},
	{
		countryName: 'Colombia',
		phoneCode: '57',
		countryCode: 'CO',
	},
	{
		countryName: 'Costa Rica',
		phoneCode: '506',
		countryCode: 'CR',
	},
	{
		countryName: 'Cuba',
		phoneCode: '53',
		countryCode: 'CU',
	},
	{
		countryName: 'Cape Verde',
		phoneCode: '238',
		countryCode: 'CV',
	},
	{
		countryName: 'Curaçao',
		phoneCode: '5999',
		countryCode: 'CW',
	},
	{
		countryName: 'Christmas Island',
		phoneCode: '61',
		countryCode: 'CX',
	},
	{
		countryName: 'Cyprus',
		phoneCode: '537',
		countryCode: 'CY',
	},
	{
		countryName: 'Czech Republic',
		phoneCode: '420',
		countryCode: 'CZ',
	},
	{
		countryName: 'Germany',
		phoneCode: '49',
		countryCode: 'DE',
	},
	{
		countryName: 'Djibouti',
		phoneCode: '253',
		countryCode: 'DJ',
	},
	{
		countryName: 'Denmark',
		phoneCode: '45',
		countryCode: 'DK',
	},
	{
		countryName: 'Dominica',
		phoneCode: '1',
		countryCode: 'DM',
	},
	{
		countryName: 'Dominican Republic',
		phoneCode: '1',
		countryCode: 'DO',
	},
	{
		countryName: 'Algeria',
		phoneCode: '213',
		countryCode: 'DZ',
	},
	{
		countryName: 'Ecuador',
		phoneCode: '593',
		countryCode: 'EC',
	},
	{
		countryName: 'Estonia',
		phoneCode: '372',
		countryCode: 'EE',
	},
	{
		countryName: 'Egypt',
		phoneCode: '20',
		countryCode: 'EG',
	},
	{
		countryName: 'Western Sahara',
		phoneCode: '210',
		countryCode: 'EH',
	},
	{
		countryName: 'Eritrea',
		phoneCode: '291',
		countryCode: 'ER',
	},
	{
		countryName: 'Spain',
		phoneCode: '34',
		countryCode: 'ES',
	},
	{
		countryName: 'Ethiopia',
		phoneCode: '251',
		countryCode: 'ET',
	},
	{
		countryName: 'Finland',
		phoneCode: '358',
		countryCode: 'FI',
	},
	{
		countryName: 'Fiji',
		phoneCode: '679',
		countryCode: 'FJ',
	},
	{
		countryName: 'Falkland Islands',
		phoneCode: '500',
		countryCode: 'FK',
	},
	{
		countryName: 'Micronesia',
		phoneCode: '691',
		countryCode: 'FM',
	},
	{
		countryName: 'Faroe Islands',
		phoneCode: '298',
		countryCode: 'FO',
	},
	{
		countryName: 'France',
		phoneCode: '33',
		countryCode: 'FR',
	},
	{
		countryName: 'Gabon',
		phoneCode: '241',
		countryCode: 'GA',
	},
	{
		countryName: 'United Kingdom',
		phoneCode: '44',
		countryCode: 'GB',
	},
	{
		countryName: 'Grenada',
		phoneCode: '1',
		countryCode: 'GD',
	},
	{
		countryName: 'Georgia',
		phoneCode: '995',
		countryCode: 'GE',
	},
	{
		countryName: 'French Guiana',
		phoneCode: '594',
		countryCode: 'GF',
	},
	{
		countryName: 'Guernsey',
		phoneCode: '44',
		countryCode: 'GG',
	},
	{
		countryName: 'Ghana',
		phoneCode: '233',
		countryCode: 'GH',
	},
	{
		countryName: 'Gibraltar',
		phoneCode: '350',
		countryCode: 'GI',
	},
	{
		countryName: 'Greenland',
		phoneCode: '299',
		countryCode: 'GL',
	},
	{
		countryName: 'Gambia',
		phoneCode: '220',
		countryCode: 'GM',
	},
	{
		countryName: 'Guinea',
		phoneCode: '224',
		countryCode: 'GN',
	},
	{
		countryName: 'Guadeloupe',
		phoneCode: '590',
		countryCode: 'GP',
	},
	{
		countryName: 'Equatorial Guinea',
		phoneCode: '240',
		countryCode: 'GQ',
	},
	{
		countryName: 'Greece',
		phoneCode: '30',
		countryCode: 'GR',
	},
	{
		countryName: 'So. Georgia & So. Sandwich Isl.',
		phoneCode: '500',
		countryCode: 'GS',
	},
	{
		countryName: 'Guatemala',
		phoneCode: '502',
		countryCode: 'GT',
	},
	{
		countryName: 'Guam',
		phoneCode: '1',
		countryCode: 'GU',
	},
	{
		countryName: 'Guinea-Bissau',
		phoneCode: '245',
		countryCode: 'GW',
	},
	{
		countryName: 'Guyana',
		phoneCode: '595',
		countryCode: 'GY',
	},
	{
		countryName: 'Hong Kong SAR China',
		phoneCode: '852',
		countryCode: 'HK',
	},
	{
		countryName: 'Honduras',
		phoneCode: '504',
		countryCode: 'HN',
	},
	{
		countryName: 'Croatia',
		phoneCode: '385',
		countryCode: 'HR',
	},
	{
		countryName: 'Haiti',
		phoneCode: '509',
		countryCode: 'HT',
	},
	{
		countryName: 'Hungary',
		phoneCode: '36',
		countryCode: 'HU',
	},
	{
		countryName: 'Indonesia',
		phoneCode: '62',
		countryCode: 'ID',
	},
	{
		countryName: 'Ireland',
		phoneCode: '353',
		countryCode: 'IE',
	},
	{
		countryName: 'Israel',
		phoneCode: '972',
		countryCode: 'IL',
	},
	{
		countryName: 'Isle of Man',
		phoneCode: '44',
		countryCode: 'IM',
	},
	{
		countryName: 'India',
		phoneCode: '91',
		countryCode: 'IN',
	},
	{
		countryName: 'British Indian Ocean Territory',
		phoneCode: '246',
		countryCode: 'IO',
	},
	{
		countryName: 'Iraq',
		phoneCode: '964',
		countryCode: 'IQ',
	},
	{
		countryName: 'Iran',
		phoneCode: '98',
		countryCode: 'IR',
	},
	{
		countryName: 'Iceland',
		phoneCode: '354',
		countryCode: 'IS',
	},
	{
		countryName: 'Italy',
		phoneCode: '39',
		countryCode: 'IT',
	},
	{
		countryName: 'Jersey',
		phoneCode: '44',
		countryCode: 'JE',
	},
	{
		countryName: 'Jamaica',
		phoneCode: '1',
		countryCode: 'JM',
	},
	{
		countryName: 'Jordan',
		phoneCode: '962',
		countryCode: 'JO',
	},
	{
		countryName: 'Japan',
		phoneCode: '81',
		countryCode: 'JP',
	},
	{
		countryName: 'Kenya',
		phoneCode: '254',
		countryCode: 'KE',
	},
	{
		countryName: 'Kyrgyzstan',
		phoneCode: '996',
		countryCode: 'KG',
	},
	{
		countryName: 'Cambodia',
		phoneCode: '855',
		countryCode: 'KH',
	},
	{
		countryName: 'Kiribati',
		phoneCode: '686',
		countryCode: 'KI',
	},
	{
		countryName: 'Comoros',
		phoneCode: '269',
		countryCode: 'KM',
	},
	{
		countryName: 'St. Kitts & Nevis',
		phoneCode: '1',
		countryCode: 'KN',
	},
	{
		countryName: 'North Korea',
		phoneCode: '850',
		countryCode: 'KP',
	},
	{
		countryName: 'South Korea',
		phoneCode: '82',
		countryCode: 'KR',
	},
	{
		countryName: 'Kuwait',
		phoneCode: '965',
		countryCode: 'KW',
	},
	{
		countryName: 'Cayman Islands',
		phoneCode: '345',
		countryCode: 'KY',
	},
	{
		countryName: 'Kazakhstan',
		phoneCode: '77',
		countryCode: 'KZ',
	},
	{
		countryName: 'Laos',
		phoneCode: '856',
		countryCode: 'LA',
	},
	{
		countryName: 'Lebanon',
		phoneCode: '961',
		countryCode: 'LB',
	},
	{
		countryName: 'St. Lucia',
		phoneCode: '1',
		countryCode: 'LC',
	},
	{
		countryName: 'Liechtenstein',
		phoneCode: '423',
		countryCode: 'LI',
	},
	{
		countryName: 'Sri Lanka',
		phoneCode: '94',
		countryCode: 'LK',
	},
	{
		countryName: 'Liberia',
		phoneCode: '231',
		countryCode: 'LR',
	},
	{
		countryName: 'Lesotho',
		phoneCode: '266',
		countryCode: 'LS',
	},
	{
		countryName: 'Lithuania',
		phoneCode: '370',
		countryCode: 'LT',
	},
	{
		countryName: 'Luxembourg',
		phoneCode: '352',
		countryCode: 'LU',
	},
	{
		countryName: 'Latvia',
		phoneCode: '371',
		countryCode: 'LV',
	},
	{
		countryName: 'Libya',
		phoneCode: '218',
		countryCode: 'LY',
	},
	{
		countryName: 'Morocco',
		phoneCode: '212',
		countryCode: 'MA',
	},
	{
		countryName: 'Monaco',
		phoneCode: '377',
		countryCode: 'MC',
	},
	{
		countryName: 'Moldova',
		phoneCode: '373',
		countryCode: 'MD',
	},
	{
		countryName: 'Montenegro',
		phoneCode: '382',
		countryCode: 'ME',
	},
	{
		countryName: 'St. Martin',
		phoneCode: '590',
		countryCode: 'MF',
	},
	{
		countryName: 'Madagascar',
		phoneCode: '261',
		countryCode: 'MG',
	},
	{
		countryName: 'Marshall Islands',
		phoneCode: '692',
		countryCode: 'MH',
	},
	{
		countryName: 'Macedonia',
		phoneCode: '389',
		countryCode: 'MK',
	},
	{
		countryName: 'Mali',
		phoneCode: '223',
		countryCode: 'ML',
	},
	{
		countryName: 'Myanmar (Burma)',
		phoneCode: '95',
		countryCode: 'MM',
	},
	{
		countryName: 'Mongolia',
		phoneCode: '976',
		countryCode: 'MN',
	},
	{
		countryName: 'Macau SAR China',
		phoneCode: '853',
		countryCode: 'MO',
	},
	{
		countryName: 'Northern Mariana Islands',
		phoneCode: '1',
		countryCode: 'MP',
	},
	{
		countryName: 'Martinique',
		phoneCode: '596',
		countryCode: 'MQ',
	},
	{
		countryName: 'Mauritania',
		phoneCode: '222',
		countryCode: 'MR',
	},
	{
		countryName: 'Montserrat',
		phoneCode: '1',
		countryCode: 'MS',
	},
	{
		countryName: 'Malta',
		phoneCode: '356',
		countryCode: 'MT',
	},
	{
		countryName: 'Mauritius',
		phoneCode: '230',
		countryCode: 'MU',
	},
	{
		countryName: 'Maldives',
		phoneCode: '960',
		countryCode: 'MV',
	},
	{
		countryName: 'Malawi',
		phoneCode: '265',
		countryCode: 'MW',
	},
	{
		countryName: 'Mexico',
		phoneCode: '52',
		countryCode: 'MX',
	},
	{
		countryName: 'Malaysia',
		phoneCode: '60',
		countryCode: 'MY',
	},
	{
		countryName: 'Mozambique',
		phoneCode: '258',
		countryCode: 'MZ',
	},
	{
		countryName: 'Namibia',
		phoneCode: '264',
		countryCode: 'NA',
	},
	{
		countryName: 'New Caledonia',
		phoneCode: '687',
		countryCode: 'NC',
	},
	{
		countryName: 'Niger',
		phoneCode: '227',
		countryCode: 'NE',
	},
	{
		countryName: 'Norfolk Island',
		phoneCode: '672',
		countryCode: 'NF',
	},
	{
		countryName: 'Nigeria',
		phoneCode: '234',
		countryCode: 'NG',
	},
	{
		countryName: 'Nicaragua',
		phoneCode: '505',
		countryCode: 'NI',
	},
	{
		countryName: 'Netherlands',
		phoneCode: '31',
		countryCode: 'NL',
	},
	{
		countryName: 'Norway',
		phoneCode: '47',
		countryCode: 'NO',
	},
	{
		countryName: 'Nepal',
		phoneCode: '977',
		countryCode: 'NP',
	},
	{
		countryName: 'Nauru',
		phoneCode: '674',
		countryCode: 'NR',
	},
	{
		countryName: 'Niue',
		phoneCode: '683',
		countryCode: 'NU',
	},
	{
		countryName: 'New Zealand',
		phoneCode: '64',
		countryCode: 'NZ',
	},
	{
		countryName: 'Oman',
		phoneCode: '968',
		countryCode: 'OM',
	},
	{
		countryName: 'Panama',
		phoneCode: '507',
		countryCode: 'PA',
	},
	{
		countryName: 'Peru',
		phoneCode: '51',
		countryCode: 'PE',
	},
	{
		countryName: 'French Polynesia',
		phoneCode: '689',
		countryCode: 'PF',
	},
	{
		countryName: 'Papua New Guinea',
		phoneCode: '675',
		countryCode: 'PG',
	},
	{
		countryName: 'Philippines',
		phoneCode: '63',
		countryCode: 'PH',
	},
	{
		countryName: 'Pakistan',
		phoneCode: '92',
		countryCode: 'PK',
	},
	{
		countryName: 'Poland',
		phoneCode: '48',
		countryCode: 'PL',
	},
	{
		countryName: 'St. Pierre & Miquelon',
		phoneCode: '508',
		countryCode: 'PM',
	},
	{
		countryName: 'Pitcairn Islands',
		phoneCode: '872',
		countryCode: 'PN',
	},
	{
		countryName: 'Puerto Rico',
		phoneCode: '1',
		countryCode: 'PR',
	},
	{
		countryName: 'Palestinian Territories',
		phoneCode: '970',
		countryCode: 'PS',
	},
	{
		countryName: 'Portugal',
		phoneCode: '351',
		countryCode: 'PT',
	},
	{
		countryName: 'Palau',
		phoneCode: '680',
		countryCode: 'PW',
	},
	{
		countryName: 'Paraguay',
		phoneCode: '595',
		countryCode: 'PY',
	},
	{
		countryName: 'Qatar',
		phoneCode: '974',
		countryCode: 'QA',
	},
	{
		countryName: 'Réunion',
		phoneCode: '262',
		countryCode: 'RE',
	},
	{
		countryName: 'Romania',
		phoneCode: '40',
		countryCode: 'RO',
	},
	{
		countryName: 'Serbia',
		phoneCode: '381',
		countryCode: 'RS',
	},
	{
		countryName: 'Russia',
		phoneCode: '7',
		countryCode: 'RU',
	},
	{
		countryName: 'Rwanda',
		phoneCode: '250',
		countryCode: 'RW',
	},
	{
		countryName: 'Saudi Arabia',
		phoneCode: '966',
		countryCode: 'SA',
	},
	{
		countryName: 'Solomon Islands',
		phoneCode: '677',
		countryCode: 'SB',
	},
	{
		countryName: 'Seychelles',
		phoneCode: '248',
		countryCode: 'SC',
	},
	{
		countryName: 'Sudan',
		phoneCode: '249',
		countryCode: 'SD',
	},
	{
		countryName: 'Sweden',
		phoneCode: '46',
		countryCode: 'SE',
	},
	{
		countryName: 'Singapore',
		phoneCode: '65',
		countryCode: 'SG',
	},
	{
		countryName: 'St. Helena',
		phoneCode: '290',
		countryCode: 'SH',
	},
	{
		countryName: 'Slovenia',
		phoneCode: '386',
		countryCode: 'SI',
	},
	{
		countryName: 'Svalbard & Jan Mayen',
		phoneCode: '47',
		countryCode: 'SJ',
	},
	{
		countryName: 'Slovakia',
		phoneCode: '421',
		countryCode: 'SK',
	},
	{
		countryName: 'Sierra Leone',
		phoneCode: '232',
		countryCode: 'SL',
	},
	{
		countryName: 'San Marino',
		phoneCode: '378',
		countryCode: 'SM',
	},
	{
		countryName: 'Senegal',
		phoneCode: '221',
		countryCode: 'SN',
	},
	{
		countryName: 'Somalia',
		phoneCode: '252',
		countryCode: 'SO',
	},
	{
		countryName: 'Suriname',
		phoneCode: '597',
		countryCode: 'SR',
	},
	{
		countryName: 'South Sudan',
		phoneCode: '211',
		countryCode: 'SS',
	},
	{
		countryName: 'São Tomé & Príncipe',
		phoneCode: '239',
		countryCode: 'ST',
	},
	{
		countryName: 'El Salvador',
		phoneCode: '503',
		countryCode: 'SV',
	},
	{
		countryName: 'Sint Maarten',
		phoneCode: '599',
		countryCode: 'SX',
	},
	{
		countryName: 'Syria',
		phoneCode: '963',
		countryCode: 'SY',
	},
	{
		countryName: 'Swaziland',
		phoneCode: '268',
		countryCode: 'SZ',
	},
	{
		countryName: 'Turks & Caicos Islands',
		phoneCode: '1',
		countryCode: 'TC',
	},
	{
		countryName: 'Chad',
		phoneCode: '235',
		countryCode: 'TD',
	},
	{
		countryName: 'Togo',
		phoneCode: '228',
		countryCode: 'TG',
	},
	{
		countryName: 'Thailand',
		phoneCode: '66',
		countryCode: 'TH',
	},
	{
		countryName: 'Tajikistan',
		phoneCode: '992',
		countryCode: 'TJ',
	},
	{
		countryName: 'Tokelau',
		phoneCode: '690',
		countryCode: 'TK',
	},
	{
		countryName: 'Timor-Leste',
		phoneCode: '670',
		countryCode: 'TL',
	},
	{
		countryName: 'Turkmenistan',
		phoneCode: '993',
		countryCode: 'TM',
	},
	{
		countryName: 'Tunisia',
		phoneCode: '216',
		countryCode: 'TN',
	},
	{
		countryName: 'Tonga',
		phoneCode: '676',
		countryCode: 'TO',
	},
	{
		countryName: 'Turkey',
		phoneCode: '90',
		countryCode: 'TR',
	},
	{
		countryName: 'Trinidad & Tobago',
		phoneCode: '1',
		countryCode: 'TT',
	},
	{
		countryName: 'Tuvalu',
		phoneCode: '688',
		countryCode: 'TV',
	},
	{
		countryName: 'Taiwan',
		phoneCode: '886',
		countryCode: 'TW',
	},
	{
		countryName: 'Tanzania',
		phoneCode: '255',
		countryCode: 'TZ',
	},
	{
		countryName: 'Ukraine',
		phoneCode: '380',
		countryCode: 'UA',
	},
	{
		countryName: 'Uganda',
		phoneCode: '256',
		countryCode: 'UG',
	},
	{
		countryName: 'United States',
		phoneCode: '1',
		countryCode: 'US',
	},
	{
		countryName: 'Uruguay',
		phoneCode: '598',
		countryCode: 'UY',
	},
	{
		countryName: 'Uzbekistan',
		phoneCode: '998',
		countryCode: 'UZ',
	},
	{
		countryName: 'Vatican City',
		phoneCode: '379',
		countryCode: 'VA',
	},
	{
		countryName: 'St. Vincent & Grenadines',
		phoneCode: '1',
		countryCode: 'VC',
	},
	{
		countryName: 'Venezuela',
		phoneCode: '58',
		countryCode: 'VE',
	},
	{
		countryName: 'British Virgin Islands',
		phoneCode: '1',
		countryCode: 'VG',
	},
	{
		countryName: 'U.S. Virgin Islands',
		phoneCode: '1',
		countryCode: 'VI',
	},
	{
		countryName: 'Vietnam',
		phoneCode: '84',
		countryCode: 'VN',
	},
	{
		countryName: 'Vanuatu',
		phoneCode: '678',
		countryCode: 'VU',
	},
	{
		countryName: 'Wallis & Futuna',
		phoneCode: '681',
		countryCode: 'WF',
	},
	{
		countryName: 'Samoa',
		phoneCode: '685',
		countryCode: 'WS',
	},
	{
		countryName: 'Yemen',
		phoneCode: '967',
		countryCode: 'YE',
	},
	{
		countryName: 'Mayotte',
		phoneCode: '262',
		countryCode: 'YT',
	},
	{
		countryName: 'South Africa',
		phoneCode: '27',
		countryCode: 'ZA',
	},
	{
		countryName: 'Zambia',
		phoneCode: '260',
		countryCode: 'ZM',
	},
	{
		countryName: 'Zimbabwe',
		phoneCode: '263',
		countryCode: 'ZW',
	},
];
