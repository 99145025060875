import React, { useImperativeHandle, memo } from 'react';
import { useReactive, useMemoizedFn } from 'ahooks';
import { useTranslation } from 'react-i18next';
import ICONclose from '../../../assets/icons/close.svg';
import { filterNullwithArray } from '../../../utils/util';

const LettersOverviewFilter = React.forwardRef((props, ref) => {
	const { t } = useTranslation();

	useImperativeHandle(ref, () => ({
		changeFilterVal: (obj) => {
			let letterType = obj.letterType;
			LOFilter.letterType = letterType;
		},
	}));

	const LOFilter = useReactive({
		letterType: '',
	});

	const changeStatus = useMemoizedFn((value) => {
		LOFilter.letterType = value;
	});

	const applyFilters = useMemoizedFn((event) => {
		let filter = filterNullwithArray(JSON.parse(JSON.stringify(LOFilter)));
		if (JSON.stringify(filter) === '{}') {
			return;
		}
		props.changeFilterNumber(Object.keys(filter).length);
		props.applyFilters(filter, 'search');
	});

	const resetFilters = useMemoizedFn((event) => {
		LOFilter.letterType = '';
		props.changeFilterNumber(0);
		props.applyFilters(JSON.parse(JSON.stringify(LOFilter)), 'reset');
	});

	return (
		<aside className={props.filtersPanel ? 'filters visible' : 'filters'}>
			<button
				className="btnHideFilters"
				onClick={() => props.changeFilterPanel()}
			>
				<img alt="Hide Filters" src={ICONclose} />
			</button>
			<ul className="lstFilters">
				<li>
					<span>{t('Overview.TypeofLetter')}</span>
					<button
						className={
							LOFilter.letterType === 'NOTICE TO CARRIER/SUPPLIER'
								? 'btnStatusFilter long active'
								: 'btnStatusFilter long'
						}
						onClick={() => changeStatus('NOTICE TO CARRIER/SUPPLIER')}
					>
						{t('Overview.NoticetoCarrierSupplier')}
					</button>
					<button
						className={
							LOFilter.letterType === 'NOTICE OF NON-CONFORMANCE'
								? 'btnStatusFilter long active'
								: 'btnStatusFilter long'
						}
						onClick={() => changeStatus('NOTICE OF NON-CONFORMANCE')}
					>
						{t('Overview.NotificationofNonConformance')}
					</button>
				</li>
				<li>
					<button className="btnApplyFilters" onClick={() => applyFilters()}>
						{t('Overview.ApplyFilter')}
					</button>
					<button className="btnResetFilters" onClick={() => resetFilters()}>
						{t('Overview.ResetFilter')}
					</button>
				</li>
			</ul>
		</aside>
	);
});

export default memo(LettersOverviewFilter);
