import React, { Fragment, memo, useState } from 'react';
import { useUpdateEffect, useMemoizedFn, useMount } from 'ahooks';
import { useTranslation } from 'react-i18next';

const Longtext = ({ val }, ref) => {
	const { t } = useTranslation();

	const [isShowMore, setIsShowMore] = useState(false);
	const [isMore, setIsMore] = useState(false);
	const [longtext, setLongtext] = useState('');

	useMount(() => {
		if (val !== '') {
			if (val.length > 290) {
				let temp = `${val.substring(0, 290)}...`;
				setIsShowMore(true);
				setLongtext(temp);
				setIsMore(true);
			} else {
				setLongtext(val);
				setIsShowMore(false);
				setIsShowMore(false);
			}
		}
	});

	useUpdateEffect(() => {
		if (val !== '') {
			if (val.length > 290) {
				let temp = `${val.substring(0, 290)}...`;
				setIsShowMore(true);
				setLongtext(temp);
				setIsMore(true);
			} else {
				setLongtext(val);
				setIsShowMore(false);
				setIsShowMore(false);
			}
		}
	}, [val]); // eslint-disable-line

	const lineRender = useMemoizedFn(() => {
		if (longtext !== '') {
			// console.log(val);
			// const newVal = val.replaceAll('\n', '</br>');
			const newVal = longtext.replace(new RegExp('##', 'g'), '</br>');
			return <span dangerouslySetInnerHTML={{ __html: newVal }}></span>;
		} else {
			return <Fragment />;
		}
	});

	const showMoreFunc = useMemoizedFn(() => {
		if (isShowMore) {
			if (isMore) {
				return (
					<span className="ismore" onClick={() => showmore()}>
						{t('Detail.ShowMore')}
					</span>
				);
			} else {
				return (
					<span className="ismore" onClick={() => showmore()}>
						{t('Detail.ShowLess')}
					</span>
				);
			}
		}
	});

	const showmore = useMemoizedFn(() => {
		if (isMore) {
			setLongtext(val);
			setIsMore(false);
		} else {
			let temp = `${val.substring(0, 290)}...`;
			setLongtext(temp);
			setIsMore(true);
		}
	});

	return (
		<Fragment>
			<p className="description">
				{lineRender()}
				{showMoreFunc()}
			</p>
		</Fragment>
	);
};

export default memo(Longtext);
