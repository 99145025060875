import React, { useEffect, useRef, useState } from 'react';
import { WidgetInstance } from 'friendly-challenge';
import axios from 'axios';
import { notificationShowHtml } from '../../../utils/util.js';

function FC_PUZZLE_EP(endpoint) {
	switch (endpoint) {
		case 'GLOBAL1':
			return 'https://api.friendlycaptcha.com/api/v1/puzzle';
		case 'EU1':
			return 'https://eu-api.friendlycaptcha.eu/api/v1/puzzle';
		default:
			return 'https://api.friendlycaptcha.com/api/v1/puzzle';
	}
}

const FriendlyCaptcha = (props) => {
	// container is set in return function, where the <div> gets the container as ref attribute.
	const container = useRef(null);
	const widget = useRef(null);

	useEffect(() => {
		if (!props.captchaRendered && container.current) {
			widget.current = new WidgetInstance(container.current, {
				puzzleEndpoint: FC_PUZZLE_EP(props.endpoint),
				startMode: props.startMode,
				doneCallback: props.solvedHandler,
				errorCallback: props.errorHandler,
				sitekey: props.siteKey,
				language: props.language,
				secret: props.secret,
				verification: props.verification,
			});
			props.setCaptchaRendered(true);
		}

		return () => {
			if (widget.current !== null && props.captchaRendered) {
				widget.current.destroy();
				props.setCaptchaRendered(false);
				props.resetHandler();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div ref={container} className="frc-captcha" data-sitekey={props.siteKey} />
	);
};

const CaptchaWidget = React.memo(FriendlyCaptcha);

function useCaptchaHook({
	siteKey,
	endpoint = 'GLOBAL1',
	language,
	startMode = 'auto',
	showAttribution = true,
	secret,
	verification,
}) {
	const [captchaStatus, setCaptchaStatus] = useState({
		solution: null,
		error: null,
	});
	const [captchaRendered, setCaptchaRendered] = useState(false);

	const solvedHandler = async (solution) => {
		let obj = {
			solution,
			secret,
			siteKey,
		};
		let temp = await axios.post(verification, obj);
		if (temp.data.success) {
			setCaptchaStatus({ solution: solution, error: null });
		} else {
			notificationShowHtml(
				'error',
				`500 Error`,
				`Error status is 500. The server could not process the request because there was a problem. Please try refreshing the page and if the problem persists, do contact us at <a href="mailto:ncmportal@basf.com" class="ui-link">ncmportal@basf.com</a>.<br><button class="btn-back-to-start"><a href="/">Back to Start</a></button>`,
				0
			);
		}
	};

	const errorHandler = (error) => {
		console.log(error.description);
		setCaptchaStatus({ solution: null, error: error });
	};

	const resetHandler = () => {
		setCaptchaStatus({ solution: null, error: null });
	};

	return {
		CaptchaWidget: (widgetProps, customWidgetStyle) => {
			return (
				<CaptchaWidget
					siteKey={siteKey}
					secret={secret}
					endpoint={endpoint}
					verification={verification}
					language={language}
					startMode={startMode}
					showAttribution={showAttribution}
					solvedHandler={solvedHandler}
					errorHandler={errorHandler}
					resetHandler={resetHandler}
					captchaRendered={captchaRendered}
					setCaptchaRendered={setCaptchaRendered}
					customWidgetStyle={customWidgetStyle}
					{...widgetProps}
				/>
			);
		},
		captchaStatus,
	};
}

export { useCaptchaHook };
