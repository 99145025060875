import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

const ContentA1Closed = ({ etcauseclosed }) => {
	const { t } = useTranslation();

	return (
		<div>
			<h5>{t('Detail.Cause')}</h5>
			<ul className="lstFeedback">
				<li>
					<input
						type="text"
						id="txb_description"
						className="txbFeedback-noborder"
						value={etcauseclosed.codegrptext || ''}
						readOnly
					/>
				</li>
				<li>
					<input
						type="text"
						id="txb_description"
						className="txbFeedback-noborder"
						value={etcauseclosed.codetext || ''}
						readOnly
					/>
				</li>
				<li>
					<textarea
						className="txaFeedback-noborder descriptionInput"
						value={etcauseclosed.urtxt || ''}
						readOnly
					/>
				</li>
			</ul>
		</div>
	);
};

export default memo(ContentA1Closed);
