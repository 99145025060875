import React, {
	Fragment,
	useEffect,
	useImperativeHandle,
	memo,
	useState,
} from 'react';
import { useReactive } from 'ahooks';
import { useTranslation } from 'react-i18next';
import { useMemoizedFn } from 'ahooks';

const SupplierContentDetail = React.forwardRef(
	(
		{
			expandedSupplier,
			quaryData,
			detailPageDTO,
			internalInfo,
			internalRefNum,
			setFalse,
			typeuser,
			status,
		},
		ref
	) => {
		const { t } = useTranslation();
		const [isShowIRN, setIsShowIRN] = useState(false);
		const [isShowIRI, setIsShowIRI] = useState(false);

		useImperativeHandle(ref, () => ({
			internalRefNum: internalForm.internalRefNum,
			internalInfo: internalForm.internalInfo,
			internalRefNumError: () => {
				document
					.getElementById('txb_int_ref_number')
					.classList.remove('isFilled');
				setIsShowIRN(true);
				// document.getElementById('txb_int_ref_number').classList.add('gotError');
				// const listItem =
				// 	document.getElementById('txb_int_ref_number').parentNode;
				// const error = listItem.getElementsByTagName('span')[1];
				// error.classList.add('visible');
			},
			internalInfoError: () => {
				document.getElementById('txa_int_info').classList.remove('isFilled');
				setIsShowIRI(true);
				// document.getElementById('txa_int_info').classList.add('gotError');
				// const listItem = document.getElementById('txa_int_info').parentNode;
				// const error = listItem.getElementsByTagName('span')[1];
				// error.classList.add('visible');
			},
		}));

		const internalForm = useReactive({
			internalRefNum: '',
			internalInfo: '',
		});

		const internalRefNumChangeHandler = useMemoizedFn((e) => {
			if (e.target.value.length > 20) {
				setIsShowIRN(true);
				return;
			} else {
				setIsShowIRN(false);
			}
			// setFalse();
			// e.target.classList.remove('gotError');
			// e.target.parentNode
			// 	.getElementsByTagName('span')[1]
			// 	.classList.remove('visible');

			internalForm.internalRefNum = e.target.value;

			// Will be generalized
			if (e.target.value !== '') {
				e.target.classList.add('isFilled');
			} else {
				e.target.classList.remove('isFilled');
			}
		});

		const internalInfoChangeHandler = useMemoizedFn((e) => {
			if (e.target.value.length > 400) {
				setIsShowIRI(true);
				return;
			} else {
				setIsShowIRI(false);
			}
			// setFalse();
			// e.target.classList.remove('gotError');
			// e.target.parentNode
			// 	.getElementsByTagName('span')[1]
			// 	.classList.remove('visible');

			internalForm.internalInfo = e.target.value;

			// Will be generalized
			if (e.target.value !== '') {
				e.target.classList.add('isFilled');
			} else {
				e.target.classList.remove('isFilled');
			}
		});

		useEffect(() => {
			internalForm.internalInfo = internalInfo;
			internalForm.internalRefNum = internalRefNum;
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [internalInfo, internalRefNum]);

		const lineRender = useMemoizedFn((val) => {
			if (val) {
				return (
					<Fragment>
						{val}
						<br />
					</Fragment>
				);
			} else {
				return <Fragment />;
			}
		});

		const lowWord = useMemoizedFn((val) => {
			if (val) {
				return val.toLowerCase();
			}
		});

		return (
			<div ref={ref}>
				<ul className={expandedSupplier ? 'lstSupplier' : 'lstSupplier hidden'}>
					<li>
						<span>{t('Overview.OriginalResponsible')}</span>
						<p>
							<a href={`mailto:${quaryData.email}`}>
								{lowWord(quaryData.email)}
							</a>
						</p>
					</li>
					<li>
						<span>{t('Detail.PurchaseOrder')}</span>
						<p>{detailPageDTO.ponumber}</p>
					</li>
					<li>
						<span>{t('Detail.PurchaseOrderItem')}</span>
						<p>{detailPageDTO.poitem}</p>
					</li>
					<li>
						<span>{t('Detail.MaterialNumber')}</span>
						<p>{detailPageDTO.matnr}</p>
					</li>
					<li>
						<span>{t('Detail.MaterialDescription')}</span>
						<p>{detailPageDTO.maktx}</p>
					</li>
					<li>
						<span>{t('Detail.ComplaintUnit')}</span>
						<p>
							{detailPageDTO.rkmng} {detailPageDTO.uom}
						</p>
					</li>
					<li>
						<span>{t('Detail.SupplierMaterial')}</span>
						<p>{detailPageDTO.supplmat}</p>
					</li>
					<li>
						<span>{t('Detail.SupplierCharge')}</span>
						<p>{detailPageDTO.supplchg}</p>
					</li>
					<li>
						<span>{t('Detail.DeliveryDate')}</span>
						<p>{detailPageDTO.deldate}</p>
					</li>
					<li>
						<span>{t('Detail.ShipTo')} </span>
						<p className="lh22">
							{lineRender(detailPageDTO.recipient)}
							{lineRender(detailPageDTO.street)}
							{lineRender(detailPageDTO.postcode1)}
							{lineRender(detailPageDTO.city1)}
							{lineRender(detailPageDTO.country)}
						</p>
					</li>

					<li>
						<span>{t('Detail.BASFContact')}</span>
						<p className="lh22">
							<a href={`mailto:${detailPageDTO.senderemail}`}>
								{detailPageDTO.senderemail}
							</a>
							<br />
							{lineRender(detailPageDTO.senderrecipient)}
							{lineRender(detailPageDTO.senderstreet)}
							{lineRender(detailPageDTO.senderpostcode1)}
							{lineRender(detailPageDTO.sendercity1)}
							{lineRender(detailPageDTO.sendercountry)}
						</p>
					</li>
					<li>
						<span>{t('Detail.MainProblemDescription')}</span>
						<p>{detailPageDTO.mreason}</p>
					</li>
					<li>
						<span>{t('Detail.DetailProblemDescription')}</span>
						<p>{detailPageDTO.dreason}</p>
					</li>
				</ul>
				<ul
					className={
						expandedSupplier ? 'lstSupplier alt' : 'lstSupplier alt hidden'
					}
				>
					<li>
						<span>{t('Detail.InternalReferenceNumber')}*</span>
						<input
							type="text"
							id="txb_int_ref_number"
							className="txbSupplier"
							value={internalForm.internalRefNum}
							onChange={(e) => internalRefNumChangeHandler(e)}
							onBlur={() => setIsShowIRN(false)}
							readOnly={typeuser === 'preview' || status === 'closed'}
						/>
						<span className={isShowIRN ? 'errInput visible' : 'errInput'}>
							{t('Detail.Internal20')}
						</span>
					</li>
					<li className="mb10">
						<span>{t('Detail.InternalInformation')}*</span>
						<textarea
							id="txa_int_info"
							className="txaSupplier"
							value={internalForm.internalInfo}
							onChange={(e) => internalInfoChangeHandler(e)}
							onBlur={() => setIsShowIRI(false)}
							readOnly={typeuser === 'preview' || status === 'closed'}
						/>
						<span className={isShowIRI ? 'errInput visible' : 'errInput'}>
							{t('Detail.Internal400')}
						</span>
					</li>
				</ul>
				<p className={expandedSupplier ? 'disclaimer' : 'disclaimer hidden'}>
					* {t('Detail.informationfeedback')}
				</p>
			</div>
		);
	}
);

export default memo(SupplierContentDetail);
