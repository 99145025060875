import React, { Fragment, memo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemoizedFn } from 'ahooks';
import { DatePicker, Input, Form, Row, Col } from 'antd';
import InputFileUpload from '../../../components/InputFileUpload/InputFileUpload';
import '../../../../node_modules/antd/lib/input/style/index.css';
import '../../../../node_modules/antd/lib/form/style/index.css';
import { onSubmitRequired, onBlurValidateDirtyword } from './patternRex';

const D3Containment = React.forwardRef((_, ref) => {
	// eslint-disable-next-line no-unused-vars
	const { t } = useTranslation();

	const [startDateData, setStartDateData] = useState(null);
	const [endDateData, setEndDateData] = useState(null);
	const [startDateData2, setStartDateData2] = useState(null);
	const [endDateData2, setEndDateData2] = useState(null);

	let fileUploadRef = useRef(null);

	const startDatePickerOnChange = (date) => {
		setStartDateData(date);
		setEndDateData(null);
	};

	const endDatePickerOnChange = (date) => {
		setEndDateData(date);
	};

	const startDatePickerOnChange2 = (date) => {
		setStartDateData2(date);
		setEndDateData2(null);
	};

	const endDatePickerOnChange2 = (date) => {
		setEndDateData2(date);
	};

	return (
		<div ref={ref}>
			<span className="antd_form_title">{t('8D.Containment')}</span>
			<Row>
				<Col xs={24}>
					<Form.Item
						className="antd_label"
						label=""
						name="d3_ca"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input className="antd_input" placeholder={t('8D.Containment')} />
					</Form.Item>
				</Col>
				<Col xs={24}>
					<Form.Item
						className="antd_label"
						label=""
						name="d3_ca_responsible"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[onSubmitRequired, onBlurValidateDirtyword]}
					>
						<Input className="antd_input" placeholder={t('8D.Responsible')} />
					</Form.Item>
				</Col>
				<Col xs={24} md={11}>
					<Form.Item
						className="antd_label"
						label=""
						name="d3_ca_datedue"
						rules={[
							{
								required: true,
								message: '',
							},
						]}
					>
						<DatePicker
							className="antd_date"
							style={{ width: '100%' }}
							suffixIcon={<></>}
							allowClear={false}
							placeholder={t('8D.DateDue')}
							format="YYYY-MM-DD"
							onChange={(date) => startDatePickerOnChange(date)}
							showToday={false}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} md={{ span: 11, offset: 2 }}>
					<Form.Item className="antd_label" label="" name="d3_ca_datecompl">
						<DatePicker
							className="antd_date"
							style={{ width: '100%' }}
							suffixIcon={<></>}
							allowClear={false}
							placeholder={t('8D.DateCompleted')}
							format="YYYY-MM-DD"
							onChange={(date) => endDatePickerOnChange(date)}
							showToday={false}
						/>
					</Form.Item>
				</Col>
			</Row>
			{/* <span className="antd_form_title">Interim Action(s)</span>
			<Row>
				<Col xs={24}>
					<Form.Item
						className="antd_label"
						label=""
						name="d3_ia"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[
							({ getFieldValue }) => ({
								validator(_, value) {
									if (
										!value &&
										(getFieldValue('d3_ia_responsible') ||
											getFieldValue('d3_ia_datedue'))
									) {
										return Promise.reject();
									} else {
										return Promise.resolve();
									}
								},
							}),
							onBlurValidateDirtyword,
						]}
					>
						<Input className="antd_input" placeholder="Interim Action(s)" />
					</Form.Item>
				</Col>
				<Col xs={24}>
					<Form.Item
						className="antd_label"
						label=""
						name="d3_ia_responsible"
						validateTrigger={['onSubmit', 'onBlur']}
						rules={[
							({ getFieldValue }) => ({
								validator(_, value) {
									if (
										!value &&
										(getFieldValue('d3_ia') || getFieldValue('d3_ia_datedue'))
									) {
										return Promise.reject();
									} else {
										return Promise.resolve();
									}
								},
							}),
							onBlurValidateDirtyword,
						]}
					>
						<Input className="antd_input" placeholder={t('8D.Responsible')} />
					</Form.Item>
				</Col>
				<Col xs={24} md={11}>
					<Form.Item
						className="antd_label"
						label=""
						name="d3_ia_datedue"
						rules={[
							({ getFieldValue }) => ({
								validator(_, value) {
									if (
										!value &&
										(getFieldValue('d3_ia') ||
											getFieldValue('d3_ia_responsible'))
									) {
										return Promise.reject();
									} else {
										return Promise.resolve();
									}
								},
							}),
						]}
					>
						<DatePicker
							className="antd_date"
							style={{ width: '100%' }}
							suffixIcon={<></>}
							allowClear={false}
							placeholder={t('8D.DateDue')}
							format="YYYY-MM-DD"
							onChange={(date) => startDatePickerOnChange2(date)}
							showToday={false}
						/>
					</Form.Item>
				</Col>
				<Col xs={24} md={{ span: 11, offset: 2 }}>
					<Form.Item className="antd_label" label="" name="d3_ia_datecompl">
						<DatePicker
							className="antd_date"
							style={{ width: '100%' }}
							suffixIcon={<></>}
							allowClear={false}
							placeholder={t('8D.DateCompleted')}
							format="YYYY-MM-DD"
							onChange={(date) => endDatePickerOnChange2(date)}
							showToday={false}
						/>
					</Form.Item>
				</Col>
			</Row> */}
			<li>
				<InputFileUpload ref={fileUploadRef} />
			</li>
		</div>
	);
});

export default memo(D3Containment);
