import React, { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

const ContentBClosed = ({ reasoning }) => {
	const { t } = useTranslation();

	return (
		<Fragment>
			<h5>{t('Detail.Reasoning')}</h5>
			<ul className="lstFeedback">
				<li>
					<textarea
						id="txaFeedback"
						value={reasoning || ''}
						className="txaFeedback-noborder reasoning isFilled"
					/>
				</li>
			</ul>
		</Fragment>
	);
};

export default memo(ContentBClosed);
