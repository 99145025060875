import React, { Fragment, useImperativeHandle, memo } from 'react';
import { useTranslation } from 'react-i18next';
import ICONdown from '../../../assets/icons/arrow-down.svg';
import ICONup from '../../../assets/icons/arrow-up.svg';
import ICONdeputy from '../../../assets/icons/deputy.svg';
import ICONinfo from '../../../assets/icons/info-i.svg';
import ICONedit from '../../../assets/icons/edit.svg';
import Acknowledged from '../../../assets/icons/acknowledged.svg';
import AcknowledgementPending from '../../../assets/icons/acknowledgementPending.svg';
import { useHistory } from 'react-router-dom';
import { useMemoizedFn } from 'ahooks';
import store from '../../../state/store';
import { awaitWrap, encryptStr } from '../../../utils/util';
import axios from '../../../axios-custom';
import moment from 'moment';
import { Tooltip } from 'antd';
import '../../../../node_modules/antd/lib/tooltip/style/index.css';
// import { InfoCircleOutlined } from '@ant-design/icons';
const { useModel } = store;

const RequestsOverview = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const history = useHistory();
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { showLoading, hideLoading, toastMessageShow } = dispatchers;

	useImperativeHandle(ref, () => ({
		//
	}));

	const openDetail = useMemoizedFn(async (e, obj) => {
		showLoading();
		//
		let statustemp = '';
		if (obj.status.includes('DRAFT')) {
			statustemp = 'O';
		} else {
			if (obj.feedback.toUpperCase() === 'X') {
				statustemp = 'C';
			} else {
				statustemp = 'O';
			}
		}
		//
		let checkobj = {
			targetSystem:
				obj.targetSystem.toUpperCase() === 'COBALT' || !obj.targetSystem
					? 'COBALT'
					: 'EC',
			email: obj.responsible,
			nc: obj.qmnum,
			report: obj.report,
			lang: 'E',
			acceptLang: localStorage.getItem('NCM_lang')
				? localStorage.getItem('NCM_lang')
				: 'en',
			imstatus: statustemp,
			reportid: obj.reportid,
			ncType: obj.nctype,
		};
		const checkresponse = await axios.post(
			'/feedbackServices/portalSap/detailedPage',
			checkobj
		);
		if (checkresponse.data.edescrtext.includes('//Closed')) {
			//
			let typeuser = '';
			if (obj.typeuser === '') {
				typeuser = 'preview';
			} else {
				typeuser = 'checkclosed';
			}
			//
			let temp = {
				email: obj.responsible,
				nc: obj.qmnum,
				report: obj.report,
				status: 'closed',
				id: '',
				refDocNo: obj.refDocNo,
				typeuser: typeuser,
				reportid: obj.reportid,
				ncType: obj.nctype,
				targetSystem:
					obj.targetSystem.toUpperCase() === 'COBALT' || !obj.targetSystem
						? 'COBALT'
						: 'EC',
			};
			hideLoading();
			let encryptVal = encryptStr(
				`email=${temp.email}&nc=${temp.nc}&report=${temp.report}&status=${temp.status}&id=${temp.id}&refDocNo=${temp.refDocNo}&typeuser=${temp.typeuser}&from=landing&reportid=${temp.reportid}&targetSystem=${temp.targetSystem}&ncTypeUrl=${temp.ncType}`
			);
			history.push(`/detail?value=${encryptVal}`);
			return;
		}
		//
		// eslint-disable-next-line no-unused-vars
		const [err, response] = await awaitWrap(
			axios.get(`/feedbackServices/apiResource/getResource/${obj.qmnum}`)
		);
		//
		if (response && response.data && response.data.isUnderEdit === 'true') {
			let notificationObj = {
				type: 'error',
				title: 'Error',
				description: `${t('Overview.lock1')} ${obj.qmnum} ${t(
					'Overview.lock2'
				)}`,
				duration: 5,
			};
			hideLoading();
			toastMessageShow(notificationObj);
			return;
		}
		//
		let scorllY =
			e.pageY ||
			e.clientY +
				(document.documentElement.scrollTop || document.body.scrollTop);
		localStorage.setItem('NCM_scrollY', scorllY);
		// let lang = '';
		// if (localStorage.getItem('NCM_lang') === 'en') {
		// 	lang = 'E';
		// } else if (localStorage.getItem('NCM_lang') === 'de') {
		// 	lang = 'D';
		// } else if (localStorage.getItem('NCM_lang') === 'fr') {
		// 	lang = 'F';
		// } else if (localStorage.getItem('NCM_lang') === 'es') {
		// 	lang = 'S';
		// } else if (localStorage.getItem('NCM_lang') === 'pt') {
		// 	lang = 'P';
		// }
		// window.scrollTo(0, 0);
		let status = '';
		let id = '';
		if (obj.status.includes('DRAFT')) {
			status = 'draft';
			let temp = obj.status.split('$$');
			id = temp[temp.length - 1];
		} else {
			if (obj.feedback.toUpperCase() === 'X') {
				status = 'closed';
			} else {
				// open----check draft
				let checkid = await axios.post(
					`/feedbackServices/api/getQmnumUniqueId?qmnum=${obj.qmnum}&report=${obj.report}`
				);
				if (checkid && checkid.data !== '') {
					status = 'draft';
					id = checkid.data;
				} else {
					status = 'open';
				}
			}
		}
		//
		let typeuser = '';
		if (obj.typeuser === '') {
			typeuser = 'preview';
		} else {
			typeuser = obj.typeuser;
		}
		let temp = {
			email: obj.responsible,
			nc: obj.qmnum,
			report: obj.report,
			status: status,
			id: id,
			refDocNo: obj.refDocNo,
			typeuser: typeuser,
			reportid: obj.reportid,
			ncType: obj.nctype,
			targetSystem:
				obj.targetSystem.toUpperCase() === 'COBALT' || !obj.targetSystem
					? 'COBALT'
					: 'EC',
		};
		//
		if (typeuser === 'preview') {
			//
		} else {
			if (status === 'open' || status === 'draft') {
				let user = JSON.parse(localStorage.getItem('NCM_User'));
				await axios.post(
					`/feedbackServices/apiResource/lockResource/${obj.qmnum}?qmnum=${obj.qmnum}&userid=${user.email}`,
					{}
				);
			}
		}
		//
		hideLoading();
		//
		let encryptVal = encryptStr(
			`email=${temp.email}&nc=${temp.nc}&report=${temp.report}&status=${temp.status}&id=${temp.id}&refDocNo=${temp.refDocNo}&typeuser=${temp.typeuser}&from=overview&reportid=${temp.reportid}&targetSystem=${temp.targetSystem}&ncTypeUrl=${temp.ncType}`
		);
		history.push(`/detail?value=${encryptVal}`);
	});

	const expandItem = (event) => {
		// 2 is better than 1 in this case I guess
		const listItem = event.target.closest('.feedback');
		const bttnItem = event.target.closest('.btnExpand');

		if (listItem.classList.contains('expanded')) {
			listItem.classList.remove('expanded');
			bttnItem.classList.remove('expanded');
		} else {
			listItem.classList.add('expanded');
			bttnItem.classList.add('expanded');
		}
	};

	const lineRender = useMemoizedFn((val) => {
		if (val) {
			return (
				<Fragment>
					{val}
					<br />
				</Fragment>
			);
		} else {
			return <Fragment />;
		}
	});

	const topStyle = useMemoizedFn((item) => {
		let status = '';
		if (item.status.includes('DRAFT')) {
			status = 'draft';
		} else {
			if (item.feedback === '') {
				status = 'open';
			} else if (item.feedback.toUpperCase() === 'X') {
				status = 'closed';
			}
		}
		if (props.expandedAll) {
			return `feedback ${status} expanded`;
		} else {
			return `feedback ${status}`;
		}
	});

	const boxInteractionStyle = useMemoizedFn((item) => {
		let status = '';
		if (item.status.includes('DRAFT')) {
			status = 'draft';
		} else {
			if (item.feedback === '') {
				status = 'open';
			} else if (item.feedback.toUpperCase() === 'X') {
				status = 'closed';
			}
		}
		return `boxInteraction ${status}`;
	});

	const mainCause = useMemoizedFn((cause, status, draftData) => {
		if (status.includes('DRAFT')) {
			if (
				draftData &&
				draftData !== null &&
				draftData['cause_maindesc'] &&
				draftData['cause_maindesc'] !== ''
			) {
				return (
					<li>
						<span>{t('Overview.MainCause')}</span>
						<p>{draftData['cause_maindesc']}</p>
					</li>
				);
			} else {
				return <Fragment />;
			}
		} else {
			if (
				cause &&
				cause.item &&
				cause.item.length !== 0 &&
				cause.item[0].mreasontext
			) {
				return (
					<li>
						<span>{t('Overview.MainCause')}</span>
						<p>{cause.item[0].mreasontext}</p>
					</li>
				);
			} else {
				return <Fragment />;
			}
		}
	});

	const detailCause = useMemoizedFn((cause, status, draftData) => {
		if (status.includes('DRAFT')) {
			if (
				draftData &&
				draftData !== null &&
				draftData['cause_detaildesc'] &&
				draftData['cause_detaildesc'] !== ''
			) {
				return (
					<li>
						<span>{t('Overview.DetailCause')}</span>
						<p>{draftData['cause_detaildesc']}</p>
					</li>
				);
			} else {
				return <Fragment />;
			}
		} else {
			if (
				cause &&
				cause.item &&
				cause.item.length &&
				cause.item[0].dreasontext
			) {
				return (
					<li>
						<span>{t('Overview.DetailCause')}</span>
						<p>{cause.item[0].dreasontext}</p>
					</li>
				);
			} else {
				return <Fragment />;
			}
		}
	});

	const mainCorrectiveAction = useMemoizedFn(
		(corraction, status, draftData) => {
			if (status.includes('DRAFT')) {
				if (
					draftData &&
					draftData !== null &&
					draftData['action_maindesc'] &&
					draftData['action_maindesc'].length
				) {
					// let temp = draftData['action_maindesc'][0].split(',');
					return (
						<li>
							<span>{t('Overview.MainCorrectiveAction')}</span>
							{draftData['action_maindesc'].map((item, index) => {
								return (
									<p key={index}>
										{index + 1}. {item}
									</p>
								);
							})}
						</li>
					);
				} else {
					return <Fragment />;
				}
			} else {
				if (corraction && corraction.item && corraction.item.length) {
					return (
						<li>
							<span>{t('Overview.MainCorrectiveAction')}</span>
							{corraction.item.map((item, index) => {
								return (
									<p key={index}>
										{index + 1}. {item.mreasontext}
									</p>
								);
							})}
						</li>
					);
				} else {
					return <Fragment />;
				}
			}
		}
	);

	const detailCorrectiveAction = useMemoizedFn(
		(corraction, status, draftData) => {
			if (status.includes('DRAFT')) {
				if (
					draftData &&
					draftData !== null &&
					draftData['action_detaildesc'] &&
					draftData['action_detaildesc'].length
				) {
					// let temp = draftData['action_detaildesc'][0].split(',');
					return (
						<li>
							<span>{t('Overview.DetailCorrectiveAction')}</span>
							{draftData['action_detaildesc'].map((item, index) => {
								return (
									<p key={index}>
										{index + 1}. {item}
									</p>
								);
							})}
						</li>
					);
				} else {
					return <Fragment />;
				}
			} else {
				if (corraction && corraction.item && corraction.item.length) {
					return (
						<li>
							<span>{t('Overview.DetailCorrectiveAction')}</span>
							{corraction.item.map((item, index) => {
								return (
									<p key={index}>
										{index + 1}. {item.dreasontext}
									</p>
								);
							})}
						</li>
					);
				} else {
					return <Fragment />;
				}
			}
		}
	);

	const status = useMemoizedFn((item) => {
		let val = '';
		if (item.fdbckpend.toUpperCase() === 'X') {
			val = 'Feedback Pending';
		} else if (item.fdbckunderrev.toUpperCase() === 'X') {
			val = 'Feedback Under Review';
		} else if (item.revcomp.toUpperCase() === 'X') {
			val = 'Review Completed';
		}
		return (
			<li>
				<span>{t('Overview.Status')}</span>
				<p>{val}</p>
			</li>
		);
	});

	const duedate = useMemoizedFn((item) => {
		if (item.overdue.toUpperCase() === 'X') {
			return (
				<li className="overdue">
					<span>{t('Overview.DueDate')}</span>
					<p>{item.duedate}</p>
					<span className="overdue">{t('Overview.Overdue')}</span>
				</li>
			);
		} else {
			if (item.firstrem.toUpperCase() === 'X') {
				return (
					<li className="pre-overdue">
						<span>{t('Overview.DueDate')}</span>
						<p>{item.duedate}</p>
					</li>
				);
			} else {
				return (
					<li>
						<span>{t('Overview.DueDate')}</span>
						<p>{item.duedate}</p>
						{/* <span className="overdue">{t('Overview.Overdue')}</span> */}
					</li>
				);
			}
		}
	});

	const DeputyIcon = useMemoizedFn((item) => {
		if (item.typeuser === 'M') {
			return <Fragment />;
		} else if (item.typeuser === 'D') {
			return (
				<button className={item.feedback === '' ? 'btnUser open' : 'btnUser'}>
					<img alt="Deputy" src={ICONdeputy} />
					<p>
						{t('Overview.until1')}
						{moment(item.deputytodate).format('YYYY-MM-DD')}
						{t('Overview.until2')}
					</p>
				</button>
			);
			// return <Fragment />;
		}
	});

	const lowWord = useMemoizedFn((val) => {
		if (val) {
			return val.toLowerCase();
		}
	});

	const InfoIcon = (item) => {
		if (item.typeuser === '') {
			return (
				<Tooltip
					title={t('Overview.previewrequest')}
					placement="left"
					overlayClassName="filetooltip"
				>
					<button
						className={item.feedback === '' ? 'btnDetail open' : 'btnDetail'}
						onClick={(e) => openDetail(e, item)}
					>
						<img alt="Details" src={ICONinfo} />
					</button>
				</Tooltip>
			);
		} else if (item.feedback.toUpperCase() === 'X') {
			return (
				<button
					className={item.feedback === '' ? 'btnDetail open' : 'btnDetail'}
					onClick={(e) => openDetail(e, item)}
				>
					<img alt="Details" src={ICONinfo} />
				</button>
			);
		} else {
			return (
				<button
					className={item.feedback === '' ? 'btnDetail open' : 'btnDetail'}
					onClick={(e) => openDetail(e, item)}
				>
					<img alt="Details" src={ICONedit} />
				</button>
			);
		}
	};

	return (
		<ul className="lstOverview">
			{/* <li className="feedback oepn">
				<div className="boxInteraction open">
					<span className="open">{t('Overview.Open')}</span>
					<button
						className={
							props.data[0].feedback === '' ? 'btnUser2 open' : 'btnUser2'
						}
						style={{ textAlign: 'center' }}
					>
						<span style={{ lineHeight: '43px', marginLeft: 0 }}>8D</span>
					</button>
					<button
						className={
							props.data[0].feedback === '' ? 'btnUser1 open' : 'btnUser1'
						}
					>
						<img alt="Deputy" src={Acknowledged} />
						<p>Acknowledged on 2024-06-21</p>
					</button>
					<button
						className={
							props.data[0].feedback === '' ? 'btnUser open' : 'btnUser'
						}
					>
						<img alt="Deputy" src={ICONdeputy} />
						<p>
							{t('Overview.until1')}
							2024-09-09
							{t('Overview.until2')}
						</p>
					</button>
					{InfoIcon(props.data[0])}
					<button
						className={
							props.expandedAll ? 'btnExpand1 open expanded' : 'btnExpand1 open'
						}
					>
						<img className="expand" alt="Expand" src={ICONdown} />
						<img className="shrink" alt="Shrink" src={ICONup} />
					</button>
				</div>
				<ul className="lstMainElements">
					<li>
						<span>{t('Overview.DueDate')}</span>
						<p>
							<li>
								<span>Due Date</span>
								<p>2024-08-23</p>
							</li>
						</p>
					</li>
					<li>
						<span>{t('Overview.NCNumber')}</span>
						<p>800002084781</p>
					</li>
					<li className="long">
						<span>{t('Overview.ReferenceDoc')}</span>
						<p>4948689710</p>
					</li>
					<li>
						<span>{t('Overview.MainProblem')}</span>
						<p>Delivery Date/Time</p>
					</li>
				</ul>
				<ul className="lstExtraElementsLeft"></ul>
				<ul className="lstExtraElementsLeft shorts"></ul>
			</li>
			<li className="feedback draft">
				<div className="boxInteraction draft">
					<span>{t('Overview.Draft')}</span>
					<button
						className={
							props.data[0].feedback === '' ? 'btnUser2 open' : 'btnUser2'
						}
						style={{ textAlign: 'center' }}
					>
						<span style={{ lineHeight: '43px', marginLeft: 0 }}>8D</span>
					</button>
					<button
						className={
							props.data[0].feedback === '' ? 'btnUser1 open' : 'btnUser1'
						}
					>
						<img alt="Deputy" src={AcknowledgementPending} />
						<p>Acknowledged on 2024-06-21</p>
					</button>
					<button
						className={
							props.data[0].feedback === '' ? 'btnUser open' : 'btnUser'
						}
					>
						<img alt="Deputy" src={ICONdeputy} />
						<p>
							{t('Overview.until1')}
							2024-09-09
							{t('Overview.until2')}
						</p>
					</button>
					{InfoIcon(props.data[0])}
					<button
						className={
							props.expandedAll ? 'btnExpand2 open expanded' : 'btnExpand2 open'
						}
					>
						<img className="expand" alt="Expand" src={ICONdown} />
						<img className="shrink" alt="Shrink" src={ICONup} />
					</button>
				</div>
				<ul className="lstMainElements">
					<li>
						<span>{t('Overview.DueDate')}</span>
						<p>
							<li>
								<span>Due Date</span>
								<p>2024-08-23</p>
							</li>
						</p>
					</li>
					<li>
						<span>{t('Overview.NCNumber')}</span>
						<p>800002084781</p>
					</li>
					<li className="long">
						<span>{t('Overview.ReferenceDoc')}</span>
						<p>4948689710</p>
					</li>
					<li>
						<span>{t('Overview.MainProblem')}</span>
						<p>Delivery Date/Time</p>
					</li>
				</ul>
				<ul className="lstExtraElementsLeft"></ul>
				<ul className="lstExtraElementsLeft shorts"></ul>
			</li>
			<li className="feedback close">
				<div className="boxInteraction closed">
					<span>{t('Overview.Closed')}</span>
					<button
						className={
							props.data[0].feedback === '' ? 'btnUser2 open' : 'btnUser2'
						}
						style={{ textAlign: 'center' }}
					>
						<span style={{ lineHeight: '43px', marginLeft: 0 }}>8D</span>
					</button>
					<button
						className={
							props.data[0].feedback === '' ? 'btnUser1 open' : 'btnUser1'
						}
					>
						<img alt="Deputy" src={Acknowledged} />
						<p>Acknowledged on 2024-06-21</p>
					</button>
					<button
						className={
							props.data[0].feedback === '' ? 'btnUser open' : 'btnUser'
						}
					>
						<img alt="Deputy" src={ICONdeputy} />
						<p>
							{t('Overview.until1')}
							2024-09-09
							{t('Overview.until2')}
						</p>
					</button>
					{InfoIcon(props.data[0])}
					<button
						className={
							props.expandedAll ? 'btnExpand2 open expanded' : 'btnExpand2 open'
						}
					>
						<img className="expand" alt="Expand" src={ICONdown} />
						<img className="shrink" alt="Shrink" src={ICONup} />
					</button>
				</div>
				<ul className="lstMainElements">
					<li>
						<span>{t('Overview.DueDate')}</span>
						<p>
							<li>
								<span>Due Date</span>
								<p>2024-08-23</p>
							</li>
						</p>
					</li>
					<li>
						<span>{t('Overview.NCNumber')}</span>
						<p>800002084781</p>
					</li>
					<li className="long">
						<span>{t('Overview.ReferenceDoc')}</span>
						<p>4948689710</p>
					</li>
					<li>
						<span>{t('Overview.MainProblem')}</span>
						<p>Delivery Date/Time</p>
					</li>
				</ul>
				<ul className="lstExtraElementsLeft"></ul>
				<ul className="lstExtraElementsLeft shorts"></ul>
			</li> */}
			{props.data.map((item, index) => {
				return (
					<li key={index} className={topStyle(item)}>
						<div className={boxInteractionStyle(item)}>
							{(() => {
								if (item.status.includes('DRAFT')) {
									return <span>{t('Overview.Draft')}</span>;
								} else {
									if (item.feedback === '') {
										return <span className="open">{t('Overview.Open')}</span>;
									} else if (item.feedback.toUpperCase() === 'X') {
										return <span>{t('Overview.Closed')}</span>;
									}
								}
							})()}
							{DeputyIcon(item)}
							{InfoIcon(item)}
							<button
								className={
									props.expandedAll
										? 'btnExpand open expanded'
										: 'btnExpand open'
								}
								onClick={(e) => expandItem(e)}
							>
								<img className="expand" alt="Expand" src={ICONdown} />
								<img className="shrink" alt="Shrink" src={ICONup} />
							</button>
						</div>
						<ul className="lstMainElements">
							{duedate(item)}
							<li>
								<span>{t('Overview.NCNumber')}</span>
								<p>{item.qmnum}</p>
							</li>
							<li className="long">
								<span>{t('Overview.ReferenceDoc')}</span>
								<p>{item.refDocNo}</p>
							</li>
							<li>
								<span>{t('Overview.MainProblem')}</span>
								<p>{item.mreason}</p>
							</li>
						</ul>
						<ul className="lstExtraElementsLeft">
							{item.nctype && item.nctype.toUpperCase() === 'Y1' ? (
								<li>
									<span>{t('Overview.nctype')}</span>
									<p>Customer Complaint</p>
								</li>
							) : (
								<Fragment />
							)}
							<li>
								<span>{t('Overview.MaterialDesciption')}</span>
								<p>{item.maktx}</p>
							</li>
							{item.mjust === 'true' ? (
								<li>
									<span>{t('Detail.Reasoning')}</span>
									<p>{item.reasoning}</p>
								</li>
							) : (
								mainCause(item.cause, item.status, item.draftData)
							)}
							{item.mjust === 'true' ? (
								<Fragment />
							) : (
								detailCause(item.cause, item.status, item.draftData)
							)}
							{item.mjust === 'true' ? (
								<Fragment />
							) : (
								mainCorrectiveAction(
									item.corraction,
									item.status,
									item.draftData
								)
							)}
							{item.mjust === 'true' ? (
								<Fragment />
							) : (
								detailCorrectiveAction(
									item.corraction,
									item.status,
									item.draftData
								)
							)}
							<li>
								<span>{t('Overview.InternalReferenceNumber')}</span>
								<p>{item.internalRefNum}</p>
							</li>
							<li>
								<span>{t('Overview.InternalInformation')}</span>
								<p>{item.internalInfo}</p>
							</li>
						</ul>
						<ul className="lstExtraElementsLeft shorts">
							<li>
								<span>{t('Overview.OriginalResponsible')}</span>
								<p>
									<a href={`mailto:${item.responsible}`}>
										{lowWord(item.responsible)}
									</a>
								</p>
							</li>
							{status(item)}
							<li>
								<span>{t('Overview.NCDate')}</span>
								<p>{item.ncdate}</p>
							</li>
							<li>
								<span>{t('Overview.FeedbackSubmitted')}</span>
								<p>
									{item.submissionDate === '0000-00-00' ? (
										<Fragment />
									) : (
										item.submissionDate
									)}
								</p>
							</li>
							<li>
								<span>{t('Overview.Shipto')}</span>
								<p>
									{lineRender(item.recipient)}
									{lineRender(item.street)}
									{lineRender(item.postcode1)}
									{lineRender(item.city1)}
									{lineRender(item.country)}
								</p>
							</li>
						</ul>
					</li>
				);
			})}
		</ul>
	);
});

export default memo(RequestsOverview);
