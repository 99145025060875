import React, { Fragment, memo } from 'react';
import './InputFileUpload.scss';
import { downloadFileByBase64 } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import axios from '../../axios-custom';
import store from '../../state/store';
const { useModel } = store;

const InputFileUploadClosedDown = (
	{ etattachment, qmnum, reportid, targetSystem },
	ref
) => {
	const { t } = useTranslation();

	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading } = dispatchers;

	const convertMB = (limit) => {
		var size = '';
		if (limit < 0.1 * 1024) {
			size = limit.toFixed(2) + ' B';
		} else if (limit < 0.1 * 1024 * 1024) {
			size = (limit / 1024).toFixed(2) + ' KB';
		} else if (limit < 0.1 * 1024 * 1024 * 1024) {
			size = (limit / (1024 * 1024)).toFixed(2) + ' MB';
		} else {
			size = (limit / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
		}

		var sizeStr = size + '';
		var index = sizeStr.indexOf('.');
		var dou = sizeStr.substr(index + 1, 2);
		if (dou === '00') {
			return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
		}
		return size;
	};

	const fileType = (name, error) => {
		let temp = name.split('.');
		let file = temp[temp.length - 1].toUpperCase();
		if (file.includes('DOC') || file.includes('DOCX')) {
			return `ext docx upload ${error}`;
		} else if (file.includes('PDF')) {
			return `ext pdf upload  ${error}`;
		} else if (file.includes('JPG') || file.includes('JPEG')) {
			return `ext jpg upload  ${error}`;
		} else if (file.includes('PNG')) {
			return `ext png upload  ${error}`;
		} else if (file.includes('XLS')) {
			return `ext xlsx upload  ${error}`;
		} else if (file.includes('MSG')) {
			return `ext msg upload  ${error}`;
		} else if (file.includes('PPT')) {
			return `ext ppt upload  ${error}`;
		} else if (file.includes('TXT')) {
			return `ext txt upload  ${error}`;
		} else {
			return `ext docx upload ${error}`;
		}
	};

	const downloadAtachFile = async (filename) => {
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		let obj = {
			ncNumber: qmnum,
			filename: filename,
			imemail: user.email,
			reportId: reportid,
			targetSystem: targetSystem,
		};
		toggleLoading();
		let temp = await axios.post(
			'/feedbackServices/portalSap/downloadAttachment',
			obj
		);
		toggleLoading();
		if (temp.data.byteToString && temp.data.filename) {
			downloadFileByBase64(temp.data.byteToString, temp.data.filename);
		}
	};

	const files2Upload = etattachment.map((file, index) => {
		return (
			<li
				key={index}
				// onClick={() => downloadFileByBase64(file.filedet, file.filename)}
				onClick={() => downloadAtachFile(file.filename)}
			>
				<span className={fileType(file.filename)}></span>

				<div className="details">
					<span className="name">{file.filename}</span>
					<span className="size">{convertMB(file.filesize)}</span>
				</div>
			</li>
		);
	});

	return (
		<Fragment>
			{etattachment.length ? (
				<Fragment>
					<h5 className="centered">{t('Detail.FileUploadOptional')}</h5>
					<div className="boxInputContainer">
						<div className="boxUpload">
							<ul
								className={
									etattachment.length >= 1
										? 'lstFilesfile'
										: 'lstFilesfile empty'
								}
							>
								{files2Upload}
							</ul>
						</div>
					</div>
				</Fragment>
			) : (
				<Fragment />
			)}
		</Fragment>
	);
};

export default memo(InputFileUploadClosedDown);
