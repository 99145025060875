import React, {
	useImperativeHandle,
	useEffect,
	Fragment,
	useState,
	memo,
} from 'react';
import { useReactive, useMemoizedFn } from 'ahooks';
import DropdownList from 'react-dropdown';
// import ICONdown from '../../../assets/icons/arrow-down.svg';
import ICONdownGrey from '../../../assets/icons/arrowdowngrey.svg';
import ICONdownBlue from '../../../assets/icons/arrowdownblue.svg';
import ICONup from '../../../assets/icons/arrow-up.svg';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/pt';
import 'moment/locale/es';
import localeEN from 'antd/es/date-picker/locale/en_US';
import localeFR from 'antd/es/date-picker/locale/fr_FR';
import localeDE from 'antd/es/date-picker/locale/de_DE';
import localePT from 'antd/es/date-picker/locale/pt_PT';
import localeES from 'antd/es/date-picker/locale/es_ES';

const ContentA2 = React.forwardRef(
	({ detailData, qpgrcorrDTO, status, setFalse, fetchDetailData }, ref) => {
		const [startDateData, setStartDateData] = useState([null]);
		const [locale, setLocale] = useState(localeEN);
		const { t, i18n } = useTranslation();
		const [mainClass, setMainClass] = useState([]);
		const [detailClass, setdetailClass] = useState([]);
		const [matxtClass, setmatxtClass] = useState([]);
		const [dateClass, setdateClass] = useState([]);
		const [usrnameClass, setusrnameClass] = useState([]);
		const [lgtextClass, setlgtextClass] = useState([]);

		useEffect(() => {
			if (i18n.language === 'en') {
				setLocale(localeEN);
			} else if (i18n.language === 'de') {
				setLocale(localeDE);
			} else if (i18n.language === 'fr') {
				setLocale(localeFR);
			} else if (i18n.language === 'es') {
				setLocale(localeES);
			} else if (i18n.language === 'pt') {
				setLocale(localePT);
			}
		}, [i18n.language]);

		useEffect(() => {
			if (JSON.stringify(detailData) !== '{}') {
				if (JSON.stringify(fetchDetailData) === '{}') {
					if (qpgrcorrDTO && qpgrcorrDTO.length) {
						addCorrectiveAction();
					}
				} else {
					actionForm.ACAData = [];
					let tempMain = [];
					let tempDetail = [];
					let tempDate = [];
					let tempStartDateData = [];
					let tempmatxt = [];
					let tempusrname = [];
					let templgtext = [];
					if (
						fetchDetailData['corr_code'].length === 0 &&
						fetchDetailData['corr_codegruppe'].length === 0 &&
						fetchDetailData['corr_katalogart'].length === 0 &&
						fetchDetailData['corr_lgtext'].length === 0 &&
						fetchDetailData['corr_matxt'].length === 0 &&
						fetchDetailData['corr_peter'].length === 0 &&
						fetchDetailData['corr_usrname'].length === 0
					) {
						if (qpgrcorrDTO && qpgrcorrDTO.length) {
							addCorrectiveAction();
						}
						return;
					}
					let tempnum = [
						fetchDetailData['corr_code'].length,
						fetchDetailData['corr_codegruppe'].length,
						fetchDetailData['corr_katalogart'].length,
						fetchDetailData['corr_lgtext'].length,
						fetchDetailData['corr_matxt'].length,
						fetchDetailData['corr_peter'].length,
						fetchDetailData['corr_usrname'].length,
					];
					tempnum.sort((a, b) => b - a);
					let num = tempnum[0];
					for (let i = 0; i < num; i++) {
						let obj = {};
						// action
						if (
							fetchDetailData['corr_katalogart'][i] &&
							fetchDetailData['corr_katalogart'][i] !== 'null'
						) {
							let detailActionOptionTempFilter =
								detailData.qpcdcorrDTO.qpctDtoList.filter((item) => {
									return (
										item.codegruppe === fetchDetailData['corr_codegruppe'][i] &&
										item.katalogart === fetchDetailData['corr_katalogart'][i]
									);
								});
							let detailActionOptionTemp = detailActionOptionTempFilter.map(
								(item) => {
									let obj = {
										value: item.code,
										label: item.kurztext,
									};
									return obj;
								}
							);
							let detailActionObjTemp = detailActionOptionTempFilter.find(
								(item) => {
									return item.code === fetchDetailData['corr_code'][i];
								}
							);
							let mainActionObjTemp = qpgrcorrDTO.find((item) => {
								return item.value === fetchDetailData['corr_codegruppe'][i];
							});
							if (mainActionObjTemp) {
								tempMain.push('ddlFeedbackNofilter isFilled');
								tempDetail.push('ddlFeedbackNofilter isFilled');
								//
								obj.mainActionOption = qpgrcorrDTO;
								obj.mainActionLabel = mainActionObjTemp.label;
								obj.mainActionValue = mainActionObjTemp.value;
								obj.mainActionObj = {};
								obj.detailActionOption = detailActionOptionTemp;
								obj.detailActionValue = fetchDetailData['corr_code'][i];
								obj.detailActionObj = detailActionObjTemp;
							} else {
								tempMain.push('ddlFeedbackNofilter');
								tempDetail.push('ddlFeedbackNofilter');
								//
								obj.mainActionOption = qpgrcorrDTO;
								obj.mainActionLabel = '';
								obj.mainActionValue = '';
								obj.mainActionObj = {};
								obj.detailActionOption = [];
								obj.detailActionValue = '';
								obj.detailActionObj = {};
							}
						} else {
							tempMain.push('ddlFeedbackNofilter');
							tempDetail.push('ddlFeedbackNofilter');
							//
							obj.mainActionOption = qpgrcorrDTO;
							obj.mainActionLabel = '';
							obj.mainActionValue = '';
							obj.mainActionObj = {};
							obj.detailActionOption = [];
							obj.detailActionValue = '';
							obj.detailActionObj = {};
						}
						// matet
						obj.matxt = fetchDetailData['corr_matxt'][i]
							? fetchDetailData['corr_matxt'][i]
							: '';
						if (
							fetchDetailData['corr_matxt'][i] &&
							fetchDetailData['corr_matxt'][i] !== ''
						) {
							tempmatxt.push('txaFeedback isFilled');
						} else {
							tempmatxt.push('txaFeedback');
						}
						// usrname
						obj.usrname = fetchDetailData['corr_usrname'][i]
							? fetchDetailData['corr_usrname'][i]
							: '';
						if (
							fetchDetailData['corr_usrname'][i] &&
							fetchDetailData['corr_usrname'][i] !== ''
						) {
							tempusrname.push('txbFeedback isFilled');
						} else {
							tempusrname.push('txbFeedback');
						}
						// peter
						if (
							fetchDetailData['corr_peter'][i] &&
							fetchDetailData['corr_peter'][i] !== ''
						) {
							let peter = moment(
								fetchDetailData['corr_peter'][i],
								'DD/MM/YYYY'
							).format('YYYYMMDD');
							obj.peter = peter;
							tempStartDateData.push(moment(peter));
							tempDate.push('dtpFeedback isFilled');
						} else {
							obj.peter = '';
							tempStartDateData.push(null);
							tempDate.push('dtpFeedback');
						}
						// lgtext
						obj.lgtext = fetchDetailData['corr_lgtext'][i]
							? fetchDetailData['corr_lgtext'][i]
							: '';
						if (
							fetchDetailData['corr_lgtext'][i] &&
							fetchDetailData['corr_lgtext'][i] !== ''
						) {
							templgtext.push('txaFeedback isFilled');
						} else {
							templgtext.push('txaFeedback');
						}
						//
						actionForm.ACAData.push(obj);
					}
					setStartDateData(tempStartDateData);
					setMainClass(tempMain);
					setdetailClass(tempDetail);
					setdateClass(tempDate);
					setusrnameClass(tempusrname);
					setlgtextClass(templgtext);
					setmatxtClass(tempmatxt);
				}
			}
		}, [qpgrcorrDTO, fetchDetailData]); // eslint-disable-line

		useImperativeHandle(ref, () => ({
			actionForm: actionForm,
			mainActionError: (i) => {
				// document.querySelector(`.mainAction${i}`).classList.add("gotError");
				let temp = [...mainClass];
				temp[i] = 'ddlFeedbackNofilter gotError';
				setMainClass(temp);
				// const listItem = document.querySelector(`.mainAction${i}`).parentNode;
				// const error = listItem.getElementsByTagName('span')[0];
				// error.classList.add('visible');
			},
			detailActionError: (i) => {
				// document.querySelector(`.detailAction${i}`).classList.add("gotError");
				let temp = [...detailClass];
				temp[i] = 'ddlFeedbackNofilter gotError';
				setdetailClass(temp);
				// const listItem = document.querySelector(`.detailAction${i}`).parentNode;
				// const error = listItem.getElementsByTagName('span')[0];
				// error.classList.add('visible');
			},
			InputError: (i, type, num) => {
				if (type === 'peter') {
					let temp = [...dateClass];
					temp[i] = 'dtpFeedback gotError';
					setdateClass(temp);
				} else if (type === 'matxt') {
					let temp = [...matxtClass];
					temp[i] = 'txaFeedback gotError';
					setmatxtClass(temp);
				} else if (type === 'usrname') {
					let temp = [...usrnameClass];
					temp[i] = 'txbFeedback gotError';
					setusrnameClass(temp);
				} else if (type === 'lgtext') {
					let temp = [...lgtextClass];
					temp[i] = 'txaFeedback gotError';
					setlgtextClass(temp);
				}
				// const listItem = document.querySelector(`.${type}${i}`).parentNode;
				// const error = listItem.getElementsByTagName('span')[num];
				// error.classList.add('visible');
			},
			clearForm: () => {
				for (let i = 0; i < actionForm.ACAData.length; i++) {
					actionForm.ACAData[i].mainActionValue = '';
					actionForm.ACAData[i].detailActionValue = '';
					actionForm.ACAData[i].matxt = '';
					actionForm.ACAData[i].usrname = '';
					actionForm.ACAData[i].peter = '';
					actionForm.ACAData[i].lgtext = '';
				}
				let temp = [];
				for (let i = 0; i < [...startDateData].length; i++) {
					temp.push(null);
				}
				setStartDateData(temp);
			},
			inputError1000: (val, index, num) => {
				document.querySelector(`.${val}${index}`).classList.remove('isFilled');
				document.querySelector(`.${val}${index}`).classList.add('gotError');
				const listItem = document.querySelector(`.${val}${index}`).parentNode;
				console.log(listItem.getElementsByTagName('span'));
				const error = listItem.getElementsByTagName('span')[num];
				error.classList.add('visible');
			},
		}));

		const actionForm = useReactive({
			ACAData: [],
		});

		const mainActionHandler = useMemoizedFn((e, i) => {
			if (e.value === '') {
				return;
			}
			setFalse();
			let find = detailData.qpgrcorrDTO.qpgtDTOList.find((item) => {
				return item.kurztext === e.label;
			});
			console.log(find);
			console.log(detailData.qpcdcorrDTO.qpctDtoList);
			let filter = detailData.qpcdcorrDTO.qpctDtoList.filter((item) => {
				return (
					item.codegruppe === find.codegruppe &&
					item.katalogart === find.katalogart
				);
			});
			console.log(filter);
			let temp = filter.map((item) => {
				let obj = {
					value: item.code,
					label: item.kurztext,
				};
				return obj;
			});
			console.log(temp);
			actionForm.ACAData[i].mainActionValue = e.value;
			actionForm.ACAData[i].mainActionLabel = e.label;
			actionForm.ACAData[i].detailActionOption = temp;
			actionForm.ACAData[i].detailActionValue = '';
			//
			// document.querySelector(`.mainAction${i}`).classList.remove("gotError");
			let tempClass = [...mainClass];
			tempClass[i] = 'ddlFeedbackNofilter isFilled';
			setMainClass(tempClass);
			//
			let tempDetail = [...detailClass];
			tempDetail[i] = 'ddlFeedbackNofilter';
			setdetailClass(tempDetail);
			// document
			// 	.querySelector(`.mainAction${i}`)
			// 	.parentNode.getElementsByTagName('span')[0]
			// 	.classList.remove('visible');
		});

		const detailActionHandler = useMemoizedFn((e, i) => {
			if (e.value === '') {
				return;
			}
			setFalse();
			let find = detailData.qpcdcorrDTO.qpctDtoList.find((item) => {
				return item.code === e.value;
			});
			console.log(find);
			actionForm.ACAData[i].detailActionObj = find;
			actionForm.ACAData[i].detailActionValue = e.value;
			//
			// document.querySelector(`.detailAction${i}`).classList.remove("gotError");
			let tempClass = [...mainClass];
			tempClass[i] = 'ddlFeedbackNofilter isFilled';
			setdetailClass(tempClass);
			// document
			// 	.querySelector(`.detailAction${i}`)
			// 	.parentNode.getElementsByTagName('span')[0]
			// 	.classList.remove('visible');
		});

		const addCorrectiveAction = useMemoizedFn(() => {
			let obj = {};
			obj.mainActionOption = qpgrcorrDTO;
			obj.mainActionLabel = '';
			obj.mainActionValue = '';
			obj.mainActionObj = {};
			// obj.detailActionOption = [
			// 	{
			// 		value: '',
			// 		label: t('Detail.Selectdetailaction'),
			// 	},
			// ];
			obj.detailActionOption = [];
			obj.detailActionValue = '';
			obj.detailActionObj = {};
			obj.matxt = '';
			obj.usrname = '';
			obj.peter = '';
			obj.lgtext = '';
			actionForm.ACAData.push(obj);
			//
			setStartDateData([...startDateData, null]);
			//
			let tempMain = [...mainClass];
			tempMain.push('ddlFeedbackNofilter');
			setMainClass(tempMain);
			let tempDetail = [...detailClass];
			tempDetail.push('ddlFeedbackNofilter');
			setdetailClass(tempDetail);
			let tempDate = [...dateClass];
			tempDate.push('dtpFeedback');
			setdateClass(tempDate);
			let tempmatxt = [...matxtClass];
			tempmatxt.push('txaFeedback');
			setmatxtClass(tempmatxt);
			let tempusrname = [...usrnameClass];
			tempusrname.push('txbFeedback');
			setusrnameClass(tempusrname);
			let templgtext = [...lgtextClass];
			templgtext.push('txaFeedback');
			setlgtextClass(templgtext);
		});

		const removeCorrectiveAction = useMemoizedFn(() => {
			setFalse();
			actionForm.ACAData.splice(actionForm.ACAData.length - 1, 1);
			// startDate
			const copy = Array.from(startDateData);
			copy.splice(startDateData.length - 1, 1);
			setStartDateData(copy);
			let tempMainClass = [...mainClass];
			tempMainClass.splice(tempMainClass.length - 1, 1);
			setMainClass(tempMainClass);
			let tempDetailClass = [...detailClass];
			tempDetailClass.splice(tempDetailClass.length - 1, 1);
			setdetailClass(tempDetailClass);
			let tempDateClass = [...dateClass];
			tempDateClass.splice(tempDateClass.length - 1, 1);
			setdateClass(tempDateClass);
			let tempusrnameClass = [...usrnameClass];
			tempusrnameClass.splice(tempusrnameClass.length - 1, 1);
			setusrnameClass(tempusrnameClass);
			let tempmatxtClass = [...matxtClass];
			tempmatxtClass.splice(tempmatxtClass.length - 1, 1);
			setmatxtClass(tempmatxtClass);
			let templgtextClass = [...lgtextClass];
			templgtextClass.splice(templgtextClass.length - 1, 1);
			setlgtextClass(templgtextClass);
		});

		const datePickerOnChange = useMemoizedFn((i, date) => {
			setFalse();
			actionForm.ACAData[i].peter = moment(date).format('YYYYMMDD');
			// startDate
			const copy = Array.from(startDateData);
			copy[i] = date;
			setStartDateData(copy);
			// document.querySelector(`.peter${i}`).classList.remove("gotError");
			// document.querySelector(`.peter${i}`).classList.add("isFilled");
			let temp = [...dateClass];
			temp[i] = 'dtpFeedback isFilled';
			setdateClass(temp);
			// document
			// 	.querySelector(`.peter${i}`)
			// 	.parentNode.getElementsByTagName('span')[1]
			// 	.classList.remove('visible');
		});

		const inputChangeHandler = useMemoizedFn((e, i, key, num) => {
			setFalse();
			e.target.classList.remove('gotError');
			e.target.parentNode
				.getElementsByTagName('span')
				[num].classList.remove('visible');
			actionForm.ACAData[i][key] = e.target.value;
			//
			if (key === 'matxt') {
				let temp = [...matxtClass];
				temp[i] = 'txaFeedback isFilled';
				setmatxtClass(temp);
			} else if (key === 'usrname') {
				let temp = [...usrnameClass];
				temp[i] = 'txbFeedback isFilled';
				setusrnameClass(temp);
			} else if (key === 'lgtext') {
				let temp = [...lgtextClass];
				temp[i] = 'txaFeedback isFilled';
				setlgtextClass(temp);
			}
			if (e.target.value !== '') {
				e.target.classList.add('isFilled');
			} else {
				e.target.classList.remove('isFilled');
			}
		});

		const disabledDate = useMemoizedFn((current) => {
			return current < moment().subtract(1, 'day').endOf('day');
		});

		return (
			<div ref={ref}>
				{/* {JSON.stringify(actionForm.ACAData)} */}
				{actionForm.ACAData.map((item, index) => {
					return (
						<div
							className={
								actionForm.ACAData.length < 4 && actionForm.ACAData.length > 1
									? 'border'
									: ''
							}
							key={index}
						>
							{index === 0 ? (
								<h5>{t('Detail.Correctiveaction')}</h5>
							) : (
								<h5>
									{t('Detail.Additionalcorrectiveaction')} {index}
								</h5>
							)}
							<ul className="lstFeedback">
								<li>
									<DropdownList
										disabled={item.mainActionOption.length === 0}
										options={item.mainActionOption}
										className={`${mainClass[index]} mainAction${index}`}
										placeholder={t('Detail.Selectmainaction')}
										menuClassName="options"
										onChange={(event) => mainActionHandler(event, index)}
										controlClassName="lineControl"
										arrowClosed={
											item.mainActionOption.length ? (
												<img
													className="iconDown"
													alt="Expand"
													src={ICONdownBlue}
												/>
											) : (
												<img
													className="iconDown"
													alt="Expand"
													src={ICONdownGrey}
												/>
											)
										}
										arrowOpen={
											<img className="iconUp" alt="Expand" src={ICONup} />
										}
										value={item.mainActionValue}
									/>
									{/* <span className="errInput">Main Action can't be empty.</span> */}
								</li>
								<li>
									<DropdownList
										disabled={item.detailActionOption.length === 0}
										options={item.detailActionOption}
										className={`${detailClass[index]} detailAction${index}`}
										placeholder={t('Detail.Selectdetailaction')}
										menuClassName="options"
										onChange={(event) => detailActionHandler(event, index)}
										controlClassName="lineControl"
										arrowClosed={
											item.detailActionOption.length ? (
												<img
													className="iconDown"
													alt="Expand"
													src={ICONdownBlue}
												/>
											) : (
												<img
													className="iconDown"
													alt="Expand"
													src={ICONdownGrey}
												/>
											)
										}
										arrowOpen={
											<img className="iconUp" alt="Expand" src={ICONup} />
										}
										value={item.detailActionValue}
									/>
									{/* <span className="errInput">
										Detailed Action can't be empty.
									</span> */}
								</li>
								<li>
									<textarea
										className={`${matxtClass[index]} matxt${index}`}
										onChange={(e) => inputChangeHandler(e, index, 'matxt', 0)}
										value={item.matxt}
										placeholder={t('Detail.Describethiscorrectiveaction')}
									/>
									<span className="errInput">{t('Detail.matxt1000')}</span>
								</li>
							</ul>
							<ul className="lstFeedback">
								<li>
									<span>{t('Detail.Responsibility')}</span>
									<input
										type="text"
										id="txb_description"
										className={`${usrnameClass[index]} usrname${index}`}
										placeholder={t('Detail.SpecifyResponsibility')}
										onChange={(e) => inputChangeHandler(e, index, 'usrname', 1)}
										value={item.usrname}
									/>
									<span className="errInput">{t('Detail.usrname1000')}</span>
								</li>
								<li>
									<span>{t('Detail.Duedate')}</span>
									<DatePicker
										locale={locale}
										disabledDate={disabledDate}
										suffixIcon={<Fragment />}
										allowClear={false}
										className={`${dateClass[index]} peter${index}`}
										onChange={(date) => datePickerOnChange(index, date)}
										placeholder={t('Detail.SelectDate')}
										format="YYYY-MM-DD"
										value={startDateData[index]}
										showToday={false}
										inputReadOnly
									/>
									{/* <span className="errInput">Due date can't be empty.</span> */}
								</li>
								<li>
									<span>{t('Detail.Verificationplan')}</span>
									<textarea
										className={`${lgtextClass[index]} lgtext${index}`}
										onChange={(e) => inputChangeHandler(e, index, 'lgtext', 1)}
										value={item.lgtext}
										placeholder={t('Detail.Addyourcomments')}
									/>
									<span className="errInput">{t('Detail.lgtext1000')}</span>
								</li>
							</ul>
						</div>
					);
				})}
				{status === 'open' || status === 'draft' ? (
					<div
						className={
							actionForm.ACAData.length < 4 && actionForm.ACAData.length > 1
								? 'boxActions bordered'
								: 'boxActions mb30'
						}
					>
						{actionForm.ACAData.length > 1 ? (
							<button
								className="btnDeleteArea"
								onClick={() => removeCorrectiveAction()}
							>
								{t('Detail.Delete')}
							</button>
						) : (
							<Fragment>&nbsp;</Fragment>
						)}
						{actionForm.ACAData.length < 4 ? (
							<button
								className="btnAddArea"
								onClick={() => addCorrectiveAction()}
							>
								+ {t('Detail.Addcorrectiveaction')}
							</button>
						) : (
							<Fragment>&nbsp;</Fragment>
						)}
					</div>
				) : (
					<Fragment />
				)}
			</div>
		);
	}
);

export default memo(ContentA2);
