import { useEffect, useRef } from 'react';

function useWatch(dep, callback, config = { immediate: false }) {

	const { immediate } = config;

	const prev = useRef();
	const inited = useRef(false);
	const stop = useRef(false);

	useEffect(() => {
		const execute = () => callback(prev.current);

		if (!stop.current) {
			if (!inited.current) {
				inited.current = true;
				if (immediate) {
					execute();
				}
			} else {
				execute();
			}
			prev.current = dep;
		}
	}, [dep]); // eslint-disable-line react-hooks/exhaustive-deps

	return () => {
		stop.current = true;
	};
}

export default useWatch;