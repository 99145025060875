import React, { Fragment, useEffect, useState, useRef } from 'react';
import IMAGlandingBig from '../../assets/images/landing_big.jpg';
import axios from '../../axios-custom';
import { useHistory } from 'react-router-dom';
import './Landing.scss';
import { useTranslation } from 'react-i18next';
import PendingRequest from './components/PendingRequest';
import UnreadLetter from './components/UnreadLetter';
// import SliderPopup from './components/SliderPopup';
import store from '../../state/store';
import landingRequestEmpty from '../../assets/images/landingRequestEmpty1.png';
import landingLetterEmpty from '../../assets/images/landingLetterEmpty.png';
// import unreadlettersIcon from '../../assets/icons/unread_letters-icon.svg';
import { useBoolean, useMount, useInterval, useReactive } from 'ahooks';
import moment from 'moment-timezone';
const { useModel } = store;

const Landing = ({ bgHandler, isUserLogin }) => {
	const { t } = useTranslation();
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading, changeShowDeputy } = dispatchers;
	const [emptyBgShow, { setFalse }] = useBoolean(true);
	const modalRef = useRef(null);
	//
	const history = useHistory();
	//
	const [toastData, setToastData] = useState([]);
	const [tabs, setTabs] = useState(true);
	const [isToastShow, setIsToastShow] = useState(false);
	const [toastMessage, setToastMessage] = useState('');
	//
	const [interval, setCheckInterval] = useState(undefined);

	const ncData = useReactive({
		pendingData: [],
		letters: [],
	});

	const renderData = useReactive({
		isShown: 0,
	});

	useMount(async () => {
		if (
			!localStorage.getItem('NCM_Token') ||
			localStorage.getItem('NCM_Token') === ''
		) {
			localStorage.removeItem('NCM_Token');
			localStorage.removeItem('NCM_User');
			localStorage.removeItem('NCM_User_bak');
			localStorage.removeItem('NCM_AuthorizationTime');
			localStorage.removeItem('NCM_landing_popup');
			localStorage.removeItem('NCM_searchval');
			localStorage.removeItem('NCM_filterval');
			localStorage.removeItem('NCM_expandedAll');
			localStorage.removeItem('NCM_scrollY');
			localStorage.removeItem('NCM_lockId');
			localStorage.removeItem('NCM_lang_bak');
			history.replace('/');
			setTimeout(() => {
				window.location.reload();
			}, 10);
			return;
		}
		if (
			localStorage.getItem('NCM_AuthorizationTime') &&
			localStorage.getItem('NCM_AuthorizationTime') !== ''
		) {
			let newtime = Date.parse(new Date()).toString();
			newtime = newtime.substr(0, 10);
			const oldtime = localStorage.getItem('NCM_AuthorizationTime');
			if (Number.parseInt(newtime) - Number.parseInt(oldtime) > 12 * 60 * 60) {
				localStorage.removeItem('NCM_Token');
				localStorage.removeItem('NCM_User');
				localStorage.removeItem('NCM_User_bak');
				localStorage.removeItem('NCM_AuthorizationTime');
				localStorage.removeItem('NCM_landing_popup');
				localStorage.removeItem('NCM_searchval');
				localStorage.removeItem('NCM_filterval');
				localStorage.removeItem('NCM_expandedAll');
				localStorage.removeItem('NCM_scrollY');
				localStorage.removeItem('NCM_lockId');
				localStorage.removeItem('NCM_lang_bak');
				history.replace('/');
				setTimeout(() => {
					window.location.reload();
				}, 10);
				return;
			}
		}
	});

	useInterval(
		async () => {
			for (let i = 0; i < toastData.length; i++) {
				let localstartstamp = moment(toastData[i].fromdate).unix();
				let localendstamp = moment(toastData[i].todate).unix();
				let curentstamp = moment().unix();
				if (curentstamp < localstartstamp) {
					setIsToastShow(false);
				} else if (curentstamp > localendstamp) {
					setIsToastShow(false);
				} else {
					setIsToastShow(true);
					//
					if (localStorage.getItem('NCM_lang') === 'en') {
						setToastMessage(toastData[i].enmessage);
					} else if (localStorage.getItem('NCM_lang') === 'de') {
						setToastMessage(toastData[i].demessage);
					} else if (localStorage.getItem('NCM_lang') === 'fr') {
						setToastMessage(toastData[i].frmessage);
					} else if (localStorage.getItem('NCM_lang') === 'es') {
						setToastMessage(toastData[i].esmessage);
					} else if (localStorage.getItem('NCM_lang') === 'pt') {
						setToastMessage(toastData[i].ptmessage);
					}
					return;
				}
			}
		},
		interval,
		{ immediate: true }
	);

	useEffect(() => {
		bgHandler(true, false, IMAGlandingBig, false);
	}, [bgHandler]);

	useEffect(() => {
		let lang = '';
		if (localStorage.getItem('NCM_lang') === 'en') {
			lang = 'E';
		} else if (localStorage.getItem('NCM_lang') === 'de') {
			lang = 'D';
		} else if (localStorage.getItem('NCM_lang') === 'fr') {
			lang = 'F';
		} else if (localStorage.getItem('NCM_lang') === 'es') {
			lang = 'S';
		} else if (localStorage.getItem('NCM_lang') === 'pt') {
			lang = 'P';
		}
		async function fetchData() {
			let user = JSON.parse(localStorage.getItem('NCM_User'));
			toggleLoading();
			let obj = {
				email: user.email,
				lang: lang,
				targetSystem: 'COBALT,EC',
			};
			let objLetter = {
				imemail: user.email,
				imlang: lang,
				targetSystem: 'COBALT,EC',
			};
			const response = await axios.post(
				'/feedbackServices/portalSap/landingPage',
				obj
			);
			const responseLetter = await axios.post(
				'/feedbackServices/portalSap/letterlandingPage',
				objLetter
			);
			console.log(responseLetter);
			//
			const adminData = await axios.get(
				'/feedbackServices/apiUser/getListOfAdminUser'
			);
			//
			let data = await axios.post('/feedbackServices/welcome/display', {
				id: '0d15984b-b2ea-4390-94e8-bc43036b7da1',
			});
			renderData.isShown = data.data.shown ? 1 : 0;
			//
			toggleLoading();
			//
			let adminFind = adminData.data.find((item) => {
				return item.id.toLowerCase() === user.email.toLowerCase();
			});
			if (adminFind) {
				changeShowDeputy(true);
				localStorage.setItem('NCM_showDeputy', '1');
			} else {
				let emailList = [
					'@wrl-ltd.com',
					'@basf.com',
					'@basf-hock.com',
					'@basf-inoac-polyurethanes.co.jp',
					'@basf-petronas.com.my',
					'@basf-sc.com.cn',
					'@basf-ypc.com.cn',
					'@btc-europe.com',
					'@cropdesign.com',
					'@metanomics.de',
					'@metanomics-health.de',
					'@partners.basf.com',
					'@partners.basf-petronas.com.my',
					'@partners.basf-ypc.com.cn',
					'@partners.btc-europe.com',
					'@partners.trinamix.de',
					'@sgbd.com.cn',
					'@slic.com.cn',
					'@toray-basf-pbt.com.my',
					'@trinamix.de',
					'@wiga-transport.de',
				];
				let domainFind = emailList.find((item) => {
					return item.includes(`@${user.email.toLowerCase().split('@')[1]}`);
				});
				if (domainFind) {
					// hide deputy icon
					changeShowDeputy(false);
					localStorage.setItem('NCM_showDeputy', '0');
				} else {
					if (
						response.data.edeputy &&
						response.data.edeputy.toLowerCase() === 'x'
					) {
						// hide deputy icon
						changeShowDeputy(false);
						localStorage.setItem('NCM_showDeputy', '0');
					} else {
						// show deputy icon
						changeShowDeputy(true);
						localStorage.setItem('NCM_showDeputy', '1');
					}
				}
			}
			//

			//
			let newArr = [];
			if (response.data.deputyDetails && response.data.deputyDetails.length) {
				for (let i = 0; i < response.data.deputyDetails.length; i++) {
					response.data.deputyDetails[i].icon = true;
					newArr.push(response.data.deputyDetails[i]);
				}
			}
			if (
				response.data.mainPartnerDetails &&
				response.data.mainPartnerDetails.length
			) {
				for (let i = 0; i < response.data.mainPartnerDetails.length; i++) {
					response.data.mainPartnerDetails[i].icon = false;
					newArr.push(response.data.mainPartnerDetails[i]);
				}
			}
			setFalse();
			ncData.pendingData = newArr;
			//
			let newTempArr = [];
			if (
				responseLetter.data.etdetailsdeputysupl &&
				responseLetter.data.etdetailsdeputysupl.length
			) {
				for (
					let i = 0;
					i < responseLetter.data.etdetailsdeputysupl.length;
					i++
				) {
					responseLetter.data.etdetailsdeputysupl[i].icon = true;
					newTempArr.push(responseLetter.data.etdetailsdeputysupl[i]);
				}
			}
			if (
				responseLetter.data.etdetailsmainpartnersupl &&
				responseLetter.data.etdetailsmainpartnersupl.length
			) {
				for (
					let i = 0;
					i < responseLetter.data.etdetailsmainpartnersupl.length;
					i++
				) {
					responseLetter.data.etdetailsmainpartnersupl[i].icon = false;
					newTempArr.push(responseLetter.data.etdetailsmainpartnersupl[i]);
				}
			}
			setFalse();
			ncData.letters = newTempArr;
			//
			const responsetime = await axios.get(
				'/feedbackServices/api/notofication-infos'
			);
			if (responsetime.data && responsetime.data.length) {
				// console.log(1111, responsetime.data);
				let notification = [];
				for (let i = 0; i < responsetime.data.length; i++) {
					let localstarttime = '';
					let localendtime = '';
					let fromDate = responsetime.data[i].fromDate;
					let toDate = responsetime.data[i].toDate;
					let startIsDst = moment(fromDate).tz('Europe/Berlin').isDST();
					let endIsDst = moment(toDate).tz('Europe/Berlin').isDST();
					let isDst = moment().tz('Europe/Berlin').isDST();
					let diff = moment(
						moment().tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss')
					).diff(moment(moment().format('YYYY-MM-DD HH:mm:ss')), 'second');
					//
					if (isDst) {
						if (diff < 0) {
							if (startIsDst) {
								localstarttime = moment(
									moment(fromDate).subtract(diff, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							} else {
								localstarttime = moment(
									moment(fromDate).subtract(diff - 3600, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							}
							if (endIsDst) {
								localendtime = moment(
									moment(toDate).subtract(diff, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							} else {
								localendtime = moment(
									moment(toDate).subtract(diff - 3600, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							}
						} else if (diff === 0) {
							localstarttime = fromDate;
							localendtime = toDate;
						} else if (diff > 0) {
							if (startIsDst) {
								localstarttime = moment(
									moment(fromDate).add(diff, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							} else {
								localstarttime = moment(
									moment(fromDate).add(diff + 3600, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							}
							if (endIsDst) {
								localendtime = moment(
									moment(toDate).add(diff, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							} else {
								localendtime = moment(
									moment(toDate).add(diff + 3600, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							}
						}
					} else {
						if (diff < 0) {
							if (startIsDst) {
								localstarttime = moment(
									moment(fromDate).subtract(diff + 3600, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							} else {
								localstarttime = moment(
									moment(fromDate).subtract(diff, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							}
							if (endIsDst) {
								localendtime = moment(
									moment(toDate).subtract(diff + 3600, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							} else {
								localendtime = moment(
									moment(toDate).subtract(diff, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							}
						} else if (diff === 0) {
							localstarttime = fromDate;
							localendtime = toDate;
						} else if (diff > 0) {
							if (startIsDst) {
								localstarttime = moment(
									moment(fromDate).add(diff - 3600, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							} else {
								localstarttime = moment(
									moment(fromDate).add(diff, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							}
							if (endIsDst) {
								localendtime = moment(
									moment(toDate).add(diff - 3600, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							} else {
								localendtime = moment(
									moment(toDate).add(diff, 'seconds')
								).format('YYYY-MM-DD HH:mm:ss');
							}
						}
					}
					//
					if (responsetime.data[i].isDeactivate === false) {
						let obj = {};
						obj.enmessage = responsetime.data[i].enmessage;
						obj.demessage = responsetime.data[i].demessage;
						obj.frmessage = responsetime.data[i].frmessage;
						obj.esmessage = responsetime.data[i].esmessage;
						obj.ptmessage = responsetime.data[i].ptmessage;
						obj.fromdate = localstarttime;
						obj.todate = localendtime;
						obj.stamp = moment(localstarttime).unix();
						notification.push(obj);
					}
				}
				notification.sort((a, b) => a.stamp - b.stamp);
				setToastData(notification);
			}
			//
			setCheckInterval(60000);
			//
			if (modalRef && modalRef.current) {
				modalRef.current.showModel();
			}
			//
			if (ncData.pendingData.length === 0 && ncData.letters.length !== 0) {
				setTabs(false);
			}
		}
		if (isUserLogin) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUserLogin]);

	const fetchLetters = async () => {
		let lang = '';
		if (localStorage.getItem('NCM_lang') === 'en') {
			lang = 'E';
		} else if (localStorage.getItem('NCM_lang') === 'de') {
			lang = 'D';
		} else if (localStorage.getItem('NCM_lang') === 'fr') {
			lang = 'F';
		} else if (localStorage.getItem('NCM_lang') === 'es') {
			lang = 'S';
		} else if (localStorage.getItem('NCM_lang') === 'pt') {
			lang = 'P';
		}
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		toggleLoading();
		let objLetter = {
			imemail: user.email,
			imlang: lang,
			targetSystem: 'COBALT,EC',
		};
		const responseLetter = await axios.post(
			'/feedbackServices/portalSap/letterlandingPage',
			objLetter
		);
		toggleLoading();
		//
		let newTempArr = [];
		if (
			responseLetter.data.etdetailsdeputysupl &&
			responseLetter.data.etdetailsdeputysupl.length
		) {
			for (let i = 0; i < responseLetter.data.etdetailsdeputysupl.length; i++) {
				responseLetter.data.etdetailsdeputysupl[i].icon = true;
				newTempArr.push(responseLetter.data.etdetailsdeputysupl[i]);
			}
		}
		if (
			responseLetter.data.etdetailsmainpartnersupl &&
			responseLetter.data.etdetailsmainpartnersupl.length
		) {
			for (
				let i = 0;
				i < responseLetter.data.etdetailsmainpartnersupl.length;
				i++
			) {
				responseLetter.data.etdetailsmainpartnersupl[i].icon = false;
				newTempArr.push(responseLetter.data.etdetailsmainpartnersupl[i]);
			}
		}
		ncData.letters = newTempArr;
	};

	const gotoOverview1 = () => {
		history.push('/overview');
	};

	const gotoOverview2 = () => {
		localStorage.setItem('NCM_Overviewletter', 'show');
		history.push('/overview');
	};

	const gotoLetter = () => {
		setTabs(false);
	};

	return (
		<section className="landing">
			{/* {renderData.isShown === 1 ? <SliderPopup ref={modalRef} /> : <Fragment />} */}
			{/* <SliderPopup ref={modalRef} /> */}
			{(() => {
				if (!emptyBgShow) {
					if (isToastShow) {
						return <h2>{toastMessage}</h2>;
					} else {
						if (tabs) {
							if (ncData.pendingData.length === 0) {
								return <h2>{t('Overview.NoRequest3')}</h2>;
							} else {
								return (
									<h2>
										{t('Landing.PDtitle1')} {t('Landing.PDtitle2')}
									</h2>
								);
							}
						} else {
							if (ncData.letters.length === 0) {
								return <h2>{t('Overview.NoRequest5')}</h2>;
							} else {
								return (
									<h2>
										{t('Landing.URtitle1')} {t('Landing.URtitle2')}
									</h2>
								);
							}
						}
					}
				}
			})()}

			<button
				className={tabs ? 'btnTab current' : 'btnTab'}
				onClick={() => setTabs(true)}
			>
				<span className="name">
					{t('Landing.PendingRequests')}
					{ncData.pendingData.length === 0 ? (
						<Fragment />
					) : (
						<span className="quantity">
							&nbsp;&nbsp;{ncData.pendingData.length}&nbsp;&nbsp;
						</span>
					)}
				</span>
			</button>
			<button
				className={tabs ? 'btnTab' : 'btnTab current'}
				onClick={() => setTabs(false)}
			>
				<span className="name">
					{t('Landing.UnreadLetters')}
					{ncData.letters.length === 0 ? (
						<Fragment />
					) : (
						<span className="quantity">
							&nbsp;&nbsp;{ncData.letters.length}&nbsp;&nbsp;
						</span>
					)}
				</span>
			</button>
			{(() => {
				if (!emptyBgShow) {
					if (tabs) {
						if (ncData.pendingData.length) {
							return (
								<PendingRequest
									tabs={tabs}
									pendingData={ncData.pendingData}
									gotoLetter={gotoLetter}
									letterslength={ncData.letters.length}
								/>
							);
						} else {
							return (
								<div className="requestempty">
									<div className="emptywrap">
										<div className="eimage">
											<img alt="empty" src={landingRequestEmpty} />
										</div>
										<div className="ewrap">
											<span className="etitle">{t('Overview.NoRequest3')}</span>
											<span className="econtent">
												{t('Overview.NoRequest4')}
											</span>
											<button className="ebutton" onClick={gotoOverview1}>
												{t('Header.Overview')}
											</button>
										</div>
									</div>
								</div>
							);
						}
					} else {
						if (ncData.letters.length) {
							return (
								<UnreadLetter
									tabs={tabs}
									letters={ncData.letters}
									fetchLetters={fetchLetters}
								/>
							);
						} else {
							return (
								<div className="requestempty">
									<div className="emptywrap">
										<div className="eimage">
											<img alt="empty" src={landingLetterEmpty} />
										</div>
										<div className="ewrap">
											<span className="etitle">{t('Overview.NoRequest5')}</span>
											<span className="econtent">
												{t('Overview.NoRequest6')}
											</span>
											<button className="ebutton" onClick={gotoOverview2}>
												{t('Header.Overview')}
											</button>
										</div>
									</div>
								</div>
							);
						}
					}
				} else {
					return <Fragment />;
				}
			})()}
		</section>
	);
};

export default Landing;
